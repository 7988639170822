import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import api from 'src/api';
import { useMutation, useQuery } from 'react-query';
import { QUESTIONS } from 'src/api/backendUrl';
import Decision from './Decision';
import TableView from './TableView';
import InitiativeName from './InitiativeName';
import Toolbar from '../../myDocument/Toolbar';
import HistoryView from '../HistoryView';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginTop: '2.36%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  }
}));

const AssessmentView = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const [questions, setQuestion] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
        } else {
          data.status < 300 && navigate(-2);
        }
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const { data: logHistory, refetch: logHistoryReFetch } = useQuery(
    ['score-log-history', { }],
    async ({ }) => {
      const { data: response } = await api.implementation.scoreHistory(id);

      return response;
    },
    { initialData: [] }
  );

  const submit = useMutation((form) => api.implementation.approve(id, form), mutateOption('Assessment'));
  const saveDraft = useMutation((form) => api.implementation.draftAssessment(id, form), mutateOption('Save Draft Assessment'));

  const handleSubmit = () => {
    setIsSubmitting(true);
    const newForm = {
      Comment: '',
      questionAssessments: questions.map((d) => ({
        score: Number(d?.score),
        questionId: d?.questionId
      }))
    };
    submit.mutate(newForm);
  };
  const handleSaveDraft = () => {
    const newForm = {
      Comment: '',
      questionAssessments: questions.map((d) => ({
        score: Number(d?.score),
        questionId: d?.questionId
      }))
    };
    saveDraft.mutate(newForm);
  };

  useQuery(QUESTIONS, async () => {
    const { data: response } = await api.masterData.questionListById(id);
    const list = response?.data;
    const generatedList = list?.map((d) => {
      const max = d.rate3 || d.rate2;
      const points = max.split('-');
      const maxScore = Number(points[points.length - 1]);
      const exist = state?.detail?.find((e) => e.questionId === d.id);
      return {
        ...d,
        questionId: d.id,
        minScore: 1,
        maxScore,
        score: exist?.score || 0,
        id: exist?.id,
        implementationId: exist?.implementationId,
        registrationApprovalId: exist?.registrationApprovalId,
        registrationId: exist?.registrationId,
      };
    });
    setQuestion(generatedList);
    return generatedList;
  }, { initialData: [] });

  const handleChange = (e, questionId, min, max) => {
    if (!e.target.value) {
      setDirty(true);
      const newValue = questions.map((d) => (d.questionId === questionId ? {
        ...d,
        score: null,
        changed: true,
      } : d));
      setQuestion(newValue);
    }
    if (e.target.value && Number(e.target.value) < min) {
      setDirty(true);
      const newValue = questions.map((d) => (d.questionId === questionId ? {
        ...d,
        score: 0,
        changed: true,
      } : d));
      setQuestion(newValue);
    }
    if (e.target.value && Number(e.target.value) > max) {
      setDirty(true);
      const newValue = questions.map((d) => (d.questionId === questionId ? {
        ...d,
        score: max,
        changed: true,
      } : d));
      setQuestion(newValue);
    }
    if (e.target.value && Number(e.target.value) >= min && Number(e.target.value) <= max) {
      setDirty(true);
      const newValue = questions.map((d) => (d.questionId === questionId ? {
        ...d,
        score: Number(e.target.value),
        changed: true,
      } : d));
      setQuestion(newValue);
    }
  };

  const canSubmit = !questions?.filter((d) => d.score === 0)?.length > 0;
  const showEdit = ((state?.isAdminSite || state?.isAdmin) && state?.isWaitingAcknowledge)
    || (state?.isAdmin && state?.isWaitingConfirmation);
  const handleEdit = () => {
    setIsEditing(!isEditing);
  };
  const updateAssessment = useMutation((body) => api.implementation.editAssessment(body), mutateOption('Edit Assessment',
    () => {
      setIsEditing(!isEditing);
      logHistoryReFetch();
    }));
  const handleUpdateAssessment = () => {
    const newForm = questions.filter((d) => (d.changed));
    updateAssessment.mutate(newForm);
  };

  return (
    <Page
      className={classes.root}
      title="Form Assessment"
    >
      <Container maxWidth={false}>
        <Toolbar path="Form Assessment" />
        <Box className={classes.formContainer}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <InitiativeName />
            </Grid>
            <Grid container item>
              <TableView
                disabled={state?.viewOnly && !isEditing}
                questions={questions}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Decision
            disabled={!state?.detail}
            viewOnly={state?.viewOnly}
            handleSubmit={handleSubmit}
            handleSaveDraft={handleSaveDraft}
            dirty={dirty}
            canSubmit={canSubmit}
            showEdit={showEdit}
            isEditing={isEditing}
            handleEdit={handleEdit}
            handleUpdateAssessment={handleUpdateAssessment}
          />
        </Box>
        {
          showEdit && (
            <Box>
              <HistoryView
                data={logHistory}
                isIdeaBlast
                isAssessmentView
              />
            </Box>
          )
        }
      </Container>
    </Page>
  );
};

export default AssessmentView;
