import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Button,
  Box,
  makeStyles
} from '@material-ui/core';

import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  isSubmitter,
  isStatusDraft,
  isEdit,
  canSaveDraft,
  canSubmit,
  canEdit,
  canApprove,
  canRegisterITMNovation,
  canManageAccess,
  setFieldValue,
  submitForm,
  handleSaveAsDraft,
  handleAccessPermissionClick,
  setIsEdit,
  setPromptAction,
  values
}) => {
  const classes = useStyles();

  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };

  const handleBackNav = () => setPromptAction({ needed: false, action: 'close' });

  const handleEdit = (editState) => {
    setFieldValue('isEdit', editState);
    setIsEdit(editState);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {
        canSaveDraft && (
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.label)}
            onClick={() => handleSaveAsDraft({ ...values, action: 'save as draft' })}
          >
            Save As Draft
          </Button>
        )
      }
      {
        canEdit && !isEdit && (
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.label)}
            onClick={() => handleEdit(true)}
          >
            Edit
          </Button>
        )
      }
      {
        isEdit && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to edit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={() => handleSubmit('edit')}
            className={clsx(classes.button, classes.label)}
          >
            Save Edit
          </ButtonConfirmation>
        )
      }
      {
        isEdit && (
          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.button, classes.label)}
            onClick={() => handleEdit(false)}
          >
            Cancel Edit
          </Button>
        )
      }
      {
        isStatusDraft && isSubmitter && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={!canSubmit}
            onConfirm={() => handleSubmit('submit')}
            className={clsx(classes.button, classes.label)}
          >
            Submit
          </ButtonConfirmation>
        )
      }
      {
        canRegisterITMNovation && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to register ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={() => handleSubmit('register')}
            className={clsx(classes.button, classes.label)}
          >
            ITMNovation Convention Registration
          </ButtonConfirmation>
        )
      }
      {
        canApprove && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to approve ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={() => handleSubmit('approve')}
            className={clsx(classes.button, classes.label)}
          >
            Approve
          </ButtonConfirmation>
        )
      }
      <Button
        variant="outlined"
        color="secondary"
        className={clsx(classes.button, classes.outlined, classes.label)}
        onClick={() => handleBackNav()}
      >
        Close
      </Button>
      {
        canManageAccess && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAccessPermissionClick}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Manage Access
          </Button>
        )
      }
    </Box>
  );
};

Decision.propTypes = {
  isSubmitter: PropTypes.bool.isRequired,
  isStatusDraft: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  canSaveDraft: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canApprove: PropTypes.bool.isRequired,
  canRegisterITMNovation: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleSaveAsDraft: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func.isRequired,
  setPromptAction: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Decision;
