import { 
    Box, 
    Grid,
    makeStyles, 
    withStyles,
    Paper, 
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold',
  },
  innerCard: {
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.background.main})`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'first baseline',
    justifyContent: 'start',
    padding: 15,
    width: '9.3%',
    margin: '2% 0 2% 12px'
  },
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 6%',
    marginTop: '2.36%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
    textAlign: 'left'
  },
  boxText: {
    color: theme.palette.common.white,
    fontSize: 36,
    fontWeight: 'bold'
  },
  boxSubText: {
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 'bold'
  },
  tableContainerMd: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    maxHeight: '40rem',
    '&::-webkit-scrollbar': {
      height: '14px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px',
    '& .MuiTableCell-root.MuiTableCell-head': {
      paddingRight: '0',
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '16px',
    },
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
}));

const ProjectDetail = (props) => {
  const classes = useStyles();
  const StyledCell = withStyles(() => ({
    head: {
      backgroundColor: '#EDEEFD',
      borderBottomWidth: 10,
      borderColor: '#F5F6F9',
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 120
    },
    body: {
      fontSize: 14,
      backgroundColor: 'white',
      borderTopWidth: 10,
      borderBottomWidth: 10,
      borderColor: '#F5F6F9'
    }
  }))(TableCell);
  const StyledRow = withStyles(() => ({
    root: {
      borderRadius: 5
    }
  }))(TableRow);
  const { title, total, subtitle, tableHeader, tableData, ...rest } = props;

  return (
    <Box display="flex" flexDirection="column" className={classes.formContainer}>
        <Grid container spacing={4}>    
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.innerCard}>
                <Typography className={classes.boxText}>{total}</Typography>
                <Typography className={classes.boxSubText}>{subtitle}</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TableContainer component={Paper} className={classes.tableContainerMd}>
                    <Table
                    stickyHeader
                    className={classes.table}
                    aria-label="simple-table"
                    >
                        <TableHead className={classes.header}>
                            <StyledRow>
                            {
                                !!tableHeader?.length
                                && tableHeader?.map((item, index) => (
                                <StyledCell key={`th-${index + 1}`}>
                                    {item.label}
                                </StyledCell>
                                ))
                            }
                            </StyledRow>
                        </TableHead>
                        <TableBody className={classes.header}>
                            {
                            !!tableData?.length
                            && tableData?.map((data, index) => (
                                <StyledRow
                                key={`tr-${index + 1}`}
                                style={{ marginBottom: '5px' }}
                                >
                                <>
                                    {
                                    tableHeader?.length
                                    && tableHeader?.map(({ key }, headerIndex) => (
                                        <StyledCell key={`td-${headerIndex + 1}`} align="left">
                                            {data[key]}
                                        </StyledCell>
                                    ))
                                    }
                                </>
                                </StyledRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </Box>
  );
};

export default ProjectDetail;