import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { NAME_LIST } from 'src/api/backendUrl';
import { useQuery } from 'react-query';
import api from 'src/api';
import StyledInput from './StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  formControl: {
    marginTop: theme.spacing(1) + 2,
    '& .MuiSelect-root.Mui-disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
  },
  placeholder: {
    color: '#D0C9D6',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  },
  item: {
    margin: 12,
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '20px',
    '&.Mui-selected': {
      backgroundColor: '#EDEEFD',
      '&:hover': {
        backgroundColor: '#EDEEFD'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 4,
    color: '#6979F8',
    backgroundColor: '#EDEEFD',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: '13px',
    paddingTop: 2,
    paddingBottom: 2,
    height: 'auto'
  },
  listItemPrimary: {
    color: 'black',
    fontSize: 15
  },
  listItemSecondary: {
    color: 'gray',
    fontSize: 12
  }
}));

const UserMultiSelector = ({
  error,
  handleDelete = () => {},
  placeholder = '',
  ...rest
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const { data: userOptions, refetch, isLoading } = useQuery([NAME_LIST, search], async () => {
    const { data: response } = await api.masterData.nameList({
      Filters: `fullName@=${search}`,
      Sorts: 'fullName',
      PageSize: 20 // Workaround to speed up search api
    });
    return response?.data;
  }, { initialData: [], enabled: false });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (search.length >= 3) {
        refetch(search);
      }
    },
    100);
    return () => clearTimeout(timeoutId);
  }, [search, refetch]);

  return (
    <Autocomplete
      multiple
      loading={isLoading}
      options={userOptions.map((d) => ({ memberName: d.fullName, ...d }))}
      getOptionLabel={(option) => option.memberName}
      getOptionSelected={(option, value) => option.memberName === value.memberName}
      inputValue={search}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      {...rest}
      renderTags={(value, getTagProps) => value?.map(
        (option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.memberName}
            label={option.memberName}
            size="small"
            className={classes.chip}
            deleteIcon={<Close color="primary" />}
            onDelete={() => handleDelete(option.memberName)}
          />
        )
      )}
      renderOption={(option) => {
        return (
          <ListItemText>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.listItemPrimary}>
                {option.fullName || option.fullname}
              </Typography>
              <Typography className={classes.listItemSecondary}>
                {option.emailOffice || option.email}
              </Typography>
              <Typography className={classes.listItemSecondary}>
                {option.workLocation}
              </Typography>
            </Box>
          </ListItemText>
        );
      }}
      renderInput={(params) => (
        <StyledInput
          error={error}
          placeholder={placeholder}
          {...params}
        />
      )}
    />
  );
};

UserMultiSelector.propTypes = {
  placeholder: PropTypes.string,
  handleDelete: PropTypes.func,
  error: PropTypes.bool,
};

export default UserMultiSelector;
