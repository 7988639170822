import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import DatePicker from 'src/components/DatePicker';

const IssuedDate = ({
  errors,
  touched,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
}) => {
  const key = 'issuedDate';
  const handleChange = (newValue) => {
    setFieldValue(key, newValue !== 'Invalid Date' ? newValue : '', true).then(() => {
      setFieldTouched(key, true, true);
    });
  };

  return (
    <FormItem
      required
      id={key}
      label="Issued Date"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <DatePicker
        name={key}
        placeholder="DD/MM/YYYY"
        format="DD/MM/YYYY"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

IssuedDate.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default IssuedDate;
