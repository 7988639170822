import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, makeStyles, Typography
} from '@material-ui/core';
import StyledAutoCompletePicker from 'src/components/StyledAutoCompletePicker';
import api from 'src/api';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textTransform: 'uppercase',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    marginTop: theme.spacing(3),
  },
  titleContainer: {
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const CommitteePickerView = ({
  committeeAssigned,
  setFieldValue,
  setFieldTouched,
  values,
}) => {
  const key = 'committees';
  const { id } = useParams();
  const currValue = values[key];
  const classes = useStyles();
  const organizationId = values?.organizationId;
  const { data: committee } = useQuery(['master-committee-list', organizationId], async () => {
    const { data: response } = await api.masterData.committeeListRegister(id, organizationId, { Sorts: 'fullname' });
    return response?.data;
  }, { enabled: !!organizationId, initialData: [] });
  const site = committee?.filter((d) => currValue.findIndex((data) => data?.id === d.id) < 0);
  const setValue = (index, newValue) => {
    const temp = currValue;
    temp[index] = newValue;
    setFieldValue(key, temp, true);
    setFieldTouched(key, true, false);
  };
  const isApproved = (val) => {
    if (val) {
      return committeeAssigned?.find((d) => d.userId === val.id)?.isApproved;
    }
    return false;
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Committee Assignment</Typography>
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>Committee #1</Typography>
        </Box>
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <StyledAutoCompletePicker
          disabled={isApproved(currValue[0])}
          value={currValue[0]}
          onChange={(newVal) => setValue(0, newVal)}
          options={site}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>Committee #2</Typography>
        </Box>
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <StyledAutoCompletePicker
          disabled={isApproved(currValue[1])}
          value={currValue[1]}
          onChange={(newVal) => setValue(1, newVal)}
          options={site}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>Committee #3</Typography>
        </Box>
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <StyledAutoCompletePicker
          disabled={isApproved(currValue[2])}
          value={currValue[2]}
          onChange={(newVal) => setValue(2, newVal)}
          options={site}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={4} xs={12}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>Committee #4</Typography>
        </Box>
      </Grid>
      <Grid item lg={8} md={8} sm={8} xs={12}>
        <StyledAutoCompletePicker
          disabled={isApproved(currValue[3])}
          value={currValue[3]}
          onChange={(newVal) => setValue(3, newVal)}
          options={site}
        />
      </Grid>
    </Grid>
  );
};

CommitteePickerView.propTypes = {
  values: PropTypes.object,
  committeeAssigned: PropTypes.array,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default CommitteePickerView;
