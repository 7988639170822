import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from 'src/api';
import Page from 'src/components/Page';
import Toolbar from 'src/components/Toolbar';
import _ from 'lodash';
import clsx from 'clsx';
import header from './header';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    width: '100%',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const Detail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data: detail } = useQuery(['score-organization-detail', id], async () => {
    const { data: response } = await api.setting.ideaBlast.score.detail(id);
    return _.sortBy(response, ['class']);
  }, { initialData: [] });

  return (
    <Page
      className={classes.root}
      title="Idea Blast Score"
    >
      <Container maxWidth={false}>
        <Toolbar
          title="Idea Blast Score"
          path={state?.name || detail[0]?.organizations?.name}
          parentPath={[{ to: '/app/setting/idea-blast/score', name: 'Idea Blast Score' }]}
        />
        <Grid container item style={{ marginTop: 20 }}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {header?.map((row, index) => {
                    return (
                      <StyledTableCell
                        align="center"
                        key={row?.field}
                        isFirst={index === 0}
                        isLast={index === (header.length - 1)}
                      >
                        <Box
                          className={classes.sortContainer}
                        >
                          {row.field}
                        </Box>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.header}>
                {detail && detail?.map((row) => {
                  return (
                    <StyledTableRow key={row?.id} style={{ marginBottom: '5px' }}>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
                      >
                        {row?.minScore}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                      >
                        {row?.maxScore}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        {row?.class}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        {Number(row?.prize || 0).toLocaleString('id')}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/app/setting/idea-blast/score')}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </Button>
        </Grid>
      </Container>
    </Page>
  );
};

export default Detail;
