import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  InputLabel,
  Grid,
  Hidden,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './style';

const LoginView = () => {
  const { login, setError } = useStoreActions((state) => state.auth);
  const loading = useStoreState((state) => state.loading.isLoading);
  const error = useStoreState((state) => state.auth.error);
  const [showPassword, setShow] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setError({ error: '' });
  }, [setError]);
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Grid container className={classes.container}>
        <Grid item lg={8} md={7} sm={false}>
          <Hidden smDown>
            <Box
              display="flex"
              flexDirection="row"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className={classes.mainImage}
                alt="login"
                src="/static/images/login.png"
              />
            </Box>
          </Hidden>
        </Grid>
        <Grid item lg={4} md={5} sm={12}>
          <Box
            className={classes.form}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              height="15%"
              mb="2.38%"
              className={classes.containerLogo}
            >
              <img
                className={classes.logoImage}
                src="/static/images/logo_inoc.png"
                alt="logo"
              />
            </Box>
            <Box mb="1.56%">
              <Typography className={classes.title}>
                Welcome To Innovation Center
              </Typography>
            </Box>
            <Box mb={error ? '4.6%' : '16.64%'}>
              <Typography className={classes.subtitle}>
                Ignite your idea and let the Innovation Spark!
                <br />
                <a href="../Guidance-inoc.pdf" target="_blank">Please click here for more guidance</a>
              </Typography>
            </Box>
            {error && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                mb="5.13%"
                className={classes.error}
              >
                {error}
              </Box>
            )}
            <Formik
              initialValues={{
                username: '',
                password: ''
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().max(255).required('Username is required'),
                password: Yup.string().max(255).required('Password is required')
              })}
              onSubmit={({ username, password }, actions) => {
                actions.setSubmitting(false);
                login({ username, password });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb="3.08%">
                    <Typography className={classes.signin}>
                      Sign In
                    </Typography>
                  </Box>
                  <Box mb="2.08%">
                    <InputLabel className={classes.caption}>Username</InputLabel>
                    <TextField
                      className={classes.input}
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      placeholder="Enter your username"
                      helperText={touched.username && errors.username}
                      margin="dense"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.username}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              alt="padlock"
                              className={classes.icon}
                              src="/static/icons/ic_username.svg"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box mb="18.33%">
                    <InputLabel className={classes.caption}>Password</InputLabel>
                    <TextField
                      className={classes.input}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      placeholder="Enter your password"
                      helperText={touched.password && errors.password}
                      margin="dense"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              alt="padlock"
                              className={classes.icon}
                              src="/static/icons/ic_padlock.svg"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton color="secondary" onClick={() => setShow(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      className={classes.button}
                      color="secondary"
                      disabled={loading || isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Login
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <Typography
              style={{
                marginTop: 'auto',
                marginLeft: 'auto',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#737AF1'
              }}
            >
              v.
              {' '}
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;
