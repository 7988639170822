import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  toggleOn: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toggleOff: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(12) + 1,
    },
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const history = useLocation();
  const classes = useStyles();
  const { token } = useStoreState((state) => state.auth);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [toggleActive, setToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  const contentRef = useRef();

  const scrollContentToTop = () => {
    contentRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollContentToTop();

    if (!token) {
      navigate('/login', { state: { from: history.pathname } });
    }
  }, [token, history, navigate]);

  return (
    <div
      className={classes.root}
      onClick={() => {
        setIsOpen(false);
        setSeeAll(false);
      }}
    >
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        seeAll={seeAll}
        setSeeAll={setSeeAll}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={() => setDrawerOpen(!isDrawerOpen)}
        openDrawer={() => setDrawerOpen(true)}
        closeDrawer={() => setDrawerOpen(false)}
        toggleActive={toggleActive}
        setToggle={setToggle}
      />
      <div className={clsx(classes.wrapper, {
        [classes.toggleOn]: toggleActive,
        [classes.toggleOff]: !toggleActive,
      })}
      >
        <div className={classes.contentContainer}>
          <div
            ref={contentRef}
            className={classes.content}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
