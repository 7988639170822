import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';

import ButtonConfirmation from 'src/components/ButtonConfirmation';
import ButtonTextFieldConfirmation from 'src/components/ButtonTextFieldConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  isValid,
  isSubmitting,
  values,
  canSaveAsDraft,
  canSubmit,
  canApprove,
  canSentBack,
  canManageAccess,
  setFieldValue,
  submitForm,
  handleSaveAsDraftForm,
  handleApproval,
  handleSentBack,
  handleAccessPermissionClick,
  isEdit,
  canEditAdmin,
  handleOpenEdit,
  handleCloseEdit,
  showRemind,
  canRemind,
  handleRemind,
  setPromptAction
}) => {
  const classes = useStyles();

  const handleBackNav = () => setPromptAction({ needed: false, action: 'close' });

  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };

  const { action, teamMembers, ...form } = values;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {
        canSubmit && (
          <ButtonConfirmation
            variant="contained"
            color="secondary"
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Yes"
            textCancel="No"
            disabled={isSubmitting || !isValid}
            onConfirm={() => handleSubmit('submit')}
            className={clsx(classes.button, classes.label)}
          >
            Submit
          </ButtonConfirmation>
        )
      }
      {
        canSaveAsDraft && (
          <ButtonConfirmation
            variant="contained"
            color="secondary"
            title="Confirmation"
            textDialog="Are you sure you want to save as draft ?"
            textConfirm="Yes"
            textCancel="No"
            onConfirm={() => handleSaveAsDraftForm(form)}
            className={clsx(classes.button, classes.label)}
          >
            Save As Draft
          </ButtonConfirmation>
        )
      }
      {isEdit && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to edit ?"
          textConfirm="Yes"
          textCancel="No"
          disabled={isSubmitting || !isValid}
          onConfirm={() => handleSubmit('edit')}
          className={clsx(classes.button, classes.label)}
        >
          Save Edit
        </ButtonConfirmation>
      )}
      {isEdit && (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleCloseEdit}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Cancel Edit
        </Button>
      )}
      {canEditAdmin && !isEdit && (
        <Button
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleOpenEdit}
          className={clsx(classes.button, classes.label)}
        >
          Edit
        </Button>
      )}
      {canApprove && (
        <ButtonTextFieldConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to approve ?"
          variant="contained"
          color="secondary"
          textConfirm="Yes"
          textCancel="No"
          onConfirm={(comment) => handleApproval(comment)}
          className={clsx(classes.button, classes.label)}
        >
          Approve
        </ButtonTextFieldConfirmation>
      )}
      {canSentBack && (
        <ButtonTextFieldConfirmation
          required
          title="Confirmation"
          textDialog="Are you sure you want to sent back ?"
          variant="contained"
          color="secondary"
          textConfirm="Yes"
          textCancel="No"
          onConfirm={(comment) => handleSentBack(comment)}
          className={clsx(classes.button, classes.label)}
        >
          Send Back
        </ButtonTextFieldConfirmation>
      )}
      <Button
        variant="outlined"
        color="secondary"
        disabled={isSubmitting}
        onClick={() => handleBackNav()}
        className={clsx(classes.button, classes.outlined, classes.label)}
      >
        Close
      </Button>
      {showRemind && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to send approval reminder email ?"
          textConfirm="Yes"
          textCancel="No"
          disabled={!canRemind}
          onConfirm={() => handleRemind()}
          className={clsx(classes.button, classes.label)}
        >
          Send Email Reminder
        </ButtonConfirmation>
      )}
      {canManageAccess && (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleAccessPermissionClick}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Manage Access
        </Button>
      )}
    </Box>
  );
};

Decision.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  canSaveAsDraft: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  canApprove: PropTypes.bool.isRequired,
  canSentBack: PropTypes.bool.isRequired,
  canManageAccess: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleSaveAsDraftForm: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleSentBack: PropTypes.func.isRequired,
  handleAccessPermissionClick: PropTypes.func,
  isEdit: PropTypes.bool,
  canEditAdmin: PropTypes.bool,
  handleOpenEdit: PropTypes.func.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  showRemind: PropTypes.bool,
  canRemind: PropTypes.bool,
  handleRemind: PropTypes.func,
  setPromptAction: PropTypes.func
};

export default Decision;
