import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const RateInvestment = ({
  rate,
  handleBlur,
  handleChange
}) => {
  const rateusd = Number(rate?.rateNow || 0).toLocaleString('en-US', { maximumFractionDigits: 2 });
  return (
    <FormItem
      label="Rate"
      id="rate"
    >
      <StyledInput
        name="rate"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={rateusd || '-'}
        disabled
      />
    </FormItem>
  );
};

RateInvestment.propTypes = {
  rate: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RateInvestment;
