import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Typography,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: 'SFProDisplay',
    fontWeight: 600,
    fontSize: '1.38rem',
    marginBottom: theme.spacing(1)
  },
  breadcumb: {
    fontFamily: 'SFProText',
    fontSize: '0.8rem',
    lineHeight: '18px'
  },
  path: {
    fontWeight: 600,
  },
  subpath: {
    fontWeight: 500,
  }
}));

const Header = ({
  title,
  parentPath,
  currentPath,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {parentPath?.map((path) => (
          <Link
            key={path.name}
            className={clsx(classes.path, classes.breadcumb)}
            color="secondary"
            href={path.to}
          >
            {path.name}
          </Link>
        ))}
        <Typography
          className={clsx(classes.subpath, classes.breadcumb)}
          color="textPrimary"
        >
          {currentPath}
        </Typography>
      </Breadcrumbs>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  parentPath: PropTypes.array,
  currentPath: PropTypes.string
};

export default Header;
