import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import deepEqual from 'deep-equal';
import {
  difference,
  isEmpty,
} from 'lodash';
import {
  Form,
  Formik,
} from 'formik';
import {
  useStore,
  useStoreActions,
  useStoreState,
} from 'easy-peasy';
import {
  Prompt,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useMutation } from 'react-query';

import {
  Box,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import api from 'src/api';

import TabMenu from 'src/components/TabMenu';
import SwipeableViews from 'react-swipeable-views';
import Decision from './Decision';
import TopView from './TopView';
import BottomView from './BottomView';
import {
  initialValues,
  validation,
} from './config';
import TabPanel from '../Tabpanel';
import { getBottomMenus } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  },
  additionalForm: {
    borderRadius: '24px'
  },
}));

const RequirementView = ({
  handleChangeTab,
  handleAccessPermissionClick,
  detail,
  registration,
  approval
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const store = useStore();

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const setFormikValues = useStoreActions((action) => action.formikValues.setFormikValues);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [isEdit, setIsEdit] = useState(false);
  const [dataDetail, setDataDetail] = useState(detail);
  const [bottomTab, setBottomTab] = useState(0);
  const user = useStoreState((state) => state.auth.user);
  const [tabChanged, setTabChanged] = useState(false);
  const [canRemind, setCanRemind] = useState(
    registration?.innovationGateStatuses?.notificationSent === false
  );
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });

  const handleChangeBottomTab = (event, newValue) => {
    setBottomTab(newValue);
    setTabChanged(true);
  };

  const isUserCanApprove = () => {
    if (detail.gateStatus === undefined || detail.gateStatus === 'Draft') return false;

    let isUserCanApproveAsPromotorFacilitator = false;
    let approvalData = [];

    const promotorFacilitatorApproval = approval?.data.filter(
      (item) => item.roles.name.includes('Promotor')
        || item.roles.name.includes('Facilitator')
    );

    const roleApproval = difference(approval?.data, promotorFacilitatorApproval, 'roles.name');

    if (promotorFacilitatorApproval && promotorFacilitatorApproval.length) {
      isUserCanApproveAsPromotorFacilitator = promotorFacilitatorApproval
        .some((item) => !item.isApproved
          && (
            (item.roles.approver && item.roles.approver.length)
            && item.roles.approver.some((userApprover) => userApprover.id === user.id)));
    }

    const isUserCanApproveByRole = roleApproval
      .some((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    approvalData = isUserCanApproveAsPromotorFacilitator
      ? promotorFacilitatorApproval.filter((item) => !item.isApproved)
      : roleApproval.filter((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    return {
      approvalValidation: isUserCanApproveAsPromotorFacilitator || isUserCanApproveByRole,
      approvalData
    };
  };

  useEffect(() => { setDataDetail(detail); }, [detail]);
  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
          return;
        }

        setPromptAction({ needed: false, action: actionMessage });
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const submit = useMutation((form) => api.requirement.create(id, form), mutateOption('Submit'));
  const saveDraft = useMutation((form) => api.requirement.draft(id, form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.requirement.edit(id, form), mutateOption('Edit'));
  const approve = useMutation((form) => api.requirement.approve(id, form), mutateOption('Approve'));
  const createG2 = useMutation(() => api.requirement.createG2(id), mutateOption('Create G2', () => handleChangeTab(2)));
  const cancel = useMutation(() => api.requirement.cancel(id), mutateOption('Cancel'));
  const reactivate = useMutation(() => api.requirement.reactivate(id), mutateOption('Reactivate'));

  const generateForm = (form) => {
    const {
      scheduledPlan,
      digiCoach,
      facilitator,
      formStatus,
      ...rest
    } = form;
    return {
      scheduledPlan: moment(scheduledPlan).format('YYYY-MM-DD'),
      ...formStatus === 'Conventional'
        ? { facilitatorId: facilitator?.id || '' }
        : { digiCoach: digiCoach?.id || '' },
      ...rest
    };
  };
  const handleSubmitForm = (form) => {
    const submitForm = generateForm(form);
    submit.mutate(submitForm);
  };
  const handleSaveDraft = (form) => {
    const draftForm = generateForm(form);
    saveDraft.mutate(draftForm);
  };
  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };
  const handleCreateG2 = async () => {
    createG2.mutate();
  };
  const handleApproval = (comment) => {
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const digitalForm = {
      registrationId: id,
      roleId: userRole.id,
      comment
    };

    approve.mutate(digitalForm);
  };
  const handleCancel = (comment) => {
    const form = {
      Comment: comment,
      registrationComment: comment
    };
    cancel.mutate(form);
  };
  const handleReactivate = (comment) => {
    const form = {
      Comment: comment,
      registrationComment: comment
    };
    reactivate.mutate(form);
  };
  const handleOpenEdit = () => setIsEdit(true);
  const handleCloseEdit = () => setIsEdit(false);
  const handleSubmit = (value, actions) => {
    actions.setSubmitting(true);
    const {
      action, ...form
    } = value;
    switch (action) {
      case 'submit':
        handleSubmitForm(form);
        break;
      case 'edit':
        handleEditAdminForm(form);
        break;
      default:
        break;
    }
    actions.setSubmitting(false);
  };

  const isCanValidateOnMount = !isEmpty(detail) && detail.gateStatus === 'Draft';
  const isValidForBenefitCost = registration?.benefitCostActivate;
  const isDigital = registration?.formCode?.toLowerCase() === 'digital';
  const yupValidation = validation(isDigital).fields;

  // current state isValidForBenefitCost is always true for IPoD category
  const bottomMenus = getBottomMenus(
    {
      ...{
        detail,
        registration,
        isValidForBenefitCost
      }
    }
  );

  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={tabChanged
        ? store.getState()?.formikValues?.formikValues
        : initialValues({ detail: dataDetail, user })}
      initialErrors={validation(isDigital)}
      isInitialValid={() => validation(isDigital).isValidSync(
        tabChanged
          ? store.getState()?.formikValues?.formikValues
          : initialValues({ detail: dataDetail, user })
      )}
      validationSchema={validation(isDigital)}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const isG1 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G1'));
        const isG2 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G2'));
        const isG2Draft = isG2 && (registration?.innovationGateStatuses?.status?.includes('Draft'));
        const isCreator = registration?.user?.id === user?.id;
        const isPO = registration?.userPermissions?.some((item) => item.id === user?.id);
        const organizationId = registration?.organizationId;
        const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
        const isAdminSite = user?.roles.find((d) => d.name === 'Admin Site');
        const isSameSite = user?.cluster?.some((item) => item.id === registration?.organizationId);
        const isAdminOfSite = isAdminSite && isSameSite;
        const isAdminApp = isAdmin && !user.designation;
        const isPIC = user?.roles?.filter((d) => ['DCOE', 'IT', 'Infinity'].indexOf(d.name) > -1);
        const isAdminPIC = !!isPIC.length
          && isPIC.findIndex((d) => d?.name === registration?.roles?.name) > -1;
        const isDraft = ['Draft'].indexOf(detail?.gateStatus) > -1;
        const isWaitingApproval = detail?.gateStatus?.includes('Waiting');
        const isCompleted = ['Completed'].indexOf(detail?.gateStatus) > -1;
        const isCancel = ['Cancelled'].indexOf(detail?.gateStatus) > -1;
        const isOnHold = ['On Hold']
          .indexOf(registration?.innovationGateStatuses?.states?.name) > -1;
        const canEditForm = isG1
          && !isCancel
          && (
            (isDraft && isPO)
            || (isEdit && isAdminApp)
            || (isEdit && isAdminPIC && !isCompleted)
            || (isEdit && isAdminOfSite && !isCompleted)
          );
        const canSubmit = isG1 && !isCancel && !isOnHold && isPO && isDraft;
        const canSaveDraft = isG1 && !isCancel && isPO && isDraft;
        const canEdit = isG1
          && !isCancel
          && !isOnHold
          && !isEdit
          && (
            isAdminApp
            || (isAdminPIC && !isCompleted)
            || (isAdminOfSite && !isCompleted)
          )
          && !isDraft;
        const canReactivate = isG1 && isAdmin && isCancel;
        const canCancel = isG1 && !isCancel && isPO && isWaitingApproval;
        const canApprove = approval?.isCanApprove;
        const canCreateG2 = isG1 && isCompleted && (isPO || isCreator);
        const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
        const showRemind = (isAdminOfSite || isAdmin) && (
          (isG1 && (isWaitingApproval || isCompleted))
          || isG2Draft);

        return (
          <Form onSubmit={formik.handleSubmit}>
            <Box className={classes.formContainer}>
              <BottomView disabled={!canEditForm || isValidForBenefitCost} {...formik} />
              <TopView
                requirementId={detail?.id}
                disabled={!canEditForm}
                organizationId={organizationId}
                validation={yupValidation}
                isDigital={isDigital}
                {...formik}
              />
            </Box>
            <Grid item style={{ marginTop: '2rem' }}>
              <TabMenu
                tab={bottomTab}
                setTab={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
                menus={bottomMenus}
              />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={bottomTab}
                onChangeIndex={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
              >
                {bottomMenus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={bottomTab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component({
                      setFieldValue: formik.setFieldValue,
                      setFieldTouched: formik.setFieldTouched,
                      gate: 'g1'
                    })}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
            <Box mt={5} mb={5}>
              <Decision
                {...{
                  id,
                  user,
                  detail,
                  isEdit,
                  handleOpenEdit,
                  handleCloseEdit,
                  handleApproval,
                  handleSaveDraft,
                  handleCancel,
                  handleReactivate,
                  handleCreateG2,
                  handleRemind,
                  handleAccessPermissionClick,
                  canCreateG2,
                  canSubmit,
                  canSaveDraft,
                  canApprove,
                  canCancel,
                  canEdit,
                  canReactivate,
                  canManageAccess,
                  showRemind,
                  canRemind,
                  setPromptAction
                }}
                {...formik}
              />
            </Box>
            <Prompt
              when={promptAction.needed
                && (canSubmit || isEdit)
                && !deepEqual(formik.initialValues, formik.values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

RequirementView.propTypes = {
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  detail: PropTypes.object,
  registration: PropTypes.object,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  })
};

export default RequirementView;
