import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import PromotorSelector from 'src/components/PromotorSelector';

const Promotor = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
}) => {
  const key = 'promotor';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };
  const message = errors?.promotor?.empId;

  return (
    <FormItem
      label="Promotor / Sponsor"
      id={key}
      error={Boolean(touched[key] && message)}
      helperText={touched[key] && message}
    >
      <PromotorSelector
        creator={values?.submittedById}
        requestor={values?.requestedFor}
        disabled={disabled}
        error={Boolean(touched[key] && message)}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter name/email/department"
      />
    </FormItem>
  );
};

Promotor.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Promotor;
