import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const ActionPlan = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values
}) => {
  const key = 'actionPlan';
  return (
    <FormItem
      label="Action Plan"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        multiline
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        maxrows={10}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter action plan"
      />
    </FormItem>
  );
};

ActionPlan.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ActionPlan;
