import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';

export const initialValues = ({
  id,
  init,
  detail = {}
}) => {
  const {
    registrationNumber,
    innovationAreas,
    innovationBenefits = [],
    innovationTypes,
    classifications,
    themeBanks,
    user,
    initiative,
    initiativeDescription,
    requested,
    category,
    innovationStatus,
    company,
    organizationId,
    strategicPlanId,
    facilitator,
    innovationTeamMembers,
    isTechnology,
    promotor,
    payment,
    diBenefit,
    diYearlyRecurring,
    inBenefit,
    inYearlyRecurring,
    innovationAttachments,
    roles,
    Comment,
    registrationApprovals,
    demandTypeId
  } = detail;
  const alreadyApprove = registrationApprovals?.find((d) => d.userId === init.id);
  const assigned = alreadyApprove?.registrationComment?.roleId;
  const commentApprove = alreadyApprove?.registrationComment?.comment;
  const isWaitingSuperior = innovationStatus === 'Waiting Superior Approval';
  const benefits = id ? _.uniq(innovationBenefits?.map((d) => d.benefitCategoryId)) : [];

  return {
    // general form
    registrationNumber: registrationNumber || '',
    innovationAreaId: innovationAreas?.id || '',
    innovationTypeId: innovationTypes?.id || '',
    classificationId: classifications?.id || '',
    themeBankId: themeBanks?.id || '',
    category: category || '',
    demandTypeId: demandTypeId || '',
    innovationStatus: innovationStatus || '',
    submittedById: id ? user : init,
    requestedFor: requested || {},
    company: company || init.company.name,
    organizationId: organizationId || '',
    strategicPlanId: strategicPlanId || '',
    facilitatorUser: facilitator || {},
    innovationTeamMembers: innovationTeamMembers || [],
    isTechnology: isTechnology || '',
    promotor: promotor || {},
    // estimate investment
    payment: payment || 0,
    initiative: initiative || '',
    initiativeDescription: initiativeDescription || '',
    currentCondition: detail?.currentCondition || '',
    innovationBenefits: benefits,
    actionPlan: detail?.actionPlan || '',
    scheduledPlan: detail?.scheduledPlan
      ? moment(detail?.scheduledPlan).toDate() : moment().toDate(),
    solution: detail?.solution || '',
    diBenefit: diBenefit || 0,
    diYearlyRecurring: diYearlyRecurring || 'Yes',
    inBenefit: inBenefit || 0,
    inYearlyRecurring: inYearlyRecurring || 'Yes',
    innovationAttachments: innovationAttachments || [],
    RoleId: isWaitingSuperior ? (assigned || '') : (roles?.id || ''),
    Comment: isWaitingSuperior ? (commentApprove || '') : (Comment || ''),
    UserAssignId: '',
    action: '',
  };
};

export const validation = {
  // general form
  innovationAreaId: Yup.string().required('Innovation Area is required'),
  innovationTypeId: Yup.string().required('Innovation Type is required'),
  classificationId: Yup.string().required('Classification is required'),
  themeBankId: Yup.string().required('Theme Bank is required'),
  strategicPlanId: Yup.string().required('ITM Strategic Plan is required'),
  facilitatorUser: Yup.object().when('category', {
    is: (category) => category !== 'Individual',
    then: Yup.object().shape({
      empId: Yup.string().required('Scrum Master/Facilitator is required')
    })
  }),
  category: Yup.string().required('Category is required'),
  demandTypeId: Yup.string().when('category', {
    is: (category) => category === 'IT Demand',
    then: Yup.string().required('Demand Type is required')
  }),
  innovationTeamMembers: Yup.array().when('category', {
    is: (category) => category === 'Individual',
    then: Yup.array().min(0).max(3, 'Team member cannot more than 3 person')
  })
    .min(1, 'Team member is required'),
  company: Yup.string().required('Company is required'),
  organizationId: Yup.string().required('Site is required'),
  isTechnology: Yup.string().when('category', {
    is: (category) => category !== 'Individual',
    then: Yup.string().required('Choose one of option available')
  }),
  promotor: Yup.object().shape({
    empId: Yup.string().required('Promotor is required')
  }),
  // estimate investment
  payment: Yup.number().required('Estimate Investment is required'),
  initiative: Yup.string().required('Initiative Name is required'),
  initiativeDescription: Yup.string().required('Initiative Description is required'),
  currentCondition: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Current Condition is required')
  }),
  innovationBenefits: Yup.array().min(1, 'Benefit Category is required'),
  actionPlan: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Action Plan is required')
  }),
  scheduledPlan: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Schedule Plan is required')
  }),
  solution: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Solution is required')
  }),
  diBenefit: Yup.number()
    .min(0, 'Estimate Direct Benefit should be 0 or more')
    .required('Estimate Direct Benefit is required'),
  diYearlyRecurring: Yup.string().required('Choose one of option available'),
  inBenefit: Yup.number()
    .min(0, 'Estimate Indirect Benefit is required')
    .required('Estimate Indirect Benefit is required'),
  inYearlyRecurring: Yup.string().required('Choose one of option available'),
  innovationAttachments: Yup.array().when('category', {
    is: (category) => category !== 'IPod',
    then: Yup.array().min(1, 'Attachment is required')
  })
};
