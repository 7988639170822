import * as Yup from 'yup';

const initialValues = (detail = {}) => {
  const {
    initiative,
    benefitStatus,
    initiativeDescription,
    organizations,
    user,
    facilitator,
    promotor,
    submittedById,
    superiorId,
  } = detail?.registration;

  const { isEdit } = detail;

  const benefits = detail?.benefitCategories;

  return {
    isEdit,
    // GENERAL
    initiative: initiative || '',
    status: benefitStatus,
    initiativeDescription: initiativeDescription || '',
    organizations: organizations?.name,
    leader: user?.fullname,
    facilitator: facilitator?.fullname,
    promotor: promotor?.fullname,
    submittedById,
    // BENEFITS
    benefits: benefits?.length
      ? benefits.map((item) => ({
        ...item,
        amount: item.amount || 0,
        description: item.description || '',
        comment: item.comment || '',
        innovationBenefitAttachments: item.innovationBenefitAttachments || [],
      }))
      : [],
    // For Validation
    superiorId,
  };
};

const validation = (userId) => Yup.object().shape(
  {
    initiative: Yup.string().required('Initiative Name is required'),
    initiativeDescription: Yup.string().required('Initiative Description is required'),
    benefits: Yup.array().of(
      Yup.object().shape({
        approveRole: Yup.string().nullable(),
        amount: Yup.number().when('approveRole', {
          is: (approveRole) => approveRole !== userId,
          then: Yup.number().required('Amount should more than 0')
        }),
        description: Yup.string().when('approveRole', {
          is: (approveRole) => approveRole !== userId,
          then: Yup.string().required('Description is required')
        }),
        innovationBenefitAttachments: Yup.array().min(1, 'Attachments is required')
      })
    )
  }
);

export { initialValues, validation };
