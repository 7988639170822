import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const Status = ({
  handleBlur,
  handleChange,
  values
}) => {
  return (
    <FormItem
      label="Status"
      id="innovationStatus"
    >
      <StyledInput
        name="status"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values.innovationStatus}
        disabled
      />
    </FormItem>
  );
};

Status.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default Status;
