import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import SiteFilter from './SiteFilter';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: fade(theme.palette.background.main, 0.2),
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: 15
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15
  },
  cardTitle: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 'bold'
  },
  innerCard: {
    background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.background.main})`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 15
  },
  percentageText: {
    color: theme.palette.common.white,
    fontSize: 36,
    fontWeight: 'bold'
  },
  employeeText: {
    color: theme.palette.accent.main,
    fontSize: 14,
    fontWeight: 'bold'
  }
}));

const Ecii = (props) => {
  const classes = useStyles();

  const { data } = props;

  return (
    <Box className={classes.filterContainer}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Typography className={classes.title}>ECII</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SiteFilter {...props} />
        </Grid>
      </Grid>

      <Grid container spacing={4} style={{ marginTop: 15 }}>
        {
          (data && data.length) && data.map((item, index) => (
            <Grid item key={`${index + 1}`} lg={4} md={4} sm={12} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                className={classes.card}
              >
                <Grid item container spacing={4} style={{ padding: 15 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className={classes.cardTitle}>{item.title || '-'}</Typography>
                  </Grid>

                  <Grid
                    item
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    className={classes.innerCard}
                  >
                    <Typography className={classes.percentageText}>
                      {item.percentage || 0}
                      %
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    lg={7}
                    md={7}
                    sm={12}
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography className={classes.cardTitle}>OF EMPLOYEE</Typography>
                    <Typography
                      className={classes.employeeText}
                      style={{ marginBottom: 10 }}
                    >
                      {Number(item.employeeTotal || 0).toLocaleString('id-ID')}
                    </Typography>

                    <Typography
                      className={classes.cardTitle}
                      style={{ marginTop: 10 }}
                    >
                      OF EMPLOYEE INVOLVED
                    </Typography>
                    <Typography
                      className={classes.employeeText}
                    >
                      {Number(item.employeeInvolved || 0).toLocaleString('id-ID')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
};

Ecii.propTypes = {
  data: PropTypes.array
};

export default Ecii;
