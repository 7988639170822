import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const RegistrationNumber = ({
  handleBlur,
  handleChange,
  values
}) => {
  return (
    <FormItem
      label="Registration Number"
      id="registration_number"
    >
      <StyledInput
        name="registrationNumber"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values.registrationNumber}
        disabled
      />
    </FormItem>
  );
};

RegistrationNumber.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default RegistrationNumber;
