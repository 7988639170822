import SFProText_Medium from './fonts/SFProText-Medium.ttf';
import SFProText_Regular from './fonts/SFProText-Regular.ttf';
import SFProText_SemiBold from './fonts/SFProText-Semibold.ttf';
import SFProDisplay_SemiBold from './fonts/SFProDisplay-Semibold.ttf';
import PTSans_Bold from './fonts/PTSans-Bold.ttf';
import PTSans_Reguler from './fonts/PTSans-Regular.ttf';

const SFProDisplaySemiBold = {
  fontFamily: 'SFProDisplay',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${SFProDisplay_SemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const SFProTextMedium = {
  fontFamily: 'SFProText',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${SFProText_Medium}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const SFProTextRegular = {
  fontFamily: 'SFProText',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${SFProText_Regular}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const SFProTextSemiBold = {
  fontFamily: 'SFProText',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${SFProText_SemiBold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const PTSansBold = {
  fontFamily: 'PTSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    url(${PTSans_Bold}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const PTSansRegular = {
  fontFamily: 'PTSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
    url(${PTSans_Reguler}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export {
  SFProTextSemiBold,
  SFProTextRegular,
  SFProTextMedium,
  SFProDisplaySemiBold,
  PTSansBold,
  PTSansRegular
};
