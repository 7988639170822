import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInputAutoSize from 'src/components/StyledInputAutoSize';

import { isValidationRequired } from 'src/utils/validation';

const AsComparedTo = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'asCompared';

  return (
    <FormItem
      normalize
      required={isValidationRequired(key, validation)}
      id={key}
      label="As Compared To"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInputAutoSize
        error={Boolean(touched[key] && errors[key])}
        disabled={disabled}
        name={key}
        rowsMin={4}
        type="text"
        placeholder="Enter as compared to"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
      />
    </FormItem>
  );
};

AsComparedTo.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default AsComparedTo;
