import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toUpper } from 'lodash';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Link,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import api from 'src/api';
import DataTable from 'src/components/DataTable';
import fileDownload from 'js-file-download';
import StatusView from 'src/views/registration/StatusView';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  }
}));

const entries = [10, 20, 50, 100, 500, 1000];

const HyperlinkPopup = ({
  year,
  detail,
  isOpen,
  setIsOpen
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isSucceed, setSucceed] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const {
    title,
  } = detail;

  const isVisible = true;

  const switchCases = {
    projectStatus: [
      'project status - queue',
      'project status - in progress',
      'project status - completed'
    ],
    projectByAging: [
      'registration',
      'g1 idea identity',
      'g2 idea sizing',
      'g3 poc',
      'g4 mvp',
      'g5 mmp'
    ],
    projectType: [
      'core process',
      'enterprise process',
      'support process',
      'operational driven',
      'strategic driven'
    ],
  };

  const exportData = async () => {
    let searchParams = '';
    let response;
    const filter = search ? {
      Filters: `search==${search}`,
      PageSize: pageSize,
      Page: currentPage
    } : {
      PageSize: pageSize,
      Page: currentPage
    };
    switch (title.toLowerCase()) {
      case switchCases.projectType[0]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'core process',
        });
        response = await api.dashboard.exportProjectTypeArea(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[1]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'enterprise process',
        });
        response = await api.dashboard.exportProjectTypeArea(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[2]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'support process',
        });
        response = await api.dashboard.exportProjectTypeArea(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[3]:
        searchParams = ({
          ...filter,
          type: 'ProjectType',
          name: 'operational driven',
        });
        response = await api.dashboard.exportProjectTypeArea(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[4]:
        searchParams = ({
          ...filter,
          type: 'ProjectType',
          name: 'strategic driven',
        });
        response = await api.dashboard.exportProjectTypeArea(searchParams, 'arraybuffer');
        break;
      case switchCases.projectStatus[0]:
        searchParams = ({
          ...filter,
          year,
          type: 'Queue',
        });
        response = await api.dashboard.exportProjectStatusIT(searchParams, 'arraybuffer');
        break;
      case switchCases.projectStatus[1]:
        searchParams = ({
          ...filter,
          year,
          type: 'InProgress',
        });
        response = await api.dashboard.exportProjectStatusIT(searchParams, 'arraybuffer');
        break;
      case switchCases.projectStatus[2]:
        searchParams = ({
          ...filter,
          year,
          type: 'Completed',
        });
        response = await api.dashboard.exportProjectStatusIT(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[0]:
        searchParams = ({
          ...filter,
          gate: 'g0',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[1]:
        searchParams = ({
          ...filter,
          gate: 'g1',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[2]:
        searchParams = ({
          ...filter,
          gate: 'g2',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[3]:
        searchParams = ({
          ...filter,
          gate: 'g3',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[4]:
        searchParams = ({
          ...filter,
          gate: 'g4',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      case switchCases.projectByAging[5]:
        searchParams = ({
          ...filter,
          gate: 'g5',
        });
        response = await api.dashboard.exportProjectGateAging(searchParams, 'arraybuffer');
        break;
      default:
        break;
    }
    const fileName = response.headers['content-disposition'].match(/(?:filename=)(.+)(?=;)/gm);

    fileDownload(response?.data, fileName);
  };

  const { data, refetch } = useQuery('hyperlink-popup', async () => {
    let response;
    let searchParams;
    const filter = search ? {
      Filters: `search==${search}`,
      PageSize: pageSize,
      Page: currentPage
    } : {
      PageSize: pageSize,
      Page: currentPage
    };

    switch (title.toLowerCase()) {
      case switchCases.projectType[0]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'core process',
        });
        response = await api.dashboard.projectTypeAreaDetail(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[1]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'enterprise process',
        });
        response = await api.dashboard.projectTypeAreaDetail(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[2]:
        searchParams = ({
          ...filter,
          type: 'ProjectArea',
          name: 'support process',
        });
        response = await api.dashboard.projectTypeAreaDetail(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[3]:
        searchParams = ({
          ...filter,
          type: 'ProjectType',
          name: 'operational driven',
        });
        response = await api.dashboard.projectTypeAreaDetail(searchParams, 'arraybuffer');
        break;
      case switchCases.projectType[4]:
        searchParams = ({
          ...filter,
          type: 'ProjectType',
          name: 'strategic driven',
        });
        response = await api.dashboard.projectTypeAreaDetail(searchParams, 'arraybuffer');
        break;
      case switchCases.projectStatus[0]:
        searchParams = ({
          ...filter,
          year,
          type: 'Queue',
        });
        response = await api.dashboard.projectStatusITList(searchParams);
        break;
      case switchCases.projectStatus[1]:
        searchParams = ({
          ...filter,
          year,
          type: 'InProgress',
        });
        response = await api.dashboard.projectStatusITList(searchParams);
        break;
      case switchCases.projectStatus[2]:
        searchParams = ({
          ...filter,
          year,
          type: 'Completed',
        });
        response = await api.dashboard.projectStatusITList(searchParams);
        break;
      case switchCases.projectByAging[0]:
        searchParams = ({
          ...filter,
          gate: 'g0',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      case switchCases.projectByAging[1]:
        searchParams = ({
          ...filter,
          gate: 'g1',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      case switchCases.projectByAging[2]:
        searchParams = ({
          ...filter,
          gate: 'g2',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      case switchCases.projectByAging[3]:
        searchParams = ({
          ...filter,
          gate: 'g3',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      case switchCases.projectByAging[4]:
        searchParams = ({
          ...filter,
          gate: 'g4',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      case switchCases.projectByAging[5]:
        searchParams = ({
          ...filter,
          gate: 'g5',
        });
        response = await api.dashboard.projectGateAgingDetail(searchParams);
        break;
      default:
        response = null;
        break;
    }

    setTotalData(response?.data?.totalData || 0);
    setTotalPage(response?.data?.totalPage || 0);
    setSucceed(false);
    return response?.data.errors ? [] : response?.data.data;
  }, { initialData: [] });
  useEffect(() => { refetch(); }, [detail, isSucceed, pageSize, currentPage, search]);

  const tableData = data;
  const tableHeader = [
    {
      key: 'no',
      label: 'No.'
    },
    {
      key: 'initiative',
      label: 'Initiative'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'modified',
      label: 'Modified'
    },
    {
      key: 'id',
      label: 'Action'
    }
  ];

  const customChild = ({ key, data, index }) => {
    switch (key) {
      case 'no':
        return index + 1;
      case 'status':
        return (
          <StatusView status={data[key]} showLabel={false} />
        );
      case 'id':
        return (
          <Link href={`${process.env.PUBLIC_URL}/app/registration/${data.id}`} target="_blank" underline="none">Doc. Link</Link>
        );
      case 'initiative':
        if (data[key]?.length > 140) {
          return data[key].slice(0, 140);
        }
        return `${data[key]}...`;

      default:
        return data[key];
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography variant="h3" className={classes.title}>
              {toUpper(title)}
            </Typography>
            <IconButton style={{ marginLeft: 'auto' }} onClick={() => setIsOpen(false)}>
              <Close className={classes.close} />
            </IconButton>
          </Box>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          <DataTable
            hyperlinkPopup
            leftComp={(
              <Button
                variant="contained"
                color="secondary"
                onClick={() => exportData()}
              >
                Export Excel
              </Button>
            )}
            tableHeader={tableHeader}
            tableData={tableData || []}
            {...{
              isVisible,
              entries,
              showEntries,
              setShowEntries,
              pageSize,
              setPageSize,
              setSearch,
              totalData,
              currentPage,
              setCurrentPage,
              totalPage,
              isCustom: true,
              children: customChild
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

HyperlinkPopup.propTypes = {
  year: PropTypes.string,
  detail: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default HyperlinkPopup;
