import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { STATE } from 'src/api/backendUrl';
import api from 'src/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AlertConfirmation from 'src/components/AlertConfirmation';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 2,
  },
}));

const StateChanger = ({
  stage,
  value,
  disabled
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(value);
  const [toBeSelected, setToBeSelected] = React.useState();
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);

  const { data: states } = useQuery(STATE, async () => {
    const { data: response } = await api.masterData.stateList();
    const filter = ['N/A', 'Cancelled'];
    return response?.data?.filter((d) => filter.indexOf(d.name) < 0);
  }, { initialData: [] });

  const getEndpoint = () => {
    switch (stage) {
    case 1:
      return 'requirement';
    case 2:
      return 'study';
    case 3:
      return 'prototype';
    case 5:
      return 'g4implementation';
    default:
      return 'requirement';
    }
  };
  const changeState = useMutation((form) => {
    const endpoint = getEndpoint();
    return api[endpoint].editState(id, form);
  }, {
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        setNotif({
          message: 'Change state success',
          option: {
            variant: 'success'
          }
        });
        setSelected(toBeSelected);
        queryClient.invalidateQueries(['detail', id]);
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || 'Change state failed',
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setToBeSelected(e.target.value);
    handleOpen();
  };

  const handleConfirm = (comment) => {
    changeState.mutate({
      GateStateId: toBeSelected,
      comment
    });
    handleClose();
  };
  const option = states?.find((d) => d.id === toBeSelected);
  return (
    <>
      <FormItem
        id="state-changer"
        label="State"
      >
        <StyledSelect
          containerClass={classes.root}
          disabled={disabled}
          onChange={handleChange}
          value={selected}
          placeholder="Select state"
          options={states || []}
        />
      </FormItem>
      <AlertConfirmation
        title="Confirm"
        textDialog={`Are you sure you want to change state to ${option?.name} ?`}
        textConfirm="Ok"
        textCancel="Cancel"
        onClose={handleClose}
        showTextInput={option?.name === 'On Hold'}
        onConfirm={(text) => handleConfirm(text)}
        open={open}
      />
    </>
  );
};

StateChanger.propTypes = {
  stage: PropTypes.number,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StateChanger;
