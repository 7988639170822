/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

import NumberFormatCustom from 'src/components/CurrencyFormat';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const Value = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'value';
  const isDisabled = values.action === 'detail';

  return (
    <FormItem
      id={key}
      label="Value (USD)"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        name={key}
        placeholder="Enter Value"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isDisabled}
        error={Boolean(touched[key] && errors[key])}
        inputProps={{
          style: {
            textAlign: 'right'
          },
          decimalScale: 3
        }}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />
    </FormItem>
  );
};

Value.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Value;
