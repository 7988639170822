import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { useQuery } from 'react-query';
import { INNOVATION_TYPES } from 'src/api/backendUrl';
import api from 'src/api';

const InnovationType = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
}) => {
  const key = 'innovationTypeId';
  const innovationTypes = useQuery(INNOVATION_TYPES, async () => {
    const { data: response } = await api.masterData.innovationTypes({ Sorts: 'name' });
    return response?.data;
  }, { initialData: [] });

  return (
    <FormItem
      label="Innovation Type"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        placeholder="Select type"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={innovationTypes?.data || []}
      />
    </FormItem>
  );
};

InnovationType.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default InnovationType;
