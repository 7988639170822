import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

const BenefitCategory = ({
  benefitCategoryOptions = [],
  values,
  setFilter
}) => {
  const key = 'benefitCategory';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  const handleOnDelete = (val) => {
    const filteredItems = values[key].filter((item) => item !== val);
    setFilter({
      ...values,
      [key]: filteredItems
    });
  };

  return (
    <FormItem
      id={key}
      label="Benefit Category"
    >
      <StyledMultiSelect
        name={key}
        placeholder="Select benefit category"
        valueFrom="name"
        value={values[key]}
        options={benefitCategoryOptions}
        onChange={handleOnChange}
        handleDelete={handleOnDelete}
      />
    </FormItem>
  );
};

BenefitCategory.propTypes = {
  benefitCategoryOptions: PropTypes.array,
  values: PropTypes.object,
  setFilter: PropTypes.func
};

export default BenefitCategory;
