import * as Yup from 'yup';
import moment from 'moment';

export const initialValues = ({ detail }) => {
  return {
    persona: detail?.persona || '',
    attachments: detail?.g2StudyAttachments?.filter((d) => d.codeAttachment === 'Attachments') || [],
    journeys: detail?.g2StudyAttachments?.filter((d) => d.codeAttachment === 'Journeys') || [],
    architectures: detail?.g2StudyAttachments?.filter((d) => d.codeAttachment === 'Architectures') || [],
    technologyArchitecture: detail?.technologyArchitecture || '',
    platform: detail?.platform || '',
    funding: detail?.funding || '',
    fundCapex: detail?.fundCapex || 0,
    pocDate: detail?.pocDate
      ? moment(detail?.pocDate).toDate()
      : moment().toDate(),
    fundOpex: detail?.fundOpex || 0,
    sprintPlan: detail?.sprintPlan || 1,
    generalDocs: detail?.g2StudyAttachments?.filter((d) => d.codeAttachment === 'General Documents') || [],
    customerJourney: detail?.customerJourney || '',
    g2RecurCost: detail?.g2RecurCost || 0,
    g2RecurBenefit: detail?.g2RecurBenefit || 0,
    g2OnetimeCost: detail?.g2OnetimeCost || 0,
    g2OnetimeBenefit: detail?.g2OnetimeBenefit || 0,
    formStatus: detail?.formStatus || '',
    action: '',
  };
};

export const validation = (isDigital) => Yup.object().shape({
  persona: Yup.string().required('Persona is required'),
  attachments: Yup.array(),
  journeys: Yup.array(),
  architectures: Yup.array(),
  technologyArchitecture: isDigital
    ? Yup.string().required('Technology architecture description is required')
    : Yup.string(),
  platform: isDigital
    ? Yup.string().required('Development Platform is required')
    : Yup.string(),
  funding: Yup.string().required('Funding Resource is required'),
  pocDate: Yup.string().required('Poc Completion Date is required'),
  sprintPlan: Yup.number()
    .min(1, 'Sprint Plan / Sub Task should be 1 or more')
    .required('Sprint Plan / Sub Task is required'),
  fundCapex: Yup.number()
    .min(0, 'Idea Sizing Fund Capex should be 0 or more')
    .required('Idea Sizing Fund Capex is required'),
  fundOpex: Yup.number()
    .min(0, 'Idea Sizing Fund Opex should be 0 or more')
    .required('Idea Sizing Fund Opex is required'),
  generalDocs: Yup.array().min(1, 'General Document is required'),
  customerJourney: Yup.string().required('Customer Journey & Pain Point is required'),
  g2RecurCost: Yup.number()
    .min(0, 'G2 Total Annual Net Recurring Cost should be 0 or more')
    .required('G2 Total Annual Net Recurring Cost is required'),
  g2RecurBenefit: Yup.number()
    .min(0, 'G2 Total Annual Net Recurring Benefit should be 0 or more')
    .required('G2 Total Annual Net Recurring Benefit is required'),
  g2OnetimeCost: Yup.number()
    .min(0, 'G2 Total Annual Net One Time Cost should be 0 or more')
    .required('G2 Total Annual Net One Time Cost is required'),
  g2OnetimeBenefit: Yup.number()
    .min(0, 'G2 Total Annual One Time Net Benefit should be 0 or more')
    .required('G2 Total Annual One Time Net Benefit is required'),
});
