import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles(() => ({
  root: {
    minHeight: 'auto',
    maxWidth: '85vw',
  },
  indicator: {
    visibility: 'hidden'
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: '3.5px 14px',
    backgroundColor: (props) => (props.bright ? '#737AF1' : '#EEECF3'),
    color: (props) => (props.bright ? '#FFFFFF' : '##BEB2C7'),
    textTransform: 'none',
    borderRadius: '8px 8px 0 0',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
      backgroundColor: '#fff',
    },
    '&$selected': {
      backgroundColor: '#fff',
    },
  },
  selected: {
    backgroundColor: '#fff'
  },
}))(({ bright, ...props }) => <Tab disableRipple {...props} />);

const TabMenu = ({
  tab = 0,
  setTab,
  menus,
  bright,
}) => {
  return (
    <StyledTabs
      value={tab}
      onChange={setTab}
      textColor="secondary"
      variant="scrollable"
    >
      {menus.map((menu, index) => (
        <StyledTab
          key={menu.name}
          label={menu.name}
          disabled={menu?.disable}
          bright={bright}
          {...a11yProps(index)}
        />
      ))}
    </StyledTabs>
  );
};

TabMenu.propTypes = {
  tab: PropTypes.number,
  menus: PropTypes.array,
  setTab: PropTypes.func,
  bright: PropTypes.bool
};

export default TabMenu;
