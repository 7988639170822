import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import UserMultiSelector from 'src/components/UserMultiSelector';

import { isValidationRequired } from 'src/utils/validation';

const TeamMember = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  validation
}) => {
  const key = 'newTeamMembers';

  const handleDeleteMember = (value) => {
    const newValue = values[key].filter((val) => val.memberName !== value);
    setFieldValue(key, newValue, true);
    setFieldTouched(key, true, false);
  };
  const handleChangeMember = (e, value) => {
    setFieldValue(key, value, true);
    setFieldTouched(key, true, false);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Team Member"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <UserMultiSelector
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        id={key}
        placeholder={disabled ? '' : 'Enter Team Member'}
        onBlur={handleBlur}
        onChange={handleChangeMember}
        value={values[key]}
        handleDelete={handleDeleteMember}
      />
    </FormItem>
  );
};

TeamMember.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default TeamMember;
