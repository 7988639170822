import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import NumberFormatCustom from 'src/components/NumberFormat';
import StyledInput from 'src/components/StyledInput';

const IDRInvestment = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values
}) => {
  const key = 'payment';
  return (
    <FormItem
      label="IDR"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
        onChange={handleChange}
        value={Number(values[key])}
        placeholder="Enter amount"
      />
    </FormItem>
  );
};

IDRInvestment.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default IDRInvestment;
