import {
  Button,
  Fade, makeStyles, Paper, Popper, Typography
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { GUIDANCE } from 'src/api/backendUrl';
import api from 'src/api';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '20vw',
    padding: theme.spacing(5),
    borderRadius: '16px'
  },
  button: {
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: '22px',
    marginBottom: theme.spacing(3)
  },
  content: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '24px'
  },
}));

const Guidance = () => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const { data: guidance } = useQuery(GUIDANCE, async () => {
    const { data: response } = await api.masterData.guidance({ filters: 'description==guidance,gatestatus==g1' });
    return response?.data[0];
  }, { initialData: {} });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="top-start" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.root}>
              <Typography className={classes.title}>Guidance</Typography>
              <Typography className={classes.content}>
                {guidance?.value}
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button className={classes.button} color="secondary" onClick={handleClick}>Guidance</Button>
    </>
  );
};
Guidance.propTypes = {
  content: PropTypes.string
};
export default Guidance;
