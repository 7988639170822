import moment from 'moment';
import * as Yup from 'yup';

const initialValues = (detail) => {
  const {
    id,
    action,
    initiative,
    tabName,
    documentNumber,
    benefitCostTypeId,
    reportDate,
    value,
    purposeId,
    frequencyId,
    metricTypeId,
    createdAt,
    frequency,
    remark
  } = detail;

  return {
    id,
    action,
    createdAt,
    number: documentNumber || '',
    demand: initiative || '',
    benefitCost: benefitCostTypeId || '',
    reportDate: reportDate
      ? moment(reportDate).toDate()
      : moment().toDate(),
    value: value || 0,
    purpose: purposeId || '',
    frequency: frequencyId || '',
    metricType: metricTypeId || '',
    stageGate: tabName || '',
    type: (frequency ? (frequency.name.toLowerCase().includes('benefit') ? 'Benefit' : 'Cost') : 'Benefit'),
    remark: remark || ''
  };
};

const validation = (detail) => {
  const { reportDate } = detail;

  return {
    createdAt: Yup.string(),
    demand: Yup.string().required('Demand is required'),
    benefitCost: Yup.string().required('Benefit/Cost is required'),
    reportDate: reportDate
      ? Yup.string()
      : Yup.string()
        .test(
          {
            name: 'reportDate',
            // eslint-disable-next-line object-shorthand, func-names
            test: function (value) {
              const { createdAt } = this.parent;
              return value && moment(value).isSameOrBefore(moment(), 'days')
                ? true
                : this.createError({
                  message: `Report Date should be same or after ${moment(createdAt).format('DD/MM/YYYY')}, or same with today, and cannot be empty`,
                  path: 'reportDate'
                });
            }
          }
        ),
    value: Yup.number().moreThan(0, 'Value cannot empty and should more than 0'),
    purpose: Yup.string().required('Purpose is required'),
    frequency: Yup.string().required('Frequency is required'),
    metricType: Yup.string().required('Metric Type is required'),
    stageGate: Yup.string().required('Stage Gate is required'),
    type: Yup.string().required('Choose an option available'),
    remark: Yup.string().required('Remark is required'),
  };
};

export { initialValues, validation };
