import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { uniqBy } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  MenuItem,
  Menu,
  InputBase,
  IconButton,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { DescriptionTwoTone } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CancelIcon from '@material-ui/icons/Cancel';

import api from 'src/api';
import {
  ORGANIZATION_ALL,
  COMPANIES,
  INNOVATION_TYPES,
  STATE,
  BENEFIT_CATEGORIES,
  SUMMARY,
  STAGE_LIST,
  YEAR_BUILT,
  CATEGORIES
} from 'src/api/backendUrl';

import Page from 'src/components/Page';
import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';
import useStyles from './style';

const entries = [10, 20, 50, 100, 500, 1000];

const dataHeader = [
  { field: 'ACTION', width: 200 },
  { field: 'YEAR', width: 200, sort: 'YearBuilt' },
  { field: 'REQUEST NO', width: 200, sort: 'RegistrationNumber' },
  { field: 'INITIATIVE NAME', width: 200, sort: 'Initiative' },
  { field: 'SUBMITTER', width: 200, sort: 'Submitter' },
  { field: 'STAGE', width: 200, sort: 'StageGateName' },
  { field: 'STATUS', width: 200, sort: 'InnovationGateStatuses.Status' },
  { field: 'SITE', width: 200, sort: 'Site' },
  { field: 'CATEGORY', width: 200, sort: 'Category' },
  { field: 'TYPE', width: 200, sort: 'Type' },
  { field: 'THEME BANK', width: 200, sort: 'ThemeBank' },
  { field: 'CLASSIFICATION', width: 200, sort: 'Classification' },
  { field: 'COMPANY', width: 200, sort: 'Company' },
  { field: 'FACILITATOR / SCRUM MASTER', width: 200, sort: 'ScrumMaster' },
  { field: 'UPDATE BY', width: 200, sort: 'UpdatedBy' },
  { field: 'MODIFIED', width: 200, sort: 'UpdatedAt' }
];

const InnovationView = () => {
  const classes = useStyles();

  const innovationFilter = useStoreState((state) => state.innovation.filter);
  const innovationParams = useStoreState((state) => state.innovation.filterParams);
  const innovationTmpFilter = useStoreState((state) => state.innovation.tmpFilter);
  const innovationCurrentPage = useStoreState((state) => state.innovation.currentPage);
  const innovationSort = useStoreState((state) => state.innovation.sort);
  const innovationPageSize = useStoreState((state) => state.innovation.pageSize);
  const setInnovationFilter = useStoreActions((action) => action.innovation.setFilter);
  const setInnovationParams = useStoreActions((action) => action.innovation.setFilterParams);
  const setInnovationTmpFilter = useStoreActions((action) => action.innovation.setTmpFilter);
  const setInnovationCurrentPage = useStoreActions((action) => action.innovation.setCurrentPage);
  const setInnovationSort = useStoreActions((action) => action.innovation.setSort);
  const setInnovationPageSize = useStoreActions((action) => action.innovation.setPageSize);
  const setNavigation = useStoreActions((action) => action.navigation.setPrevious);

  const [showFilter, setShowFilter] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [currentPage, setCurrentPage] = useState(innovationCurrentPage);
  const [headerTable, setHeaderTable] = useState(dataHeader);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [sorts, setSorts] = useState('-updatedAt');
  const strFilter = 'InnovationGateStatusesStatus!=draft';
  const [filters, setFilters] = useState(null);
  const [tmpFilter, setTmpFilter] = useState({});
  const [totalProject, setTotalProject] = useState(0);
  const [totalConventional, setTotalConventional] = useState(0);
  const [totalDigital, setTotalDigital] = useState(0);
  const [totalBenefit, setBenefit] = useState(0);
  const [totalEcii, setTotalEcii] = useState(0);
  const [search, setSearch] = useState('');
  const [innerSearch, setInnerSearch] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);

  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });

  const [allValues, setAllValues] = useState({
    year: [],
    company: [],
    innovation_type: [],
    benefit_categories: [],
    innovation_statuses: [],
    organization: [],
    category: [],
    state: [],
    stage: [],
    benefitValidation: []
  });

  const status = [
    { id: 'Draft', name: 'Draft' },
    { id: 'Waiting Superior Approval', name: 'Waiting Superior Approval' },
    { id: 'Waiting Promotor/Sponsor Approval ', name: 'Waiting Promotor/Sponsor Approval' },
    { id: 'Waiting Facilitator/Scrum Master Approval', name: 'Waiting Facilitator/Scrum Master Approval' },
    { id: 'Waiting Approval', name: 'Waiting Approval' },
    { id: 'Waiting Admin Verify', name: 'Waiting Admin Verify' },
    { id: 'Waiting Admin Acknowledge', name: 'Waiting Admin Acknowledge' },
    { id: 'Waiting Assessment Committee', name: 'Waiting Assessment Committee' },
    { id: 'Waiting Confirmation', name: 'Waiting Confirmation' },
    { id: 'Completed', name: 'Completed' },
    { id: 'Send Back', name: 'Send Back' },
    { id: 'Cancelled', name: 'Cancelled' },
    { id: 'Rejected', name: 'Rejected' }
  ];

  const benefitValidations = [
    { id: 'Draft', name: 'Draft' },
    { id: 'Waiting Verification', name: 'Waiting Verification' },
    { id: 'Verified', name: 'Verified' },
    { id: 'Completed', name: 'Completed' }
  ];

  const groupStage = (stages) => {
    let transformedObj = {};
    const transformedArray = [];
    const ideaBlastData = stages.filter((item) => item.name.includes('Idea Blast'));
    const nonIdeaBlastData = stages.filter((item) => !item.name.includes('Idea Blast'));

    ideaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          ...stage,
          name: stage?.name?.substring(3)
        }
      };

      transformedArray.push(transformedObj);
    });

    nonIdeaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          id: nonIdeaBlastData
            .filter((item) => item.name.includes(stage?.name?.substring(0, 2)))
            .map((item) => item.id)
            .join('|'),
          name: stage?.name?.split('(')[0]
        }
      };

      transformedArray.push(transformedObj);
    });

    return uniqBy(transformedArray, 'name');
  };

  const stage = useQuery([STAGE_LIST, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.stageList(query);

    return response?.data?.length
      ? groupStage(response?.data)
      : [];
  });

  const companies = useQuery([COMPANIES, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.companies(query);
    return response?.data;
  });

  const organization = useQuery([ORGANIZATION_ALL, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.organizationAll(query);
    return response?.data;
  });

  const categories = useQuery([CATEGORIES, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.categories(query);

    return response?.data;
  });

  const benefitCategories = useQuery([BENEFIT_CATEGORIES, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.benefitCategories(query);
    return response?.data;
  });

  const innovationTypes = useQuery([INNOVATION_TYPES, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.innovationTypes(query);
    return response?.data;
  });

  const state = useQuery([STATE, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.stateList(query);
    return response?.data;
  });

  const summary = useQuery(SUMMARY, async () => {
    const currentYear = moment().format('YYYY');
    const { data: { data: responseProjectStatus } } = await api.dashboard.projectStatusAll({
      year: currentYear
    });
    const { data: { data: responseValueCapture } } = await api.dashboard.valueCapture({
      year: currentYear
    });
    const { data: responseEcii } = await api.dashboard.ecii({
      year: currentYear
    });
    setTotalProject(responseProjectStatus?.series[0]?.data[0]);
    setTotalConventional(responseProjectStatus?.series[0]?.data[2]);
    setTotalDigital(responseProjectStatus?.series[0]?.data[1]);
    setBenefit(responseValueCapture?.series[0]?.data[1]);
    setTotalEcii(responseEcii[0]?.percentage);
    return {
      TotalProject: responseProjectStatus?.series[0]?.data[0],
      TotalDigital: responseProjectStatus?.series[0]?.data[1],
      TotalConventional: responseProjectStatus?.series[0]?.data[2],
      TotalECII: responseEcii[0]?.percentage,
      TotalBenefitImpact: responseValueCapture?.series[0]?.data[1]
    };
  });

  const years = useQuery([YEAR_BUILT, { sorts: 'name' }], async ({ queryKey }) => {
    const [key, query] = queryKey;
    const { data: response } = await api.masterData.yearBuilt(query);
    return response?.data;
  });

  const fetchInnovations = async () => {
    if (filters) {
      setIsFetching(true);

      const arrayFilter = filters.split(',');
      const newFilter = arrayFilter?.filter((d) => {
        const equalIndex = d?.lastIndexOf('=');
        return d?.charAt(equalIndex + 1);
      });

      const query = {
        Filters: `${newFilter?.join()},${search && `search==${search}`}`,
        page: currentPage,
        pageSize,
        sorts
      };

      const { data: response } = await api.registration.list(query);

      setTotalData(response?.totalData || 0);
      setTotalPage(response?.totalPage || 0);
      setShowing({
        show: response?.totalData === 0
          ? response?.totalData
          : showing.show,
        to: response?.totalData <= pageSize
          ? response?.totalData
          : showing.to
      });

      setData(response?.data || []);
      setIsFetching(false);
    }
  };

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  const addSpaceInAbbreviation = (value) => {
    return value.replace(/([A-Z])/g, ' $1');
  };
  const dataList = [
    {
      img: '/static/images/project.png',
      status: 'Project Status',
      value: totalProject
    },
    {
      img: '/static/images/digital.png',
      status: 'Strategic/Digital Initiatives Status',
      value: totalDigital
    },
    {
      img: '/static/images/conventional.png',
      status: 'Operational Initiatives Status',
      value: totalConventional
    },
    {
      img: '/static/images/benefit.png',
      status: 'Benefit Impact',
      value: addSpaceInAbbreviation(formatter.format(totalBenefit || 0))
    },
    {
      img: '/static/images/ecii.png',
      status: 'ECII',
      value: `${totalEcii} %`
    }
  ];

  useEffect(() => {
    if (innovationFilter) {
      setAllValues(innovationFilter);
    }

    if (innovationTmpFilter) {
      setTmpFilter(innovationTmpFilter);
    }

    if (innovationParams) {
      setFilters(innovationParams);
    }

    setPageSize(innovationPageSize);
    setSorts(innovationSort);
  }, []);

  useEffect(() => {
    setInnovationFilter(allValues);
    setInnovationParams(filters);
    setInnovationCurrentPage(currentPage);
    setInnovationSort(sorts);
    setInnovationPageSize(pageSize);

    fetchInnovations()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [allValues, filters, pageSize, currentPage, sorts]);

  useEffect(() => {
    fetchInnovations()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [search]);

  useEffect(() => {
    setInnovationTmpFilter(tmpFilter);
  }, [tmpFilter]);

  const download = async () => {
    const query = {
      page: currentPage,
      pageSize,
      filters,
      sorts
    };
    const arrayFilter = filters.split(',');
    const newFilter = arrayFilter?.filter((d) => {
      const equalIndex = d?.lastIndexOf('=');
      return d?.charAt(equalIndex + 1);
    });
    const newQuery = { ...query, filters: newFilter?.join() };
    const responseType = 'blob';
    const { data: exportfile } = await api.registration.download(newQuery, responseType);
    const fileDownload = require('js-file-download');
    const date = new Date();
    const filename = `innovation-registration-${ date.getTime() }.xlsx`;
    fileDownload(exportfile, filename);
  };

  const handleChange = (event, newValue) => {
    let params = {
      company: tmpFilter.company ? tmpFilter.company : [],
      OrganizationId: tmpFilter.OrganizationId ? tmpFilter.OrganizationId : [],
      innovationStatus: tmpFilter.innovationStatus ? tmpFilter.innovationStatus : [],
      YearBuilt: tmpFilter.YearBuilt ? tmpFilter.YearBuilt : [],
      stageId: tmpFilter.stageId ? tmpFilter.stageId : [],
      StateId: tmpFilter.StateId ? tmpFilter.StateId : [],
      InnovationTypeId: tmpFilter.InnovationTypeId ? tmpFilter.InnovationTypeId : [],
      category: tmpFilter.category || [],
      benefitCategory: tmpFilter.benefitCategory ? tmpFilter.benefitCategory : [],
      benefitValidation: tmpFilter.benefitValidation ? tmpFilter.benefitValidation : []
    };

    if (event.target.name === 'company') {
      params.company.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'organization') {
      params.OrganizationId.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'innovation_statuses') {
      params.innovationStatus.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'stage') {
      params.stageId.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'year') {
      params.YearBuilt.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'state') {
      params.StateId.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'innovation_type') {
      params.InnovationTypeId.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'category') {
      params.category.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'benefit_categories') {
      params.benefitCategory.push({ [newValue.props.value]: newValue.props.children });
    } else if (event.target.name === 'benefitValidation') {
      params.benefitValidation.push({ [newValue.props.value]: newValue.props.children });
    }
    setTmpFilter(params);

    const newCompany = params.company.map((d) => Object.values(d)).join('|');
    const newOrganization = params.OrganizationId.map((d) => Object.keys(d)).join('|');
    const newStatus = params.innovationStatus.map((d) => Object.values(d)).join('|');
    const newStage = params.stageId.map((d) => Object.keys(d)).join('|');
    const newYear = params.YearBuilt.map((d) => Object.keys(d)).join('|');
    const newState = params.StateId.map((d) => Object.keys(d)).join('|');
    const newInnovType = params.InnovationTypeId.map((d) => Object.keys(d)).join('|');
    const newCategory = params.category.map((d) => Object.keys(d)).join('|');
    const newBenefitCategory = params.benefitCategory.map((d) => Object.values(d)).join('|');
    const newBenefitValidation = params.benefitValidation.map((d) => Object.values(d)).join('|');
    params = {
      ...params,
      company: newCompany,
      OrganizationId: newOrganization,
      innovationStatus: newStatus,
      stageId: newStage,
      YearBuilt: newYear,
      StateId: newState,
      InnovationTypeId: newInnovType,
      category: newCategory,
      benefitCategory: newBenefitCategory,
      benefitValidation: newBenefitValidation
    };

    let filtering = '';
    if (params.innovationStatus === '') {
      filtering = JSON.stringify(params)
        .replaceAll('"', '')
        .replaceAll(':', '==')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('innovationStatus==', 'innovationStatus!=draft');
    } else {
      filtering = JSON.stringify(params)
        .replaceAll('"', '')
        .replaceAll(':', '==')
        .replaceAll('{', '')
        .replaceAll('}', '');
    }
    filtering = filtering
      .replace('StateId', 'InnovationGateStatuses.StateId')
      .replace('stageId', 'InnovationGateStatusesStage')
      .replace('innovationStatus', 'InnovationGateStatusesStatus');

    setShowing({
      show: 1,
      to: pageSize
    });
    setFilters(filtering);
    setAllValues({ ...allValues, [event.target.name]: event.target.value });
    setCurrentPage(1);
  };

  const clearFilter = (event) => {
    let newValueMultiSelect = [];
    let params = {
      company: tmpFilter.company ? tmpFilter.company : [],
      OrganizationId: tmpFilter.OrganizationId ? tmpFilter.OrganizationId : [],
      innovationStatus: tmpFilter.innovationStatus ? tmpFilter.innovationStatus : [],
      YearBuilt: tmpFilter.YearBuilt ? tmpFilter.YearBuilt : [],
      stageId: tmpFilter.stageId ? tmpFilter.stageId : [],
      StateId: tmpFilter.StateId ? tmpFilter.StateId : [],
      InnovationTypeId: tmpFilter.InnovationTypeId ? tmpFilter.InnovationTypeId : [],
      category: tmpFilter.category || [],
      benefitCategory: tmpFilter.benefitCategory ? tmpFilter.benefitCategory : [],
      benefitValidation: tmpFilter.benefitValidation ? tmpFilter.benefitValidation : []
    };

    if (event === 'year') {
      params.YearBuilt = '';
    } else if (event === 'state') {
      params.StateId = '';
    } else if (event === 'category') {
      params.category = '';
    } else if (event === 'innovation_type') {
      params.InnovationTypeId = '';
    } else if (event.target) {
      if (event.target.name === 'company') {
        newValueMultiSelect = params.company
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.company = newValueMultiSelect;
      } else if (event.target.name === 'organization') {
        newValueMultiSelect = params.OrganizationId
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.OrganizationId = newValueMultiSelect;
      } else if (event.target.name === 'innovation_statuses') {
        newValueMultiSelect = params.innovationStatus
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.innovationStatus = newValueMultiSelect;
      } else if (event.target.name === 'stage') {
        newValueMultiSelect = params.stageId
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.stageId = newValueMultiSelect;
      } else if (event.target.name === 'year') {
        newValueMultiSelect = params.YearBuilt
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.YearBuilt = newValueMultiSelect;
      } else if (event.target.name === 'state') {
        newValueMultiSelect = params.StateId
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.StateId = newValueMultiSelect;
      } else if (event.target.name === 'category') {
        newValueMultiSelect = params.category
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.category = newValueMultiSelect;
      } else if (event.target.name === 'innovation_type') {
        newValueMultiSelect = params.InnovationTypeId
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.InnovationTypeId = newValueMultiSelect;
      } else if (event.target.name === 'benefit_categories') {
        newValueMultiSelect = params.benefitCategory
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.benefitCategory = newValueMultiSelect;
      } else if (event.target.name === 'benefitValidation') {
        newValueMultiSelect = params.benefitValidation
          .filter((d) => Object.keys(d)[0] !== event.target.value);

        params.benefitValidation = newValueMultiSelect;
      }
    }
    setTmpFilter(params);

    const newCompany = params.company.map((d) => Object.values(d)).join('|');
    const newOrganization = params.OrganizationId.map((d) => Object.keys(d)).join('|');
    const newStatus = params.innovationStatus.map((d) => Object.values(d)).join('|');
    const newStage = params.stageId.map((d) => Object.keys(d)).join('|');
    const newYear = params.YearBuilt.map((d) => Object.keys(d)).join('|');
    const newState = params.StateId.map((d) => Object.keys(d)).join('|');
    const newInnovType = params.InnovationTypeId.map((d) => Object.keys(d)).join('|');
    const newCategory = params.category.map((d) => Object.keys(d)).join('|');
    const newBenefitCategory = params.benefitCategory.map((d) => Object.values(d)).join('|');
    const newBenefitValidation = params.benefitValidation.map((d) => Object.values(d)).join('|');
    params = {
      ...params,
      company: newCompany,
      OrganizationId: newOrganization,
      innovationStatus: newStatus,
      stageId: newStage,
      YearBuilt: newYear,
      StateId: newState,
      InnovationTypeId: newInnovType,
      category: newCategory,
      benefitCategory: newBenefitCategory,
      benefitValidation: newBenefitValidation
    };

    let filtering = '';
    if (params.innovationStatus === 'draft' || params.innovationStatus !== '') {
      filtering = JSON.stringify(params).replaceAll('"', '').replaceAll(':', '==').replaceAll('{', '')
        .replaceAll('}', '');
    } else {
      filtering = JSON.stringify(params).replaceAll('"', '').replaceAll(':', '==').replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('innovationStatus==', 'innovationStatus!=draft');
    }
    filtering = filtering
      .replace('StateId', 'InnovationGateStatuses.StateId')
      .replace('stageId', 'InnovationGateStatusesStage')
      .replace('innovationStatus', 'InnovationGateStatusesStatus');

    setShowing({
      show: 1,
      to: pageSize
    });
    setFilters(filtering);
    if (event.target) {
      const newValue = newValueMultiSelect.map((d) => Object.keys(d)[0]);
      setAllValues({ ...allValues, [event.target.name]: newValue });
    } else {
      setAllValues({ ...allValues, [event]: '' });
    }
    setCurrentPage(1);
    setInnerSearch('');
    setSearch('');
  };

  const resetFilter = () => {
    setTmpFilter({});
    setFilters('InnovationGateStatusesStatus!=draft');
    setAllValues({
      year: [],
      company: [],
      innovation_type: [],
      benefit_categories: [],
      innovation_statuses: [],
      organization: [],
      category: [],
      state: [],
      stage: [],
      benefitValidation: []
    });
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setSearch(innerSearch);
  };

  const handleEnterSearch = (ev) => {
    if (ev && ev.key === 'Enter') {
      setSearch(innerSearch);
    }
  };

  const resetSearch = () => {
    setInnerSearch('');
    setSearch('');
  };

  const onFilter = (filter) => {
    setShowFilter(!filter);
  };

  const handleClick = (event) => {
    setShowEntries(event.currentTarget);
  };

  const handleClose = () => {
    setShowEntries(null);
  };

  const changePagination = (event, page) => {
    let first = 1;
    let last = pageSize * page;
    for (let i = 1; i < pageSize * page; i += pageSize) {
      first = i;
    }
    if (totalData > last) {
      last = pageSize * page;
    } else {
      last = totalData;
    }
    setShowing({
      show: first,
      to: last
    });
    setCurrentPage(page);
  };

  const handleItems = (item) => {
    setShowEntries(null);
    setCurrentPage(1);
    setShowing({
      show: 1,
      to: item
    });
    setPageSize(item);
  };

  const sortTable = (sort, index) => {
    if (sort !== 'action') {
      headerTable.forEach((element) => {
        if (element.sort === sort) {
          element.isSort = !element.isSort;
          setSorts(element.isSort ? sort : `-${sort}`);
        } else {
          element.isSort = null;
        }
      });
      setHeaderTable([...headerTable]);
    }
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#EDEEFD',
      borderBottomWidth: 10,
      borderColor: '#F5F6F9',
      paddingTop: 10,
      paddingBottom: 10
    },
    body: {
      fontSize: 14,
      backgroundColor: 'white',
      borderTopWidth: 10,
      borderBottomWidth: 10,
      borderColor: '#F5F6F9'
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      borderRadius: 5
    }
  }))(TableRow);

  const generateStatusColor = (benefitStatus) => {
    switch (true) {
      case benefitStatus === 'Draft':
        return '#D0C9D6';
      case benefitStatus?.includes('Admin'):
        return '#6236FF';
      case benefitStatus?.includes('Waiting'):
        return '#44D7B6';
      case benefitStatus === 'Completed':
        return '#06a137';
      case benefitStatus === 'Send Back':
        return '#f59842';
      case benefitStatus === 'Cancelled':
        return '#f54242';
      case benefitStatus === 'Rejected':
        return '#fa8072';
      default:
        return '#FFFFFF';
    }
  };

  const generateSortIcon = (isSort) => {
    return isSort
      ? (<ArrowDropDownIcon style={{ fontSize: 24, cursor: 'pointer' }} />)
      : (<ArrowDropUpIcon style={{ fontSize: 24, cursor: 'pointer' }} />);
  };

  const trim = (value) => {
    return value?.length > 35 ? `${value.substring(0, 35)}...` : value;
  };

  const gotoDetail = (id) => {
    setNavigation('/app/navigation');

    return id
      ? `/app/registration/${id}`
      : '/app/registration';
  };

  return (
    <Page
      className={classes.root}
      title="Innovation List"
    >
      <Grid container className={classes.container} style={{}}>
        <Grid item lg={12} md={12} sm={false}>
          <Box mb="1.56%">
            <Typography className={classes.title}>
              Innovation List
            </Typography>
            <Typography className={classes.subtitle}>
              Innovation List
            </Typography>
          </Box>
        </Grid>
        <Grid container className={classes.contentSummary} spacing={4} style={{ margin: 'auto', marginBottom: 30 }}>
          {dataList.map((items, index) => {
            return (
              <Grid
                item
                lg={2}
                md={4}
                sm={6}
                xs={12}
                key={index}
                className={classes.box}
                style={{ padding: 20 }}
              >
                <div style={{
                  marginBottom: 15, flexDirection: 'row', display: 'flex', justifyContent: 'space-between'
                }}
                >
                  <img src={items.img} style={{ width: 60, height: 60, marginRight: 10 }} />
                  <Typography className={classes.innovationValue}>
                    {items.value}
                  </Typography>
                </div>
                <Typography className={classes.innovationTitle}>
                  {items.status}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={4} className={classes.boxFilter}>
          <Grid item container spacing={4}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                style={{ borderRadius: 15 }}
              >
                <Typography className={classes.titleFilter} onClick={() => onFilter(showFilter)}>
                  Filter
                </Typography>
                {showFilter === false
                  ? <ArrowLeftIcon style={{ fontSize: 40, cursor: 'pointer' }} onClick={() => onFilter(showFilter)} />
                  : <ArrowDropDownIcon style={{ fontSize: 40, cursor: 'pointer' }} onClick={() => onFilter(showFilter)} />}
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {
                isFetching && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignSelf="end"
                      alignItems="center"
                      marginRight={1}
                      height="100%"
                    >
                      <CircularProgress size={24} style={{ alignSelf: 'center' }} />
                    </Box>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          {showFilter === true
            && (
              <Box p={5}>
                <Grid container spacing={4}>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="YEAR"
                      id="year"
                    >
                      <StyledMultiSelect
                        name="year"
                        placeholder="Select year"
                        onChange={handleChange}
                        value={allValues.year}
                        options={years?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'year', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="SITE"
                      id="organization"
                    >
                      <StyledMultiSelect
                        name="organization"
                        placeholder="Select site"
                        onChange={handleChange}
                        value={allValues.organization}
                        options={organization?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'organization', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="CATEGORY"
                      id="category"
                    >
                      <StyledMultiSelect
                        valueFrom="key"
                        name="category"
                        placeholder="Select category"
                        onChange={handleChange}
                        value={allValues.category}
                        options={categories?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'category', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="BENEFIT CATEGORY"
                      id="benefit_categories"
                    >
                      <StyledMultiSelect
                        name="benefit_categories"
                        placeholder="Select category"
                        onChange={handleChange}
                        value={allValues.benefit_categories}
                        options={benefitCategories?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'benefit_categories', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="STAGE"
                      id="stage"
                    >
                      <StyledMultiSelect
                        name="stage"
                        placeholder="Select stage"
                        onChange={handleChange}
                        value={allValues.stage}
                        options={stage?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'stage', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="STATUS"
                      id="innovation_statuses"
                    >
                      <StyledMultiSelect
                        name="innovation_statuses"
                        placeholder="Select status"
                        onChange={handleChange}
                        value={allValues.innovation_statuses}
                        options={status}
                        handleDelete={(value) => clearFilter({ target: { name: 'innovation_statuses', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="TYPE"
                      id="type"
                    >
                      <StyledMultiSelect
                        name="innovation_type"
                        placeholder="Select type"
                        onChange={handleChange}
                        value={allValues.innovation_type}
                        options={innovationTypes?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'innovation_type', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="STATE"
                      id="state"
                    >
                      <StyledMultiSelect
                        name="state"
                        placeholder="Select state"
                        onChange={handleChange}
                        value={allValues.state}
                        options={state?.data || []}
                        handleDelete={(value) => clearFilter({ target: { name: 'state', value } })}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12} style={{}}>
                    <FormItem
                      label="BENEFIT VALIDATION"
                      id="benefitValidation"
                    >
                      <StyledMultiSelect
                        name="benefitValidation"
                        placeholder="Select benefit validation"
                        onChange={handleChange}
                        value={allValues.benefitValidation}
                        options={benefitValidations}
                        handleDelete={(value) => clearFilter({ target: { name: 'benefitValidation', value } })}
                      />
                    </FormItem>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 15, marginTop: 5 }}
                >
                  <Grid
                    item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isFetching}
                      style={{ alignSelf: 'self-end' }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Grid>
        <Grid container spacing={4} style={{ marginTop: 20, marginBottom: 20, margin: 'auto', }}>
          <Grid
            item
            lg={1}
            md={6}
            sm={12}
            xs={12}
            style={{}}
          >
            <Button
              component={RouterLink}
              to={gotoDetail()}
              className={classes.button}
              color="secondary"
              size="large"
              type="submit"
              variant="contained"
              fullWidth
            >
              CREATE
            </Button>
          </Grid>
          <Grid item lg={1} md={6} sm={12} xs={12} style={{}}>
            <Button
              className={classes.button}
              style={{}}
              color="secondary"
              size="large"
              type="submit"
              variant="outlined"
              fullWidth
              disabled={data?.length === 0}
              onClick={() => download()}
            >
              DOWNLOAD
            </Button>
          </Grid>
          <Grid item lg={7} md={6} sm={12} xs={12} style={{ margin: 'auto' }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              style={{}}
            >
              <Typography className={classes.innovationTitle}>
                Show
              </Typography>
              <Box className={classes.boxInputNumber} onClick={handleClick}>
                <div style={{
                  display: 'flex',
                  height: 28,
                  marginRight: 2,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  <UnfoldMoreIcon />
                </div>
                <div>
                  <Typography className={classes.inputNumber}>
                    {pageSize}
                  </Typography>
                </div>
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={showEntries}
                keepMounted
                open={Boolean(showEntries)}
                onClose={handleClose}
              >
                {entries.map((entree, index) => (
                  <MenuItem key={index} onClick={() => handleItems(entree)}>{entree}</MenuItem>
                ))}
              </Menu>
              <Typography className={classes.innovationTitle}>
                Entries
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12} style={{ padding: 10 }}>
            <div className={classes.search} style={{}}>
              <InputBase
                name="search"
                placeholder="Search…"
                fullWidth
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={innerSearch}
                endAdornment={(
                  <>
                    { search
                      && (
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => resetSearch()}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    <IconButton style={{ padding: 0 }} onClick={() => handleSearch()}>
                      <SearchIcon />
                    </IconButton>
                  </>
                )}
                inputProps={{ 'aria-label': 'search', color: '#D0C9D6' }}
                onChange={(e) => setInnerSearch(e.target.value)}
                onKeyDown={(ev) => handleEnterSearch(ev)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <TableContainer component={Paper} className={classes.tableContainerMd}>
            <Table
              stickyHeader
              aria-label="simple table"
              className={classes.table}
            >
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {headerTable.map((row, index) => {
                    return (
                      <StyledTableCell
                        key={index}
                        style={{
                          borderTopLeftRadius: index === 0 ? 10 : 0,
                          borderBottomLeftRadius: index === 0 ? 17 : 0,
                          borderTopRightRadius: index === headerTable.length - 1 ? 10 : 0,
                          borderBottomRightRadius: index === headerTable.length - 1 ? 17 : 0
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => sortTable(row.sort, index)}
                        >
                          {row.field}
                          {
                            row.sort
                              ? generateSortIcon(row.isSort)
                              : (<div style={{ width: 24 }} />)
                          }
                        </Box>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.header}>
                {data && data.map((row, index) => {
                  const currentStatus = row?.innovationGateStatuses?.status;
                  const filterIdeaBlast = row?.innovationGateStatuses?.stages?.name?.includes('Idea Blast')
                    ? row?.innovationGateStatuses?.stages?.name?.substring(3)
                    : row?.innovationGateStatuses?.stages?.name;

                  return (
                    <StyledTableRow key={index} style={{ marginBottom: '5px' }}>
                      <StyledTableCell style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }} align="left">
                        <Box flexDirection="row" display="flex">
                          <IconButton
                            component={RouterLink}
                            to={gotoDetail(row.id)}
                            style={{ padding: 0, marginRight: 10 }}
                          >
                            <Tooltip title="Open" placement="top" arrow>
                              <DescriptionTwoTone style={{ color: '#737AF1' }} />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.yearBuilt}</StyledTableCell>
                      <StyledTableCell align="left">{row.registrationNumber}</StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          whiteSpace: 'normal',
                          wordWrap: 'break-word'
                        }}
                      >
                        {trim(row.initiative)}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row?.user?.fullname}</StyledTableCell>
                      <StyledTableCell align="left">{filterIdeaBlast}</StyledTableCell>
                      <StyledTableCell align="left">
                        <Box style={{
                          backgroundColor: generateStatusColor(currentStatus),
                          borderRadius: 14,
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10
                        }}
                        >
                          <Typography style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                            {currentStatus}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.organizations && row.organizations.name}</StyledTableCell>
                      <StyledTableCell align="left">{row?.category}</StyledTableCell>
                      <StyledTableCell align="left">{row.innovationTypes && row.innovationTypes.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.themeBanks && row.themeBanks.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.classifications && row.classifications.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.company}</StyledTableCell>
                      <StyledTableCell align="left">{row?.facilitator?.fullname}</StyledTableCell>
                      <StyledTableCell align="left">{row?.updatedBy}</StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          borderTopRightRadius: 25,
                          borderBottomRightRadius: 25
                        }}
                      >
                        {moment(row.updatedAt).format('DD MMMM YYYY')}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container direction="row" alignContent="space-between" alignItems="center">
          <Grid
            item
            className={classes.contentShowing}
            lg={4}
            md={4}
            sm={5}
            xs={12}
            style={{ marginTop: 20 }}
          >
            <Typography className={classes.subtitle}>
              Showing
              {' '}
              {showing.show}
              {' '}
              to
              {' '}
              {showing.to}
              {' '}
              of
              {' '}
              {totalData}
              {' '}
              data entries
            </Typography>
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={7}
            xs={12}
            className={classes.contentPagination}
            style={{ marginTop: 20 }}
          >
            <Pagination
              onChange={changePagination}
              color="secondary"
              type="next"
              count={totalPage}
              page={currentPage}
              shape="rounded"
            />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default InnovationView;
