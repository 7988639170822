import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { CheckCircleOutlineRounded } from '@material-ui/icons';

import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import NumberFormatCustom from 'src/components/NumberFormat';

const G1TotalNet = ({
  disabled = true,
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  isGateCompleted
}) => {
  const key = 'g1TotalNet';

  return (
    <FormItem
      id={key}
      label="G1 Total Net Benefit"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <StyledInput
          name={key}
          disabled={disabled}
          value={values[key]}
          error={Boolean(touched[key] && errors[key])}
          onBlur={handleBlur}
          onChange={handleChange}
          InputProps={{
            inputComponent: NumberFormatCustom
          }}
        />
        <CheckCircleOutlineRounded
          style={{
            marginLeft: 10,
            color: isGateCompleted(Number(key.charAt(1)))
          }}
        />
      </Box>
    </FormItem>
  );
};

G1TotalNet.propTypes = {
  disabled: PropTypes.bool,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isGateCompleted: PropTypes.func.isRequired
};

export default G1TotalNet;
