import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { ORGANIZATION_LIST } from 'src/api/backendUrl';

import StyledSelect from 'src/components/StyledSelect';
import FormItem from 'src/components/FormItem';

import { isValidationRequired } from 'src/utils/validation';

const Site = ({
  disabled,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  validation
}) => {
  const key = 'organizationId';

  const { data, refetch } = useQuery(ORGANIZATION_LIST, async () => {
    const response = await api.masterData.organizationlist({
      empId: values?.submitter?.empId,
      Sorts: 'name'
    });

    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });

  useEffect(() => {
    refetch();
  }, [values?.submitter]);

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Site"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        placeholder="Enter site"
        options={data}
      />
    </FormItem>
  );
};

Site.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default Site;
