const getProperties = (id, detail, user, isEdit) => {
  const isRegistration = id && detail?.innovationGateStatuses?.stages?.name?.includes('G0');
  const isImplementation = id && detail?.innovationGateStatuses?.stages?.name?.includes('G1');
  const isImplementationDraft = isImplementation && (detail?.innovationGateStatuses?.status === 'Draft');
  const isPO = detail?.userPermissions?.some((item) => item.id === user?.id);
  const isAdmin = user?.roles?.findIndex((d) => d.name === 'Administrator') > -1;
  const isAdminSite = user?.roles?.findIndex((d) => d.name === 'Admin Site') > -1
    && user?.cluster?.some((item) => item.id === detail?.organizationId);
  const isAdminApp = isAdmin && !user.designation;
  const isCommittee = user?.roles?.find((d) => d.name === 'Committee Site');
  // Status Project
  const isDraft = detail?.innovationStatus === 'Draft';
  const isSentBack = detail?.innovationStatus === 'Send Back';
  const isWaitingSuperior = detail?.innovationStatus === 'Waiting Superior Approval';
  const isWaitingReviewAdmin = detail?.innovationStatus === 'Waiting Admin Verify';
  const isWaitingCommittee = detail?.innovationStatus === 'Waiting Assessment Committee';
  const isWaitingAcknowledge = detail?.innovationStatus === 'Waiting Admin Acknowledge';
  const isCompleted = detail?.innovationStatus === 'Completed';
  const isCancelled = detail?.innovationStatus === 'Cancelled';
  const committeeAssigned = detail?.registrationApprovals?.filter((d) => d.roles?.name === 'Committee Site' && d.gateDescription === 'G0');
  const isAssignedCommittee = committeeAssigned?.find((d) => d.userId === user.id);
  const canSubmit = isRegistration && (
    ((isDraft || isSentBack) && isPO)
      || (isAdminApp && isEdit && !isCompleted)
      || (!isDraft && isEdit && !isCompleted && isAdminSite));
  const canEdit = (isRegistration
      && !isDraft
      && !isEdit
      && ((isAdminApp && !isCompleted) || (!isCompleted && isAdminSite)));
  const canReview = isRegistration && (isWaitingReviewAdmin && isAdminSite);
  const canAcknowledge = isRegistration && (isWaitingAcknowledge && isAdminSite);
  const canSuggest = isRegistration && (
    isWaitingCommittee && isCommittee && isAssignedCommittee && !isAssignedCommittee?.isApproved
  );
  const canEditCommittee = isRegistration && (isWaitingCommittee && isAdminSite);
  const canCancel = isRegistration
    && !isCancelled
    && isPO
    && (isWaitingSuperior || isWaitingReviewAdmin || isSentBack);
  const canReactivate = isRegistration && isCancelled && (isAdmin || isAdminSite);
  const canCreateImplementation = isRegistration
    && isCompleted
    && detail?.userPermissions.some((item) => item.id === user?.id);
  const canViewSuggestionList = canAcknowledge
    || canCreateImplementation
    || canEditCommittee
    || isCompleted;
  const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
  const isWaitingApproval = isWaitingAcknowledge
    || isWaitingCommittee
    || isWaitingReviewAdmin
    || isWaitingSuperior;

  return {
    isAdmin,
    isCommittee,
    isDraft,
    isCompleted,
    isSentBack,
    isWaitingCommittee,
    isRegistration,
    isImplementationDraft,
    isWaitingReviewAdmin,
    isWaitingApproval,
    committeeAssigned,
    canSubmit,
    canEdit,
    canReview,
    canEditCommittee,
    canAcknowledge,
    canSuggest,
    canCancel,
    canReactivate,
    canCreateImplementation,
    canViewSuggestionList,
    canManageAccess
  };
};

export default getProperties;
