import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { uniqBy } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import fileDownload from 'js-file-download';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowLeft, ArrowDropDown } from '@material-ui/icons';

import api from 'src/api';

import Page from 'src/components/Page';
import TableList from 'src/components/TableList';
import {
  CATEGORIES,
  INNOVATION_TYPES,
  ORGANIZATION_ALL,
  STAGE_LIST,
  YEAR_BUILT
} from 'src/api/backendUrl';

import Filter from './Filter';
import header from '../myDocument/header';
import Toolbar from '../myDocument/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  },
}));

const MyActivity = () => {
  const classes = useStyles();

  const myActivityFilter = useStoreState((state) => state.myActivity.filter);
  const myActivityCurrentPage = useStoreState((state) => state.myActivity.currentPage);
  const setMyActivityFilter = useStoreActions((action) => action.myActivity.setFilter);
  const setMyActivityCurrentPage = useStoreActions((action) => action.myActivity.setCurrentPage);

  const [headerTable, setHeaderTable] = useState(header);
  const [currentPage, setCurrentPage] = useState(myActivityCurrentPage);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [sorts, setSorts] = useState('-updatedAt');
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    yearOptions: [],
    stageOptions: [],
    siteOptions: [],
    categoryOptions: [],
    innovationTypeOptions: [],
    statusOptions: [
      { id: 'Draft', name: 'Draft' },
      { id: 'Waiting Superior Approval', name: 'Waiting Superior Approval' },
      { id: 'Waiting Promotor/Sponsor Approval ', name: 'Waiting Promotor/Sponsor Approval' },
      { id: 'Waiting Facilitator/Scrum Master Approval', name: 'Waiting Facilitator/Scrum Master Approval' },
      { id: 'Waiting Approval', name: 'Waiting Approval' },
      { id: 'Waiting Admin Verify', name: 'Waiting Admin Verify' },
      { id: 'Waiting Admin Acknowledge', name: 'Waiting Admin Acknowledge' },
      { id: 'Waiting Assessment Committee', name: 'Waiting Assessment Committee' },
      { id: 'Waiting Confirmation', name: 'Waiting Confirmation' },
      { id: 'Completed', name: 'Completed' },
      { id: 'Send Back', name: 'Send Back' },
      { id: 'Cancelled', name: 'Cancelled' },
      { id: 'Rejected', name: 'Rejected' }
    ]
  });
  const [filter, setFilter] = useState({
    year: [],
    status: [],
    stage: [],
    site: [],
    category: [],
    innovationType: []
  });
  const [filterParams, setFilterParams] = useState('');
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const sortTable = (sort) => {
    if (sort !== 'action') {
      headerTable.forEach((element) => {
        if (element.sort === sort) {
          element.isSort = !element.isSort;
          if (element.isSort === true) {
            if (sort === 'stateId' || sort === 'stageId') {
              sort = `InnovationGateStatuses.${sort}`;
            }
            setSorts(sort);
          } else {
            setSorts(`-${sort}`);
          }
        } else {
          element.isSort = null;
        }
      });
      setHeaderTable([...headerTable]);
    }
  };

  const changePagination = (event, page) => {
    let first = 1;
    let last = pageSize * page;
    for (let i = 1; i < pageSize * page; i += pageSize) {
      first = i;
    }
    if (totalData > last) {
      last = pageSize * page;
    } else {
      last = totalData;
    }
    setShowing({
      show: first,
      to: last
    });
    setCurrentPage(page);
  };

  const handleLimitChange = (item) => {
    setCurrentPage(1);
    setShowing({
      show: 1,
      to: item
    });
    setPageSize(item);
  };

  const groupStage = (stages) => {
    let transformedObj = {};
    const transformedArray = [];
    const ideaBlastData = stages.filter((item) => item.name.includes('Idea Blast'));
    const nonIdeaBlastData = stages.filter((item) => !item.name.includes('Idea Blast'));

    ideaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          ...stage,
          name: stage?.name?.substring(3)
        }
      };

      transformedArray.push(transformedObj);
    });

    nonIdeaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          id: nonIdeaBlastData
            .filter((item) => item.name.includes(stage?.name?.substring(0, 2)))
            .map((item) => item.id)
            .join('|'),
          name: stage?.name?.split('(')[0]
        }
      };

      transformedArray.push(transformedObj);
    });

    return uniqBy(transformedArray, 'name');
  };

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: stages } = useQuery(
    [STAGE_LIST, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.stageList(query);

      return response?.data?.data?.length
        ? groupStage(response.data.data)
        : [];
    }, { initialData: [] }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_ALL, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.organizationAll(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: categories } = useQuery(
    [CATEGORIES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.categories(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: innovationTypes } = useQuery(
    [INNOVATION_TYPES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.innovationTypes(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const fetchMyActivity = async () => {
    setIsFetching(true);

    const query = {
      Filters: filterParams,
      page: currentPage,
      pageSize,
      sorts
    };

    const { data: response } = await api.myActivity.list({
      ...search && { filters: `search==${search}` },
      ...query
    });
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response?.totalData === 0
        ? response?.totalData
        : showing.show,
      to: response?.totalData <= pageSize
        ? response?.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData(response?.data);
  };

  const createParamValue = (value) => value.join('|');

  const generateParams = () => new Promise((resolve, reject) => {
    const paramValues = [];
    let paramName = '';

    Object.keys(filter).forEach((key) => {
      if (Array.isArray(filter[key]) && filter[key].length) {
        switch (key) {
          case 'status': {
            paramName = 'InnovationGateStatusesStatus';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'stage': {
            paramName = 'InnovationGateStatuses.stageId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'site': {
            paramName = 'OrganizationId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'year': {
            paramName = 'YearBuilt';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'category': {
            paramName = 'Category';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'innovationType': {
            paramName = 'InnovationTypeId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    resolve(paramValues);
    reject();
  });

  const resetFilter = () => {
    setFilter({
      year: [],
      status: [],
      stage: [],
      site: [],
      category: [],
      innovationType: []
    });
    setCurrentPage(1);
  };

  const handleEnterSearch = async (ev) => {
    if (ev.key === 'Enter') {
      await fetchMyActivity();
    }
  };

  useEffect(() => {
    if (myActivityFilter) {
      setFilter(myActivityFilter);
    }
  }, []);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      yearOptions: years,
      stageOptions: stages,
      siteOptions: sites,
      categoryOptions: categories,
      innovationTypeOptions: innovationTypes
    });
  }, [years, stages, sites, categories, innovationTypes]);

  useEffect(() => {
    const setParams = async () => {
      const paramData = await generateParams();
      setCurrentPage(1);
      setFilterParams(paramData.join(','));
    };

    setMyActivityFilter(filter);

    setParams()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [filter]);

  useEffect(() => {
    setCurrentPage(1);

    fetchMyActivity()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [search]);

  useEffect(() => {
    setMyActivityCurrentPage(currentPage);

    fetchMyActivity()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [filterParams, pageSize, currentPage]);

  const handleClearSearch = () => {
    setSearch('');
  };

  const download = async () => {
    const query = {
      page: currentPage,
      pageSize,
      sorts
    };
    const newQuery = {
      ...search && { filters: `search==${search}` },
      ...query
    };
    const responseType = 'blob';
    const { data: exportfile } = await api.myActivity.download(newQuery, responseType);
    const date = new Date();
    const filename = `my-activity-${date.getTime()}.xlsx`;
    fileDownload(exportfile, filename);
  };

  return (
    <Page
      className={classes.root}
      title="My Activity"
    >
      <Container maxWidth={false}>
        <Toolbar path="My Activity" />
        <Box className={classes.card} mt={5}>
          <Grid container spacing={4}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"

              >
                <Typography
                  className={classes.titleFilter}
                  onClick={() => setIsFilterShown(!isFilterShown)}
                >
                  Filter
                </Typography>
                {
                  isFilterShown
                    ? (
                      <ArrowDropDown
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(false)}
                      />
                    )
                    : (
                      <ArrowLeft
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(true)}
                      />
                    )
                }
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {
                isFetching && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignSelf="end"
                      alignItems="center"
                      marginRight={1}
                      height="100%"
                    >
                      <CircularProgress size={24} style={{ alignSelf: 'center' }} />
                    </Box>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          {
            isFilterShown && (
              <Box paddingX={5}>
                <Filter {...{
                  ...filterOptions,
                  values: { ...filter },
                  setFilter
                }}
                />
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 15, marginTop: 5 }}
                >
                  <Grid
                    item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isFetching}
                      style={{ alignSelf: 'self-end' }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Box>
        <TableList
          navigationUrl="/app/my-activity"
          data={documentData}
          search={search}
          setSearch={setSearch}
          handleDownload={download}
          header={headerTable}
          totalData={totalData}
          currentPage={currentPage}
          onChangePagination={changePagination}
          totalPage={totalPage}
          showing={showing}
          pageSize={pageSize}
          handleSort={sortTable}
          handleLimitItems={handleLimitChange}
          handleSearch={() => fetchMyActivity()}
          handleEnterSearch={handleEnterSearch}
          handleClearSearch={handleClearSearch}
        />
      </Container>
    </Page>
  );
};

export default MyActivity;
