import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, InputBase } from '@material-ui/core';
import SendBird from 'sendbird';
import { useStoreActions } from 'easy-peasy';
import useStyles from './styles';
import { sbSendMessageGroup } from './SendBirdController';

const SendChat = ({
  currentStage,
  channel,
  onMessageSend,
  disabled,
}) => {
  const classes = useStyles();
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const [input, setInput] = React.useState('');
  const [isSending, setIsSending] = React.useState(false);
  const handleSendChat = async () => {
    try {
      if (channel && !isSending && input && input.trim() !== '' && !disabled) {
        setIsSending(true);
        const sb = SendBird.getInstance();
        const params = new sb.UserMessageParams();
        params.message = input;
        params.data = `${currentStage}`;
        params.customType = '';
        params.mentionType = 'channel';
        params.translationTargetLanguages = ['en', 'id'];
        params.pushNotificationDeliveryOption = 'default';
        const message = await sbSendMessageGroup(channel, params);
        if (message) {
          setInput('');
          onMessageSend && onMessageSend(message);
        }
      }
    } catch (error) {
      setNotif({
        message: error || 'Sending Message Failed',
        option: {
          variant: 'error'
        }
      });
    } finally {
      setIsSending(false);
    }
  };
  return (
    <Box className={classes.containerInput}>
      <InputBase
        fullWidth
        value={input}
        disabled={disabled}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            ev.preventDefault();
            handleSendChat();
          }
        }}
        className={classes.input}
        placeholder="Type your message…"
        endAdornment={(
          <IconButton size="small" disabled={disabled} onClick={() => handleSendChat()}>
            <img src="/static/icons/send.svg" alt="send" />
          </IconButton>
          )}
      />
    </Box>
  );
};

SendChat.propTypes = {
  currentStage: PropTypes.number,
  channel: PropTypes.object,
  disabled: PropTypes.bool,
  onMessageSend: PropTypes.func
};

export default SendChat;
