import React from 'react';
import PropTypes from 'prop-types';
import { startCase, toLower } from 'lodash';
import {
  Box,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '10% 7%',
    marginTop: '4.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
  },
  title: {
    color: '#1A051D',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '19px',
    marginBottom: '9.36%'
  },
  textStatus: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textBolder: {
    fontWeight: 'bolder'
  }
}));

const HistoryApprovalView = ({ data }) => {
  const classes = useStyles();
  return (
    <Box className={classes.formContainer}>
      <Grid container spacing={3}>
        <Grid item lg={7} md={12} sm={12} xs={12} className={classes.title}>
          History Approval
        </Grid>
        <Grid item lg={5} md={12} sm={12} xs={12} className={classes.title} style={{ textAlign: 'center' }}>
          Status
        </Grid>
      </Grid>
      {
        (data && data.length)
        && data.map((row, index) => {
          return (
            <Grid container key={`key-${index + 1}`} spacing={3} style={{ marginBottom: 10 }}>
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.textBolder}>{row?.roles?.name}</Typography>
                  {row?.roles?.approver && row?.roles.approver.length
                    ? row?.roles?.approver?.map((approver, appIndex) => (
                      <Typography
                        key={`key-${appIndex + 1}`}
                        style={{
                          paddingBottom: 5,
                          color: '#737AF1',
                          fontSize: '0.875rem',
                          fontWeight: 'bold'
                        }}
                      >
                        {
                          !row?.isApproved && (`${appIndex + 1}.`)
                        }
                        {' '}
                        {startCase(toLower(approver.name)) }
                      </Typography>
                    ))
                    : (
                      <Typography style={{
                        paddingBottom: 5,
                        color: '#737AF1',
                        fontSize: '0.875rem',
                        fontStyle: 'italic',
                        fontWeight: 'bold'
                      }}
                      >
                        Unknown
                      </Typography>
                    )}
                </Box>
              </Grid>
              <Grid item lg={5} md={12} sm={12} xs={12}>
                <Box style={{
                  backgroundColor: row?.isApproved
                    ? '#81d5b8'
                    : '#f7da72',
                  borderRadius: 20,
                  padding: 3,
                  paddingRight: 10,
                  paddingLeft: 10
                }}
                >
                  <Typography className={classes.textStatus}>
                    {row?.isApproved ? 'Approved' : 'Waiting'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          );
        })
      }
    </Box>
  );
};

HistoryApprovalView.propTypes = {
  data: PropTypes.array,
};

export default HistoryApprovalView;
