import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

const YearFilter = ({
  yearOptions = [],
  values,
  setFilter
}) => {
  const key = 'year';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  return (
    <FormItem
      id={key}
      label="Year"
    >
      <StyledSelect
        name={key}
        placeholder="Select year"
        value={values[key]}
        options={yearOptions}
        onChange={handleOnChange}
      />
    </FormItem>
  );
};

YearFilter.propTypes = {
  yearOptions: PropTypes.array,
  values: PropTypes.object,
  setFilter: PropTypes.func
};

export default YearFilter;
