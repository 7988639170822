import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import PlannedFeaturesMmp from './PlannedFeaturesMmp';
import ActualFeaturesMmp from './ActualFeaturesMmp';
import ActualImplementationScopeMmp from './ActualImplementationScopeMmp';
import MmpResult from './MmpResult';
import Attachment from './Attachment';
import ScaleUpMilestone from './ScaleUpMilestone';
import SolutionArchitect from './SolutionArchitect';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const GeneralView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: 5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>MMP</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedFeaturesMmp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActualFeaturesMmp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActualImplementationScopeMmp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MmpResult {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>SCALE UP</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ScaleUpMilestone {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>ROLES</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <SolutionArchitect {...props} />
      </Grid>
    </Grid>
  );
};

export default GeneralView;
