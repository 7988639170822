import { User } from 'react-feather';
import * as Yup from 'yup';

const initialValues = (detail) => {
  const {
    id,
    action,
    initiative,
    tabName,
    user
  } = detail;

  let productOwnerOpsi = [];
  if (detail?.requested?.fullname) productOwnerOpsi.push({ name: detail?.requested?.fullname });
  if (user?.fullname) productOwnerOpsi.push({ name: user?.fullname });

  return {
    id,
    action,
    number: detail?.number || '',
    registrationId: detail?.registrationId || '',
    state: detail?.state?.id || '',
    demand: initiative || '',
    stageGate: tabName || '',
    productOwner: detail?.productOwner ? { name: detail?.productOwner } : 
      detail?.requested?.fullname ? { name: detail?.requested?.fullname } : 
      user?.fullname ? { name: user?.fullname } : null,
    productOwnerOpsi: productOwnerOpsi || [],
    successesWeek: detail?.successesWeek || '',
    challengesConcerns: detail?.challengesConcerns || '',
    nextPriorities: detail?.nextPriorities || '',
    thingsOfInterest: detail?.thingsOfInterest || '',
  };
};

const validation = (detail) => {

  return {
    state: Yup.string().required('State is required'),
    productOwner: Yup.object().required('Product owner is required').nullable(),
    successesWeek: Yup.string().required('This week successes is required'),
    challengesConcerns: Yup.string().required('Challenges is required'),
    nextPriorities: Yup.string().required('Next week priorities is required'),
  };
};

export { initialValues, validation };
