import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { difference, isEmpty } from 'lodash';
import deepEqual from 'deep-equal';
import {
  Box,
  Grid,
  makeStyles,
  Divider,
  useTheme
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useStore, useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, useNavigate, Prompt } from 'react-router-dom';
import { useMutation } from 'react-query';

import api from 'src/api';

import TabMenu from 'src/components/TabMenu';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../Tabpanel';
import Decision from './Decision';
import FirstView from './FirstView';
import SecondView from './SecondView';
import ThirdView from './ThirdView';
import FourthView from './FourthView';
import ChangeTeamView from './ChangeTeamView';
import { initialValues, validation } from './config';
import { getBottomMenus } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    backgroundColor: theme.palette.common.white,
  },
  additionalForm: {
    borderRadius: '24px'
  },
}));

const G4ImplementationView = ({
  detail,
  registration,
  approval,
  handleChangeTab,
  handleAccessPermissionClick
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const store = useStore();

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const setFormikValues = useStoreActions((action) => action.formikValues.setFormikValues);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [isEdit, setIsEdit] = useState(false);
  const [benefitCost, setBenefitCost] = useState(null);
  const [dataDetail, setDataDetail] = useState(detail);
  const [bottomTab, setBottomTab] = useState(0);
  const user = useStoreState((state) => state.auth.user);
  const [tabChanged, setTabChanged] = useState(false);
  const [canRemind, setCanRemind] = useState(
    registration?.innovationGateStatuses?.notificationSent === false
  );
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });

  const handleChangeBottomTab = (event, newValue) => {
    setBottomTab(newValue);
    setTabChanged(true);
  };

  const isUserCanApprove = () => {
    if (detail.gateStatus === undefined || detail.gateStatus === 'Draft') return false;

    let isUserCanApproveAsPromotorFacilitator = false;
    let approvalData = [];

    const promotorFacilitatorApproval = approval?.data.filter(
      (item) => item.roles.name.includes('Promotor')
        || item.roles.name.includes('Facilitator')
    );

    const roleApproval = difference(approval?.data, promotorFacilitatorApproval, 'roles.name');

    if (promotorFacilitatorApproval.length) {
      isUserCanApproveAsPromotorFacilitator = promotorFacilitatorApproval
        .some((item) => !item.isApproved
          && item.roles.approver.some((userApprover) => userApprover.id === user.id));
    }

    const isUserCanApproveByRole = roleApproval
      .some((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    approvalData = isUserCanApproveAsPromotorFacilitator
      ? promotorFacilitatorApproval.filter((item) => !item.isApproved)
      : roleApproval.filter((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    return {
      approvalValidation: isUserCanApproveAsPromotorFacilitator || isUserCanApproveByRole,
      approvalData
    };
  };

  useEffect(() => { setDataDetail(detail); }, [detail]);
  useEffect(() => {
    if (benefitCost) {
      const {
        oneTimeBenefit,
        oneTimeCosts,
        recurringBenefit,
        recurringCosts
      } = benefitCost;

      const {
        g4OnetimeBenefit,
        g4OnetimeCost,
        g4RecurBenefit,
        g4RecurCost,
        ...rest
      } = dataDetail;

      setDataDetail({
        g4OnetimeBenefit: oneTimeBenefit,
        g4OnetimeCost: oneTimeCosts,
        g4RecurBenefit: recurringBenefit,
        g4RecurCost: recurringCosts,
        ...rest
      });
    }
  }, [benefitCost]);
  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
          return;
        }

        setPromptAction({ needed: false, action: actionMessage });
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const submit = useMutation((form) => api.g4implementation.create(id, form), mutateOption('Submit'));
  const saveDraft = useMutation((form) => api.g4implementation.draft(id, form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.g4implementation.edit(id, form), mutateOption('Edit'));
  const approve = useMutation((form) => api.g4implementation.approve(id, form), mutateOption('Approve'));
  const reactivate = useMutation(() => api.g4implementation.reactivate(id), mutateOption('Reactivate'));
  const createG5 = useMutation(() => api.g4implementation.createG5(id), mutateOption('Create G5', () => handleChangeTab(5)));
  const sendback = useMutation((form) => api.g4implementation.sendback(id, form), mutateOption('Sent Back'));

  const transformArrayMember = (value) => {
    return value.map((item) => ({ id: item.id, name: item.name }));
  };

  const transformBenefitCategory = (value) => {
    return value.map((item) => ({ benefitCategoryId: item }));
  };

  const generateForm = async (form) => {
    const {
      dataArchitects,
      developerTeams,
      actualBenefitCategory1,
      g4ImplementationAttachments,
      submitter,
      facilitator,
      newTeamMembers,
      ...rest
    } = form;

    return {
      dataArchitects: transformArrayMember(dataArchitects),
      developerTeams: transformArrayMember(developerTeams),
      actualBenefitCategories: transformBenefitCategory(actualBenefitCategory1),
      newTeamMembers: newTeamMembers.map((item) => ({
        id: item.id || item.empId,
        name: item.memberName || item.fullName || item.fullname
      })),
      submitterId: submitter?.id || submitter?.empId,
      facilitatorId: facilitator?.id || facilitator?.empId,
      ...rest
    };
  };
  const handleSubmitForm = async (form) => {
    const submitForm = await generateForm(form);
    submit.mutate(submitForm);
  };
  const handleSaveDraft = async (form) => {
    const draftForm = await generateForm(form);
    saveDraft.mutate(draftForm);
  };
  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };
  const handleCreateG5 = async () => {
    createG5.mutate();
  };
  const handleApproval = (comment) => {
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const form = {
      registrationId: id,
      roleId: userRole.id,
      comment
    };

    approve.mutate(form);
  };
  const handleReactivate = (comment) => {
    const form = {
      Comment: comment,
      registrationComment: comment
    };
    reactivate.mutate(form);
  };
  const handleSentBack = async (comment) => {
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const form = {
      registrationId: id,
      roleId: userRole.id,
      comment
    };

    sendback.mutate(form);
  };
  const handleOpenEdit = () => setIsEdit(true);
  const handleCloseEdit = () => setIsEdit(false);
  const handleSubmit = (value, actions) => {
    actions.setSubmitting(true);
    const {
      action, ...form
    } = value;
    switch (action) {
      case 'submit':
        handleSubmitForm(form);
        break;
      case 'edit':
        handleEditAdminForm(form);
        break;
      default:
        break;
    }
    actions.setSubmitting(false);
  };

  const isCanValidateOnMount = !isEmpty(detail) && (detail.gateStatus === 'Draft' || detail.gateStatus === 'Send Back');
  const isValidForBenefitCost = registration?.benefitCostActivate;

  // current state isValidForBenefitCost is always true for IPoD category
  const bottomMenus = getBottomMenus(
    {
      ...{
        detail,
        registration,
        capexKey: 'mvpFundingCapex',
        opexKey: 'mvpFundingOpex',
        isValidForBenefitCost
      }
    }
  );

  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };

  return (
    <Formik
      enableReinitialize={!!id}
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={tabChanged
        ? store.getState()?.formikValues?.formikValues
        : initialValues({ detail: dataDetail, registration })}
      initialErrors={validation}
      isInitialValid={() => validation.isValidSync(
        tabChanged
          ? store.getState()?.formikValues?.formikValues
          : initialValues({ detail: dataDetail, registration })
      )}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const isG4 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G4'));
        const isG5 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G5'));
        const isG5Draft = isG5 && (registration?.innovationGateStatuses?.status?.includes('Draft'));
        const isIPod = registration?.category === 'IPod';
        const isCreator = registration?.user?.id === user?.id;
        const isPO = registration?.userPermissions?.some((item) => item.id === user?.id);
        const organizationId = registration?.organizationId;
        const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
        const isAdminSite = user?.roles.find((d) => d.name === 'Admin Site');
        const isSameSite = user?.cluster?.some((item) => item.id === registration?.organizationId);
        const isAdminOfSite = isAdminSite && isSameSite;
        const isAdminApp = isAdmin && !user.designation;
        const isPIC = user?.roles?.filter((d) => ['DCOE', 'IT', 'Infinity'].indexOf(d.name) > -1);
        const isAdminPIC = !!isPIC.length
          && isPIC.findIndex((d) => d?.name === registration?.roles?.name) > -1;
        const isDraft = ['Draft'].indexOf(registration?.innovationGateStatuses?.status) > -1 || ['Draft'].indexOf(detail?.gateStatus) > -1;
        const isSendBack = ['Send Back'].indexOf(registration?.innovationGateStatuses?.status) > -1 || ['Send Back'].indexOf(detail?.gateStatus) > -1;
        const isWaitingApproval = registration?.innovationGateStatuses?.status?.includes('Waiting Approval') || detail?.gateStatus?.includes('Waiting Approval');
        const isCompleted = ['Completed'].indexOf(registration?.innovationGateStatuses?.status) > -1 || ['Completed'].indexOf(detail?.gateStatus) > -1;
        const isCancel = ['Cancelled'].indexOf(registration?.innovationGateStatuses?.status) > -1 || ['Cancelled'].indexOf(detail?.gateStatus) > -1;
        const isOnHold = ['On Hold']
          .indexOf(registration?.innovationGateStatuses?.states?.name) > -1;
        const canEditForm = isG4 && !isCancel
          && (((isDraft || isSendBack) && isPO) || (isEdit && isAdminApp)
          || (isAdminPIC && isEdit && !isCompleted));
        const canSubmit = isG4 && !isCancel && !isOnHold && isPO && (isDraft || isSendBack);
        const canSaveDraft = isG4 && !isCancel && isPO && (isDraft || isSendBack);
        const canEdit = (isG4
          && !isCancel
          && !isOnHold
          && !isEdit
          && (isAdminApp || (isAdminPIC && !isCompleted) || isAdminOfSite)
          && !(isDraft || isSendBack));
        const canReactivate = isG4 && isAdmin && isCancel;
        const canCreateG5 = isG4 && !isG5 && isCompleted && (isPO || isCreator);
        const canApprove = approval?.isCanApprove;
        const canSentBack = approval?.isCanApprove;
        const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
        const showRemind = (isAdminOfSite || isAdmin) && (
          (isG4 && (isWaitingApproval || isCompleted))
          || isG5Draft);
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Box className={classes.formContainer}>
              <FirstView
                disabled={!canEditForm}
                organizationId={organizationId}
                isValidForBenefitCost={isValidForBenefitCost}
                validation={validation.fields}
                {...formik}
              />
            </Box>
            {
              isIPod && (
                <>
                  <Divider />
                  <Box className={classes.formContainerBtm}>
                    <ChangeTeamView
                      disabled={!canEditForm}
                      validation={validation.fields}
                      {...formik}
                    />
                  </Box>
                </>
              )
            }
            <Divider />
            <Box className={classes.formContainerBtm}>
              <SecondView
                disabled={!canEditForm}
                organizationId={organizationId}
                g4implementId={detail.id}
                validation={validation.fields}
                {...formik}
              />
            </Box>
            <Divider />
            <Box className={classes.formContainerBtm}>
              <ThirdView
                disabled={!canEditForm}
                organizationId={organizationId}
                validation={validation.fields}
                {...formik}
              />
            </Box>
            <Divider />
            <Box className={classes.formContainerBtm}>
              <FourthView
                disabled={(!canEditForm)}
                organizationId={organizationId}
                validation={validation.fields}
                {...formik}
              />
            </Box>
            <Divider />
            <Grid item style={{ marginTop: '2rem' }}>
              <TabMenu
                tab={bottomTab}
                setTab={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
                menus={bottomMenus}
              />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={bottomTab}
                onChangeIndex={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
              >
                {bottomMenus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={bottomTab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component({
                      setFieldValue: formik.setFieldValue,
                      setFieldTouched: formik.setFieldTouched,
                      gate: 'g4'
                    })}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
            <Box mt={5} mb={5}>
              <Decision
                {...{
                  id,
                  user,
                  detail,
                  isEdit,
                  handleOpenEdit,
                  handleCloseEdit,
                  handleSaveDraft,
                  handleApproval,
                  handleReactivate,
                  handleCreateG5,
                  handleSentBack,
                  handleRemind,
                  handleAccessPermissionClick,
                  canCreateG5,
                  canReactivate,
                  canEdit,
                  canSubmit,
                  canSaveDraft,
                  canApprove,
                  canSentBack,
                  canManageAccess,
                  showRemind,
                  canRemind,
                  setPromptAction
                }}
                {...formik}
              />
            </Box>
            <Prompt
              when={promptAction.needed
                && (canSubmit || isEdit)
                && !deepEqual(formik.initialValues, formik.values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

G4ImplementationView.propTypes = {
  detail: PropTypes.object,
  registration: PropTypes.object,
  requirement: PropTypes.object,
  study: PropTypes.object,
  prototype: PropTypes.object,
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  })
};

export default G4ImplementationView;
