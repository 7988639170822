import {
  DASHBOARD,
  BENEFIT_CATEGORIES,
  CLASSIFICATIONS,
  COMPANIES,
  INNOVATION_AREAS,
  INNOVATION_STATUSES,
  INNOVATION_TYPES,
  LOGIN,
  LOGOUT,
  NAME_LIST,
  ORGANIZATION_LIST,
  REGISTRATION,
  ROLES,
  THEME_BANKS,
  ORGANIZATION,
  ORGANIZATION_ALL,
  REGISTRATION_SAVE_DRAFT,
  RATE_LIST,
  STATE,
  SUMMARY,
  APPROVE,
  SENTBACK,
  CANCEL,
  COMMITTEE_LIST,
  REMINDER,
  STAGE_LIST,
  STAGE_GATES,
  ROLE_LIST,
  CREATE_G1,
  REQUIREMENT,
  SUBMIT_G1,
  SAVE_DRAFT,
  REGISTRATION_DOWNLOAD,
  SUBMIT_DRAFT,
  REGISTRATION_EDIT_DRAFT,
  CHANGE_STATE,
  REACTIVATE_FORM,
  PROMOTOR_LIST,
  REGISTRATION_APPROVAL,
  FACILITATOR_LIST,
  SITE_CI_LIST,
  SITE_IT_LIST,
  G1_APPROVAL_HISTORY,
  YEAR_BUILT,
  LOG_HISTORY,
  EDIT_SUBMIT,
  MY_ACTIVITY,
  MY_ACTIVITY_DOWNLOAD,
  MY_DOCUMENT,
  MY_DOCUMENT_DOWNLOAD,
  CREATE_G2,
  STUDY,
  SUBMIT_G2,
  SITE_FINANCE_LIST,
  COUNTRY_FINANCE_LIST,
  ADMIN_SITE_LIST,
  APPROVE_IDEA_BLAST,
  STUDY_SAVE_DRAFT,
  G2_APPROVAL_HISTORY,
  APPROVE_CONVENTIONAL,
  CREATE_G3,
  MASTER_DATA,
  PROTOTYPE,
  SUBMIT_G3,
  G3_APPROVAL_HISTORY,
  CREATE_G4,
  ADD_COMMITTEE,
  CREATE_IMPLEMENTATION,
  IMPLEMENTATION,
  APPROVE_IMPLEMENTATION,
  BENEFIT_CATEGORY,
  TOTAL_INVESTMENT,
  IB_STATUS_OVERVIEW,
  IB_BENEFIT_IMPACT,
  BENEFIT_IMPACT,
  ECII,
  QUESTIONS,
  APPROVAL_HISTORY,
  SAVE_DRAFT_ASSESSMENT,
  CONFIRMATION,
  USER,
  ADD_USER,
  USER_HRIS,
  USER_BANPU,
  EDIT_USER_ROLE,
  SCORE_SITE_LIST,
  SCORE,
  ADD_SCORE,
  EDIT_SCORE,
  DELETE_SCORE,
  PROJECT_STATUS_G0,
  PROJECT_STATUS_G1_COMPLETE,
  GUIDANCE,
  COMMITTEE_LIST_REGISTER,
  COMMITTEE_LIST_IMPLEMENTATION,
  IMPLEMENTATION_BENEFIT_CATEGORIES,
  SENDBIRD,
  STRATEGIC_PLAN,
  REQUESTED_FOR,
  CATEGORIES,
  DIGITAL_COACH,
  REG_BENEFIT_CATEGORY,
  BENEFIT_COST,
  BENEFIT_COST_TYPES,
  BENEFIT_COST_PURPOSES,
  BENEFIT_COST_FREQUENCIES,
  BENEFIT_COST_METRIC_TYPES,
  BENEFIT_COST_STAGE_GATE,
  G4_IMPLEMENTATION,
  SUBMIT_G4,
  G4_APPROVAL_HISTORY,
  CREATE_G5,
  SCALE_UP,
  SUBMIT_G5,
  SCALE_UP_SAVE_AS_DRAFT,
  G5_APPROVAL_HISTORY,
  DEMAND_TYPES,
  WEEKLY_UPDATE,
  WEEKLY_UPDATE_MULTIPLE,
  WEEKLY_UPDATE_GET,
  EXCEL,
  IMPACT_TRACKING,
  BENEFIT_TYPE,
  INNOVATION_REPO,
  PROJECT_DETAILS,
  PROJECT_BENEFIT,
  PROJECT_STATUS_IT_DEMAND,
  EXPORT_PROJECT_STATUS_IT_DEMAND,
  PROJECT_STATUS_IT_DEMAND_DETAIL,
  PROJECT_GATE_AGING,
  FRAMES,
  PROJECT_GATE_AGING_DETAIL,
  EXPORT_PROJECT_GATE_AGING,
  PROJECT_TYPE_AREA,
  PROJECT_TYPE_AREA_DETAIL,
  EXPORT_PROJECT_TYPE_AREA,
  PROJECT_STATUS_ALL,
  VALUE_CAPTURE,
  MANUAL,
  SCORE_LOG_HISTORY,
  EDIT_ASSESSMENT,
  SCORE_EDIT_REGISTER,
  ICON,
  READ_NOTIFICATION,
  IMPLEMENTATION_REWARD,
  IMPLEMENTATION_EXPORT_REWARD,
  LOG_HISTORY_REMINDER,
  ALL_BENEFIT_CATEGORY,
  WORK_FLOW,
  ASSESSMENT_LIST,
  ITMNOVATION,
  ASSESSMENT_JUDGES,
  START_CONVENTION,
  ASSESSMENT_ITM_LEVEL_LIST,
  INNOVATION_INVOLVEMENTS,
  HAKI
} from './backendUrl';
import {
  get,
  post,
  put,
  _delete
} from './base';

export default {
  login: (username, password) => post(LOGIN, { username, password }),
  logout: (id) => post(LOGOUT, { id }),
  implementation: {
    view: (id, query, opts) => get(`${IMPLEMENTATION}/${id}`, { query }, opts),
    approval: (id, query, opts) => get(`${IMPLEMENTATION}/${id}/${APPROVAL_HISTORY}`, { query }, opts),
    create: (id, body, opts) => put(`${IMPLEMENTATION}/${id}`, body, opts),
    draft: (id, body, opts) => put(`${IMPLEMENTATION}/${id}/${SAVE_DRAFT}`, body, opts),
    sendback: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${SENTBACK}`, body, opts),
    cancel: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${CANCEL}`, body, opts),
    reactivate: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${REACTIVATE_FORM}`, body, opts),
    addCommittee: (id, body, opts) => put(`${IMPLEMENTATION}/${id}/${ADD_COMMITTEE}`, body, opts),
    approve: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${APPROVE_IMPLEMENTATION}`, body, opts),
    draftAssessment: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${SAVE_DRAFT_ASSESSMENT}`, body, opts),
    confirmation: (id, body, opts) => post(`${IMPLEMENTATION}/${id}/${CONFIRMATION}`, body, opts),
    edit: (id, body, opts) => put(`${IMPLEMENTATION}/${id}/${EDIT_SUBMIT}`, body, opts),
    benefitCategories: (id, body, opts) => get(`${REGISTRATION}/${id}/${IMPLEMENTATION_BENEFIT_CATEGORIES}`, body, opts),
    scoreHistory: (id, query, opts) => get(`${IMPLEMENTATION}/${SCORE_LOG_HISTORY}/${id}`, { query }, opts),
    editAssessment: (body, opts) => put(`${IMPLEMENTATION}/${EDIT_ASSESSMENT}`, body, opts),
    implementationReward: (query, opts) => get(IMPLEMENTATION_REWARD, { query }, opts),
    implementationExportReward: (query, opts) => get(
      IMPLEMENTATION_EXPORT_REWARD,
      { query, responseType: opts }
    )
  },
  registration: {
    list: (query, opts) => get(REGISTRATION, { query }, opts),
    view: (id, query, opts) => get(`${REGISTRATION}/${id}`, { query }, opts),
    approve: (id, body, opts) => post(`${REGISTRATION}/${id}/${APPROVE}`, body, opts),
    approval: (id, query, opts) => get(`${REGISTRATION}/${id}/${REGISTRATION_APPROVAL}`, { query }, opts),
    edit: (id, body, opts) => put(`${REGISTRATION}/${id}/${EDIT_SUBMIT}`, body, opts),
    sendback: (id, body, opts) => post(`${REGISTRATION}/${id}/${SENTBACK}`, body, opts),
    cancel: (id, body, opts) => post(`${REGISTRATION}/${id}/${CANCEL}`, body, opts),
    reminder: (id, body, opts) => post(`${REGISTRATION}/${id}/${REMINDER}`, body, opts),
    reactivate: (id, body, opts) => post(`${REGISTRATION}/${id}/${REACTIVATE_FORM}`, body, opts),
    create: (body, opts) => post(REGISTRATION, body, opts),
    draft: (body, opts) => post(REGISTRATION_SAVE_DRAFT, body, opts),
    updatedraft: (id, body, opts) => put(`${REGISTRATION_EDIT_DRAFT}/${id}`, body, opts),
    submitdraft: (id, body, opts) => post(`${REGISTRATION}/${id}/${SUBMIT_DRAFT}`, body, opts),
    createG1: (id, body, opts) => post(`${REGISTRATION}/${id}/${CREATE_G1}`, body, opts),
    download: (query, opts) => get(REGISTRATION_DOWNLOAD, { query, responseType: opts }),
    // Idea Blast
    approveIdeaBlast: (id, body, opts) => post(`${REGISTRATION}/${id}/${APPROVE_IDEA_BLAST}`, body, opts),
    addCommittee: (id, body, opts) => put(`${REGISTRATION}/${id}/${ADD_COMMITTEE}`, body, opts),
    createImplementation: (id, body, opts) => post(`${REGISTRATION}/${id}/${CREATE_IMPLEMENTATION}`, body, opts),
    updateChannel: (channelUrl, query, opts) => get(`${REGISTRATION}/${SENDBIRD}/${channelUrl}`, { query }, opts),
    reject: (id, body) => post(`${REGISTRATION}/${id}/reject`, body),
    searchBar: (query, opts) => get(`${REGISTRATION}/search-bar`, { query }, opts)
  },
  requirement: {
    view: (id, query, opts) => get(`${REQUIREMENT}/${id}`, { query }, opts),
    cancel: (id, body, opts) => post(`${REQUIREMENT}/${id}/${CANCEL}`, body, opts),
    reactivate: (id, body, opts) => post(`${REQUIREMENT}/${id}/${REACTIVATE_FORM}`, body, opts),
    create: (id, body, opts) => put(`${REQUIREMENT}/${id}/${SUBMIT_G1}`, body, opts),
    draft: (id, body, opts) => put(`${REQUIREMENT}/${id}/${SAVE_DRAFT}`, body, opts),
    approve: (id, body, opts) => post(`${REQUIREMENT}/${id}/${APPROVE}`, body, opts),
    approveConventional: (id, body, opts) => post(`${REQUIREMENT}/${id}/${APPROVE_CONVENTIONAL}`, body, opts),
    approval: (id, query, opts) => get(`${REQUIREMENT}/${id}/${G1_APPROVAL_HISTORY}`, { query }, opts),
    edit: (id, body, opts) => put(`${REQUIREMENT}/${id}/${EDIT_SUBMIT}`, body, opts),
    sendback: (id, body, opts) => post(`${REQUIREMENT}/${id}/${SENTBACK}`, body, opts),
    editState: (id, body, opts) => put(`${REQUIREMENT}/${id}/${CHANGE_STATE}`, body, opts),
    createG2: (id, body, opts) => post(`${REQUIREMENT}/${id}/${CREATE_G2}`, body, opts),
    uploadFiles: (id, body, opts) => post(`${REQUIREMENT}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${REQUIREMENT}/${id}/delete-file`, body, opts)
  },
  study: {
    view: (id, query, opts) => get(`${STUDY}/${id}`, { query }, opts),
    create: (id, body, opts) => put(`${STUDY}/${id}/${SUBMIT_G2}`, body, opts),
    draft: (id, body, opts) => put(`${STUDY}/${id}/${STUDY_SAVE_DRAFT}`, body, opts),
    approve: (id, body, opts) => post(`${STUDY}/${id}/${APPROVE}`, body, opts),
    approval: (id, query, opts) => get(`${STUDY}/${id}/${G2_APPROVAL_HISTORY}`, { query }, opts),
    edit: (id, body, opts) => put(`${STUDY}/${id}/${EDIT_SUBMIT}`, body, opts),
    cancel: (id, body, opts) => post(`${STUDY}/${id}/${CANCEL}`, body, opts),
    reactivate: (id, body, opts) => post(`${STUDY}/${id}/${REACTIVATE_FORM}`, body, opts),
    createG3: (id, body, opts) => post(`${STUDY}/${id}/${CREATE_G3}`, body, opts),
    editState: (id, body, opts) => put(`${STUDY}/${id}/${CHANGE_STATE}`, body, opts),
    uploadFiles: (id, body, opts) => post(`${STUDY}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${STUDY}/${id}/delete-file`, body, opts)
  },
  prototype: {
    view: (id, query, opts) => get(`${PROTOTYPE}/${id}`, { query }, opts),
    create: (id, body, opts) => put(`${PROTOTYPE}/${id}/${SUBMIT_G3}`, body, opts),
    draft: (id, body, opts) => put(`${PROTOTYPE}/${id}/${STUDY_SAVE_DRAFT}`, body, opts),
    approve: (id, body, opts) => post(`${PROTOTYPE}/${id}/${APPROVE}`, body, opts),
    approval: (id, query, opts) => get(`${PROTOTYPE}/${id}/${G3_APPROVAL_HISTORY}`, { query }, opts),
    edit: (id, body, opts) => put(`${PROTOTYPE}/${id}/${EDIT_SUBMIT}`, body, opts),
    cancel: (id, body, opts) => post(`${PROTOTYPE}/${id}/${CANCEL}`, body, opts),
    editState: (id, body, opts) => put(`${PROTOTYPE}/${id}/${CHANGE_STATE}`, body, opts),
    reactivate: (id, body, opts) => post(`${PROTOTYPE}/${id}/${REACTIVATE_FORM}`, body, opts),
    createG4: (id, body, opts) => post(`${PROTOTYPE}/${id}/${CREATE_G4}`, body, opts),
    uploadFiles: (id, body, opts) => post(`${PROTOTYPE}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${PROTOTYPE}/${id}/delete-file`, body, opts)
  },
  g4implementation: {
    view: (id, query, opts) => get(`${G4_IMPLEMENTATION}/${id}`, { query }, opts),
    create: (id, body, opts) => put(`${G4_IMPLEMENTATION}/${id}/${SUBMIT_G4}`, body, opts),
    draft: (id, body, opts) => put(`${G4_IMPLEMENTATION}/${id}/${STUDY_SAVE_DRAFT}`, body, opts),
    approve: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/${APPROVE}`, body, opts),
    approval: (id, query, opts) => get(`${G4_IMPLEMENTATION}/${id}/${G4_APPROVAL_HISTORY}`, { query }, opts),
    edit: (id, body, opts) => put(`${G4_IMPLEMENTATION}/${id}/${EDIT_SUBMIT}`, body, opts),
    cancel: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/${CANCEL}`, body, opts),
    editState: (id, body, opts) => put(`${G4_IMPLEMENTATION}/${id}/${CHANGE_STATE}`, body, opts),
    reactivate: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/${REACTIVATE_FORM}`, body, opts),
    createG5: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/${CREATE_G5}`, body, opts),
    sendback: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/${SENTBACK}`, body, opts),
    uploadFiles: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${G4_IMPLEMENTATION}/${id}/delete-file`, body, opts)
  },
  masterData: {
    companies: (query, opts) => get(COMPANIES, { query }, opts),
    innovationAreas: (query, opts) => get(INNOVATION_AREAS, { query }, opts),
    innovationTypes: (query, opts) => get(INNOVATION_TYPES, { query }, opts),
    classification: (query, opts) => get(CLASSIFICATIONS, { query }, opts),
    themeBanks: (query, opts) => get(THEME_BANKS, { query }, opts),
    innovationStatuses: (query, opts) => get(INNOVATION_STATUSES, { query }, opts),
    benefitCategories: (query, opts) => get(BENEFIT_CATEGORIES, { query }, opts),
    benefitCategoryById: (id, query, opts) => get(`${BENEFIT_CATEGORIES}/${id}`, { query }, opts),
    allBenefitCategory: (query, opts) => get(ALL_BENEFIT_CATEGORY, { query }, opts),
    postBenefitCategory: (body, opts) => post(BENEFIT_CATEGORIES, body, opts),
    putBenefitCategory: (body, opts) => put(BENEFIT_CATEGORIES, body, opts),
    nameList: (query, opts) => get(NAME_LIST, { query }, opts),
    stageList: (query, opts) => get(STAGE_LIST, { query }, opts),
    stageGates: (query, opts) => get(STAGE_GATES, { query }, opts),
    roles: (query, opts) => get(ROLES, { query }, opts),
    organization: (query, opts) => get(ORGANIZATION, query, opts),
    organizationlist: (query, opts) => get(ORGANIZATION_LIST, { query }, opts),
    organizationAll: (query, opts) => get(ORGANIZATION_ALL, { query }, opts),
    rateList: (query, opts) => get(RATE_LIST, { query }, opts),
    stateList: (query, opts) => get(STATE, { query }, opts),
    roleList: (query, opts) => get(ROLE_LIST, { query }, opts),
    committeeList: (id, query, opts) => get(`${REGISTRATION}/${id}/${COMMITTEE_LIST}`, { query }, opts),
    masterCommitteeList: (id, query, opts) => get(`${MASTER_DATA}/${COMMITTEE_LIST}/${id}`, { query }, opts),
    masterCommitteeListByInov: (id, idRegistration, query, opts) => get(`${MASTER_DATA}/${COMMITTEE_LIST}/${id}/${idRegistration}`, { query }, opts),
    summary: (query, opts) => get(SUMMARY, { query }, opts),
    promotorList: (query, opts) => get(PROMOTOR_LIST, { query }, opts),
    facilitatorAll: (query, opts) => get(`${FACILITATOR_LIST}`, { query }, opts),
    facilitatorList: (id, query, opts) => get(`${FACILITATOR_LIST}/${id}`, { query }, opts),
    siteCIList: (id, query, opts) => get(`${SITE_CI_LIST}/${id}`, { query }, opts),
    siteITList: (id, query, opts) => get(`${SITE_IT_LIST}/${id}`, { query }, opts),
    siteFinanceList: (id, query, opts) => get(`${SITE_FINANCE_LIST}/${id}`, { query }, opts),
    countryFinanceList: (id, query, opts) => get(`${COUNTRY_FINANCE_LIST}/${id}`, { query }, opts),
    adminSiteList: (id, query, opts) => get(`${ADMIN_SITE_LIST}/${id}`, { query }, opts),
    yearBuilt: (query, opts) => get(YEAR_BUILT, { query }, opts),
    questionList: (query, opts) => get(QUESTIONS, { query }, opts),
    questionListById: (idRegistration, query, opts) => get(`${QUESTIONS}/${idRegistration}`, { query }, opts),
    guidanceDetail: (id, query, opts) => get(`${GUIDANCE}/${id}`, { query }, opts),
    guidance: (query, opts) => get(GUIDANCE, { query }, opts),
    committeeListRegister: (id, idOrganization, query, opts) => get(`${COMMITTEE_LIST_REGISTER}/${idOrganization}/${id}`, { query }, opts),
    committeeListImplementation: (id, idOrganization, query, opts) => get(`${COMMITTEE_LIST_IMPLEMENTATION}/${idOrganization}/${id}`, { query }, opts),
    strategicPlan: (query, opts) => get(`${STRATEGIC_PLAN}`, { query }, opts),
    requestedFor: (query, opts) => get(`${REQUESTED_FOR}`, { query }, opts),
    categories: (query, opts) => get(`${CATEGORIES}`, { query }, opts),
    digitalCoach: (orgId, query, opts) => get(`${DIGITAL_COACH}/${orgId}`, { query }, opts),
    demandTypes: (query, opts) => get(`${DEMAND_TYPES}`, { query }, opts),
    frames: (query, opts) => get(FRAMES, { query }, opts)
  },
  logHistory: {
    list: (id, query, opts) => get(`${LOG_HISTORY}/${id}`, { query }, opts),
    reminder: (query, opts) => get(LOG_HISTORY_REMINDER, { query }, opts)
  },
  myActivity: {
    list: (query, opts) => get(MY_ACTIVITY, { query }, opts),
    download: (query, opts) => get(MY_ACTIVITY_DOWNLOAD, { query, responseType: opts }),
  },
  myDocument: {
    list: (query, opts) => get(MY_DOCUMENT, { query }, opts),
    download: (query, opts) => get(MY_DOCUMENT_DOWNLOAD, { query, responseType: opts }),
  },
  dashboard: {
    summary: (query, opts) => get(`${DASHBOARD}/summary-project`, { query }, opts),
    projectStatus: (query, opts) => get(`${DASHBOARD}/project-status`, { query }, opts),
    projectStatusG0: (query, opts) => get(PROJECT_STATUS_G0, { query }, opts),
    projectStatusG1Complete: (query, opts) => get(PROJECT_STATUS_G1_COMPLETE, { query }, opts),
    benefitCategory: (query, opts) => get(BENEFIT_CATEGORY, { query }, opts),
    totalInvestment: (query, opts) => get(TOTAL_INVESTMENT, { query }, opts),
    statusOverview: (query, opts) => get(IB_STATUS_OVERVIEW, { query }, opts),
    iBenefitImpact: (query, opts) => get(IB_BENEFIT_IMPACT, { query }, opts),
    benefitImpact: (query, opts) => get(BENEFIT_IMPACT, { query }, opts),
    benefitType: (query, opts) => get(BENEFIT_TYPE, { query }, opts),
    projectDetails: (query, opts) => get(PROJECT_DETAILS, { query }, opts),
    ecii: (query, opts) => get(ECII, { query }, opts),
    projectBenefit: (query, opts) => get(PROJECT_BENEFIT, { query }, opts),
    projectStatusIT: (query, opts) => get(PROJECT_STATUS_IT_DEMAND, { query }, opts),
    projectStatusITList: (query, opts) => get(PROJECT_STATUS_IT_DEMAND_DETAIL, { query }, opts),
    exportProjectStatusIT: (query, opts) => get(
      EXPORT_PROJECT_STATUS_IT_DEMAND,
      { query, responseType: opts }
    ),
    projectGateAging: (query, opts) => get(PROJECT_GATE_AGING, { query }, opts),
    projectGateAgingDetail: (query, opts) => get(PROJECT_GATE_AGING_DETAIL, { query }, opts),
    exportProjectGateAging: (query, opts) => get(
      EXPORT_PROJECT_GATE_AGING,
      { query, responseType: opts }
    ),
    projectTypeArea: (query, opts) => get(PROJECT_TYPE_AREA, { query }, opts),
    projectTypeAreaDetail: (query, opts) => get(PROJECT_TYPE_AREA_DETAIL, { query }, opts),
    exportProjectTypeArea: (query, opts) => get(
      EXPORT_PROJECT_TYPE_AREA,
      { query, responseType: opts }
    ),
    projectStatusAll: (query, opts) => get(PROJECT_STATUS_ALL, { query }, opts),
    valueCapture: (query, opts) => get(VALUE_CAPTURE, { query }, opts),
  },
  setting: {
    user: {
      list: (query, opts) => get(USER, { query }, opts),
      hris: (query, opts) => get(USER_HRIS, { query }, opts),
      banpu: (query, opts) => get(USER_BANPU, { query }, opts),
      add: (body, opts) => post(ADD_USER, body, opts),
      editRole: (id, body, opts) => put(`${USER}/${id}/${EDIT_USER_ROLE}`, body, opts),
    },
    ideaBlast: {
      score: {
        siteList: (query, opts) => get(SCORE_SITE_LIST, { query }, opts),
        detail: (id, query, opts) => get(`${SCORE}/${id}`, { query }, opts),
        add: (id, body, opts) => post(`${SCORE}/${id}/${ADD_SCORE}`, body, opts),
        edit: (id, body, opts) => put(`${SCORE}/${id}/${EDIT_SCORE}`, body, opts),
        editRegister: (body, opts) => put(`${SCORE_EDIT_REGISTER}`, body, opts),
        delete: (id, body, opts) => put(`${SCORE}/${id}/${DELETE_SCORE}`, body, opts)
      }
    }
  },
  benefitCategory: {
    updateStatus: (id, query, opts) => put(`${REG_BENEFIT_CATEGORY}/${id}/update-benefit-status`, { query }, opts),
    list: (id, query, opts) => get(`${REG_BENEFIT_CATEGORY}/${id}`, { query }, opts),
    uploadFiles: (id, body, opts) => post(`${REG_BENEFIT_CATEGORY}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${REG_BENEFIT_CATEGORY}/${id}/delete-file`, body, opts),
    submit: (body, opts) => put(`${REG_BENEFIT_CATEGORY}`, body, opts),
    register: (id, body, opts) => put(`${REG_BENEFIT_CATEGORY}/${id}/registration`, body, opts),
    verify: (id, body, opts) => put(`${REG_BENEFIT_CATEGORY}/${id}/verify`, body, opts),
    approve: (id, body, opts) => put(`${REG_BENEFIT_CATEGORY}/${id}/approve`, body, opts)
  },
  benefitCost: {
    list: (id, query, opts) => get(`${BENEFIT_COST}/${id}`, { query }, opts),
    submit: (body, opts) => post(`${BENEFIT_COST}`, body, opts),
    edit: (id, body, opts) => put(`${BENEFIT_COST}/${id}`, body, opts),
    delete: (id) => _delete(`${BENEFIT_COST}/${id}`),
    type: (query, opts) => get(`${BENEFIT_COST_TYPES}`, { query }, opts),
    purpose: (query, opts) => get(`${BENEFIT_COST_PURPOSES}`, { query }, opts),
    frequency: (query, opts) => get(`${BENEFIT_COST_FREQUENCIES}`, { query }, opts),
    metricType: (query, opts) => get(`${BENEFIT_COST_METRIC_TYPES}`, { query }, opts),
    stageGate: (query, opts) => get(`${BENEFIT_COST_STAGE_GATE}`, { query }, opts)
  },
  scaleUp: {
    view: (id, query, opts) => get(`${SCALE_UP}/${id}`, { query }, opts),
    create: (id, body, opts) => put(`${SCALE_UP}/${id}/${SUBMIT_G5}`, body, opts),
    saveAsDraft: (id, body, opts) => put(`${SCALE_UP}/${id}/${SCALE_UP_SAVE_AS_DRAFT}`, body, opts),
    uploadFiles: (id, body, opts) => post(`${SCALE_UP}/${id}/upload-files`, body, opts),
    deleteFile: (id, body, opts) => post(`${SCALE_UP}/${id}/delete-file`, body, opts),
    sendback: (id, body, opts) => post(`${SCALE_UP}/${id}/${SENTBACK}`, body, opts),
    approve: (id, body, opts) => post(`${SCALE_UP}/${id}/${APPROVE}`, body, opts),
    approval: (id, query, opts) => get(`${SCALE_UP}/${id}/${G5_APPROVAL_HISTORY}`, { query }, opts),
  },
  weeklyUpdate: {
    list: (id, query, opts) => get(`${BENEFIT_COST}/${WEEKLY_UPDATE}/${id}`, { query }, opts),
    get: (id) => get(`${BENEFIT_COST}/${WEEKLY_UPDATE_GET}/${id}`),
    deleteMulti: (body) => _delete(`${BENEFIT_COST}/${WEEKLY_UPDATE_MULTIPLE}`, body),
    submit: (body, opts) => post(`${BENEFIT_COST}/${WEEKLY_UPDATE}`, body, opts),
    edit: (id, body, opts) => put(`${BENEFIT_COST}/${WEEKLY_UPDATE}/${id}`, body, opts),
    delete: (id) => _delete(`${BENEFIT_COST}/${WEEKLY_UPDATE}/${id}`),
    export: (id, body, opts) => post(`${BENEFIT_COST}/${WEEKLY_UPDATE}/${EXCEL}?id=${id}`, body, { responseType: opts }),
  },
  impactTracking: {
    view: (id, query, opts) => get(`${IMPACT_TRACKING}/${id}`, { query }, opts)
  },
  innovationRepo: {
    list: (query, opts) => get(`${INNOVATION_REPO}`, { query }, opts),
    documents: (query, opts) => get(`${INNOVATION_REPO}/documents`, { query }, opts),
    download: (url, query, opts) => get(`${url}`, { query, responseType: opts }),
    multiDownload: (body, opts) => post(`${INNOVATION_REPO}/zip-document`, body, { responseType: opts }),
    exportExcel: (query, opts) => get(`${INNOVATION_REPO}/export-excel`, { query, responseType: opts })
  },
  innovationInvolvement: {
    list: (query, opts) => get(`${INNOVATION_INVOLVEMENTS}/list`, { query }, opts),
    detail: (userId, query, opts) => get(`${INNOVATION_INVOLVEMENTS}/${userId}/projects`, { query }, opts),
    roles: (query, opts) => get(`${INNOVATION_INVOLVEMENTS}/roles`, { query }, opts),
    chart: (query, opts) => get(`${INNOVATION_INVOLVEMENTS}`, { query }, opts),
    permissions: (id, query, opts) => get(`${INNOVATION_INVOLVEMENTS}/${id}/permissions`, { query }, opts),
    putPermissions: (body) => put(`${INNOVATION_INVOLVEMENTS}/permissions`, body)
  },
  notification: {
    manual: (id, body, opts) => post(`${MANUAL}?id=${id}`, body, opts),
    list: (query, opts) => get(`${ICON}`, { query }, opts),
    read: (id, body, opts) => put(`${READ_NOTIFICATION}?registrationId=${id}`, body, opts),
  },
  workFlow: {
    get: (id, query, opts) => get(`${WORK_FLOW}/form-status/${id}`, { query }, opts)
  },
  assessment: {
    getList: (query, opts) => get(`${ASSESSMENT_LIST}`, { query }, opts),
    getITMList: (query, opts) => get(`${ASSESSMENT_ITM_LEVEL_LIST}`, { query }, opts),
    items: (id, query, opts) => get(`${ITMNOVATION}/${id}/items`, { query }, opts),
    itemsITMLevel: (id, query, opts) => get(`${ITMNOVATION}/${id}/itmlevel-items`, { query }, opts),
    summaryList: (level, type, query, opts) => {
      return level === 'itm'
        ? get(`${ITMNOVATION}/summary-${type}/itm-level`, { query }, opts)
        : get(`${ITMNOVATION}/summary-${type}`, { query }, opts);
    },
    judges: (query, opts) => get(`${ASSESSMENT_JUDGES}`, { query }, opts),
    judgesITMLevel: (query, opts) => get(`${ASSESSMENT_JUDGES}/itm-level`, { query }, opts),
    post: (body, opts) => post(`${ITMNOVATION}`, body, opts),
    put: (id, body, opts) => put(`${ITMNOVATION}/${id}`, body, opts),
    submitAssessmentITMLevel: (body, opts) => post(`${ITMNOVATION}/itm-level`, body, opts),
    finish: (body, opts) => put(`${ITMNOVATION}/cut-off`, body, opts),
    finishITM: (body, opts) => put(`${ITMNOVATION}/cut-off/itm-level`, body, opts),
    reAssign: (body, opts) => put(`${ITMNOVATION}/update-status`, body, opts),
    getQuestions: (query, opts) => get(`${ITMNOVATION}/questions`, { query }, opts),
    cloneQuestion: (body, opts) => post(`${ITMNOVATION}/clone-question`, body, opts),
    addQuestion: (body, opts) => post(`${ITMNOVATION}/questions`, body, opts),
    editQuestion: (id, body, opts) => put(`${ITMNOVATION}/questions/${id}`, body, opts),
    deleteQuestion: (id, opts) => _delete(`${ITMNOVATION}/questions/${id}`, opts),
    unregister: (idRegistration, body, opts) => put(`${ITMNOVATION}/${idRegistration}/unregister`, body, opts),
    startConvention: (body, opts) => post(`${START_CONVENTION}`, body, opts),
    startConventionITMLevel: (body, opts) => post(`${START_CONVENTION}/itm-level`, body, opts),
  },
  haki: {
    list: (id, query, opts) => get(`${HAKI}/${id}`, { query }, opts),
    uploadFiles: (body, opts) => post(`${HAKI}/attachments`, body, opts),
    deleteFile: (node, body, opts) => _delete(`${HAKI}/attachments?node=${node}`, body, opts),
    submit: (body, opts) => post(`${HAKI}`, body, opts),
    detail: (id, query, opts) => get(`${HAKI}/details/${id}`, { query }, opts),
    edit: (id, body, opts) => put(`${HAKI}/details/${id}`, body, opts),
    delete: (id) => _delete(`${HAKI}/details/${id}`),
  }
};
