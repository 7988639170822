import * as Yup from 'yup';
import moment from 'moment';

export const initialValues = ({ detail, registration }) => {
  return {
    pocMilestone: detail?.pocMilestone || '',
    pocResult: detail?.pocResult || '',
    riskCompliance: detail?.riskCompliance || '',
    scrumMaster: detail?.g3Facilitator?.fullname || '',
    developerTeam: detail?.developerTeams || [],
    technologyTeam: detail?.technologyTeams || [],
    dataScientist: detail?.dataScientists || [],
    dataArchitect: detail?.dataArchitects || [],
    dataEngineer: detail?.dataEngineers || [],
    uiUxLead: detail?.uiUxLeads || [],
    dataVisualization: detail?.dataVisualizations || [],
    backlogs: detail?.g3PrototypeAttachments?.filter((d) => d.codeAttachment === 'Backlogs') || [],
    productBacklog: detail?.productBacklog || '',
    tools: detail?.g3PrototypeAttachments?.filter((d) => d.codeAttachment === 'Tools') || [],
    devOps: detail?.devOps || '',
    attachments: detail?.g3PrototypeAttachments?.filter((d) => d.codeAttachment === 'attachment') || [],
    mvpCompletionDate: detail?.mvpCompletionDate
      ? moment(detail?.mvpCompletionDate).toDate()
      : moment().toDate(),
    mmpTimeframe: detail?.mmpTimeframe
      ? moment(detail?.mmpTimeframe).toDate()
      : moment().toDate(),
    mvpMilestone: detail?.mvpMilestone || '',
    mvpFundingCapex: detail?.mvpFundingCapex || 0,
    mvpTimeframe: detail?.mvpTimeframe
      ? moment(detail?.mmpTimeframe).toDate()
      : moment().toDate(),
    mvpFundingOpex: detail?.mvpFundingOpex || 0,
    scaleupCompletionDate: detail?.scaleupCompletionDate
      ? moment(detail?.scaleupCompletionDate).toDate()
      : moment().toDate(),
    scaleupTimeframe: detail?.scaleupTimeframe
      ? moment(detail?.scaleupTimeframe).toDate()
      : moment().toDate(),
    scaleupFeature: detail?.scaleupFeature || '',
    scaleImplementation: detail?.scaleImplementation || '',
    plannedFeatureMvp: detail?.plannedFeatureMvp || '',
    plannedScopeMvp: detail?.plannedScopeMvp || '',
    plannedFeaturesMmp: detail?.plannedFeaturesMmp || '',
    plannedImplementationMmp: detail?.plannedImplementationMmp || '',
    g3RecurCost: detail?.g3RecurCost || 0,
    g3RecurBenefit: detail?.g3RecurBenefit || 0,
    g3OnetimeCost: detail?.g3OnetimeCost || 0,
    g3OnetimeBenefit: detail?.g3OnetimeBenefit || 0,
    formStatus: detail?.formStatus || '',
    teamMembers: registration?.innovationTeamMembers ? (registration?.innovationTeamMembers?.length
      ? registration.innovationTeamMembers.map((item) => ({ id: item.id, name: item.memberName }))
      : []) : [],
    action: '',
  };
};

export const validation = (isDigital) => Yup.object().shape({
  pocMilestone: Yup.string().required('Poc Milestones Information is required'),
  pocResult: Yup.string().required('Poc Result is required'),
  riskCompliance: Yup.string().required('Risk & Regulation is required'),
  backlogs: Yup.array().min(1, 'Product Backlog is required'),
  productBacklog: Yup.string().required('Product Backlog Description is required'),
  tools: isDigital
    ? Yup.array().min(1, 'DevOps Tools is required')
    : Yup.array(),
  devOps: isDigital
    ? Yup.string().required('DevOps Tools Description is required')
    : Yup.string(),
  developerTeam: isDigital
    ? Yup.array().min(1, 'Developer Team is required')
    : Yup.array(),
  technologyTeam: isDigital
    ? Yup.array().min(1, 'Technology Team is required')
    : Yup.array(),
  dataArchitect: isDigital
    ? Yup.array().min(1, 'Data Architect is required')
    : Yup.array(),
  dataEngineer: isDigital
    ? Yup.array().min(1, 'Developer Team is required')
    : Yup.array(),
  dataScientist: Yup.array(),
  mvpCompletionDate: Yup.string().required('MVP Completion Date is required'),
  mvpFundingCapex: Yup.number()
    .min(0, 'MVP Funding Capex should be 0 or more')
    .required('MVP Funding Capex is required'),
  mvpFundingOpex: Yup.number()
    .min(0, 'MVP Funding Opex should be 0 or more')
    .required('MVP Funding Opex is required'),
  attachments: Yup.array().min(1, 'Attachments is required'),
  g3RecurCost: Yup.number()
    .min(0, 'G3 Total Annual Net Recurring Cost should be 0 or more')
    .required('G3 Total Annual Net Recurring Cost is required'),
  g3RecurBenefit: Yup.number()
    .min(0, 'G3 Total Annual Net Recurring Benefit should be 0 or more')
    .required('G3 Total Annual Net Recurring Benefit is required'),
  g3OnetimeCost: Yup.number()
    .min(0, 'G3 Total Annual Net One Time Cost should be 0 or more')
    .required('G3 Total Annual Net One Time Cost is required'),
  g3OnetimeBenefit: Yup.number()
    .min(0, 'G3 Total Annual One Time Net Benefit should be 0 or more')
    .required('G3 Total Annual One Time Net Benefit is required'),
});
