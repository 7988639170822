import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { DEMAND_TYPES } from 'src/api/backendUrl';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { isValidationRequired } from 'src/utils/validation';

const DemandType = ({
  disabled,
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  validation
}) => {
  const key = 'demandTypeId';
  const { data: options, refetch } = useQuery([DEMAND_TYPES], async () => {
    const response = await api.masterData.demandTypes();
    return response?.data?.errors
      ? []
      : response?.data?.data;
  }, { initialData: [] });

  useEffect(() => { refetch(); }, []);

  return (
    <FormItem
      required={isValidationRequired(key, validation, values, 'IT Demand')}
      id={key}
      label="Demand Type"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        name={key}
        disabled={disabled}
        placeholder="Select demand type"
        value={values[key]}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FormItem>
  );
};

DemandType.propTypes = {
  disabled: PropTypes.bool,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default DemandType;
