import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';
import { useQuery } from 'react-query';
import { BENEFIT_CATEGORIES } from 'src/api/backendUrl';
import api from 'src/api';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textinput: {

  }
}));

const CommitteeSuggestion = ({
  title,
  message,
  isSubmitting = false,
  onConfirm,
  textConfirm = 'Yes',
  textCancel = 'No',
  textDialog = '',
  children,
  ...buttonProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('');
  const [suggestion, setSuggestion] = React.useState([]);
  const [disable, setDisable] = React.useState(isSubmitting);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const benefitOptions = useQuery(BENEFIT_CATEGORIES, async () => {
    const { data: response } = await api.masterData.benefitCategories({ Sorts: 'name' });
    return response?.data;
  }, { initialData: [] });
  const handleChange = (value) => {
    if (value) {
      setSuggestion(value);
    }
  };
  const handleDeleteBenefit = (value) => {
    const newValue = suggestion.filter((val) => val !== value);
    setSuggestion(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setDisable(true);
    onConfirm && onConfirm(text, suggestion);
    setOpen(false);
    setDisable(false);
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => handleClickOpen()}
      >
        {children}
      </Button>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {textDialog}
          </DialogContentText>
          <FormItem
            label="Suggestion Benefit Category"
            id="suggestion"
          >
            <StyledMultiSelect
              name="suggestion"
              onChange={(e) => handleChange(e.target.value)}
              handleDelete={(value) => handleDeleteBenefit(value)}
              value={suggestion}
              placeholder="Select Category"
              options={benefitOptions?.data || []}
            />
          </FormItem>
          <FormItem
            label="Comment"
          >
            <TextField
              className={classes.textinput}
              name="comment"
              variant="outlined"
              multiline
              rows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </FormItem>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={disable || suggestion.length < 1}
            onClick={handleConfirm}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            {textConfirm}
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button variant="outlined" disabled={disable} onClick={handleClose} color="secondary">
            {textCancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CommitteeSuggestion.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  textDialog: PropTypes.string,
  textConfirm: PropTypes.string,
  textCancel: PropTypes.string,
  children: PropTypes.node,
};

export default CommitteeSuggestion;
