import SendBird from 'sendbird';

export const sbUpdateProfile = (nickname) => {
  return new Promise((resolve, reject) => {
    if (!nickname) {
      reject(new Error('Nickname is required.'));
      return;
    }
    const sb = SendBird.getInstance();
    sb.updateCurrentUserInfo(nickname, '', (user, error) => {
      if (error) {
        reject(new Error('Update profile failed.'));
      } else {
        resolve(user);
      }
    });
  });
};

export const sbConnect = (userId, nickname, APP_ID) => {
  return new Promise((resolve, reject) => {
    const sb = new SendBird({ appId: APP_ID });
    try {
      sb.connect(userId, (user, error) => {
        if (error) {
          reject(new Error('SendBird Login Failed.'));
        } else {
          resolve(sbUpdateProfile(nickname));
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sbDisconnect = () => {
  return new Promise((resolve) => {
    const sb = SendBird.getInstance();
    if (sb) {
      sb.disconnect(() => {
        resolve(null);
      });
    } else {
      resolve(null);
    }
  });
};

export const sbGetCurrentInfo = () => {
  const sb = SendBird.getInstance();
  return {
    profileUrl: sb.currentUser.profileUrl,
    nickname: sb.currentUser.nickname
  };
};

export const sbCreateGroupChannelListQuery = () => {
  const sb = SendBird.getInstance();
  return sb.GroupChannel.createMyGroupChannelListQuery();
};

export const sbGetGroupChannelList = (groupChannelListQuery) => {
  return new Promise((resolve, reject) => {
    try {
      if (groupChannelListQuery.hasNext) {
        groupChannelListQuery.next((channels, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(channels);
          }
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const sbGetGroupChannel = (channelUrl) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(channel);
      }
    });
  });
};

export const sbLeaveGroupChannel = (channelUrl) => {
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel) => {
        channel.leave((response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error) => reject(error));
  });
};

export const sbHideGroupChannel = (channelUrl) => {
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel) => {
        channel.hide((response, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        });
      })
      .catch((error) => reject(error));
  });
};

export const sbCreateUserListQuery = () => {
  const sb = SendBird.getInstance();
  return sb.createApplicationUserListQuery();
};

export const sbGetUserList = (userListQuery) => {
  return new Promise((resolve, reject) => {
    userListQuery.next((users, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(users);
      }
    });
  });
};

export const sbRegisterPushToken = (fcm) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.registerGCMPushTokenForCurrentUser(fcm)
      .then((response, error) => {
        if (error) {
          reject(error);
        } else {
          sb.setBackgroundState();
          resolve(response);
        }
      })
      .catch((error) => reject(error));
  });
};

export const sbUnregisterPushToken = (fcm) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    sb.unregisterPushTokenAllForCurrentUser()
      .then((response, error) => {
        if (error) {
          reject(error);
        } else {
          sbRegisterPushToken(fcm);
        }
      })
      .catch((error) => reject(error));
  });
};

export const sbCreateGroupChannel = (inviteUserIdList, isDistinct) => {
  return new Promise((resolve, reject) => {
    const sb = SendBird.getInstance();
    try {
      sb.GroupChannel.createChannelWithUserIds(inviteUserIdList, isDistinct, (channel, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(channel);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sbInviteGroupChannel = (inviteUserIdList, channelUrl) => {
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel) => {
        channel.inviteWithUserIds(inviteUserIdList, (channels, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(channels);
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sbSendMessageGroup = (channel, params) => {
  return new Promise((resolve, reject) => {
    try {
      channel.sendUserMessage(params, (message, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(message);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sbSendFileGroup = (channel, params, handle) => {
  return new Promise((resolve, reject) => {
    channel.sendFileMessage(params, (event) => {
      handle(event);
    }, (message, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(message);
      }
    });
  });
};

export const sbCreatePreviousMessageListQuery = (groupChannel, limit, reverse) => {
  return new Promise((resolve, reject) => {
    const query = groupChannel.createPreviousMessageListQuery();
    query.limit = limit;
    query.reverse = reverse;
    query.load((messages, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(messages);
      }
    });
  });
};

export const sbGetPreviousMessagesByID = (groupChannel, messageId, members, size = 10) => {
  return new Promise((resolve, reject) => {
    groupChannel.getPreviousMessagesByID(
      messageId,
      true,
      size,
      false,
      '',
      '',
      members,
      false,
      false, (messages, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(messages);
        }
      }
    );
  });
};

export const sbGetTotalUnreadMessageCount = () => {
  return new Promise((resolve, reject) => {
    try {
      const sb = SendBird.getInstance();
      sb.getTotalUnreadMessageCount((count, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(count);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const sbGetTotalUnreadChannelCount = () => {
  return new Promise((resolve, reject) => {
    try {
      const sb = SendBird.getInstance();
      sb.getTotalUnreadChannelCount((count, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(count);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
