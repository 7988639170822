import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const USDInvestment = ({
  handleBlur,
  handleChange,
  values,
  rate
}) => {
  const usd = Number(values.payment / rate?.rateNow || 0).toLocaleString('en-US', { maximumFractionDigits: 2 });
  return (
    <FormItem
      label="USD"
      id="usd"
    >
      <StyledInput
        name="usd"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={usd || '-'}
        disabled
      />
    </FormItem>
  );
};

USDInvestment.propTypes = {
  rate: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default USDInvestment;
