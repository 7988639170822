import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import { useStoreState } from 'easy-peasy';

import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import api from 'src/api';

import Page from 'src/components/Page';
import CardView from './CardView';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: 'bold'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      backgroundColor: '#EDEEFD',
      color: '#1A051D',
      fontWeight: 'bold',
    },
  },
  contentPagination: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  contentShowing: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  }
}));

const GeneralSearch = () => {
  const classes = useStyles();

  const pageSize = 10;
  const prevNavigation = useStoreState((state) => state.navigation.previous);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const searchParams = params.get('search');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });

  const { data, refetch } = useQuery('search-bar', async () => {
    setIsFetching(true);

    const filter = {
      Filters: `searchbar==${searchParams || ''}`,
      pageSize: 10,
      page: currentPage
    };

    const { data: response } = await api.registration.searchBar(filter);
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response?.totalData === 0
        ? response?.totalData
        : showing.show,
      to: response?.totalData <= pageSize
        ? response?.totalData
        : showing.to
    });

    setIsFetching(false);
    return response?.data;
  });

  const changePagination = (event, page) => {
    let first = 1;
    let last = pageSize * page;
    for (let i = 1; i < pageSize * page; i += pageSize) {
      first = i;
    }
    if (totalData > last) {
      last = pageSize * page;
    } else {
      last = totalData;
    }
    setShowing({
      show: first,
      to: last
    });
    setCurrentPage(page);
  };

  const handleBackButton = () => {
    navigate(prevNavigation);
  };

  useEffect(() => {
    refetch();
  }, [searchParams, currentPage]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Box>
          <Grid container spacing={3}>
            <Grid item lg={11} md={11} sm={11} xs={11}>
              <Typography className={classes.title}>
                Result for
                &quot;
                {searchParams}
                &quot;
              </Typography>

              <Typography>
                Result
              </Typography>
            </Grid>

            <Grid
              item
              lg={1}
              md={1}
              sm={1}
              xs={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {
                isFetching && (
                  <CircularProgress size={24} />
                )
              }
            </Grid>
          </Grid>

          <Box mt={5}>
            <Grid
              container
              spacing={4}
              style={{ maxHeight: '30rem', overflow: 'auto' }}
            >
              {
                (data && data.length)
                  ? data.map((item, index) => (
                    <Grid item key={`item-${index + 1}`} lg={12} md={12} sm={12} xs={12}>
                      <CardView {...item} />
                    </Grid>
                  ))
                  : (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography className={classes.title}>
                        { isFetching ? 'Searching...' : 'No Data' }
                      </Typography>
                    </Box>
                  )
              }
            </Grid>
            {
              (data && data.length)
                ? (
                  <Grid
                    container
                    direction="row"
                    alignContent="space-between"
                    alignItems="center"
                    style={{ marginTop: 35 }}
                  >
                    <Grid
                      item
                      lg
                      md
                      sm
                      xs
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ width: '10rem' }}
                        onClick={() => handleBackButton()}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid
                      item
                      lg
                      md
                      sm
                      xs
                      className={classes.contentPagination}
                    >
                      <Pagination
                        className={classes.pagination}
                        onChange={changePagination}
                        color="secondary"
                        type="next"
                        count={totalPage}
                        page={currentPage}
                        shape="rounded"
                      />
                    </Grid>
                  </Grid>
                )
                : (
                  <Typography />
                )
            }
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default GeneralSearch;
