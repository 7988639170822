import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Header from 'src/components/Header';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Toolbar = ({
  className, path, detail, ...rest
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
      >
        <Grid container>
          <Grid item lg={6} md={4} sm={12}>
            <Header
              title={path}
              currentPath={path}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  detail: PropTypes.object
};

export default Toolbar;
