import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Checkbox,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { DescriptionTwoTone } from '@material-ui/icons';
import { useQuery, useMutation } from 'react-query';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import api from 'src/api';
import {
  ASSESSMENT_JUDGES,
  ITMNOVATION,
  ORGANIZATION_ALL,
  YEAR_BUILT,
} from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import ButtonConfirmation from 'src/components/ButtonConfirmation';
import PopUp from 'src/views/innovationConvention/summary/PopUp';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  boxFilter: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15,
    position: 'flex',
    flexDirection: 'column'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
}));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    borderBottomWidth: 4,
    borderLeft: '4px solid white',
    borderColor: 'white',
    color: 'white',
    fontWeight: 600,
    paddingTop: 6,
    paddingBottom: 6,
  },
  body: {
    fontSize: 14,
    backgroundColor: 'rgba(205,198,225,0.25)',
    borderTopWidth: 4,
    borderBottomWidth: 4,
    borderLeft: '4px solid white',
    borderColor: 'white',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const Summary = () => {
  const classes = useStyles();
  const { level } = useParams();
  const { user } = useStoreState((state) => state.auth);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const isAdmin = user?.roles?.some((role) => role.name === 'Administrator' || role.name === 'Admin Site');
  const isAdminOnly = user?.roles?.some((role) => role.name === 'Administrator');
  const organizationName = user?.organizationName;
  const isLevelITM = level.toLowerCase() === 'itm';
  const initialAdminDetailHeader = [
    ...isAdminOnly
      ? [{ key: 'action', title: 'Action' }]
      : [],
    { key: 'projectName', title: 'Project Name' },
    ...isLevelITM
      ? [{ key: 'site', title: 'Site' }]
      : [],
    { key: 'score', title: 'Score' },
    { key: 'ranking', title: 'Ranking' }
  ];
  const [filter, setFilter] = useState({
    year: '',
    site: '',
    showScore: false,
    showDetail: false
  });
  const [regId, setRegId] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tableAdminDetailHeader, setTableAdminDetailHeader] = useState(initialAdminDetailHeader);
  const [tableAdminDetailSubHeader, setTableAdminDetailSubHeader] = useState([]);

  const currentYear = new Date().getFullYear();
  /**
   * This header used for judges and admin
   * when in admin mode, this is a non-detail header
   * for detail header see `tableAdminDetailHeader`
   * */
  const tableNonScoreHeader = [
    ...isAdminOnly
      ? [{ key: 'action', title: 'Action' }]
      : [],
    { key: 'projectName', title: 'Project Name' },
    ...isLevelITM
      ? [{ key: 'site', title: 'Site' }]
      : [],
    { key: 'finalScore', title: 'Final Score' },
    { key: 'finalRanking', title: 'Ranking' },
    ...isLevelITM
      ? [{ key: 'bandOfWinner', title: 'Band of Winner' }]
      : []
  ];
  const tableScoreHeader = [
    { key: 'projectName', title: 'Project Name' },
    ...isLevelITM
      ? [{ key: 'site', title: 'Site' }]
      : [],
    { key: 'score', title: 'Score' },
    { key: 'ranking', title: 'Ranking' },
    { key: 'finalScore', title: 'Final Score' },
    { key: 'finalRanking', title: 'Ranking' }
  ];

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response.data.data;
    }, {
      initialData: [],
      onSuccess: (data) => {
        if (data.length > 0 && data.some((val) => Number(val.id) === currentYear)) {
          setFilter((prevState) => ({ ...prevState, year: currentYear }));
        }
      }
    }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_ALL, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.organizationAll(query);

      return response.data.data;
    }, {
      initialData: [],
      onSuccess: (data) => {
        if (data.length > 0) {
          const siteId = data.find(
            (val) => val.name.toLowerCase() === organizationName.toLowerCase(),
          )
            ? data.find((val) => val.name === organizationName).id
            : null;

          if (siteId) {
            setFilter((prevState) => ({ ...prevState, site: siteId }));
          }
        }
      }
    }
  );

  const {
    data: judges,
    isFetching: judgesIsFetching
  } = useQuery(
    [ASSESSMENT_JUDGES, regId],
    async () => {
      const response = isLevelITM
        ? await api.assessment.judgesITMLevel({ filters: `RegistrationId==${regId}` })
        : await api.assessment.judges({ filters: `RegistrationId==${regId}` });

      return response?.data?.data;
    },
    {
      initialData: [],
      enabled: Boolean(regId)
    }
  );

  const {
    data: summary,
    isFetching,
    refetch: summaryRefetch
  } = useQuery(
    [ITMNOVATION, filter],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const { year, site } = query;
      const response = await api.assessment.summaryList(
        level.toLowerCase(),
        isAdmin
          ? 'admin'
          : 'judges',
        {
          year,
          ...isAdmin
            ? { siteId: isLevelITM ? '' : site }
            : undefined
        }
      );

      return isAdmin
        ? response?.data?.data
        : {
          isAllAssessed: false,
          datas: response?.data?.data
        };
    }, {
      initialData: {
        isAllAssessed: false,
        datas: []
      },
      enabled: isAdmin
        ? (Boolean(filter.year) && Boolean(filter.site))
        : (Boolean(filter.year))
    }
  );

  const {
    mutate: finishSummaryMutate,
    isLoading: finishSummaryIsLoading,
    isSuccess: finishSummaryIsSuccess,
    isError: finishSummaryIsError,
    data: finishSummaryData,
    error: finishSummaryError,
  } = useMutation((form) => api.assessment.finish(form));

  const {
    mutate: finishSummaryITMMutate,
    isLoading: finishSummaryITMIsLoading,
    isSuccess: finishSummaryITMIsSuccess,
    isError: finishSummaryITMIsError,
    data: finishSummaryITMData,
    error: finishSummaryITMError,
  } = useMutation((form) => api.assessment.finishITM(form));

  const {
    mutate: reAssignMutate,
    isLoading: reAssignIsLoading,
    isSuccess: reAssignIsSuccess,
    isError: reAssignIsError,
    data: reAssignData,
    error: reAssignError,
  } = useMutation((form) => api.assessment.reAssign(form));

  const onFilterChange = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const onActionClick = (registrationId) => {
    setIsDialogOpen(true);

    setRegId(registrationId);
  };

  const onFinishSummary = () => {
    return isLevelITM
      ? finishSummaryITMMutate({ year: filter.year })
      : finishSummaryMutate({ year: filter.year, siteId: filter.site });
  };

  const onReassignJudges = (judgeList) => {
    const transformedBody = {
      registrationId: regId,
      judges: judgeList.map((id) => ({ judgeId: id })),
      assessmentType: level.toUpperCase()
    };

    reAssignMutate(transformedBody);
  };

  const transformAdminDetailHeader = (data) => {
    const firstHeader = initialAdminDetailHeader[0];
    const secondHeader = initialAdminDetailHeader[1];
    const thirdHeader = initialAdminDetailHeader[2];
    const lastHeader = isAdmin
      ? initialAdminDetailHeader.splice(isLevelITM ? 3 : 2)
      : initialAdminDetailHeader.splice(1);
    const setOfJudges = new Set();
    const subHeaderCollections = [];

    if (data && Boolean(data.length)) {
      data.forEach((item) => {
        item.judges.forEach((judge) => {
          setOfJudges.add(judge.name);
        });
      });

      const judgeHeader = Array.from(setOfJudges).map((item) => ({
        key: item,
        title: item,
        children: [
          {
            key: `subScore_${item}`,
            title: 'Score',
          },
          {
            key: `subRanking_${item}`,
            title: 'Ranking'
          }
        ]
      }));

      judgeHeader.forEach((item) => {
        subHeaderCollections.push(...item.children);
      });

      return {
        ...isAdmin
          ? {
            headers: [
              firstHeader,
              secondHeader,
              ...isLevelITM
                ? [thirdHeader]
                : [],
              ...judgeHeader,
              ...lastHeader
            ]
          }
          : {
            headers: [
              firstHeader,
              ...judgeHeader,
              ...lastHeader
            ]
          },
        subHeaders: subHeaderCollections
      };
    }

    return {
      headers: [],
      subHeaders: []
    };
  };

  const roundAdminScore = (key, data) => {
    if (key === 'score') {
      return (data[key] || data[key] === 0)
        ? Math.round(data[key])
        : '-';
    }

    return data[key];
  };

  const findJudgesData = (data, header, key) => {
    if ((data && data.length) && header) {
      const judgesData = data.find((item) => header.key === item.name);

      if (judgesData) {
        if (key === 'score') {
          return (judgesData[key] || judgesData[key] === 0)
            ? Math.round(judgesData[key])
            : '-';
        }

        return judgesData[key] || '-';
      }
    }

    return '-';
  };

  useEffect(() => {
    if (isAdmin && summary) {
      const transformedHeader = transformAdminDetailHeader(summary.datas);

      setTableAdminDetailHeader(transformedHeader.headers);
      setTableAdminDetailSubHeader(transformedHeader.subHeaders);
    }
  }, [summary]);

  useEffect(() => {
    switch (true) {
      case finishSummaryIsSuccess:
      case finishSummaryITMIsSuccess:
      case reAssignIsSuccess: {
        let successData = null;
        setIsDialogOpen(false);
        setRegId('');

        if (finishSummaryIsSuccess) {
          successData = finishSummaryData;
        } else {
          successData = finishSummaryITMIsSuccess
            ? finishSummaryITMData
            : reAssignData;
        }

        if (successData && successData.status >= 400) {
          setNotif({
            message: successData?.data?.message?.messageEng || (
              (finishSummaryIsSuccess || finishSummaryITMIsSuccess)
                ? 'Assessment failed to finish (cutoff)'
                : 'Assessment failed to reassign'
            ),
            option: {
              variant: 'error'
            }
          });
          break;
        }

        setNotif({
          message: (finishSummaryIsSuccess || finishSummaryITMIsSuccess)
            ? 'Assessment finished (cutoff) successfully'
            : 'Assessment reassigned successfully',
          option: {
            variant: 'success'
          }
        });

        summaryRefetch();
        break;
      }
      case finishSummaryIsError:
      case finishSummaryITMIsError:
      case reAssignIsError: {
        let errorData = null;

        if (finishSummaryIsError) {
          errorData = finishSummaryError;
        } else {
          errorData = finishSummaryITMIsError
            ? finishSummaryITMError
            : reAssignError;
        }

        setNotif({
          message: errorData?.message || (
            (finishSummaryIsError || finishSummaryITMIsError)
              ? 'Assessment failed to finish (cutoff)'
              : 'Assessment failed to reassign'
          ),
          option: {
            variant: 'error'
          }
        });
        break;
      }
      default:
        break;
    }
  }, [
    finishSummaryIsSuccess,
    finishSummaryIsError,
    finishSummaryError,
    finishSummaryITMIsSuccess,
    finishSummaryITMIsError,
    finishSummaryITMError,
    reAssignIsSuccess,
    reAssignIsError,
    reAssignError
  ]);

  return (
    <Page
      title={`Innovation Convention Level ${isLevelITM ? 'ITM' : 'Site'}`}
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path={`Innovation Convention Summary Level ${isLevelITM ? 'ITM' : 'Site'}`} />
        <Grid container spacing={4} className={classes.boxFilter}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.titleFilter}>
              Filter
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
          >
            <FormItem
              label="YEAR"
              id="year"
            >
              <StyledSelect
                name="year"
                placeholder="Select year"
                value={filter.year}
                options={years}
                onChange={(ev) => onFilterChange('year', ev.target.value)}
              />
            </FormItem>
          </Grid>
          {
            isAdmin && (
              <>
                {
                  isLevelITM
                    ? (
                      <Grid item xs={12} sm={12} md={5} lg={5} />
                    )
                    : (
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <FormItem
                          label="SITE"
                          id="site"
                        >
                          <StyledSelect
                            name="site"
                            placeholder="Select site"
                            value={filter.site}
                            options={sites}
                            onChange={(ev) => onFilterChange('site', ev.target.value)}
                          />
                        </FormItem>
                      </Grid>
                    )
                }
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  <Checkbox
                    checked={filter.showDetail}
                    onChange={(ev) => onFilterChange('showDetail', ev.target.checked)}
                  />
                  <span>SHOW DETAIL</span>
                </Grid>
              </>
            )
          }
        </Grid>
        <Grid container className={classes.card}>
          {
            !isAdmin && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <Checkbox
                  checked={filter.showScore}
                  onChange={(ev) => onFilterChange('showScore', ev.target.checked)}
                />
                <span>SHOW MY SCORE</span>
              </Grid>
            )
          }
          {
            isFetching && (
              <LinearProgress style={{ marginBottom: '0.5rem' }} />
            )
          }
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
          >
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {
                    isAdmin
                      ? (
                        <>
                          {
                            filter.showDetail
                              ? (
                                <>
                                  {
                                    tableAdminDetailHeader
                                      .map((row, index) => {
                                        return (
                                          <StyledTableCell
                                            align="center"
                                            key={row?.key}
                                            isFirst={index === 0}
                                            isLast={index === (tableAdminDetailHeader.length - 1)}
                                            {
                                              ...(row.children && row.children.length)
                                                ? { colSpan: 2 }
                                                : { rowSpan: 2 }
                                            }
                                          >
                                            <Box className={classes.sortContainer}>
                                              {row.title}
                                            </Box>
                                          </StyledTableCell>
                                        );
                                      })
                                  }
                                </>
                              )
                              : (
                                <>
                                  {
                                    tableNonScoreHeader.map((row, index) => {
                                      return (
                                        <StyledTableCell
                                          align="center"
                                          key={row?.key}
                                          isFirst={index === 0}
                                          isLast={index === (tableNonScoreHeader.length - 1)}
                                        >
                                          <Box className={classes.sortContainer}>
                                            {row.title}
                                          </Box>
                                        </StyledTableCell>
                                      );
                                    })
                                  }
                                </>
                              )
                          }
                        </>
                      )
                      : (
                        <>
                          {
                            filter.showScore
                              ? (
                                <>
                                  {
                                    tableScoreHeader.map((row, index) => {
                                      return (
                                        <StyledTableCell
                                          align="center"
                                          key={row?.key}
                                          isFirst={index === 0}
                                          isLast={index === (tableScoreHeader.length - 1)}
                                        >
                                          <Box className={classes.sortContainer}>
                                            {row.title}
                                          </Box>
                                        </StyledTableCell>
                                      );
                                    })
                                  }
                                </>
                              )
                              : (
                                <>
                                  {
                                    tableNonScoreHeader.map((row, index) => {
                                      return (
                                        <StyledTableCell
                                          align="center"
                                          key={row?.key}
                                          isFirst={index === 0}
                                          isLast={index === (tableNonScoreHeader.length - 1)}
                                        >
                                          <Box className={classes.sortContainer}>
                                            {row.title}
                                          </Box>
                                        </StyledTableCell>
                                      );
                                    })
                                  }
                                </>
                              )
                          }
                        </>
                      )
                  }
                </StyledTableRow>
                {
                  isAdmin && filter.showDetail && (
                    <StyledTableRow>
                      {
                        tableAdminDetailSubHeader
                          .map((row, index) => (
                            <StyledTableCell
                              align="center"
                              key={`${row?.key}_${index + 1}`}
                            >
                              <Box className={classes.sortContainer}>
                                {row.title}
                              </Box>
                            </StyledTableCell>
                          ))
                      }
                    </StyledTableRow>
                  )
                }
              </TableHead>
              <TableBody className={classes.header}>
                {
                  isAdmin
                    ? (
                      <>
                        {
                          filter.showDetail
                            ? (
                              <>
                                {
                                  // eslint-disable-next-line max-len
                                  (summary && Boolean(summary.datas.length)) && summary.datas.map((row) => {
                                    return (
                                      <StyledTableRow
                                        key={row?.registrationId}
                                        style={{ marginBottom: '5px' }}
                                      >
                                        {
                                          tableAdminDetailHeader.map((header, index) => (
                                            <Fragment key={`cell_${header.key}_${index + 1}`}>
                                              {
                                                (isAdminOnly && header.key === 'action')
                                                  ? (
                                                    <StyledTableCell align="center">
                                                      <DescriptionTwoTone
                                                        style={{
                                                          color: '#737AF1',
                                                          cursor: 'pointer'
                                                        }}
                                                        onClick={
                                                          () => onActionClick(row?.registrationId)
                                                        }
                                                      />
                                                    </StyledTableCell>
                                                  )
                                                  : (
                                                    <>
                                                      {
                                                        initialAdminDetailHeader
                                                          // eslint-disable-next-line max-len
                                                          .some((initialHeader) => initialHeader.key === header.key)
                                                          ? (
                                                            <StyledTableCell
                                                              {...header.key !== 'projectName' && { align: 'center' }}
                                                            >
                                                              {roundAdminScore(header.key, row)}
                                                            </StyledTableCell>
                                                          )
                                                          : (
                                                            <>
                                                              <StyledTableCell align="center">
                                                                {findJudgesData(row.judges, header, 'score')}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {findJudgesData(row.judges, header, 'ranking')}
                                                              </StyledTableCell>
                                                            </>
                                                          )
                                                      }
                                                    </>
                                                  )
                                              }
                                            </Fragment>
                                          ))
                                        }
                                      </StyledTableRow>
                                    );
                                  })
                                }
                              </>
                            )
                            : (
                              <>
                                {
                                  // eslint-disable-next-line max-len
                                  (summary && Boolean(summary.datas.length)) && summary.datas.map((row) => (
                                    <StyledTableRow
                                      key={row?.registrationId}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      {
                                        isAdminOnly && (
                                          <StyledTableCell align="center">
                                            <DescriptionTwoTone
                                              style={{
                                                color: '#737AF1',
                                                cursor: 'pointer'
                                              }}
                                              onClick={() => onActionClick(row?.registrationId)}
                                            />
                                          </StyledTableCell>
                                        )
                                      }
                                      <StyledTableCell>
                                        {row?.projectName}
                                      </StyledTableCell>
                                      {
                                        isLevelITM && (
                                          <StyledTableCell align="center">
                                            {row?.site}
                                          </StyledTableCell>
                                        )
                                      }
                                      <StyledTableCell align="center">
                                        {
                                          (row?.score || row?.score === 0)
                                            ? Math.round(row?.score)
                                            : '-'
                                        }
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row?.ranking}
                                      </StyledTableCell>
                                      {
                                        isLevelITM && (
                                          // WIP: Integrate with real key from API
                                          <StyledTableCell align="center">
                                            {row?.bandOfWinner || '-'}
                                          </StyledTableCell>
                                        )
                                      }
                                    </StyledTableRow>
                                  ))
                                }
                              </>
                            )
                        }
                      </>
                    )
                    : (
                      <>
                        {
                          filter.showScore
                            ? (
                              <>
                                {
                                  // eslint-disable-next-line max-len
                                  (summary && Boolean(summary.datas.length)) && summary.datas.map((row) => (
                                    <StyledTableRow
                                      key={row?.registrationId}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      <StyledTableCell>
                                        {row?.projectName}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {
                                          (row?.score || row?.score === 0)
                                            ? Math.round(row?.score)
                                            : '-'
                                        }
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row?.ranking}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {
                                          (row?.finalScore || row?.finalScore === 0)
                                            ? Math.round(row?.finalScore)
                                            : '-'
                                        }
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row?.finalRanking}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ))
                                }
                              </>
                            )
                            : (
                              <>
                                {
                                  // eslint-disable-next-line max-len
                                  (summary && Boolean(summary.datas.length)) && summary.datas.map((row) => (
                                    <StyledTableRow
                                      key={row?.registrationId}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      <StyledTableCell>
                                        {row?.projectName}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {
                                          (row?.finalScore || row?.finalScore === 0)
                                            ? Math.round(row?.finalScore)
                                            : '-'
                                        }
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {row?.finalRanking}
                                      </StyledTableCell>
                                      {
                                        isLevelITM && (
                                          <StyledTableCell align="center">
                                            {row?.bandOfWinner}
                                          </StyledTableCell>
                                        )
                                      }
                                    </StyledTableRow>
                                  ))
                                }
                              </>
                            )
                        }
                      </>
                    )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {
          (summary && summary?.isAllAssessed) && (
            <Box style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '20px'
            }}
            >
              <ButtonConfirmation
                isSubmitting={finishSummaryIsLoading || finishSummaryITMIsLoading}
                title="Confirmation"
                textDialog="Are you sure you want to Finish ?"
                textConfirm="Ok"
                textCancel="Cancel"
                variant="contained"
                color="secondary"
                disabled={false}
                onConfirm={onFinishSummary}
                className={clsx(classes.button, classes.label)}
              >
                Finish
              </ButtonConfirmation>
            </Box>
          )
        }
      </Container>

      <PopUp
        {...{
          isDialogOpen,
          isLoading: judgesIsFetching,
          isSubmitting: reAssignIsLoading,
          data: judges,
          handleClose: () => {
            setRegId('');
            setIsDialogOpen(false);
          },
          handleConfirm: (judgeList) => onReassignJudges(judgeList)
        }}
      />
    </Page>
  );
};

export default Summary;
