import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { BENEFIT_COST_FREQUENCIES } from 'src/api/backendUrl';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

const Frequency = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values,
  typeId,
}) => {
  const key = 'frequency';
  const { data } = useQuery(BENEFIT_COST_FREQUENCIES, async () => {
    const response = await api.benefitCost.frequency();
    return response?.data.errors
      ? []
      : response?.data.data;
  });
  const frequencies = data?.filter((item) => item.type.toLowerCase() == typeId.toLowerCase());
  const isDisabled = values.action === 'detail';

  return (
    <FormItem
      id={key}
      label="Frequency"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        name={key}
        placeholder="Enter Frequency"
        options={frequencies}
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isDisabled}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

Frequency.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Frequency;
