const colors = ['#539ef4', '#8de5ac', '#efbe55', '#dd6678', '#8271c8', '#72848d', '#fffb8f', '#69b1a9', '#c862e1'];
export default {
  chartData: {
    benefitDonut1: {
      series: [],
      labels: [],
      title: '',
    },
    benefitDonut2: {
      series: [],
      labels: [],
      title: '',
    },
    benefitDonut3: {
      series: [],
      labels: [],
      title: '',
    },
    projectBenefitIdeaBlast: {
      series1: [{
        name: '',
        data: []
      }],
      series2: [{
        name: '',
        data: []
      }],
      labels1: [],
      labels2: [],
      title: ''
    },
    projectBenefitInfinity: {
      series1: [{
        name: '',
        data: []
      }],
      series2: [{
        name: '',
        data: []
      }],
      labels1: [],
      labels2: [],
      title: ''
    },
    projectStatusOverviewG0: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        colors: ['#5a91bf', '#539ef4', '#efbe55'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1
        },
      },
    },
    projectStatusOverviewG1Complete: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: ['#7dae52', '#fcf75f', '#c85d4c', '#c8c8c8'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            columnWidth: '55%',
            borderRadius: 8,
            horizontal: false,
          },
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1
        }
      }
    },
    projectBenefitImpact: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        colors: ['#f1c753', '#7dae52', '#000000', '#4a6ec3'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter(val) {
              return `Rp${ val}`;
            }
          }
        }
      },
    },
    totalInvestment: {
      series: [],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        colors: ['#b2422f', '#6a9ad0'],
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            }
          }
        }],
        tooltip: {
          y: {
            formatter(val) {
              return `$${ val}`;
            }
          }
        },
        title: {
          text: 'Total Investment VS Value Impact',
          align: 'center',
          style: {
            fontWeight: 'normal',
            fontFamily: 'SFProText',
          },
        }
      },
    },
    ideaBlastStatusOverview: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        colors: ['#fcf75f', '#7fab55', '#366fba'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
            endingShape: 'rounded',
            distributed: true
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          shared: false
        },
        title: {
          text: 'Idea Blast Status Overview',
          align: 'center',
          style: {
            fontWeight: 'normal',
            fontFamily: 'SFProText',
          },
        }
      },
    },
    ideaBlastBenefitImpact: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 50
        },
        colors: ['#568d47'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        },
        title: {
          text: 'Idea Blast Benefit Impact',
          align: 'center',
          style: {
            fontWeight: 'normal',
            fontFamily: 'SFProText',
          },
        }
      },
    },
    ecii: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 50
        },
        colors: ['#366fba'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            }
          }
        },
        title: {
          text: 'ECII',
          align: 'center',
          style: {
            fontWeight: 'normal',
            fontFamily: 'SFProText',
          },
        }
      },
    },
    benefitCategory: {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors,
        plotOptions: {
          bar: {
            columnWidth: '15%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors
            }
          }
        },
        title: {
          text: 'Benefit Category',
          align: 'center',
          style: {
            fontWeight: 'normal',
            fontFamily: 'SFProText',
          },
        }
      },
    },
    projectStatus: {
      linkPortofolio: '',
      values: [
        {
          icon: '/static/icons/ic_projectstatus_queue.svg',
          alt: 'project status queue icon',
          value: 0,
          subtext: 'Queue',
        },
        {
          icon: '/static/icons/ic_projectstatus_inprogress.svg',
          alt: 'project status in progress icon',
          value: 0,
          subtext: 'In Progress',
        },
        {
          icon: '/static/icons/ic_projectstatus_completed.svg',
          alt: 'project status completed icon',
          value: 0,
          subtext: 'Completed',
        },
      ]
    },
    valueCapture: {
      values: [
        {
          icon: '/static/icons/ic_investment.svg',
          alt: 'value capture investment icon',
          value: 0,
          subtext: 'Investment',
        },
        {
          icon: '/static/icons/ic_benefit_impact.svg',
          alt: 'value capture benefit icon',
          value: 0,
          subtext: 'Benefit Impact',
        },
      ]
    },
    projectDetail1: {
      title: '',
      total: 0,
      subtitle: '',
      tableHeader: [{ key: '', label: '' }],
      tableData: []
    },
    projectDetail2: {
      title: '',
      total: 0,
      subtitle: '',
      tableHeader: [{ key: '', label: '' }],
      tableData: []
    },
    projectGateByAging: {
      series: [],
      labelCategories: [],
    },
    projectTypeArea: {
      series1: [{}],
      series2: [{}],
    },
  }
};
