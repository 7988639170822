const header = [
  { field: 'ACTION', width: 200, sort: 'action' },
  { field: 'YEAR', width: 200, sort: 'YearBuilt' },
  { field: 'REQUEST NO', width: 200, sort: 'registrationNumber' },
  { field: 'INITIATIVE NAME', width: 200, sort: 'initiative' },
  { field: 'SUBMITTER', width: 200, sort: 'user.fullname' },
  { field: 'STAGE', width: 200, sort: 'stageId' },
  { field: 'STATUS', width: 200, sort: 'innovationStatus' },
  { field: 'SITE', width: 200, sort: 'OrganizationId' },
  { field: 'CATEGORY', width: 200, sort: 'category' },
  { field: 'TYPE', width: 200, sort: 'innovationTypeId' },
  { field: 'THEME BANK', width: 200, sort: 'themeBankId' },
  { field: 'CLASSIFICATION', width: 200, sort: 'classificationId' },
  { field: 'COMPANY', width: 200, sort: 'company' },
  { field: 'FACILITATOR / SCRUM MASTER', width: 200, sort: 'facilitatorUser.fullname' },
  { field: 'UPDATE BY', width: 200, sort: 'submittedById' },
  { field: 'MODIFIED', width: 200, sort: 'updatedAt' }
];
export default header;
