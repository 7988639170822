import React, { useEffect } from 'react';
import {
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { USER_HRIS, USER_BANPU } from 'src/api/backendUrl';
import { useQuery } from 'react-query';
import api from 'src/api';
import StyledInput from './StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1) + 2,
      paddingBottom: theme.spacing(1) + 2,
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: theme.spacing(1),
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '80vw'
    },
    zIndex: theme.zIndex.modal + 200,
    fontSize: 13,
    color: '#586069',
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    boxShadow: 'none',
    margin: 2,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  listbox: {
    maxHeight: 150,
    padding: 3,
  },
  inputBase: {
    padding: theme.spacing(2),
    borderBottom: '1px dashed #ECEBED'
  }
}));

const HRISSelector = ({
  setFieldValue,
  checkbox,
  placeholder = '',
  onChange,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const query = {
    Filters: `(fullName|emailOffice)@=*${search}`,
    Sorts: 'fullName',
    PageSize: 20 // Workaround to speed up search api
  };
  const queryBanpu = {
    filter: search,
    // Sorts: 'fullName',
    // PageSize: 20 // Workaround to speed up search api
  };

  const { data: userOptions, refetch, isFetching } = useQuery([USER_HRIS, query], async () => {
    const { data: response } = await api.setting.user.hris(query);
    return response;
  }, { initialData: [], enabled: false });

  const { data: userOptionsBanpu, refetch: refetchBanpu, isFetching: isFetchingBanpu } = useQuery([USER_BANPU, queryBanpu], async () => {
    const { data: response } = await api.setting.user.banpu(queryBanpu);
    return response;
  }, { initialData: [], enabled: false });

  useEffect(() => {
    setSearch('');
    setFieldValue('roles', [], true);
  }, [checkbox]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (search.length > 4) {
        if (checkbox) { 
          refetchBanpu(search);
        } else {
          refetch(search);
        }
      }
    },
    100);
    return () => clearTimeout(timeoutId);
  }, [search, refetch, refetchBanpu]);

  return (
    <Autocomplete
      freeSolo
      onChange={(event, newValue) => {
        onChange(newValue || {});
      }}
      inputValue={search}
      onInputChange={(event, newValue) => {
        setSearch(newValue);
      }}
      classes={{
        paper: classes.paper,
        option: classes.option,
        popper: classes.popper,
        listbox: classes.listbox
      }}
      loading={checkbox ? isFetchingBanpu : isFetching}
      renderTags={() => null}
      noOptionsText="No User Found"
      renderOption={(option) => (
        <ListItemText
          primary={option.fullName}
          secondary={`${option.emailOffice || ""}, ${option.company || ""}`}
        />
      )}
      options={(checkbox ? userOptionsBanpu : userOptions) || []}
      getOptionSelected={(option) => option.fullName}
      getOptionLabel={(option) => option.fullName || ''}
      renderInput={(params) => (
        <StyledInput
          {...params}
          placeholder={placeholder}
          {...rest}
        />
      )}
    />
  );
};

HRISSelector.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

export default HRISSelector;
