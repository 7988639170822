import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import ReturnOnInvestment from './ReturnOnInvestment';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const OtherView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: 5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Other</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ReturnOnInvestment {...props} />
      </Grid>
    </Grid>
  );
};

export default OtherView;
