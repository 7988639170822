import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold',
  },
  donutChart: {
    fontSize: 18,
    fontWeight: 'bold',
  }
}));

const DonutChart = (props) => {
  const classes = useStyles();
  const { series, labels, title, ...rest } = props;

  return (
    <>
        <Typography className={classes.title}>{title}</Typography>
        <ReactApexChart className={classes.donutChart} series={series} type="donut" options={
            {
              labels: labels,
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.globals.series[opts.seriesIndex];
                },
              },
              legend: {
                  show: true,
                  position: 'right',
                  horizontalAlign: 'left',
                  formatter: function(seriesName, opts) {
                    return (seriesName.length > 4 ? `${seriesName.slice(0,3)+"."}` : seriesName)
                  }
              },
              plotOptions: {
                  pie: { donut: { 
                    labels: {
                      show: true,
                      name: { show: false },
                      total: {
                          show: true, 
                          showAlways: true,
                          label: '',
                      },
                    },
                    background: '#c5c5c5',
                  },}
              },
              chart: {
                dropShadow: {
                  enabled: true,
                  color: '#111',
                  top: -1,
                  left: 3,
                  blur: 3,
                  opacity: 0.2
                }
              },
            }
        }/>
    </>
  );
};

DonutChart.propTypes = {
  series: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default DonutChart;
