import React from 'react';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  formControl: {
    marginTop: theme.spacing(1) + 2,
  },
  itemLabel: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  },
  itemRoot: {
    marginRight: 'auto'
  }
}));

const StyledRadio = ({
  error,
  options,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth
      error={error}
      variant="outlined"
      className={classes.formControl}
    >
      <RadioGroup
        row
        className={classes.root}
        {...rest}
      >
        {options.map((option) => (
          <FormControlLabel
            classes={{
              root: classes.itemRoot,
              label: classes.itemLabel
            }}
            disabled={rest?.disabled}
            key={option?.value}
            value={option?.value}
            control={<Radio color="secondary" />}
            label={option?.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

StyledRadio.propTypes = {
  options: PropTypes.array.isRequired,
  error: PropTypes.bool,
};

export default StyledRadio;
