import axios from 'axios';
import store from 'src/store';
import { REFRESH_TOKEN } from '../backendUrl';

const baseURL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;
const httpInstance = axios.create({
  timeout: 0,
  timeoutErrorMessage: 'Request Timeout Error',
  baseURL
});

const handleResponse = (res) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(res, 'httpInstance:handleResponse');
  }

  if (!res) return { ok: false };

  const { data, status, headers } = res;
  const ok = status && status >= 200 && status < 300;

  return {
    ok, status, data, headers
  };
};

const isTokenExpired = (res) => res?.status === 401;
const isServerFault = (res) => res?.status >= 500 && res?.status < 600;

const refreshTokenAndRetry = (req, token, refreshToken) => {
  return axios
    .get(`${baseURL}${REFRESH_TOKEN}?token=${token}refresh_token=${refreshToken}`)
    .then((res) => {
      const setNewToken = store?.getActions().auth.setNewToken;
      setNewToken({ token: res.data.token });
      req.headers.Authorization = `Bearer ${res.data.token}`;
      return httpInstance.request(req);
    })
    .catch((error) => {
      const { logout, setLastRoute } = store?.getActions().auth;
      const setNotif = store?.getActions().notif.setNotif;
      const { response } = error;
      const errorMessage = response?.data?.message?.messageEng;

      if (isTokenExpired(response) || !isServerFault(response)) {
        if (errorMessage) {
          setNotif({
            message: errorMessage,
            option: {
              variant: 'danger'
            }
          });
        }
        setLastRoute('/app/innovation-view');
        logout();
      }

      return handleResponse(error?.response);
    });
};

httpInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    const { response, config, message } = error;
    const { token, refreshToken } = store?.getState().auth;

    if (process.env.NODE_ENV !== 'production') {
      console.log(response, config, message, error, 'httpInstance:AxiosResponseErrorInterceptor');
    }

    if (isTokenExpired(error.response) && !config._retry) {
      config._retry = true;
      if (refreshToken) {
        return refreshTokenAndRetry(config, token, refreshToken);
      }
    }

    // TODO: HANDLE ERROR WITH UNDEFINED RESPONSE ( CLIENT ERROR MESSAGE )
    if (process.env.NODE_ENV !== 'production') {
      if (message) {
        console.log(message);
      }
    }

    return handleResponse(response);
  },
);

export default httpInstance;
