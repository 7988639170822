import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { BENEFIT_COST_STAGE_GATE } from 'src/api/backendUrl';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

const StageGate = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'stageGate';
  const { data } = useQuery(BENEFIT_COST_STAGE_GATE, async () => {
    const response = await api.benefitCost.stageGate();
    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });
  const stageGates = data;
  if (stageGates.length) {
    const gateData = stageGates
      .find((gate) => gate.name.toLowerCase().includes(values[key].toLowerCase()));

    if (gateData) {
      values[key] = gateData.id;
    }
  }

  return (
    <FormItem
      id={key}
      label="Stage Gate"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled
        name={key}
        type="text"
        placeholder="Enter Stage Gate"
        options={stageGates}
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

StageGate.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default StageGate;
