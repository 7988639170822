import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useStoreActions } from 'easy-peasy';

import {
  Box,
  Button,
  Container,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';
import { isUndefined } from 'lodash';

import api from 'src/api';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import DataTable from 'src/components/DataTable';

import PopUp from './PopUp';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1)
  }
}));

const entries = [10, 20, 50, 100, 200];

const BenefitCategory = () => {
  const classes = useStyles();

  const setNotification = useStoreActions((action) => action.notif.setNotif);

  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [documentData, setDocumentData] = useState([]);
  const [benefitData, setBenefitData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const tableHeaders = [
    { key: 'action', label: 'Action' },
    { key: 'name', label: 'Name' },
    { key: 'status', label: 'Status' },
    { key: 'updatedBy', label: 'Action By' },
    { key: 'updatedAt', label: 'Last Modified' }
  ];

  const fetchAllBenefitCategory = async () => {
    setIsFetching(true);

    const query = {
      page: currentPage,
      sorts: '-status,name',
      pageSize
    };
    const { data: response } = await api.masterData.allBenefitCategory({
      ...search && { filters: `search==${search}` },
      ...query
    });
    setTotalData(response?.data?.totalData || 0);
    setTotalPage(response?.data?.totalPage || 0);
    setShowing({
      show: response?.data?.totalData === 0
        ? response?.data?.totalData
        : showing.show,
      to: response?.data?.totalData <= pageSize
        ? response?.data?.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData((response?.data && response?.data?.data?.length)
      ? response?.data?.data
      : []);
  };

  const upsertBenefitCategory = async (data) => {
    setIsSubmitting(true);

    const { id, ...rest } = data;

    try {
      isUndefined(id)
        ? await api.masterData.postBenefitCategory({ ...rest })
        : await api.masterData.putBenefitCategory({ id, ...rest });

      setIsSubmitting(false);
      setIsDialogOpen(false);
      setBenefitData(null);

      fetchAllBenefitCategory()
        .then(/* Nothing here is expected */)
        .catch(/* Nothing here is expected */);
    } catch (err) {
      setIsSubmitting(false);
      setIsDialogOpen(false);
      setBenefitData(null);

      setNotification({
        message: err?.message || err?.data?.message || 'Something went wrong, please call your administrator',
        option: {
          variant: 'error'
        }
      });
    }
  };

  const openDialog = (dialogState, data) => {
    setIsDialogOpen(dialogState);
    setBenefitData(data);
  };

  const handleConfirm = async (data) => {
    await upsertBenefitCategory(data);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    fetchAllBenefitCategory()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [
    search,
    pageSize,
    currentPage
  ]);

  return (
    <Page
      title="Benefit Category"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Benefit Category" />
        <Box my={5}>
          <DataTable
            isCustom
            isVisible
            tableHeader={tableHeaders}
            tableData={documentData}
            leftComp={
              isFetching
                ? <CircularProgress size={24} />
                : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => openDialog(true, null)}
                  >
                    Create
                  </Button>
                )
            }
            actionSlot={(data) => (
              <EditOutlined
                style={{
                  color: '#737AF1',
                  cursor: 'pointer'
                }}
                onClick={() => openDialog(true, data)}
              />
            )}
            {...{
              entries,
              showEntries,
              setShowEntries,
              pageSize,
              setPageSize,
              setSearch,
              totalData,
              currentPage,
              setCurrentPage,
              totalPage
            }}
          >
            {({ key, data }) => {
              switch (key) {
                case 'status': {
                  return (
                    <Typography style={{
                      color: data[key]
                        ? '#06a137'
                        : '#f54242'
                    }}
                    >
                      {data[key]
                        ? 'Active'
                        : 'Inactive'}
                    </Typography>
                  );
                }
                case 'updatedAt': {
                  return <Typography>{moment(data[key]).format('DD MMM YYYY HH:mm')}</Typography>;
                }
                default: {
                  return <Typography>{data[key]}</Typography>;
                }
              }
            }}
          </DataTable>
        </Box>
      </Container>

      <PopUp
        data={benefitData}
        {...{
          isDialogOpen,
          isSubmitting,
          handleConfirm,
          handleClose
        }}
      />
    </Page>
  );
};

export default BenefitCategory;
