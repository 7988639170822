import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledFilePicker from 'src/components/StyledFilePicker';
import _ from 'lodash';

const Attachment = ({
  disabled,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
}) => {
  const key = 'attachments';
  const handleFilePicked = (value) => {
    const newAttachment = _.unionBy(value, values[key], 'name');
    setFieldValue(key, newAttachment, true);
    setFieldTouched(key, true, false);
  };
  const handleFileRemove = (value) => {
    if (!value.node) {
      const newValue = values[key].filter((val) => val.name !== value.name);
      setFieldValue(key, newValue);
      setFieldTouched(key, true);
    } else {
      const newValue = values[key].filter((val) => val.node !== value.node);
      setFieldValue(key, newValue);
      setFieldTouched(key, true);
    }
  };
  return (
    <FormItem
      label="Attachment"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledFilePicker
        error={Boolean(touched[key] && errors[key])}
        files={values[key]}
        disabled={disabled}
        onFilePicked={handleFilePicked}
        onFileRemoved={handleFileRemove}
      />
    </FormItem>
  );
};

Attachment.propTypes = {
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Attachment;
