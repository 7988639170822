import React, { useEffect } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  ListItemText,
  makeStyles,
  Popper,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { Close, Search } from '@material-ui/icons';
import { useQuery } from 'react-query';
import api from 'src/api';
import StyledInput from './StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1) + 2,
      paddingBottom: theme.spacing(1) + 2,
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: theme.spacing(1),
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '80vw'
    },
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    boxShadow: 'none',
    margin: 2,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  listbox: {
    maxHeight: 150,
    padding: 3,
  },
  inputBase: {
    padding: theme.spacing(2),
    borderBottom: '1px dashed #ECEBED'
  },
  listItemPrimary: {
    color: 'black',
    fontSize: 15
  },
  listItemSecondary: {
    color: 'gray',
    fontSize: 12
  }
}));

const Selector = ({
  apiId,
  keyApi = '',
  apiMasterData = '',
  excludeQuery = '',
  querySearch = 'fullName',
  placeholder = '',
  onChange,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const query = {
    Filters: `${querySearch}@=*${search}${excludeQuery}`,
    Sorts: 'fullName',
    PageSize: 20 // Workaround to speed up search api
  };
  const { data: userOptions, refetch, isLoading } = useQuery([keyApi, query], async () => {
    const { data: response } = apiId
      ? await api.masterData[apiMasterData](apiId, query)
      : await api.masterData[apiMasterData](query);
    return response?.data;
  }, { initialData: [], enabled: false });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (search.length >= 3 && keyApi && apiMasterData) {
        refetch(search);
      }
    },
    100);
    return () => clearTimeout(timeoutId);
  }, [search, refetch, keyApi, apiMasterData]);

  const handleClick = (event) => {
    if (rest?.disabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'autocomplete' : undefined;
  const nameNotEmpty = (value?.fullname && value?.fullname !== '') || (value?.fullName && value?.fullName !== '');
  return (
    <>
      <StyledInput
        id={id}
        placeholder={placeholder}
        aria-describedby={id}
        onClick={handleClick}
        value={value?.fullname || value?.fullName || ''}
        InputProps={{
          readOnly: true,
          endAdornment:
            !rest?.disabled && nameNotEmpty && (
              <IconButton
                color="secondary"
                size="small"
                onClick={() => onChange({})}
              >
                <Close />
              </IconButton>
            )
        }}
        {...rest}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <Autocomplete
          open
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            listbox: classes.listbox
          }}
          freeSolo
          onChange={(event, newValue) => {
            onChange(newValue || {});
          }}
          inputValue={search}
          onInputChange={(event, newValue) => {
            setSearch(newValue);
          }}
          disablePortal
          autoComplete
          loading={isLoading}
          renderTags={() => null}
          noOptionsText="No User Found"
          renderOption={(option) => (
            <ListItemText>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.listItemPrimary}>
                  {option.fullName || option.fullname}
                </Typography>
                <Typography className={classes.listItemSecondary}>
                  {option.emailOffice || option.email}
                </Typography>
                <Typography className={classes.listItemSecondary}>
                  {option.workLocation}
                </Typography>
              </Box>
            </ListItemText>
          )}
          options={userOptions || []}
          getOptionSelected={(option) => option.fullName || option.fullname}
          getOptionLabel={(option) => option.fullName || option.fullname}
          renderInput={(params) => (
            <InputBase
              fullWidth
              type="search"
              ref={params.InputProps.ref}
              startAdornment={<Search style={{ marginRight: 5 }} />}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </>
  );
};

Selector.propTypes = {
  apiId: PropTypes.string,
  keyApi: PropTypes.string.isRequired,
  apiMasterData: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  querySearch: PropTypes.string,
  excludeQuery: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};

export default Selector;
