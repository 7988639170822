import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  isNull,
  isUndefined,
} from 'lodash';

import api from 'src/api';

import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import NumberFormatCustom from 'src/components/NumberFormat';

const PopUp = ({
  isDialogOpen,
  isSubmitting,
  data,
  handleClose,
  handleConfirm
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [generalLinkageData, setGeneralLinkageData] = useState({ id: undefined, name: '', status: true });
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkage, setIsLinkage] = useState(false);
  const [isLinkageReachLimit, setIsLinkageReachLimit] = useState(false);
  const [linkageData, setLinkageData] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const fetchBenefitDetail = async (id) => {
    setIsLoading(true);

    try {
      const { data: { data: benefitDetail } } = await api.masterData.benefitCategoryById(id);
      const {
        name,
        status,
        isLinkageToFormAssessment,
        question
      } = benefitDetail;

      setGeneralLinkageData({ id, name, status });

      if (
        (!isNull(isLinkageToFormAssessment) && !isUndefined(isLinkageToFormAssessment))
        && (!isNull(question) && !isUndefined(question))
      ) {
        setIsLinkage(isLinkageToFormAssessment);
        setLinkageData(() => {
          const tempLinkage = [];

          Object.keys(question)
            .filter((key) => key.includes('rate'))
            .forEach((rateKey) => {
              const keyNumber = parseInt(rateKey.match(/\d+/)[0], 10);

              if (question[rateKey]) {
                const splitNumber = question[rateKey].split('-');

                tempLinkage.push({
                  rateStart: splitNumber[0],
                  rateEnd: splitNumber[1],
                  description: question[`description${keyNumber}`]
                });
              }
            });

          return tempLinkage;
        });

        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const generateButtonTitle = () => {
    return (data && data?.id)
      ? 'Edit'
      : 'Save';
  };

  const resetForm = () => {
    setGeneralLinkageData({ id: undefined, name: '', status: true });
    setIsLinkage(false);
    setIsLinkageReachLimit(false);
    setLinkageData([]);
  };

  const onLinkageChecked = (isChecked) => {
    setIsLinkage(isChecked);

    if (isChecked) {
      setLinkageData([{ rateStart: 0, rateEnd: 0, description: '' }]);

      return;
    }

    setLinkageData([]);
  };

  const onLinkageModified = (isAdd, index) => {
    if (isAdd) {
      if (linkageData.length === 3) {
        setIsLinkageReachLimit(true);

        return;
      }

      setLinkageData((prevState) => {
        return [...prevState, { rateStart: 0, rateEnd: 0, description: '' }];
      });

      return;
    }

    setLinkageData((prevState) => {
      return prevState.filter((_, prevIndex) => prevIndex !== index);
    });
  };

  const onLinkageDataChange = (index, key, ev) => {
    setLinkageData((prevState) => {
      return prevState.map((prevItem, prevIndex) => {
        if (prevIndex === index) {
          return {
            ...prevItem,
            [key]: key.includes('rate')
              ? Number(ev?.target?.value)
              : ev?.target?.value
          };
        }

        return { ...prevItem };
      });
    });
  };

  const handleChange = (ev) => {
    setGeneralLinkageData((prevState) => ({
      ...prevState,
      name: ev?.target?.value
    }));
  };

  const handleSave = () => {
    handleConfirm({
      ...generalLinkageData,
      isLinkageToFormAssessment: isLinkage,
      ...isLinkage
        ? {
          question: {
            rate1: `${linkageData[0].rateStart}-${linkageData[0].rateEnd}`,
            description1: linkageData[0].description,
            rate2: linkageData.length > 1
              ? `${linkageData[1].rateStart}-${linkageData[1].rateEnd}`
              : null,
            description2: linkageData.length > 1
              ? linkageData[1].description
              : null,
            rate3: linkageData.length > 2
              ? `${linkageData[2].rateStart}-${linkageData[2].rateEnd}`
              : null,
            description3: linkageData.length > 2
              ? linkageData[2].description
              : null
          }
        }
        : undefined
    });

    resetForm();
  };

  useEffect(() => {
    if (isLinkageReachLimit) {
      setTimeout(() => {
        setIsLinkageReachLimit(false);
      }, 3000);
    }
  }, [isLinkageReachLimit]);

  useEffect(() => {
    if (data) {
      const { id } = data;

      fetchBenefitDetail(id)
        .then(/* Nothing expected here */)
        .catch(/* Nothing expected here */);
    }
  }, [data]);

  useEffect(() => {
    setIsValid(() => Boolean(generalLinkageData.name)
      && linkageData.every((item) => Boolean(item.rateStart)
        && Boolean(item.rateEnd)
        && Boolean(item.description)));
  }, [
    generalLinkageData,
    linkageData
  ]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
    >
      <DialogTitle id="confirmation-dialog">
        Add Benefit Category
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={12} lg={10}>
            <FormItem
              required
              id="name"
              label="Benefit Category Name"
            >
              <StyledInput
                name="name"
                value={generalLinkageData.name}
                type="text"
                placeholder="Enter benefit category name"
                onChange={handleChange}
              />
            </FormItem>
          </Grid>
          <Grid item sm={12} md={12} lg={2}>
            <FormItem
              id="status"
              label="Active"
            >
              <Checkbox
                checked={generalLinkageData.status}
                onChange={(ev) => setGeneralLinkageData(
                  (prevState) => ({
                    ...prevState,
                    status: ev.target.checked
                  })
                )}
                style={{ justifyContent: 'flex-start' }}
              />
            </FormItem>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isLinkage}
                  onChange={(ev) => onLinkageChecked(ev.target.checked)}
                />
              )}
              label="Linkage to form assessment"
            />
          </Grid>
        </Grid>
        {
          isLinkage && linkageData.length && (
            <>
              {
                isLinkageReachLimit && (
                  <Alert
                    variant="filled"
                    severity="error"
                    style={{ margin: '1rem 0 1rem 0' }}
                  >
                    Rate and Description cannot be more than 3 items
                  </Alert>
                )
              }
              {
                linkageData.map((item, index) => (
                  <Fragment key={`key-${index + 1}`}>
                    Rate
                    {' '}
                    {index + 1}
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '1rem'
                    }}
                    >
                      <StyledInput
                        name={`rate-${index}-start`}
                        value={item.rateStart}
                        placeholder="start"
                        style={{ width: '5rem' }}
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                        onChange={(ev) => onLinkageDataChange(index, 'rateStart', ev)}
                      />
                      <div style={{ margin: '0 1rem 0 1rem' }}>
                        -
                      </div>
                      <StyledInput
                        name={`rate-${index}-end`}
                        value={item.rateEnd}
                        placeholder="end"
                        style={{ width: '5rem' }}
                        InputProps={{
                          inputComponent: NumberFormatCustom
                        }}
                        onChange={(ev) => onLinkageDataChange(index, 'rateEnd', ev)}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => onLinkageModified(index === 0, index)}
                      >
                        {
                          index !== 0
                            ? 'Delete'
                            : 'Add'
                        }
                      </Button>
                    </Box>
                    <FormItem
                      id={`desc-${index}`}
                      label={`Description ${index + 1}`}
                    >
                      <StyledInput
                        multiline
                        name={`desc-${index}`}
                        rows={4}
                        placeholder="Enter description"
                        value={item.description}
                        onChange={(ev) => onLinkageDataChange(index, 'description', ev)}
                      />
                    </FormItem>
                  </Fragment>
                ))
              }
            </>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          isLoading && (
            <CircularProgress size={24} style={{ marginRight: 'auto' }} />
          )
        }
        <Button
          variant="contained"
          disabled={isSubmitting || !isValid}
          onClick={handleSave}
          color="secondary"
        >
          { generateButtonTitle() }
          {
            isSubmitting && (
              <CircularProgress size={14} style={{ marginLeft: 10 }} />
            )
          }
        </Button>
        <Button
          variant="outlined"
          disabled={isSubmitting}
          onClick={() => {
            resetForm();
            handleClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopUp.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

export default PopUp;
