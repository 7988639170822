import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import HyperlinkPopup from '../HyperlinkPopup';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.common.black,
        fontSize: 18,
        fontWeight: 'bold',
    },
    barChart: {
        padding: '3.62% 6% 0 0',
        borderRadius: '24px',
        '& .apexcharts-legend': {
          backgroundColor: '#EDEEFD',
          display: 'grid',
          gridTemplateColumns: 'auto',
          padding: '3.62% 6%',
          borderRadius: '8px', 
        },
        '& .apexcharts-legend-series.apexcharts-no-click': {
          cursor: 'pointer',
        }
    },
    formContainer: {
      display: 'flex',
      flexGrow: '1 1 auto',
      flexDirection: 'column',
      padding: '3.62% 6%',
      marginTop: '2.36%',
      marginBottom: '2.36%',
      backgroundColor: theme.palette.common.white,
      borderRadius: '24px',
      textAlign: 'left'
    },
}));

const ProjectTypeArea = ({
    projectTypeArea
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState({ title: '' });

  const chartType = ['Innovation Type', 'Innovation Area'];
  const chartHeight = '471px';

  const openHyperPopup = (title) => {
    setDetail({ title });
    setIsOpen(true);
  };

  return (
      <Box display="flex" flexDirection="column" className={classes.formContainer}>
        <Typography className={classes.title}>Project Type/Area</Typography>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ReactApexChart height={chartHeight} className={classes.barChart} series={projectTypeArea?.series1 || []} type="bar" options={
              {
                labels: ['a'],
                chart: {
                  events: {
                    legendClick: function(chartContext, seriesIndex, config) {
                      openHyperPopup(config.config.series[seriesIndex].name);
                    }
                  }
                },
                tooltip: {
                  x: {
                    formatter: function (v, x) {
                      return chartType[0];
                    },
                  },
                  y: {
                    formatter: function (v, x) {
                      return v;
                    },
                    title: {
                      formatter: function (v, x) {
                        return v;
                      }
                    }
                  }
                },
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    colors: { ranges: [{ color: '#FF8E00' }] }
                  }
                },
                xaxis: {
                  labels: {
                    show: false,
                  },
                  title: { text: chartType[0] },
                },
                legend: {
                  onItemClick: { toggleDataSeries: false },
                },
              }
            }/>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ReactApexChart height={chartHeight} className={classes.barChart} series={projectTypeArea?.series2 || []} type="bar" options={
              {
                labels: ['a'],
                chart: {
                  events: {
                    legendClick: function(chartContext, seriesIndex, config) {
                      openHyperPopup(config.config.series[seriesIndex].name);
                    }
                  }
                },
                tooltip: {
                  x: {
                    formatter: function (v, x) {
                      return chartType[1];
                    },
                  },
                  y: {
                    formatter: function (v, x) {
                      return v;
                    },
                    title: {
                      formatter: function (v, x) {
                        return v;
                      }
                    }
                  }
                },
                plotOptions: {
                  bar: {
                    borderRadius: 10,
                    colors: { ranges: [{ color: '#FF8E00' }] }
                  }
                },
                xaxis: {
                  labels: {
                    show: false,
                  },
                  title: { text: chartType[1] },
                },
                yaxis: {
                  labels: {
                    show: false,
                  },
                },
                legend: {
                  onItemClick: { toggleDataSeries: false },
                },
              }
            }/>
          </Grid>
        </Grid>
        <HyperlinkPopup
            {...{
            detail,
            isOpen,
            setIsOpen
            }}
        />
      </Box>
  );
};

export default ProjectTypeArea;
