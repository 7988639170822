import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import ButtonConfirmation from 'src/components/ButtonConfirmation';
import ButtonTextFieldConfirmation from 'src/components/ButtonTextFieldConfirmation';
import CommitteeSuggestion from './CommitteeSuggestion';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  delete: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    }
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  id,
  isDraft,
  isSentBack,
  canEditCommittee,
  showEdit,
  canApprove,
  canReview,
  canSuggest,
  canAcknowledge,
  canEdit,
  canSubmit,
  canSentBack,
  canCancel,
  canCreateImplementation,
  canReactivate,
  canManageAccess,
  handleReactivate,
  handleSaveDraft,
  handleApproval,
  handleVerify,
  handleSentBack,
  handleCancel,
  handleCreateG1,
  handleOpenEdit,
  handleCloseEdit,
  handleAccessPermissionClick,
  values,
  disabled,
  submitForm,
  isSubmitting,
  dirty,
  isValid,
  isEdit,
  handleOpenEditAdmin,
  handleCloseEditAdmin,
  setFieldValue,
  showRemind,
  canRemind,
  handleRemind,
  isCustomFlow,
  isWaitingReviewAdmin,
  isWaitingCommittee
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackNav = () => {
    if (location.key === 'default') {
      navigate('/app/innovation-view');
    } else {
      navigate(-1);
    }
  };
  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };
  const {
    action,
    rate,
    registration_number,
    ...form
  } = values;
  const showSubmit = id ? (isDraft || isSentBack) : true;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {
        canSubmit
        && showSubmit
        && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting || !isValid || disabled}
            onConfirm={() => handleSubmit('submit')}
            className={clsx(classes.button, classes.label)}
          >
            Submit
          </ButtonConfirmation>
        )
      }
      {
        isEdit && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to edit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting || !isValid || disabled}
            onConfirm={() => handleSubmit('edit')}
            className={clsx(classes.button, classes.label)}
          >
            Save Edit
          </ButtonConfirmation>
        )
      }
      {
        isEdit && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleCloseEditAdmin}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel Edit
          </Button>
        )
      }
      {
        !showEdit
        && !isEdit
        && canEdit
        && (
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleOpenEditAdmin}
            className={clsx(classes.button, classes.label)}
          >
            Edit
          </Button>
        )
      }
      {
        canApprove && (
          <>
            {
              isCustomFlow && isWaitingReviewAdmin && (
                <ButtonConfirmation
                  title="Confirmation"
                  textDialog="Are you sure you want to approve ?"
                  textConfirm="Ok"
                  textCancel="Cancel"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting || values.committees.length < 1}
                  onConfirm={() => handleApproval('', '', form, values.committees)}
                  className={clsx(classes.button, classes.label)}
                >
                  Verify
                </ButtonConfirmation>
              )
            }
            {
              isCustomFlow && isWaitingCommittee && (
                <CommitteeSuggestion
                  textConfirm="Ok"
                  textCancel="Cancel"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting || !isValid || disabled}
                  className={clsx(classes.button, classes.label)}
                  onConfirm={
                    (comment, suggestion) => handleApproval(
                      comment,
                      suggestion,
                      form,
                      values.committees
                    )
                  }
                >
                  Process
                </CommitteeSuggestion>
              )
            }
            {
              !isWaitingReviewAdmin && !isWaitingCommittee && (
                <ButtonTextFieldConfirmation
                  title="Confirmation"
                  textDialog="Are you sure you want to approve ?"
                  textConfirm="Ok"
                  textCancel="Cancel"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  onConfirm={(comment) => handleApproval(comment, '', form, values.committees)}
                  className={clsx(classes.button, classes.label)}
                >
                  Approve
                </ButtonTextFieldConfirmation>
              )
            }
          </>
        )
      }
      {
        canApprove && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to sent back ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleSentBack(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Send Back
          </ButtonConfirmation>
        )
      }
      {
        canAcknowledge && (
          <ButtonTextFieldConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to acknowledge ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={(comment) => handleApproval(comment)}
            className={clsx(classes.button, classes.label)}
          >
            Acknowledge
          </ButtonTextFieldConfirmation>
        )
      }
      {
        !showEdit
        && canEditCommittee
        && (
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitting || values.committees.length < 1}
            onClick={() => handleOpenEdit()}
            className={clsx(classes.button, classes.label)}
          >
            Edit Committee
          </Button>
        )
      }
      {
        showEdit
        && canEditCommittee
        && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting || values.committees.length < 1}
            onClick={() => handleCloseEdit()}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel
          </Button>
        )
      }
      {
        canSubmit
        && showSubmit
        && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to save as draft ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting || disabled}
            onConfirm={() => handleSaveDraft(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Save as Draft
          </ButtonConfirmation>
        )
      }
      {
        canCreateImplementation && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to create Implementation ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={() => handleCreateG1(form)}
            className={clsx(classes.button, classes.label)}
          >
            Create Implementation
          </ButtonConfirmation>
        )
      }
      {
        canCancel && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to cancel ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleCancel(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel
          </ButtonConfirmation>
        )
      }
      {
        canReactivate && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to reactivate ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleReactivate()}
            className={clsx(classes.button, classes.label)}
          >
            Reactivate
          </ButtonConfirmation>
        )
      }
      {
        dirty
          ? (
            <ButtonConfirmation
              title="Confirmation"
              textDialog="Are you sure you want to close ?"
              textConfirm="Ok"
              textCancel="Cancel"
              variant="outlined"
              color="secondary"
              disabled={isSubmitting}
              onConfirm={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </ButtonConfirmation>
          )
          : (
            <Button
              variant="outlined"
              color="secondary"
              disabled={isSubmitting}
              onClick={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </Button>
          )
      }
      {
        showRemind && (
          <ButtonConfirmation
            variant="contained"
            color="secondary"
            title="Confirmation"
            textDialog="Are you sure you want to send approval reminder email ?"
            textConfirm="Yes"
            textCancel="No"
            disabled={!canRemind}
            onConfirm={() => handleRemind()}
            className={clsx(classes.button, classes.label)}
          >
            Send Email Reminder
          </ButtonConfirmation>
        )
      }
      {
        canManageAccess && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleAccessPermissionClick}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Manage Access
          </Button>
        )
      }
    </Box>
  );
};

Decision.propTypes = {
  id: PropTypes.string,
  isAdmin: PropTypes.bool,
  isAdminSite: PropTypes.bool,
  isCommittee: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isDraft: PropTypes.bool,
  isSentBack: PropTypes.bool,
  isIdeaBlast: PropTypes.bool,
  isEdit: PropTypes.bool,
  isWaitingCommittee: PropTypes.bool,
  isWaitingReviewAdmin: PropTypes.bool,
  isCustomFlow: PropTypes.bool,
  disabled: PropTypes.bool,
  showEdit: PropTypes.bool,
  canEditCommittee: PropTypes.bool,
  canApprove: PropTypes.bool,
  canSuggest: PropTypes.bool,
  canReview: PropTypes.bool,
  canAssesment: PropTypes.bool,
  canAcknowledge: PropTypes.bool,
  canConfirm: PropTypes.bool,
  canCancel: PropTypes.bool,
  canEdit: PropTypes.bool,
  canSubmit: PropTypes.bool,
  canSentBack: PropTypes.bool,
  canRemind: PropTypes.bool,
  canCreateImplementation: PropTypes.bool,
  canReactivate: PropTypes.bool,
  canManageAccess: PropTypes.bool,
  handleReactivate: PropTypes.func,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  handleOpenEditAdmin: PropTypes.func.isRequired,
  handleCloseEditAdmin: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  handleVerify: PropTypes.func,
  handleReminder: PropTypes.func,
  handleSentBack: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreateG1: PropTypes.func.isRequired,
  handleAccessPermissionClick: PropTypes.func,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
};

export default Decision;
