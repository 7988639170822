import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import HyperlinkPopup from '../HyperlinkPopup';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.common.black,
        fontSize: 18,
        fontWeight: 'bold',
    },
    barChart: {
        padding: '3.62% 6% 0 0',
        borderRadius: '24px',
        '& .apexcharts-legend': {
          backgroundColor: '#EDEEFD',
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          padding: '3.62% 6%',
          borderRadius: '8px',
        },
        '& .apexcharts-legend-series.apexcharts-no-click': {
          cursor: 'pointer',
        },
        '& .apexcharts-legend.position-bottom.apexcharts-align-center': {
          justifyContent: 'space-around',
        },
        '& .apexcharts-tooltip-series-group': {
          display: 'grid',
          gridTemplateColumns: 'auto auto',
        },
        '& .apexcharts-tooltip-y-group': {
          display: 'grid',
          gridTemplateColumns: 'auto',
        },
        '& .apexcharts-tooltip-text-value': {
          color: '#01AABD',
        },
    },
    formContainer: {
      display: 'flex',
      flexGrow: '1 1 auto',
      flexDirection: 'column',
      padding: '3.62% 6%',
      marginTop: '2.36%',
      marginBottom: '2.36%',
      backgroundColor: theme.palette.common.white,
      borderRadius: '24px',
      textAlign: 'left'
    },
}));

const ProjectByAging = ({
  projectGateByAging
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState({ title: '' });

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  const chartHeight = '471px';

  const openHyperPopup = (title) => {
    setDetail({ title });
    setIsOpen(true);
  };

  return (
      <Box display="flex" flexDirection="column" className={classes.formContainer}>
        <Typography className={classes.title}>Project Gate By Aging</Typography>
        <ReactApexChart height={chartHeight} className={classes.barChart} series={projectGateByAging?.series || []} type="bar" options={
          {
            labels: projectGateByAging?.labelCategories || [],
            chart: { 
              stacked: true,
              events: {
                legendClick: function(chartContext, seriesIndex, config) {
                  openHyperPopup(config.config.series[seriesIndex].name);
                }
              }
            },
            colors: ['#737AF1','#FCD035','#E02020','#CA46FC','#FF8E00','#80EDB2'],
            tooltip: {
              x: { show: false },
              y: {
                formatter: function (v, x) {
                  return v;
                },
                title: {
                  formatter: function (v, x) {
                    return v;
                  }
                }
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                colors: { ranges: [{ color: '#FF8E00' }] }
              }
            },
            xaxis: {
              labels: {
                formatter: function(value, timestamp, opts) {
                  return value;
                }
              }
            },
            legend: {
              onItemClick: { toggleDataSeries: false },
            },
          }
        }/>
        <HyperlinkPopup
            {...{
            detail,
            isOpen,
            setIsOpen
            }}
        />
      </Box>
  );
};

export default ProjectByAging;
