import React from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import { getIn } from 'formik';
import {
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';

import ButtonConfirmation from 'src/components/ButtonConfirmation';
import Amount from './Amount';
import Description from './Description';
import Attachment from './Attachment';
import Comment from './Comment';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '10px',
    margin: '15px 0 5px 0',
    padding: '10px'
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    borderRadius: theme.spacing(1),
    height: 40,
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const StyledCell = withStyles(() => ({
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 120
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  }
}))(TableCell);

const StyledRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const BenefitView = (props) => {
  const classes = useStyles();

  const { form } = props;
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    submitForm,
    initialValues,
  } = form;
  const { superiorId } = initialValues;
  const user = useStoreState((state) => state.auth.user);

  const { isEdit, status } = values;
  const isSubmitter = user.id === values.submittedById;
  const isStatusVerified = status === 'Verified';
  const canVerify = (item) => {
    return item.isCanVerify ?? false;
  };

  const handleSubmit = (action, rowIndex) => {
    setFieldValue('action', action);
    setFieldValue('rowIndex', rowIndex);
    submitForm();
  };

  const financeType = 'Finance';

  return (
    <>
      {
        values.benefits.map((item, index) => (
          <StyledRow key={`key-${index + 1}`} style={{ marginBottom: '5px' }}>
            <StyledCell>{index + 1}</StyledCell>
            <StyledCell>{item.benefitCategories.name}</StyledCell>
            <StyledCell>
              {
                canVerify(item) || (!isSubmitter && !isEdit) || isStatusVerified
                  ? (Number(item.amount).toLocaleString('id-ID'))
                  : (
                    <Amount
                      disabled={Boolean(item.benefitCategories.name === financeType
                        || item.verifiedBy)}
                      {...{
                        item,
                        index,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        getIn
                      }}
                    />
                  )
              }
            </StyledCell>
            <StyledCell>
              {
                canVerify(item) || (!isSubmitter && !isEdit) || isStatusVerified
                  ? (
                    <Typography style={{
                      width: '35rem',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word'
                    }}
                    >
                      {item.description}
                    </Typography>
                  )
                  : (
                    <Description
                      disabled={Boolean(item.verifiedBy)}
                      {...{
                        item,
                        index,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        getIn
                      }}
                    />
                  )
              }
            </StyledCell>
            <StyledCell>
              <Attachment
                isInputVisible={!isStatusVerified}
                disabled={
                  (canVerify(item) || !!item.verifiedBy)
                  || (!isSubmitter && !isEdit)
                  || isStatusVerified
                }
                {...{
                  item,
                  index,
                  touched,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                  getIn
                }}
              />
            </StyledCell>
            <StyledCell>{item.verifiedBy}</StyledCell>
            <StyledCell>{item.department}</StyledCell>
            <StyledCell>
              {
                canVerify(item) && !isStatusVerified
                  ? (
                    <Comment
                      {...{
                        item,
                        index,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        getIn
                      }}
                    />
                  )
                  : (item.comment)
              }
            </StyledCell>
            <StyledCell>
              {
                canVerify(item) && !item.verifiedBy && (
                  <ButtonConfirmation
                    title="Confirmation"
                    textDialog="Are you sure you want to verify ?"
                    textConfirm="Ok"
                    textCancel="Cancel"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={!item.comment}
                    onConfirm={() => handleSubmit('verify', index)}
                  >
                    Verify
                  </ButtonConfirmation>
                )
              }
            </StyledCell>
          </StyledRow>
        ))
      }
    </>
  );
};

BenefitView.propTypes = {
  form: PropTypes.object.isRequired
};

export default BenefitView;
