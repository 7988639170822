import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button } from '@material-ui/core';

const Notifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { message, option, dismissed } = useStoreState(state => state.notif)
  const setDismissed = useStoreActions(actions => actions.notif.setDismissed)

  useEffect(() => {
    if ( message && !dismissed ) {
      enqueueSnackbar(
        message, 
        { 
          ...option, 
          onExited: (event, myKey) => {
            setDismissed(true)
          },
          action: key => (
            <Button color="inherit" onClick={() => closeSnackbar(key)}>close</Button>
          ),
        },
      )
    }
  }, [message, option, dismissed, enqueueSnackbar, setDismissed, closeSnackbar])

  return null;
}

export default Notifier;