import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import DatePicker from 'src/components/DatePicker';

import { isValidationRequired } from 'src/utils/validation';

const ProposeSchedulePlan = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'scheduledPlan';
  const handleChange = (newValue) => {
    setFieldValue(key, newValue !== 'Invalid date' ? newValue : '', true);
    setFieldTouched(key, true, true);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Propose Schedule Plan"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <DatePicker
        error={Boolean(touched[key] && errors[key])}
        disabled={disabled}
        name={key}
        placeholder="DD/MM/YYYY"
        format="DD/MM/YYYY"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
      />
    </FormItem>
  );
};

ProposeSchedulePlan.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default ProposeSchedulePlan;
