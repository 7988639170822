import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { ArrowDropDown, ArrowLeft } from '@material-ui/icons';

import api from 'src/api';
import {
  ORGANIZATION_ALL,
  STAGE_LIST,
  YEAR_BUILT
} from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import DataTable from 'src/components/DataTable';
import FileDownload from 'js-file-download';
import Filter from './Filter';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  }
}));

const entries = [10, 20, 50, 100, 200];

const RewardSummary = () => {
  const classes = useStyles();

  const rewardSummaryFilter = useStoreState((state) => state.ideaBlastReward.filter);
  const setNotification = useStoreActions((action) => action.notif.setNotif);
  const setRewardSummaryFilter = useStoreActions((action) => action.ideaBlastReward.setFilter);

  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    yearOptions: [],
    siteOptions: [],
    phaseOptions: []
  });
  const [filter, setFilter] = useState({
    year: [],
    site: [],
    phase: []
  });
  const [filterParams, setFilterParams] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const tableHeaders = [
    { key: 'registrationNumber', label: 'Request No' },
    { key: 'initiative', label: 'Initiative Name' },
    { key: 'submitter', label: 'Submitter' },
    { key: 'status', label: 'Status' },
    { key: 'rewardRegister', label: 'Reward Register' },
    { key: 'rewardImplementation', label: 'Reward Implementation' },
    { key: 'totalReward', label: 'Total Reward' }
  ];

  const filterIdeaBlastStage = (stages) => {
    let transformedObj = {};
    const transformedArray = [];
    const ideaBlastData = stages.filter((item) => item.name.includes('Idea Blast'));

    ideaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          ...stage,
          name: stage?.name?.substring(3)
        }
      };

      transformedArray.push(transformedObj);
    });

    return transformedArray;
  };

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response?.data?.data;
    }, { initialData: [] }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_ALL, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.organizationAll(query);

      return response?.data?.data;
    }, { initialData: [] }
  );

  const { data: stages } = useQuery(
    [STAGE_LIST, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.stageList(query);

      return response?.data?.data.length
        ? filterIdeaBlastStage(response.data.data)
        : [];
    }, { initialData: [] }
  );

  const fetchRewardSummary = async () => {
    setIsFetching(true);

    const query = {
      Filters: filterParams,
      page: currentPage,
      pageSize
    };
    const { data: response } = await api.implementation.implementationReward({
      ...search && { filters: `search==${search}` },
      ...query
    });
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response?.totalData === 0
        ? response?.totalData
        : showing.show,
      to: response?.totalData <= pageSize
        ? response?.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData((response?.data && response?.data.length)
      ? response?.data
      : []);
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    const query = {
      Filters: filterParams,
      page: currentPage,
      pageSize
    };

    try {
      const response = await api.implementation.implementationExportReward({
        ...search && { filters: `search==${search}` },
        ...query
      }, 'blob');

      const fileName = response.headers['content-disposition'].split('\'');

      FileDownload(response.data, fileName[2]);
      setIsDownloading(false);
    } catch (data) {
      setNotification({
        message: data?.data?.Message || data?.data?.message?.messageEng || 'Download failed',
        option: {
          variant: 'error'
        }
      });

      setIsDownloading(false);
    }
  };

  const createParamValue = (value) => value.join('|');

  const generateParams = () => new Promise((resolve, reject) => {
    const paramValues = [];
    let paramName = '';

    Object.keys(filter).forEach((key) => {
      if (filter[key].length) {
        switch (key) {
          case 'year': {
            paramName = 'YearBuilt';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'site': {
            paramName = 'OrganizationId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'phase': {
            paramName = 'InnovationGateStatuses.stageId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    resolve(paramValues);
    reject();
  });

  const resetFilter = () => {
    setFilter({
      year: [],
      site: [],
      phase: []
    });
  };

  useEffect(() => {
    if (rewardSummaryFilter) {
      setFilter(rewardSummaryFilter);
    }
  }, []);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      yearOptions: years,
      siteOptions: sites,
      phaseOptions: stages
    });
  }, [years, sites, stages]);

  useEffect(() => {
    const setParams = async () => {
      const paramData = await generateParams();
      setCurrentPage(1);
      setFilterParams(paramData.join(','));
    };

    setRewardSummaryFilter(filter);

    setParams()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [filter]);

  useEffect(() => {
    fetchRewardSummary()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [search, filterParams, pageSize, currentPage]);

  return (
    <Page
      title="Reward Summary"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Reward Summary" />
        <Box my={5} className={classes.card}>
          <Grid container spacing={4}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography
                  className={classes.titleFilter}
                  onClick={() => setIsFilterShown(!isFilterShown)}
                >
                  Filter
                </Typography>
                {
                  isFilterShown
                    ? (
                      <ArrowDropDown
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(false)}
                      />
                    )
                    : (
                      <ArrowLeft
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(true)}
                      />
                    )
                }
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {
                isFetching && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignSelf="end"
                      alignItems="center"
                      marginRight={1}
                      height="100%"
                    >
                      <CircularProgress size={24} style={{ alignSelf: 'center' }} />
                    </Box>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          {
            isFilterShown && (
              <Box paddingX={5}>
                <Filter
                  {...{
                    ...filterOptions,
                    values: { ...filter },
                    setFilter
                  }}
                />
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 15, marginTop: 5 }}
                >
                  <Grid
                    item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isFetching}
                      style={{ alignSelf: 'self-end' }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Box>
        <DataTable
          isCustom
          isVisible
          tableHeader={tableHeaders}
          tableData={documentData}
          leftComp={isDownloading
            ? (
              <CircularProgress size={24} />
            )
            : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload()}
              >
                Download
              </Button>
            )}
          {...{
            entries,
            showEntries,
            setShowEntries,
            pageSize,
            setPageSize,
            setSearch,
            totalData,
            currentPage,
            setCurrentPage,
            totalPage
          }}
        >
          {({ key, data }) => {
            switch (key) {
              case 'rewardRegister':
              case 'rewardImplementation':
              case 'totalReward': {
                return (
                  <Typography>
                    {
                      data[key]
                        ? Number(data[key]).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
                        : data[key]
                    }
                  </Typography>
                );
              }
              default: {
                return <Typography>{data[key]}</Typography>;
              }
            }
          }}
        </DataTable>
      </Container>
    </Page>
  );
};

export default RewardSummary;
