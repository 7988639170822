import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from 'src/api';
import convertToBase64 from 'src/utils/files';
import _ from 'lodash';
import EstimateView from './EstimateView';
import GeneralView from './GeneralView';
import Decision from './Decision';
import {
  initialValues,
  validationIdeaBlast
} from './config';
import getProperties from './helpers';
import CommitteePickerView from './CommitteePickerView';
import CommitteeSuggestionListView from './CommitteeSuggestionListView';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  additionalForm: {
    borderRadius: '24px'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  },
}));

const IdeaBlastView = ({
  handleChangeTab,
  handleAccessPermissionClick,
  detail,
  approval
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const user = useStoreState((state) => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [canRemind, setCanRemind] = useState(
    detail?.innovationGateStatuses?.notificationSent === false
  );
  const init = {
    fullname: user.name,
    ...user
  };
  const properties = getProperties(id, detail, user, isEdit);
  const {
    isRegistration,
    isImplementationDraft,
    isCompleted,
    canSubmit,
    canReview,
    committeeAssigned,
    canViewSuggestionList,
    canManageAccess,
    isWaitingApproval,
    isWaitingReviewAdmin,
    isWaitingCommittee
  } = properties;

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
        } else {
          data.status < 300 && location.key === 'default'
            ? navigate('/app/innovation-view') : navigate(-1);
        }
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const saveDraft = useMutation((form) => api.registration.draft(form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.registration.edit(id, form), mutateOption('Edit'));
  const submitDraft = useMutation((form) => api.registration.submitdraft(id, form), mutateOption('Submit'));
  const updateDraft = useMutation((form) => api.registration.updatedraft(id, form), mutateOption('Save Draft'));
  const sendback = useMutation((form) => api.registration.sendback(id, form), mutateOption('Sent Back'));
  const cancel = useMutation(() => api.registration.cancel(id), mutateOption('Cancel'));
  const reactivate = useMutation((form) => api.registration.reactivate(id, form), mutateOption('Reactivate'));
  const createImplementation = useMutation(
    () => api.registration.createImplementation(id), mutateOption('Create Implementation', () => handleChangeTab(1))
  );
  const approve = useMutation((form) => api.registration.approve(id, form), mutateOption('Approve'));
  const approveIdeaBlast = useMutation((form) => api.registration.approveIdeaBlast(id, form), mutateOption('Approve'));
  const addCommittee = useMutation((form) => api.registration.addCommittee(id, form), mutateOption('Assign Committee'));

  const generateForm = async (form) => {
    const {
      isCoach,
      isTechnology,
      innovationAttachments,
      innovationStatus,
      innovationBenefits,
      adminSite,
      submittedById,
      requestedFor,
      promotor,
      ...rest
    } = form;
    const base64files = await convertToBase64(innovationAttachments);
    const newForm = {
      ...rest,
      innovationBenefits: _.uniq(innovationBenefits.map((d) => ({ benefitCategoryId: d }))),
      submittedById: submittedById.id,
      innovationAttachments: base64files,
      requestedFor: requestedFor?.empId || requestedFor?.userAd || '',
      promotorUser: promotor?.empId || promotor?.userAd || ''
    };
    return newForm;
  };
  const handleSubmitForm = async (form) => {
    const newForm = await generateForm(form);
    submitDraft.mutate(newForm);
  };
  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };
  const handleSaveDraft = async (form) => {
    const newForm = await generateForm(form);
    if (detail.innovationStatus === 'Draft') {
      updateDraft.mutate(newForm);
    } else {
      saveDraft.mutate(newForm);
    }
  };
  const handleApproval = async (
    comment,
    suggestion,
    form,
    committees
  ) => {
    /**
     * `approval?.isCustomFlow` === true
     *  if `status` === 'Waiting Admin Verify' (isWaitingReviewAdmin)
     *    call `addCommittee`
     *  if `status` === 'Waiting Assessment Committee' (isWaitingCommittee)
     *    call `approveIdeaBlast`
     *  else
     *    call 'approveIdeaBlast'
     *
     * `approval?.isCustomFlow` === false
     *    call `approve`
     * */
    switch (true) {
      case approval?.isCustomFlow: {
        switch (true) {
          case isWaitingReviewAdmin: {
            const body = {
              registrationApprovals: committees.filter((d) => !!d).map((d) => ({ userId: d.id }))
            };

            addCommittee.mutate(body);
            break;
          }
          case isWaitingCommittee: {
            const ideaBlastSuggestions = suggestion?.map((d) => ({ benefitCategoryId: d }));
            const body = {
              Comment: comment,
              ...suggestion && { ideaBlastSuggestions }
            };

            approveIdeaBlast.mutate(body);
            break;
          }
          default: {
            const body = {
              Comment: comment
            };

            approveIdeaBlast.mutate(body);
            break;
          }
        }
        break;
      }
      default: {
        const body = {
          Comment: comment,
          RoleId: form.RoleId
        };

        approve.mutate(body);
        break;
      }
    }
  };
  const handleVerify = async (committees) => {
    const newForm = {
      registrationApprovals: committees.filter((d) => !!d).map((d) => ({ userId: d.id }))
    };
    addCommittee.mutate(newForm);
  };
  const handleSentBack = async (comment) => {
    const newForm = { Comment: null };
    sendback.mutate(newForm);
  };
  const handleReactivate = async () => {
    reactivate.mutate();
  };
  const handleOpenEditAdmin = () => setIsEdit(true);
  const handleCloseEditAdmin = () => setIsEdit(false);
  const handleCancel = async () => { cancel.mutate(); };
  const handleCreateG1 = async () => { createImplementation.mutate(); };
  const handleOpenEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);
  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };
  const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
  const isAdminSite = approve?.isAdminSite;
  const isSameSite = user?.cluster?.id === detail?.organizationId;
  const isAdminOfSite = isAdminSite && isSameSite;
  const showRemind = (isAdminOfSite || isAdmin) && (
    (isRegistration && (isWaitingApproval || isCompleted))
    || isImplementationDraft);

  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      validateOnMount={false}
      initialValues={initialValues({ id, init, detail })}
      validationSchema={Yup.object().shape(validationIdeaBlast)}
      onSubmit={(value, actions) => {
        actions.setSubmitting(true);
        const {
          action, rate, registration_number, ...form
        } = value;
        switch (action) {
          case 'submit':
            handleSubmitForm(form);
            break;
          case 'edit':
            handleEditAdminForm(form);
            break;
          default:
            break;
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        submitForm,
        isSubmitting,
        handleSubmit,
        isValid,
        dirty
      }) => {
        const canApprove = approval?.isCanApprove;

        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <GeneralView
                disabled={!isRegistration || !canSubmit}
                {...{
                  ...properties,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
              <EstimateView
                {...{
                  ...properties,
                  id,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
            </Box>
            {(canReview || showEdit) && (
              <Box className={classes.formContainerBtm}>
                <CommitteePickerView
                  {...{
                    ...properties,
                    committeeAssigned,
                    id,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    values,
                  }}
                />
              </Box>
            )}
            {canViewSuggestionList && !showEdit && (
              <Box className={classes.formContainerBtm}>
                <CommitteeSuggestionListView values={committeeAssigned} />
              </Box>
            )}
            <Box mt={5} mb={5}>
              <Decision
                {...{
                  ...properties,
                  showEdit,
                  id,
                  detail,
                  values,
                  submitForm,
                  isSubmitting,
                  isValid,
                  setFieldValue,
                  dirty,
                  showRemind,
                  isEdit,
                  canRemind,
                  canApprove,
                  canManageAccess,
                  canSentBack: canApprove,
                  isCustomFlow: approval?.isCustomFlow,
                  handleOpenEdit,
                  handleCloseEdit,
                  handleReactivate,
                  handleSaveDraft,
                  handleApproval,
                  handleVerify,
                  handleSentBack,
                  handleCancel,
                  handleCreateG1,
                  handleOpenEditAdmin,
                  handleCloseEditAdmin,
                  handleRemind,
                  handleAccessPermissionClick
                }}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

IdeaBlastView.propTypes = {
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  detail: PropTypes.object,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    isAdminSite: PropTypes.bool,
    data: PropTypes.array
  })
};

export default IdeaBlastView;
