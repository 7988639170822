import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const Demand = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'demand';
  return (
    <FormItem
      id={key}
      label="Initiative Name"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        disabled
        name={key}
        type="text"
        placeholder="Enter initiative name"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

Demand.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Demand;
