import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormItem from './FormItem';

const useStyles = makeStyles(() => ({
  root: {},
}));

const AlertConfirmation = ({
  title,
  showLoading = false,
  showTextInput = false,
  hideCancelButton = false,
  open = false,
  onConfirm,
  onClose,
  textDialog = '',
  textConfirm = 'Yes',
  textCancel = 'No',
  children,
  ...anotherProps
}) => {
  const classes = useStyles();
  const [disable, setDisable] = React.useState(showLoading);
  const [comment, setComment] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleConfirm = () => {
    setDisable(true);
    onConfirm && onConfirm(comment);
    setDisable(false);
  };

  return (
    <>
      {children}
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby={textConfirm}
        {...anotherProps}
      >
        <DialogTitle id={textConfirm}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {textDialog}
          </DialogContentText>
          {showTextInput && (
            <FormItem
              label="Comment"
            >
              <TextField
                name="comment"
                variant="outlined"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormItem>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={disable}
            onClick={handleConfirm}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            {textConfirm}
            {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          {!hideCancelButton && (
            <Button variant="outlined" disabled={disable} onClick={onClose} color="secondary">
              {textCancel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

AlertConfirmation.propTypes = {
  title: PropTypes.string,
  showLoading: PropTypes.bool,
  showTextInput: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  textConfirm: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  textDialog: PropTypes.string,
  textCancel: PropTypes.string,
  children: PropTypes.node,
};

export default AlertConfirmation;
