import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import api from 'src/api';
import convertToBase64 from 'src/utils/files';
import EstimateView from './EstimateView';
import GeneralView from './GeneralView';
import Decision from './Decision';
import {
  initialValues, validationImplementation
} from './config';
import getProperties from './helpers';
import CommitteePickerView from './CommitteePickerView';
import CommitteeAssesmentListView from './CommitteeAssesmentListView';
import CommitteeAssignmentScoreView from './CommitteeAssignmentScore';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  additionalForm: {
    borderRadius: '24px'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  },
}));

const ImplementationView = ({
  detail,
  registration,
  approval,
  handleAccessPermissionClick
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const user = useStoreState((state) => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [canRemind, setCanRemind] = useState(
    registration?.innovationGateStatuses?.notificationSent === false
  );
  const init = {
    fullname: user.name,
    ...user
  };
  const properties = getProperties(id, detail, user, registration, approval, isEdit);
  const {
    isAdmin,
    isAdminSite,
    canReview,
    canViewAssessment,
    canAcknowledge,
    getCommitteeScore,
    committeeAssigned,
    isWaitingReviewAdmin,
    isWaitingCommittee,
    isWaitingAcknowledge,
    isWaitingConfirmation,
    isWaitingApproval,
    isImplementation,
    canViewConfirm,
    canManageAccess
  } = properties;

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
        } else {
          data.status < 300 && location.key === 'default'
            ? navigate('/app/innovation-view') : navigate(-1);
        }
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const saveDraft = useMutation((form) => api.implementation.draft(id, form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.implementation.edit(id, form), mutateOption('Edit'));
  const submit = useMutation((form) => api.implementation.create(id, form), mutateOption('Submit'));
  const sendback = useMutation((form) => api.implementation.sendback(id, form), mutateOption('Sent Back'));
  const cancel = useMutation(() => api.implementation.cancel(id), mutateOption('Cancel'));
  const reactivate = useMutation((form) => api.implementation.reactivate(id, form), mutateOption('Reactivate'));
  const approve = useMutation((form) => api.implementation.approve(id, form), mutateOption('Approve'));
  const confirm = useMutation((form) => api.implementation.confirmation(id, form), mutateOption('Confirm'));
  const addCommittee = useMutation((form) => api.implementation.addCommittee(id, form), mutateOption('Assign Committee'));
  const approveIdeaBlast = useMutation((form) => api.registration.approveIdeaBlast(id, form), mutateOption('Approve'));
  const approveRegistration = useMutation((form) => api.registration.approve(id, form), mutateOption('Approve'));

  const generateForm = async (form) => {
    const {
      finalResult,
      actionDone,
      actualTimePlan,
      solution,
      inBenefit,
      diBenefit,
      attachments
    } = form;
    const base64files = await convertToBase64(attachments);
    const newForm = {
      finalResult,
      actionDone,
      actualTimePlan,
      inBenefit,
      diBenefit,
      Solution: solution,
      attachments: base64files
    };
    return newForm;
  };
  const handleSubmitForm = async (form) => {
    const newForm = await generateForm(form);
    submit.mutate(newForm);
  };
  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };
  const handleSaveDraft = async (form) => {
    const newForm = await generateForm(form);
    saveDraft.mutate(newForm);
  };
  const handleApproval = async (
    comment,
    suggestion,
    form,
    committees
  ) => {
    /**
     * `approval?.isCustomFlow` === true
     *  if `status` === 'Waiting Admin Verify' (isWaitingReviewAdmin)
     *    call `addCommittee`
     *  if `status` === 'Waiting Assessment Committee' (isWaitingCommittee)
     *    call `approveIdeaBlast`
     *  else
     *    call 'approve'
     *
     * `approval?.isCustomFlow` === false
     *    call `approveRegistration`
     * */
    switch (true) {
      case approval?.isCustomFlow: {
        switch (true) {
          case isWaitingReviewAdmin: {
            const newForm = {
              registrationApprovals: committees.filter((d) => !!d).map((d) => ({ userId: d.id }))
            };

            addCommittee.mutate(newForm);
            break;
          }
          case isWaitingCommittee: {
            const ideaBlastSuggestions = suggestion?.map((d) => ({ benefitCategoryId: d }));
            const body = {
              Comment: comment,
              ...suggestion && { ideaBlastSuggestions }
            };

            approveIdeaBlast.mutate(body);
            break;
          }
          default: {
            const newForm = { Comment: comment };

            approve.mutate(newForm);
            break;
          }
        }
        break;
      }
      default: {
        const body = {
          Comment: comment,
          RoleId: form.RoleId
        };

        approveRegistration.mutate(body);
        break;
      }
    }
  };
  const handleVerify = async (committees) => {
    const newForm = {
      registrationApprovals: committees.filter((d) => !!d).map((d) => ({ userId: d.id }))
    };
    addCommittee.mutate(newForm);
  };
  const handleAssessment = () => {
    navigate(`/app/form-assessment/${id}`, {
      state: {
        initiative: registration.initiative,
        detail: getCommitteeScore(user.id)
      }
    });
  };
  const handleViewAssessment = (committee) => {
    navigate(`/app/form-assessment/${id}`, {
      state: {
        initiative: registration.initiative,
        detail: getCommitteeScore(committee),
        viewOnly: true,
        isAdmin,
        isAdminSite,
        isWaitingAcknowledge,
        isWaitingConfirmation,
      }
    });
  };
  const handleSentBack = async (comment) => {
    const newForm = { Comment: null };
    sendback.mutate(newForm);
  };
  const handleReactivate = async () => {
    reactivate.mutate();
  };
  const handleCancel = async () => {
    cancel.mutate();
  };
  const handleConfirm = async () => {
    confirm.mutate();
  };
  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };
  const handleOpenEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleOpenEditAdmin = () => setIsEdit(true);
  const handleCloseEditAdmin = () => setIsEdit(false);
  const showRemind = isImplementation && (isAdminSite || isAdmin) && (isWaitingApproval);

  return (
    <Formik
      validateOnChange={false}
      enableReinitialize
      validateOnMount={false}
      initialValues={initialValues({
        id, init, detail, registration, approval
      })}
      validationSchema={Yup.object().shape(validationImplementation)}
      onSubmit={(value, actions) => {
        actions.setSubmitting(true);
        const {
          action, rate, registration_number, ...form
        } = value;
        switch (action) {
          case 'submit':
            handleSubmitForm(form);
            break;
          case 'edit':
            handleEditAdminForm(form);
            break;
          default:
            break;
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        submitForm,
        isSubmitting,
        handleSubmit,
        isValid,
        dirty
      }) => {
        const canApprove = approval?.isCanApprove;

        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <GeneralView
                disabled
                {...{
                  ...properties,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
              <EstimateView
                {...{
                  ...properties,
                  id,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
            </Box>
            {(canReview || showEdit) && (
              <Box className={classes.formContainerBtm}>
                <CommitteePickerView
                  {...{
                    ...properties,
                    id,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    values,
                  }}
                />
              </Box>
            )}
            {(isAdmin || isAdminSite || canViewAssessment || canAcknowledge) && !showEdit && (
              <Box className={classes.formContainerBtm}>
                <CommitteeAssesmentListView
                  detail={detail}
                  values={committeeAssigned}
                  handleViewAssessment={handleViewAssessment}
                  isAdmin={isAdmin}
                  isAdminSite={isAdminSite}
                />
              </Box>
            )}
            {canViewConfirm && (
              <Box className={classes.formContainerBtm}>
                <CommitteeAssignmentScoreView
                  processed={isWaitingAcknowledge || isWaitingConfirmation}
                  detail={detail}
                />
              </Box>
            )}
            <Box mt={5} mb={5}>
              <Decision {...{
                ...properties,
                showEdit,
                isEdit,
                id,
                detail,
                values,
                submitForm,
                isSubmitting,
                isValid,
                setFieldValue,
                dirty,
                showRemind,
                canRemind,
                canApprove,
                canManageAccess,
                canSentBack: canApprove,
                isCustomFlow: approval?.isCustomFlow,
                handleOpenEditAdmin,
                handleCloseEditAdmin,
                handleOpenEdit,
                handleCloseEdit,
                handleReactivate,
                handleSaveDraft,
                handleApproval,
                handleVerify,
                handleAssessment,
                handleSentBack,
                handleCancel,
                handleConfirm,
                handleRemind,
                handleAccessPermissionClick
              }}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

ImplementationView.propTypes = {
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  detail: PropTypes.object,
  registration: PropTypes.object,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  })
};

export default ImplementationView;
