import * as Yup from 'yup';

const initialValues = (props) => {
  const { detail } = props;

  return { ...detail };
};

const validation = Yup.object().shape({
  oneTimeBenefit: Yup.string(),
  oneTimeCost: Yup.string(),
  recurringBenefit: Yup.string(),
  recurringCost: Yup.string(),
  g1TotalNet: Yup.string(),
  g1TotalRecurring: Yup.string(),
  g2TotalNet: Yup.string(),
  g2TotalRecurring: Yup.string(),
  g3TotalNet: Yup.string(),
  g3TotalRecurring: Yup.string(),
  g4TotalNet: Yup.string(),
  g4TotalRecurring: Yup.string(),
  g5TotalNet: Yup.string(),
  g5TotalRecurring: Yup.string(),
  totalCapex: Yup.string(),
  totalOpex: Yup.string(),
  ideaSizingCapex: Yup.string(),
  ideaSizingOpex: Yup.string(),
  pocCapex: Yup.string(),
  pocOpex: Yup.string(),
  mvpCapex: Yup.string(),
  mvpOpex: Yup.string(),
  mmpCapex: Yup.string(),
  mmpOpex: Yup.string(),
  returnOnInvestment: Yup.string()
});

export {
  initialValues,
  validation
};
