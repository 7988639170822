import React from 'react';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import { useLocation } from 'react-router-dom';

const InitiativeName = () => {
  const { state } = useLocation();
  const key = 'initiative';
  return (
    <FormItem
      label="Initiative Name"
      id={key}
    >
      <StyledInput
        disabled
        name={key}
        type="text"
        value={state?.initiative}
        placeholder="Enter initiative name"
      />
    </FormItem>
  );
};

export default InitiativeName;
