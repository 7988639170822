import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import PromotorSelector from 'src/components/PromotorSelector';
import { isValidationRequired } from 'src/utils/validation';

const Promotor = ({
  disabled,
  isIndividual,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  validation
}) => {
  const key = 'promotor';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };
  const message = errors?.promotor?.empId;
  const requestor = values?.requestedFor;
  const creator = values?.submittedById;

  useEffect(() => {
    if (!isEmpty(values[key])) {
      const data = values[key];

      if (data.empId === creator?.empId || data.empId === requestor?.empId) {
        handleChange({});
      }
    }
  }, [values[key], creator, requestor]);

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Promotor / Sponsor"
      id={key}
      error={Boolean(touched[key] && message)}
      helperText={touched[key] && message}
    >
      <PromotorSelector
        creator={creator}
        requestor={requestor}
        disabled={disabled}
        error={Boolean(touched[key] && message)}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter name/email/department"
      />
    </FormItem>
  );
};

Promotor.propTypes = {
  disabled: PropTypes.bool,
  isIndividual: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Promotor;
