import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RecurringCost from './RecurringCost';
import RecurringBenefit from './RecurringBenefit';
import OneTimeCost from './OneTimeCost';
import OneTimeBenefit from './OneTimeBenefit';
import Guidance from './Guidance';
import MvpFundingCapex from './MvpFundingCapex';
import MvpFundingOpex from './MvpFundingOpex';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const FourthView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginBottom: 15 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Planned Benefit & Cost</Typography>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RecurringCost {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RecurringBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <OneTimeCost {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <OneTimeBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MvpFundingCapex {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MvpFundingOpex {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Guidance content="lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};

export default FourthView;
