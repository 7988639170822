import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import UserSelector from 'src/components/UserSelector';

import { isValidationRequired } from 'src/utils/validation';

const DigitalCoach = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  organizationId,
  values,
  validation
}) => {
  const key = 'digiCoach';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };
  const error = errors[key];
  const message = error?.id;

  return (
    <FormItem
      required={isValidationRequired(key, validation, values, 'Digital')}
      id={key}
      label="Digital Coach"
      error={Boolean(touched[key] && message)}
      helperText={touched[key] && message}
    >
      <UserSelector
        organizationId={organizationId}
        error={Boolean(touched[key] && message)}
        disabled={disabled}
        name={key}
        type="text"
        placeholder="Enter digital coach"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </FormItem>
  );
};

DigitalCoach.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired
};

export default DigitalCoach;
