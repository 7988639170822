import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import NumberFormatCustom from 'src/components/NumberFormat';

const ReturnOnInvestment = ({
  disabled = true,
  touched,
  errors,
  values,
  handleBlur,
  handleChange
}) => {
  const key = 'returnOnInvestment';

  return (
    <FormItem
      id={key}
      label="Return On Investment (ROI) %"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        name={key}
        disabled={disabled}
        value={values[key]}
        error={Boolean(touched[key] && errors[key])}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />
    </FormItem>
  );
};

ReturnOnInvestment.propTypes = {
  disabled: PropTypes.bool,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default ReturnOnInvestment;
