import * as Yup from 'yup';
import moment from 'moment';

export const initialValues = ({ detail }) => {
  return {
    useCase: detail?.useCase || '',
    toAchieve: detail?.toAchieve || '',
    throughBenefit: detail?.throughBenefit || '',
    asCompared: detail?.asCompared || '',
    valueDesc: detail?.valueDesc || '',
    scheduledPlan: detail?.scheduledPlan
      ? moment(detail?.scheduledPlan).toDate()
      : moment().toDate(),
    digiCoach: detail?.digitalCoach || {},
    withinScope: detail?.withinScope || '',
    outsideScope: detail?.outsideScope || '',
    originDesc: detail?.originDesc || '',
    g1RecurCost: detail?.g1RecurCost || 0,
    g1RecurBenefit: detail?.g1RecurBenefit || 0,
    g1OnetimeCost: detail?.g1OnetimeCost || 0,
    g1OnetimeBenefit: detail?.g1OnetimeBenefit || 0,
    formStatus: detail?.formStatus || '',
    attachments: detail?.g1RequirementAttachments || [],
    action: '',
  };
};

export const validation = (isDigital) => Yup.object().shape({
  useCase: Yup.string().required('Our Use Case Help is required'),
  toAchieve: Yup.string().required('To Achieve is required'),
  throughBenefit: Yup.string().required('Throught The Benefit Of is required'),
  asCompared: Yup.string().required('As Compared To is required'),
  valueDesc: Yup.string().required('Value Description is required'),
  scheduledPlan: Yup.string().required('Propose Schedule Plan is required'),
  digiCoach: isDigital
    ? Yup.object().shape({
      id: Yup.string().required('Digital coach is required')
    })
    : Yup.object(),
  withinScope: Yup.string().required('Within Scope is required'),
  outsideScope: Yup.string().required('Outside Scope is required'),
  originDesc: Yup.string().required('Original Description is required'),
  attachments: Yup.array().min(1, 'Attachments is required'),
  g1RecurCost: Yup.number()
    .min(0, 'G1 Total Annual Net Recurring Cost should be 0 or more')
    .required('G1 Total Annual Net Recurring Cost is required'),
  g1RecurBenefit: Yup.number()
    .min(0, 'G1 Total Annual Net Recurring Benefit should be 0 or more')
    .required('G1 Total Annual Net Recurring Benefit is required'),
  g1OnetimeCost: Yup.number()
    .min(0, 'G1 Total Annual Net One Time Cost should be 0 or more')
    .required('G1 Total Annual Net One Time Cost is required'),
  g1OnetimeBenefit: Yup.number()
    .min(0, 'G1 Total Annual One Time Net Benefit should be 0 or more')
    .required('G1 Total Annual One Time Net Benefit is required'),
});
