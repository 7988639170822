import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import {
  useQuery,
  useMutation
} from 'react-query';
import { useStoreActions } from 'easy-peasy';
import {
  Button,
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Delete,
  Edit
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import {
  isEmpty,
  orderBy
} from 'lodash';

import api from 'src/api';
import { YEAR_BUILT } from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import IconButtonConfirmation from 'src/components/IconButtonConfirmation';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

import PopUpForm from './PopUpForm';
import PopUpClone from './PopUpClone';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  boxFilter: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    maxHeight: 'calc(100vh - 27.5rem)',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  }
}));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: theme.palette.secondary.main,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    color: '#FFFFFF',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  }
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const AssessmentQuestion = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogCloneOpen, setIsDialogCloneOpen] = useState(false);
  const [filter, setFilter] = useState({
    year: moment().format('YYYY'),
    level: 'SITE'
  });
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({
    year: '',
    level: '',
    descriptionEn: '',
    descriptionId: '',
    weight: 0
  });
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const headers = [
    { key: 'action', title: 'Action' },
    { key: 'descriptionEn', title: 'Description (EN)' },
    { key: 'descriptionId', title: 'Description (ID)' },
    { key: 'weight', title: 'Weight' }
  ];

  const levels = [
    { id: 'ITM', name: 'ITM' },
    { id: 'SITE', name: 'SITE' }
  ];

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const { data: response } = await api.masterData.yearBuilt(query);

      return orderBy(response?.data, 'name', 'desc');
    }, { initialData: [] }
  );

  const {
    data: {
      canAdd,
      data: questions
    },
    refetch: questionsRefetch
  } = useQuery(
    ['questions', filter],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const { year, level } = query;

      try {
        const { data: response } = await api.assessment.getQuestions({ Filters: `Year==${year},Level==${level}` });
        return response?.data;
      } catch (err) {
        return {
          canAdd: false,
          data: []
        };
      }
    }, {
      initialData: {
        canAdd: false,
        data: []
      }
    }
  );

  const {
    mutate: cloneMutate,
    isLoading: cloneIsLoading,
    isSuccess: cloneIsSuccess,
    isError: cloneIsError,
    data: cloneData,
    error: cloneError
  } = useMutation((body) => api.assessment.cloneQuestion(body));

  const {
    mutate: addQuestionMutate,
    isSuccess: addQuestionIsSuccess,
    isError: addQuestionIsError,
    data: addQuestionData,
    error: addQuestionError
  } = useMutation((body) => api.assessment.addQuestion(body));

  const {
    mutate: editQuestionMutate,
    isSuccess: editQuestionIsSuccess,
    isError: editQuestionIsError,
    data: editQuestionData,
    error: editQuestionError
  } = useMutation(({ id, body }) => api.assessment.editQuestion(id, body));

  const {
    mutate: deleteQuestionMutate,
    isSuccess: deleteQuestionIsSuccess,
    isError: deleteQuestionIsError,
    data: deleteQuestionData,
    error: deleteQuestionError
  } = useMutation((id) => api.assessment.deleteQuestion(id));

  const docId = useRef(null);

  const canClone = useMemo(() => {
    return canAdd && !isEmpty(years);
  }, [
    canAdd,
    years
  ]);

  const onFilterChange = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleOpenForm = (val) => {
    if (!isEmpty(val)) {
      docId.current = val.id;
    }

    setIsEdit(!isEmpty(val));
    setForm(() => {
      if (isEmpty(val)) {
        return {
          ...filter,
          descriptionId: '',
          descriptionEn: '',
          weight: 0
        };
      }

      return { ...val };
    });

    setIsDialogOpen(true);
  };

  const handleSubmitClone = (value) => {
    cloneMutate({
      year: filter.year,
      site: filter.level,
      originYear: value
    });
  };

  const handleFormSubmit = (val) => {
    if (isEdit) {
      const { canModify, ...rest } = val;
      editQuestionMutate({
        id: docId.current,
        body: { ...rest }
      });

      return;
    }

    addQuestionMutate(val);
  };

  const handleDelete = (item) => {
    const { id } = item;

    deleteQuestionMutate(id);
  };

  useEffect(() => {
    if (!isDialogOpen) {
      docId.current = null;
    }
  }, [isDialogOpen]);

  useEffect(() => {
    switch (true) {
      case cloneIsSuccess: {
        setIsDialogCloneOpen(false);

        if (cloneData && cloneData.status >= 400) {
          setNotif({
            message: cloneError?.message || 'Failed to clone question',
            option: {
              variant: 'error'
            }
          });

          break;
        }

        setNotif({
          message: 'Question cloned successfully',
          option: {
            variant: 'success'
          }
        });

        questionsRefetch();
        break;
      }
      case cloneIsError: {
        setIsDialogCloneOpen(false);
        setNotif({
          message: cloneError?.message || 'Failed to clone question',
          option: {
            variant: 'error'
          }
        });
        break;
      }
      case addQuestionIsSuccess:
      case editQuestionIsSuccess:
      case deleteQuestionIsSuccess: {
        let successData = null;

        setForm({
          year: '',
          level: '',
          descriptionEn: '',
          descriptionId: '',
          weight: 0
        });
        setIsDialogOpen(false);

        switch (true) {
          case addQuestionIsSuccess: {
            successData = {
              ...addQuestionData,
              success: 'Question added successfully',
              error: 'Failed to add question'
            };
            break;
          }
          case editQuestionIsSuccess: {
            successData = {
              ...editQuestionData,
              success: 'Question edited successfully',
              error: 'Failed to edit question'
            };
            break;
          }
          case deleteQuestionIsSuccess: {
            successData = {
              ...deleteQuestionData,
              success: 'Question deleted successfully',
              error: 'Failed to delete question'
            };
            break;
          }
          default:
            break;
        }

        if (successData && successData.status >= 400) {
          setNotif({
            message: successData?.message?.messageEng || successData?.error,
            option: {
              variant: 'error'
            }
          });

          break;
        }

        setNotif({
          message: successData?.message?.messageEng || successData?.success,
          option: {
            variant: 'success'
          }
        });

        questionsRefetch();
        break;
      }
      case addQuestionIsError:
      case editQuestionIsError:
      case deleteQuestionIsError: {
        let errorData = null;

        setForm({
          year: '',
          level: '',
          descriptionEn: '',
          descriptionId: '',
          weight: 0
        });
        setIsDialogOpen(false);

        switch (true) {
          case addQuestionIsError: {
            errorData = {
              ...addQuestionError,
              message: 'Failed to add question'
            };
            break;
          }
          case editQuestionIsError: {
            errorData = {
              ...editQuestionError,
              message: 'Failed to edit question'
            };
            break;
          }
          case deleteQuestionIsError: {
            errorData = {
              ...deleteQuestionError,
              message: 'Failed to delete question'
            };
            break;
          }
          default:
            break;
        }

        setNotif({
          message: errorData?.message?.messageEng || errorData?.message,
          option: {
            variant: 'error'
          }
        });
        break;
      }
      default:
        break;
    }
  }, [
    cloneIsSuccess,
    cloneIsError,
    cloneError,
    addQuestionIsSuccess,
    addQuestionIsError,
    addQuestionError,
    editQuestionIsSuccess,
    editQuestionIsError,
    editQuestionError,
    deleteQuestionIsSuccess,
    deleteQuestionIsError,
    deleteQuestionError
  ]);

  return (
    <Page
      title="Assessment Question"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Assessment Question" />
        <Grid container spacing={4} className={classes.boxFilter}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.titleFilter}>
              Filter
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormItem
              id="year"
              label="Year"
            >
              <StyledSelect
                name="year"
                placeholder="Select Year"
                value={filter.year}
                options={years}
                onChange={(ev) => onFilterChange('year', ev.target.value)}
              />
            </FormItem>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <FormItem
              id="level"
              label="Level"
            >
              <StyledSelect
                name="level"
                placeholder="Select Level"
                value={filter.level}
                options={levels}
                onChange={(ev) => onFilterChange('level', ev.target.value)}
              />
            </FormItem>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: 15 }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              disabled={!canAdd}
              variant="contained"
              color="secondary"
              style={{ alignSelf: 'self-end', marginRight: 10 }}
              onClick={() => handleOpenForm()}
            >
              Add Question
            </Button>
            <Button
              disabled={!canClone}
              variant="outlined"
              color="secondary"
              style={{ alignSelf: 'self-end' }}
              onClick={() => setIsDialogCloneOpen(true)}
            >
              Clone From Year
            </Button>
          </Grid>
        </Grid>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          <Table
            stickyHeader
            className={classes.table}
          >
            <TableHead className={classes.header}>
              <StyledTableRow>
                {
                  headers.map((item, index) => (
                    <StyledTableCell
                      align="center"
                      key={item?.key}
                      isFirst={index === 0}
                      isLast={index === (headers.length - 1)}
                      style={{
                        width: item.key === 'action'
                          ? 120
                          : 'auto'
                      }}
                    >
                      <Box className={classes.sortContainer}>
                        {item.title}
                      </Box>
                    </StyledTableCell>
                  ))
                }
              </StyledTableRow>
            </TableHead>
            <TableBody className={classes.header}>
              {
                questions.map((item, index) => (
                  <StyledTableRow key={`key-data-${index + 1}`}>
                    {
                      headers.map(({ key }, headIndex) => (
                        <StyledTableCell
                          key={`key-cell-data-${headIndex + 1}`}
                          align="left"
                        >
                          {
                            key === 'action'
                              ? (
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  {
                                    item.canModify && (
                                      <>
                                        <Edit
                                          color="secondary"
                                          onClick={() => handleOpenForm(item)}
                                        />
                                        <IconButtonConfirmation
                                          title="Confirmation"
                                          textDialog="Are you sure you want to delete this question?"
                                          textConfirm="Ok"
                                          textCancel="Cancel"
                                          onConfirm={() => handleDelete(item)}
                                        >
                                          <Delete color="error" />
                                        </IconButtonConfirmation>
                                      </>
                                    )
                                  }
                                </Box>
                              )
                              : (
                                <>{item[key]}</>
                              )
                          }
                        </StyledTableCell>
                      ))
                    }
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>

        <PopUpForm
          data={form}
          isEdit={isEdit}
          isDialogOpen={isDialogOpen}
          handleFormSubmit={handleFormSubmit}
          handleClose={() => setIsDialogOpen(false)}
        />

        <PopUpClone
          isDialogOpen={isDialogCloneOpen}
          isSubmitting={cloneIsLoading}
          years={years}
          selectedYear={filter.year}
          handleSubmit={handleSubmitClone}
          handleClose={() => setIsDialogCloneOpen(false)}
        />
      </Container>
    </Page>
  );
};

export default AssessmentQuestion;
