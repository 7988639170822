const stageName = {
  individual: [
    'REGISTRATION',
    'IMPLEMENTATION'
  ],
  team: [
    'REGISTRATION',
    'G1 REQUIREMENT',
    'G2 Study',
    'G3 Prototype',
    'G4 Implement',
    'G5 Scale Up',
  ]
};
export default stageName;
