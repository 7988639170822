import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';

import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  delete: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    }
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  id,
  isAdmin,
  isAdminSite,
  isDraft,
  canApprove,
  canEdit,
  canSubmit,
  canSentBack,
  canCancel,
  canCreateG1,
  canReactivate,
  canReject,
  canManageAccess,
  handleReactivate,
  handleSaveDraft,
  handleApproval,
  handleSentBack,
  handleCancel,
  handleCreateG1,
  handleReject,
  handleRemind,
  handleAccessPermissionClick,
  values,
  submitForm,
  isSubmitting,
  dirty,
  isValid,
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
  setFieldValue,
  showRemind,
  canRemind,
  setPromptAction
}) => {
  const classes = useStyles();
  const handleBackNav = () => setPromptAction({ needed: false, action: 'close' });

  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };

  const {
    action,
    rate,
    registration_number,
    Comment,
    ...form
  } = values;

  const approveText = (isAdmin || isAdminSite) ? 'Verify' : 'Approve';

  const showSubmit = id ? isDraft : true;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {
        canSubmit && showSubmit && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting || !isValid}
            onConfirm={() => handleSubmit('submit')}
            className={clsx(classes.button, classes.label)}
          >
            Submit
          </ButtonConfirmation>
        )
      }
      {
        isEdit && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to edit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting || !isValid}
            onConfirm={() => handleSubmit('edit')}
            className={clsx(classes.button, classes.label)}
          >
            Save Edit
          </ButtonConfirmation>
        )
      }
      {
        isEdit && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleCloseEdit}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel Edit
          </Button>
        )
      }
      {
        canEdit
        && !isEdit
        && (
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleOpenEdit}
            className={clsx(classes.button, classes.label)}
          >
            Edit
          </Button>
        )
      }
      {
        canApprove && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog={`Are you sure you want to ${isAdmin || isAdminSite ? 'verify' : 'approve'} ?`}
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleApproval({ Comment, ...form })}
            className={clsx(classes.button, classes.label)}
          >
            { approveText }
          </ButtonConfirmation>
        )
      }
      {
        canSubmit
        && showSubmit
        && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to save as draft ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleSaveDraft(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Save as Draft
          </ButtonConfirmation>
        )
      }
      {
        canSentBack && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to sent back ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting || !Comment.length}
            onConfirm={() => handleSentBack({ Comment, ...form })}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Send Back
          </ButtonConfirmation>
        )
      }
      {
        canReject && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to reject ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleReject(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Reject
          </ButtonConfirmation>
        )
      }
      {
        canCreateG1 && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to create G1 ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={() => handleCreateG1(form)}
            className={clsx(classes.button, classes.label)}
          >
            Create G1
          </ButtonConfirmation>
        )
      }
      {
        canCancel && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to cancel ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleCancel(form)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel
          </ButtonConfirmation>
        )
      }
      {
        canReactivate && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to reactivate ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={() => handleReactivate()}
            className={clsx(classes.button, classes.label)}
          >
            Reactivate
          </ButtonConfirmation>
        )
      }
      {
        dirty
          ? (
            <ButtonConfirmation
              title="Confirmation"
              textDialog="Are you sure you want to close ?"
              textConfirm="Ok"
              textCancel="Cancel"
              variant="outlined"
              color="secondary"
              disabled={isSubmitting}
              onConfirm={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </ButtonConfirmation>
          )
          : (
            <Button
              variant="outlined"
              color="secondary"
              disabled={isSubmitting}
              onClick={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </Button>
          )
      }
      {
        showRemind && (
          <ButtonConfirmation
            variant="contained"
            color="secondary"
            title="Confirmation"
            textDialog="Are you sure you want to send approval reminder email ?"
            textConfirm="Yes"
            textCancel="No"
            disabled={!canRemind}
            onConfirm={() => handleRemind()}
            className={clsx(classes.button, classes.label)}
          >
            Send Email Reminder
          </ButtonConfirmation>
        )
      }
      {
        canManageAccess && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleAccessPermissionClick}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Manage Access
          </Button>
        )
      }
    </Box>
  );
};

Decision.propTypes = {
  id: PropTypes.string,
  isEdit: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isAdminSite: PropTypes.bool,
  isDirectSuperior: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isDraft: PropTypes.bool,
  isIdeaBlast: PropTypes.bool,
  isWaitingSuperior: PropTypes.bool,
  canApprove: PropTypes.bool,
  canCancel: PropTypes.bool,
  canEdit: PropTypes.bool,
  canSubmit: PropTypes.bool,
  canSentBack: PropTypes.bool,
  canCreateG1: PropTypes.bool,
  canReactivate: PropTypes.bool,
  canReject: PropTypes.bool,
  canManageAccess: PropTypes.bool,
  handleReactivate: PropTypes.func,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleSentBack: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleCreateG1: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleRemind: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  showRemind: PropTypes.bool,
  canRemind: PropTypes.bool,
  setPromptAction: PropTypes.func
};

export default Decision;
