import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  InputBase,
  ButtonBase,
  Fade,
  Paper,
  Popper,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import { fade, styled } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Logo from 'src/components/Logo';
import api from 'src/api';
import { useQuery } from 'react-query';
import NavAuth from './NavAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center'
  },
  notifContainer: {
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    padding: '4.72% 5.91%',
    width: '406px',
  },
  notifItem: {
    backgroundColor: '#EDEEFD',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    margin: '8px 0',
    padding: '3% 4.47%',
  },
  notifBulb: {
    alignSelf: 'flex-start',
    height: '20px',
    flexShrink: 1,
    marginRight: '2%',
  },
  notifId: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '14px',
    fontWeight: 'semibold',
    lineHeight: '20px',
    textAlign: 'left',
  },
  notifStatus: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '20px',
    textAlign: 'left',
  },
  notifTimePassed: {
    color: '#3943EF',
    fontFamily: 'SFProText',
    fontSize: '11px',
    lineHeight: '20px',
    marginTop: '4%',
    textAlign: 'left',
  },
  container: {
    width: '42%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  search: {
    position: 'relative',
    border: '2px solid #D7D0DC',
    borderRadius: 18,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    color: theme.palette.common.black,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderRadius: 18,
    paddingRight: `calc(0.5em + ${theme.spacing(1)}px)`,
  },
  inputInput: {
    paddingLeft: `calc(0.5em + ${theme.spacing(1)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  logo: {
    height: '30px',
    objectFit: 'contain',
    paddingBottom: 8
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: 0,
    marginBottom: 0,
    marginTop: '5px',
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: '#FCD035',
    color: `${theme.palette.background.paper}`,
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  toggleDrawer,
  isOpen,
  setIsOpen,
  seeAll,
  setSeeAll,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const prevNavigation = useStoreState((state) => state.navigation.previous);
  const setNavigation = useStoreActions((action) => action.navigation.setPrevious);
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: notifications, refetch: notificationsReFetch } = useQuery(
    ['notification', { }],
    async ({ }) => {
      const { data: response } = await api.notification.list();

      return response?.notificationIcon;
    },
    { initialData: [] }
  );

  const handleSearchEnter = (ev) => {
    if (ev.key === 'Enter') {
      const searchParams = ev.target.value;

      if (searchParams) {
        if (location.pathname !== '/app/search') {
          setNavigation(location.pathname);
        }

        navigate({
          pathname: '/app/search',
          search: `?${createSearchParams({ search: ev.target.value })}`
        });

        return;
      }

      navigate(prevNavigation);
    }
  };

  const handleNotif = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
    event.stopPropagation();
  };

  useEffect(() => {
    if (!isOpen) {
      notificationsReFetch();
    }
  }, [isOpen]);

  const handleSeeAll = (event) => {
    setSeeAll(!seeAll);
    event.stopPropagation();
  };

  const handleNotifItemClick = (event, item) => {
    if (item?.id && item?.status.toLowerCase().includes('has been approved')) api.notification.read(item?.id);
    event.stopPropagation();
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      position="fixed"
      elevation={1}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Box
          className={classes.container}
          display="flex"
          height="100%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ButtonBase onClick={toggleDrawer}>
            <Logo />
          </ButtonBase>
          <Hidden mdDown>
            <div className={classes.search}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                endAdornment={(
                  <SearchIcon />
                )}
                inputProps={{ 'aria-label': 'search' }}
                onKeyDown={handleSearchEnter}
              />
            </div>
          </Hidden>
        </Box>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <img className={classes.logo} src="/static/images/logo_itm.png" alt="logo ITM" />
          <IconButton color="inherit" onClick={handleNotif}>
            <StyledBadge
              badgeContent={!isOpen ? notifications?.length : 0}
              slots={{ root: 'div', badge: 'div' }}
            >
              <NotificationsIcon color="secondary" />
            </StyledBadge>
          </IconButton>
          <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-end" style={{ zIndex: '9999' }} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={12} className={classes.notifContainer}>
                  <Typography style={{ fontWeight: 'semibold', marginBottom: '5px' }}>New Notification</Typography>
                  <div style={!seeAll ? { height: 'auto' } : { height: '330px', overflowY: 'scroll' }}>
                    {
                      notifications
                        .slice(0, (!seeAll ? 3 : notifications.length))
                        .map((notif, index) => (
                          <Link
                            onClick={(e) => handleNotifItemClick(e, notif)}
                            className={classes.notifItem}
                            href={`${process.env.PUBLIC_URL}/app/registration/${notif?.id}`}
                            target="_blank"
                            underline="none"
                          >
                            <img className={classes.notifBulb} src="/static/icons/ic_notifBulb.svg" alt="notif bulb" />
                            <div style={{ flexDirection: 'column' }}>
                              <Typography className={classes.notifId}>{`${notif?.title}`}</Typography>
                              <Typography className={classes.notifStatus}>{`${notif?.status}`}</Typography>
                              <Typography className={classes.notifTimePassed}>{`${notif?.lastModified}`}</Typography>
                            </div>
                          </Link>
                        ))
                    }
                  </div>
                  <Button
                    style={notifications.length < 3 || seeAll ? { display: 'none' } : {}}
                    variant="contained"
                    color="secondary"
                    onClick={handleSeeAll}
                    className={clsx(classes.button, classes.label)}
                  >
                    SEE ALL
                  </Button>
                </Paper>
              </Fade>
            )}
          </Popper>
          <NavAuth />
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  toggleDrawer: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  seeAll: PropTypes.bool,
  setSeeAll: PropTypes.func
};

export default TopBar;
