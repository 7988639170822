import React from 'react';
import { Grid } from '@material-ui/core';
import Initiative from './Initiative';
import Status from './Status';
import InitiativeDescription from './InitiativeDescription';
import Organization from './Organization';
import Leader from './Leader';
import Facilitator from './Facilitator';
import Promotor from './Promotor';

const GeneralView = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Initiative {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Status {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InitiativeDescription {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Organization {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Leader {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Facilitator {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Promotor {...props} />
      </Grid>
    </Grid>
  );
};

export default GeneralView;
