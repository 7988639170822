import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const SubmittedBy = ({
  handleBlur,
  handleChange,
  values
}) => {
  return (
    <FormItem
      label="Submitted By"
      id="submitted_by"
    >
      <StyledInput
        name="submitted_by"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values?.submittedById?.fullname || ''}
        disabled
      />
    </FormItem>
  );
};

SubmittedBy.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default SubmittedBy;
