import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Box, makeStyles } from '@material-ui/core';

import { initialValues, validation } from './config';

import CostView from './CostView';
import GateBenefitView from './GateBenefitView';
import FundingView from './FundingView';
import OtherView from './OtherView';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  }
}));

const ImpactTracking = ({ detail, registration }) => {
  const classes = useStyles();

  const isGateCompleted = (keyNumber) => {
    const { innovationGateStatuses } = registration;
    const { stages, status } = innovationGateStatuses;
    const gateNumber = Number(stages?.name?.charAt(1) || 0);

    switch (true) {
      case keyNumber < gateNumber:
        return 'green';
      case keyNumber === gateNumber: {
        if (status === 'Completed') {
          return 'green';
        }

        return 'transparent';
      }
      default:
        return 'transparent';
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      initialValues={initialValues({ detail })}
      initialErrors={validation}
      validationSchema={validation}
      onSubmit={() => { /* Nothing here is expected */ }}
    >
      {({
        touched,
        errors,
        handleBlur,
        handleChange,
        values
      }) => (
        <Form>
          <Box className={classes.formContainer}>
            <CostView
              disabled
              {...{
                touched,
                errors,
                values,
                handleBlur,
                handleChange
              }}
            />
            <GateBenefitView
              disabled
              {...{
                touched,
                errors,
                values,
                handleBlur,
                handleChange,
                isGateCompleted
              }}
            />
            <FundingView
              disabled
              {...{
                touched,
                errors,
                values,
                handleBlur,
                handleChange
              }}
            />
            <OtherView
              disabled
              {...{
                touched,
                errors,
                values,
                handleBlur,
                handleChange
              }}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

ImpactTracking.propTypes = {
  detail: PropTypes.object,
  registration: PropTypes.object
};

export default ImpactTracking;
