import { action } from 'easy-peasy';

export default {
  previous: null,
  queryParams: null,
  setPrevious: action((state, payload) => {
    state.previous = payload;
  }),
  setQueryParams: action((state, payload) => {
    state.queryParams = payload;
  })
};
