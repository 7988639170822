import React from 'react';
import PropTypes from 'prop-types';
import {
  Box
} from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import SendBird from 'sendbird';
import store from 'src/store';
import _ from 'lodash';
import api from 'src/api';
import useStyles from './styles';
import ChatList from './ChatList';
import SendChat from './SendChat';
import {
  sbConnect,
  sbCreatePreviousMessageListQuery,
  sbGetGroupChannel,
  sbGetPreviousMessagesByID
} from './SendBirdController';

const ChatView = ({
  data
}) => {
  const classes = useStyles();
  const currentStage = data?.innovationGateStatuses?.stages;
  const category = data?.category;
  const stageIndex = currentStage?.name && Number(currentStage?.name?.charAt(1));
  const channel_url = data?.sendBirdChannelUrl;
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID;
  const user = useStoreState((state) => state.auth.user);
  const { messages } = useStoreState((state) => state.chat);
  const { setMessages } = useStoreActions((state) => state.chat);
  const [currUser, setUser] = React.useState(null);
  const [channel, setChannel] = React.useState(null);
  const [metaData, setMetaData] = React.useState([]);
  const [isGettingOldMessage, setisGettingOldMessage] = React.useState(false);

  const getLastMessageOnScrollTop = async (grup) => {
    if ((grup || channel) && messages?.length === 30 && !isGettingOldMessage) {
      setisGettingOldMessage(true);
      const messagesQuery = await sbGetPreviousMessagesByID(
        grup || channel,
          messages[0]?.messageId,
          [],
          30
      );
      const newMessages = _.unionBy(messagesQuery, messages, 'messageId');
      setMessages(newMessages);
    }
  };

  const addMessageToList = (newMessage) => {
    setisGettingOldMessage(false);
    setMessages([...messages, newMessage]);
  };
  const handleMessageSend = (newMessage) => {
    addMessageToList(newMessage);
  };

  React.useEffect(() => {
    const initSendbird = async () => {
      if (messages?.length > 0) {
        setMessages([]);
      }
      const connect = await sbConnect(
        user.username,
        user.name,
        appId,
      );
      const grup = await sbGetGroupChannel(channel_url);
      const meta = JSON.parse(grup?.data);
      if (meta?.users) {
        setMetaData(meta?.users);
      }
      const sb = SendBird.getInstance();
      const channelHandle = new sb.ChannelHandler();
      const connectionHandle = new sb.ConnectionHandler();
      // Listener Channel
      channelHandle.onMessageReceived = (channelGrup, message) => {
        channelGrup.markAsRead();
        const { messages: list } = store.getState().chat;
        const { setMessages: set } = store.getActions().chat;
        set([...list, message]);
      };
      sb.addChannelHandler('groupChat', channelHandle);
      const getLastMessage = async (grupChannel) => {
        setisGettingOldMessage(false);
        if (grupChannel || channel) {
          const messagesQuery = await sbCreatePreviousMessageListQuery(
            grupChannel || channel,
            30,
            false
          );
          setMessages(messagesQuery);
        }
      };
      // Listener Connection
      connectionHandle.onReconnectSucceeded = async () => {
        channel && channel.refresh();
        const reconnectGrup = await sbGetGroupChannel(channel_url);
        setChannel(reconnectGrup);
        getLastMessage(reconnectGrup);
      };
      connectionHandle.onReconnectFailed = () => {
        sb.reconnect();
      };

      sb.addConnectionHandler('groupChat', connectionHandle);
      setUser(connect);
      setChannel(grup);
      getLastMessage(grup);
    };
    const updateChannel = async () => {
      await api.registration.updateChannel(data?.id);
    };
    if (!currUser && !!appId && !!channel_url) {
      updateChannel();
      setTimeout(() => initSendbird(messages), 3000);
      // delay to make sure sendbird channel updated first
    }
    return () => {};
  }, [currUser, user, channel, setMessages, messages, appId]);

  const currentInnovMessages = messages.filter(
    (message) => message.channelUrl === data.sendBirdChannelUrl
  );
  return (
    <Box className={classes.formContainer}>
      <ChatList
        category={category}
        user={user}
        messages={currentInnovMessages}
        metaData={metaData}
        isGettingOldMessage={isGettingOldMessage}
        getLastMessageOnScrollTop={getLastMessageOnScrollTop}
      />
      <SendChat
        currentStage={stageIndex}
        onMessageSend={(msg) => handleMessageSend(msg)}
        channel={channel}
        disabled={!appId || !currUser || !channel}
      />
    </Box>
  );
};

ChatView.propTypes = {
  data: PropTypes.object
};

export default ChatView;
