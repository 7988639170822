import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import OurUseCaseHelp from './OurUseCaseHelp';
import ToAchieve from './ToAchieve';
import ThroughTheBenefitOf from './ThroughTheBenefitOf';
import AsComparedTo from './AsComparedTo';
import ValueDescription from './ValueDescription';
import WithinScope from './WithinScope';
import OutsideOfScope from './OutsideOfScope';
import OriginalDescription from './OriginalDescription';
import ProposeSchedulePlan from './ProposeSchedulePlan';
import DigitalCoach from './DigitalCoach';
import Attachment from './Attachment';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const TopView = ({
  organizationId,
  isDigital,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <OurUseCaseHelp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ToAchieve {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ThroughTheBenefitOf {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <AsComparedTo {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ValueDescription {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <WithinScope {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <OutsideOfScope {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <OriginalDescription {...props} />
      </Grid>
      <Grid item lg={!isDigital ? 6 : 4} md={12} sm={12} xs={12}>
        <ProposeSchedulePlan {...props} />
      </Grid>
      {isDigital && (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Roles</Typography>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <DigitalCoach
              organizationId={organizationId}
              {...props}
            />
          </Grid>
        </>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment {...props} />
      </Grid>
    </Grid>
  );
};

TopView.propTypes = {
  organizationId: PropTypes.string,
  isDigital: PropTypes.bool
};

export default TopView;
