import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { ORGANIZATION_LIST } from 'src/api/backendUrl';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { isValidationRequired } from 'src/utils/validation';

const Site = ({
  requestedFor,
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  validation
}) => {
  const key = 'organizationId';
  const { data, refetch } = useQuery(ORGANIZATION_LIST, async () => {
    const response = await api.masterData.organizationlist({
      empId: requestedFor?.empId || values?.submittedById?.empId,
      Sorts: 'name'
    });

    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });

  const sites = data;

  if (sites.length === 1) {
    values[key] = sites[0].id;
  }

  useEffect(() => {
    refetch();
  }, [requestedFor, values?.submittedById]);

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Site"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        placeholder="Enter site"
        options={sites}
      />
    </FormItem>
  );
};

Site.propTypes = {
  disabled: PropTypes.bool,
  requestedFor: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Site;
