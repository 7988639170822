import React from 'react';
import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import TotalCapex from './TotalCapex';
import TotalOpex from './TotalOpex';
import IdeaSizingCapex from './IdeaSizingCapex';
import IdeaSizingOpex from './IdeaSizingOpex';
import PocCapex from './pocCapex';
import PocOpex from './pocOpex';
import MvpCapex from './mvpCapex';
import MvpOpex from './mvpOpex';
import MmpCapex from './mmpCapex';
import MmpOpex from './mmpOpex';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const FundingView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: 5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Funding</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TotalCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TotalOpex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <IdeaSizingCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <IdeaSizingOpex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <PocCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <PocOpex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MvpCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MvpOpex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MmpCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MmpOpex {...props} />
      </Grid>
    </Grid>
  );
};

export default FundingView;
