import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import { InsertDriveFile, Edit, Delete } from '@material-ui/icons';

import api from 'src/api';
import { WEEKLY_UPDATE, WEEKLY_UPDATE_MULTIPLE } from 'src/api/backendUrl';
import DataTable from 'src/components/DataTable';
import IconButtonConfirmation from 'src/components/IconButtonConfirmation';
import fileDownload from 'js-file-download';
import ModalForm from './ModalForm';

const entries = [20, 50, 100, 500, 1000];

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100%'
    }
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  required: {
    color: theme.palette.error.main
  }
}));

const WeeklyUpdateView = (props) => {
  const classes = useStyles();

  const { id } = useParams();
  const fileRef = useRef(null);
  const [fileUrl, setFileUrl] = useState('');
  const [filename, setFilename] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSucceed, setSucceed] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [detail, setDetail] = useState({ ...props });
  const [checked, setChecked] = useState([]);
  const [isEditable, setIsEditable] = useState(false);

  const changeAllChecked = () => {
    if (checked.length === data.length) {
      setChecked([]);
    } else {
      setChecked(data.map((item) => item.id));
    }
  };

  const setEditable = () => { setIsEditable(!isEditable); };

  const user = useStoreState((state) => state.auth.user);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const {
    tabName,
    currentGate,
    submittedById,
    requestedFor,
    gate
  } = detail;

  const isUserValid = submittedById === user.id
    || detail?.requested?.id === user.id;
  const isVisible = (currentGate - Number(tabName.charAt(1))) <= 1 && isUserValid;

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (data?.data?.status === 20001) {
          setNotif({
            message: data?.data?.message || 'There is different assignment composition of each Committee. Please check and confirm among Committee',
            option: {
              variant: 'warning'
            }
          });
        } else {
          if (onSuccess) {
            data.status < 300 && onSuccess(data?.data);
          } else if (data.status < 300 && id) {
            if (location.key === 'default') {
              navigate('/app/innovation-view');
            } else {
              navigate(-1);
            }
          } else {
            navigate('/app/innovation-view');
          }
          setNotif({
            message: `${actionMessage} success`,
            option: {
              variant: 'success'
            }
          });
        }
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const deleteData = useMutation(
    () => {
      if (checked.length > 0) return api.weeklyUpdate.deleteMulti(checked);
    },
    mutateOption('Delete Multiple', (data) => {
      setSucceed(true);
    })
  );

  const exportData = async () => {
    if (checked && checked.length > 0) {
      const response = await api.weeklyUpdate.export(id, checked, 'arraybuffer');
      const fileName = response.headers['content-disposition'].match(/(?:filename=")(.+)(?=";)/gm);

      fileDownload(response.data, fileName);
    }
  };

  const { data, refetch } = useQuery(WEEKLY_UPDATE, async () => {
    const response = await api.weeklyUpdate.list(id, (
      search
        ? {
          Filters: `search==${search}`,
          Sorts: 'number',
          PageSize: pageSize,
          Page: currentPage
        }
        : {
          Sorts: 'number',
          PageSize: pageSize,
          Page: currentPage
        }
    ));

    setTotalData(response?.data.totalData);
    setTotalPage(response?.data.totalPage);
    setSucceed(false);
    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });
  useEffect(() => { refetch(); }, [isSucceed, pageSize, currentPage, search]);

  const tableData = data;
  const tableHeader = [
    {
      key: 'action',
      label: (
        <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <Checkbox
              checked={Boolean(checked.length === data.length)}
              onChange={() => changeAllChecked()}
            />
          </Grid>
        </Grid>
      )
    },
    {
      key: 'number',
      label: 'Number'
    },
    {
      key: 'stageGate',
      label: 'Stage Gate'
    },
    {
      key: 'productOwner',
      label: 'Product Owner'
    }
  ];

  const showCreate = () => {
    setIsEditable(true);
    setDetail({ ...props, action: 'submit' });
    setIsOpen(true);
  };

  const handleOnClick = (item) => {
    setIsEditable(false);
    setDetail({ ...detail, ...item, action: 'update' });
    setIsOpen(true);
  };

  const handleCheck = (id) => {
    if (checked.find((n) => n === id)) {
      const temp1 = checked.slice();
      const temp2 = checked.findIndex((x) => x === id);
      temp1.splice(temp2, 1);
      setChecked(temp1);
    } else {
      setChecked([...checked, id]);
    }
  };

  return (
    <Box className={classes.formContainer}>
      <a hidden href={fileUrl} download={filename} ref={fileRef} />
      <Typography className={classes.title} style={{ marginBottom: 15 }}>
        Weekly Update
        {' '}
        {detail.required && (
          <span className={classes.required}>*</span>
        )}
      </Typography>
      <DataTable
        leftComp={(
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                variant="contained"
                color="secondary"
                disabled={currentGate < Number(tabName.charAt(1))}
                onClick={() => showCreate()}
              >
                Create
              </Button>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                disabled={checked.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => exportData()}
              >
                Export
              </Button>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                disabled={checked.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => { deleteData.mutate(); }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        )}
        leftCompNotVisible={(
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => exportData()}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        )}
        tableHeader={tableHeader}
        tableData={tableData}
        actionSlot={(item) => (
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <Checkbox
                checked={Boolean(checked.find((x) => x === item.id))}
                onChange={() => handleCheck(item.id)}
              />
            </Grid>
          </Grid>
        )}
        {...{
          isVisible,
          entries,
          showEntries,
          setShowEntries,
          pageSize,
          setPageSize,
          setSearch,
          totalData,
          currentPage,
          setCurrentPage,
          totalPage,
          handleOnClick
        }}
      />
      <ModalForm
        {...{
          isVisible,
          isEditable,
          setEditable,
          detail,
          isOpen,
          setIsOpen,
          setSucceed
        }}
      />
    </Box>
  );
};

export default WeeklyUpdateView;
