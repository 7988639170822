import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';
import FormItem from 'src/components/FormItem';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    marginTop: theme.spacing(2) - 4
  },
  draft: {
    backgroundColor: '#D0C9D6',
  },
  approval: {
    backgroundColor: '#44D7B6',
    color: '#FFF'
  },
  admin: {
    backgroundColor: '#6236FF',
    color: '#FFF'
  },
  completed: {
    backgroundColor: '#06a137',
    color: '#FFF'
  },
  sentback: {
    backgroundColor: '#f59842',
  },
  cancel: {
    backgroundColor: '#f54242',
  },
}));

const StatusView = ({
  status,
  showLabel = true
}) => {
  const classes = useStyles();

  return (
    <FormItem
      id="status-top"
      label="Status"
      showLabel={showLabel}
    >
      <Chip
        size="small"
        label={status}
        className={clsx(classes.chip, {
          [classes.draft]: status === 'Draft',
          [classes.approval]: status?.includes('Waiting'),
          [classes.admin]: status === status?.includes('Admin'),
          [classes.completed]: status === 'Completed',
          [classes.sentback]: status === 'Send Back',
          [classes.cancel]: status === 'Cancelled',
        })}
      />
    </FormItem>
  );
};

StatusView.propTypes = {
  status: PropTypes.string
};

export default StatusView;
