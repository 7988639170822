import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import UserSelector from 'src/components/UserSelector';

const RequestedFor = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
}) => {
  const key = 'requestedFor';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };

  return (
    <FormItem
      label="Requested For"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <UserSelector
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter name/email/department"
      />
    </FormItem>
  );
};

RequestedFor.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default RequestedFor;
