import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Year from './Year';
import Status from './Status';
import Stage from './Stage';
import Site from './Site';
import Category from './Category';
import InnovationType from './InnovationType';

const Filter = (props) => {
  const {
    yearOptions,
    statusOptions,
    stageOptions,
    siteOptions,
    categoryOptions,
    innovationTypeOptions
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Year
          {...props}
          yearOptions={yearOptions}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Category
          {...props}
          categoryOptions={categoryOptions}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <InnovationType
          {...props}
          innovationTypeOptions={innovationTypeOptions}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Status
          {...props}
          statusOptions={statusOptions}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Stage
          {...props}
          stageOptions={stageOptions}
        />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Site
          {...props}
          siteOptions={siteOptions}
        />
      </Grid>
    </Grid>
  );
};

Filter.propTypes = {
  yearOptions: PropTypes.array,
  statusOptions: PropTypes.array,
  stageOptions: PropTypes.array,
  siteOptions: PropTypes.array,
  categoryOptions: PropTypes.array,
  innovationTypeOptions: PropTypes.array
};

export default Filter;
