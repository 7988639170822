import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import Selector from 'src/components/Selector';
import { NAME_LIST } from 'src/api/backendUrl';
import { isValidationRequired } from 'src/utils/validation';

const FacilitatorOrScrumMaster = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'facilitatorUser';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };
  const error = errors[key];
  const message = error?.empId;
  const requestor = values?.requestedFor;
  const creator = values?.submittedById;
  const excludeRequestor = requestor?.empId ? `empId!=${requestor?.empId || ''}` : `userAd!=${requestor?.userAd || ''}`;
  const excludeCreator = creator?.empId ? `empId!=${creator?.empId || ''}` : `userAd!=${creator?.userAd || ''}`;

  useEffect(() => {
    if (!isEmpty(values[key])) {
      const data = values[key];

      if ((isEmpty(requestor) && data.empId === creator?.empId)
        || (!isEmpty(requestor) && data.empId === requestor?.empId)) {
        handleChange({});
      }
    }
  }, [values[key], creator, requestor]);

  return (
    <FormItem
      required={isValidationRequired(key, validation, values, 'Individual', false)}
      label="Scrum Master/Facilitator"
      id={key}
      error={Boolean(touched[key] && message)}
      helperText={touched[key] && message}
    >
      <Selector
        error={Boolean(touched[key] && message)}
        disabled={disabled}
        name={key}
        type="text"
        placeholder="Enter scrum master/facilitator"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        apiMasterData="nameList"
        keyApi={NAME_LIST}
        excludeQuery={values?.requestedFor ? `,${excludeRequestor}` : `,${excludeCreator}`}
      />
    </FormItem>
  );
};

FacilitatorOrScrumMaster.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object
};

export default FacilitatorOrScrumMaster;
