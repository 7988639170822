import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

import { isValidationRequired } from 'src/utils/validation';

const PlannedFeaturesMmp = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  validation
}) => {
  const key = 'plannedFeaturesMmp';

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Planned Features MMP"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        multiline
        disabled
        name={key}
        rows={4}
        placeholder="Enter planned features MMP"
        value={values[key]}
        error={Boolean(touched[key] && errors[key])}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </FormItem>
  );
};

PlannedFeaturesMmp.propTypes = {
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired
};

export default PlannedFeaturesMmp;
