import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  ListItemText,
  makeStyles, Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

import api from 'src/api';
import StyledInput from './StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1) + 2,
      paddingBottom: theme.spacing(1) + 2,
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: theme.spacing(1),
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '80vw'
    },
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    boxShadow: 'none',
    margin: 2,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  listbox: {
    maxHeight: 150,
    padding: 3,
  },
  inputBase: {
    padding: theme.spacing(2),
    borderBottom: '1px dashed #ECEBED'
  },
  listItemPrimary: {
    color: 'black',
    fontSize: 15
  },
  listItemSecondary: {
    color: 'gray',
    fontSize: 12
  }
}));

const SelectMultiFreeText = ({
  placeholder = '',
  alwaysShowOpsi = false,
  multiple = true,
  isSearchNetwork = false,
  error,
  values,
  handleDelete,
  teamMembers,
  ...rest
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);

  const query = {
    Filters: `(fullName|emailOffice)@=*${search}`,
    Sorts: 'fullName',
    PageSize: 20 // Workaround to speed up search api
  };

  const { refetch } = useQuery(['user-search', query], async () => {
    if (isSearchNetwork) {
      const { data: response } = await api.masterData.nameList(query);
      const optionData = response.data
        .map((item) => ({
          id: item.empId,
          name: item.fullName,
          ...item
        }));
      optionData.push({ id: null, name: search });
      setOptions(optionData);
    }
  }, { initialData: [] });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (search.length === 0) {
        if (alwaysShowOpsi) {
          setOptions(teamMembers);
        } else {
          setOptions([]);
        }
        return;
      }

      if (search.length >= 3) {
        if (isSearchNetwork) {
          refetch(search);
          return;
        }

        const optionData = teamMembers
          .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
        optionData.push({ id: null, name: search });
        setOptions(optionData);
      }
      
      if (alwaysShowOpsi && search.length >= 0) {
        if (isSearchNetwork) {
          refetch(search);
          return;
        }

        const optionData = teamMembers
          .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
        optionData.push({ id: null, name: search });
        setOptions(optionData);
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [search]);

  return (
    <>
      <Autocomplete
        multiple={multiple}
        options={options}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        {...rest}
        renderTags={(value, getTagProps) => {
          return value?.map(
            (option, index) => (
              <Chip
                key={option.name}
                label={option.name}
                size="small"
                className={classes.chip}
                deleteIcon={<Close color="primary" />}
                onDelete={() => handleDelete(option.name)}
                {...getTagProps({ index })}
              />
            )
          );
        }}
        renderOption={(option) => {
          return isSearchNetwork ? (
            <ListItemText>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.listItemPrimary}>
                  {option.name}
                </Typography>
                {option?.emailOffice && (
                  <Typography className={classes.listItemSecondary}>
                    {option.emailOffice || option.email}
                  </Typography>
                )}
                {option?.workLocation && (
                  <Typography className={classes.listItemSecondary}>
                    {option.workLocation}
                  </Typography>
                )}
              </Box>
            </ListItemText>
          )
            : (
              <ListItemText
                primary={option.name}
                secondary={option.emailOffice}
              />
            );
        }}
        renderInput={(params) => (
          <StyledInput
            placeholder={placeholder}
            error={error}
            {...params}
          />
        )}
      />
    </>
  );
};

SelectMultiFreeText.propTypes = {
  placeholder: PropTypes.string,
  isSearchNetwork: PropTypes.bool,
  error: PropTypes.bool,
  values: PropTypes.object,
  handleDelete: PropTypes.func,
  teamMembers: PropTypes.array
};

export default SelectMultiFreeText;
