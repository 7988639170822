import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { difference, isEmpty } from 'lodash';
import deepEqual from 'deep-equal';
import {
  Box,
  Grid,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useStore, useStoreActions, useStoreState } from 'easy-peasy';
import { useParams, useNavigate, Prompt } from 'react-router-dom';
import { useMutation } from 'react-query';

import api from 'src/api';

import TabMenu from 'src/components/TabMenu';
import SwipeableViews from 'react-swipeable-views';
import Decision from './Decision';
import FirstView from './FirstView';
import FourthView from './FourthView';
import { initialValues, validation } from './config';
import TabPanel from '../Tabpanel';
import { getBottomMenus } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  },
  additionalForm: {
    borderRadius: '24px'
  },
}));

const PrototypeView = ({
  detail,
  registration,
  approval,
  handleChangeTab,
  handleAccessPermissionClick
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const store = useStore();

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const user = useStoreState((state) => state.auth.user);
  const setFormikValues = useStoreActions((action) => action.formikValues.setFormikValues);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [isEdit, setIsEdit] = useState(false);
  const [dataDetail, setDataDetail] = useState(detail);

  const [bottomTab, setBottomTab] = useState(0);
  const [tabChanged, setTabChanged] = useState(false);
  const [canRemind, setCanRemind] = useState(
    registration?.innovationGateStatuses?.notificationSent === false
  );
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });

  const handleChangeBottomTab = (event, newValue) => {
    setBottomTab(newValue);
    setTabChanged(true);
  };

  const isUserCanApprove = () => {
    if (detail.gateStatus === undefined || detail.gateStatus === 'Draft') return false;

    let isUserCanApproveAsPromotorFacilitator = false;
    let approvalData = [];

    const promotorFacilitatorApproval = approval?.data.filter(
      (item) => item.roles.name.includes('Promotor')
        || item.roles.name.includes('Facilitator')
    );

    const roleApproval = difference(approval?.data, promotorFacilitatorApproval, 'roles.name');

    if (promotorFacilitatorApproval.length) {
      isUserCanApproveAsPromotorFacilitator = promotorFacilitatorApproval
        .some((item) => !item.isApproved
          && item.roles.approver.some((userApprover) => userApprover.id === user.id));
    }

    const isUserCanApproveByRole = roleApproval
      .some((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    approvalData = isUserCanApproveAsPromotorFacilitator
      ? promotorFacilitatorApproval.filter((item) => !item.isApproved)
      : roleApproval.filter((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    return {
      approvalValidation: isUserCanApproveAsPromotorFacilitator || isUserCanApproveByRole,
      approvalData
    };
  };

  useEffect(() => { setDataDetail(detail); }, [detail]);
  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
          return;
        }

        setPromptAction({ needed: false, action: actionMessage });
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const submit = useMutation((form) => api.prototype.create(id, form), mutateOption('Submit'));
  const saveDraft = useMutation((form) => api.prototype.draft(id, form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.prototype.edit(id, form), mutateOption('Edit'));
  const approve = useMutation((form) => api.prototype.approve(id, form), mutateOption('Approve'));
  const cancel = useMutation(() => api.prototype.cancel(id), mutateOption('Cancel'));
  const reactivate = useMutation(() => api.prototype.reactivate(id), mutateOption('Reactivate'));
  const createBenefit = useMutation(() => api.benefitCategory.updateStatus(id),
    mutateOption('Benefit Category', () => {
      handleChangeTab(6);
    }));
  const createG4 = useMutation(() => api.prototype.createG4(id), mutateOption('Create G4', () => handleChangeTab(4)));

  const transformArrayMember = (value) => {
    return value.map((item) => ({ id: item.id, name: item.name }));
  };

  const generateForm = async (form) => {
    const {
      teamMembers,
      dataArchitect,
      dataEngineer,
      dataScientist,
      dataVisualization,
      developerTeam,
      technologyTeam,
      uiUxLead,
      backlogs,
      tools,
      ...rest
    } = form;

    return {
      ...registration?.classifications?.name === 'Digital' ? {
        dataArchitect: transformArrayMember(dataArchitect),
        dataEngineer: transformArrayMember(dataEngineer),
        dataScientist: transformArrayMember(dataScientist),
        dataVisualization: transformArrayMember(dataVisualization),
        developerTeam: transformArrayMember(developerTeam),
        technologyTeam: transformArrayMember(technologyTeam),
        uiUxLead: transformArrayMember(uiUxLead),
        backlogs,
        tools
      } : {
        backlogs
      },
      ...rest
    };
  };
  const handleSubmitForm = async (form) => {
    const submitForm = await generateForm(form);
    submit.mutate(submitForm);
  };
  const handleSaveDraft = async (form) => {
    const draftForm = await generateForm(form);
    saveDraft.mutate(draftForm);
  };
  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };
  const handleCreateG4 = async () => {
    createG4.mutate();
  };
  const handleApproval = (comment) => {
    const isInfinity = registration?.category === 'Project Team';
    const isInnovationTypeStrategic = registration?.innovationTypes?.name === 'Strategic Driven';
    const isInfinityStrategic = isInfinity && isInnovationTypeStrategic;
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const form = detail?.formStatus === 'Conventional' && !isInfinityStrategic
      ? {
        Comment: comment
      }
      : {
        registrationId: id,
        roleId: userRole.id,
        comment
      };

    approve.mutate(form);
  };
  const handleCancel = (comment) => {
    const form = {
      Comment: comment,
      registrationComment: comment
    };
    cancel.mutate(form);
  };
  const handleReactivate = (comment) => {
    const form = {
      Comment: comment,
      registrationComment: comment
    };
    reactivate.mutate(form);
  };
  const handleOpenEdit = () => setIsEdit(true);
  const handleCloseEdit = () => setIsEdit(false);
  const handleSubmit = (value, actions) => {
    actions.setSubmitting(true);
    const {
      action, ...form
    } = value;
    switch (action) {
      case 'submit':
        handleSubmitForm(form);
        break;
      case 'edit':
        handleEditAdminForm(form);
        break;
      default:
        break;
    }
    actions.setSubmitting(false);
  };
  const handleCreateBenefit = () => createBenefit.mutate();

  const isCanValidateOnMount = !isEmpty(detail) && detail.gateStatus === 'Draft';
  const isValidForBenefitCost = registration?.benefitCostActivate;
  const isDigital = registration?.formCode?.toLowerCase() === 'digital';
  const yupValidation = validation(isDigital).fields;

  // current state isValidForBenefitCost is always true for IPoD category
  const bottomMenus = getBottomMenus(
    {
      ...{
        detail,
        registration,
        capexKey: 'mvpFundingCapex',
        opexKey: 'mvpFundingOpex',
        isValidForBenefitCost
      }
    }
  );

  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={tabChanged
        ? store.getState()?.formikValues?.formikValues
        : initialValues({ detail: dataDetail, registration })}
      initialErrors={validation(isDigital)}
      isInitialValid={() => validation(isDigital).isValidSync(
        tabChanged
          ? store.getState()?.formikValues?.formikValues
          : initialValues({ detail: dataDetail, registration })
      )}
      validationSchema={validation(isDigital)}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const isG3 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G3'));
        const isG4 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G4'));
        const isG4Draft = isG4 && (registration?.innovationGateStatuses?.status?.includes('Draft'));
        const isCreator = registration?.user?.id === user?.id;
        const isPO = registration?.userPermissions?.some((item) => item.id === user?.id);
        const organizationId = registration?.organizationId;
        const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
        const isAdminSite = user?.roles.find((d) => d.name === 'Admin Site');
        const isSameSite = user?.cluster?.some((item) => item.id === registration?.organizationId);
        const isAdminOfSite = isAdminSite && isSameSite;
        const isAdminApp = isAdmin && !user.designation;
        const isPIC = user?.roles?.filter((d) => ['DCOE', 'IT', 'Infinity'].indexOf(d.name) > -1);
        const isAdminPIC = !!isPIC.length
          && isPIC.findIndex((d) => d?.name === registration?.roles?.name) > -1;
        const isDraft = ['Draft'].indexOf(detail?.gateStatus) > -1;
        const isWaitingApproval = detail?.gateStatus?.includes('Waiting Approval');
        const isCompleted = ['Completed'].indexOf(detail?.gateStatus) > -1;
        const isCancel = ['Cancelled'].indexOf(detail?.gateStatus) > -1;
        const isOnHold = ['On Hold']
          .indexOf(registration?.innovationGateStatuses?.states?.name) > -1;
        const benefitStatus = registration?.benefitStatus;
        const canEditForm = isG3 && !isCancel && ((isDraft && isPO) || (isEdit && isAdminApp)
        || (isAdminPIC && isEdit && !isCompleted));
        const canSubmit = isG3 && !isCancel && !isOnHold && isPO && isDraft;
        const canSaveDraft = isG3 && !isCancel && isPO && isDraft;
        const canEdit = (isG3
          && !isCancel
          && !isOnHold
          && !isEdit
          && (isAdminApp || (isAdminPIC && !isCompleted) || isAdminOfSite)
          && !isDraft);
        const canReactivate = isG3 && isAdmin && isCancel;
        const canCancel = isG3 && !isCancel && isPO && isWaitingApproval;
        const canCreateG4 = isG3 && !isG4 && isCompleted && (isPO || isCreator) && !!benefitStatus;
        const canApprove = approval?.isCanApprove;
        const canCreateBenefit = isG3 && isCreator && isCompleted && !benefitStatus;
        const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
        const showRemind = (isAdminOfSite || isAdmin)
          && ((isG3 && (isWaitingApproval || isCompleted)) || isG4Draft);

        return (
          <Form onSubmit={formik.handleSubmit}>
            <Box className={classes.formContainer}>
              <FourthView
                disabled={!canEditForm || isValidForBenefitCost}
                organizationId={organizationId}
                prototypeId={detail.id}
                validation={yupValidation}
                {...formik}
              />
              <FirstView
                disabled={!canEditForm}
                organizationId={organizationId}
                isDigital={isDigital}
                prototypeId={detail.id}
                validation={yupValidation}
                {...formik}
              />
            </Box>
            <Grid item style={{ marginTop: '2rem' }}>
              <TabMenu
                tab={bottomTab}
                setTab={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
                menus={bottomMenus}
              />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={bottomTab}
                onChangeIndex={(v, e) => {
                  setFormikValues(formik.values);
                  handleChangeBottomTab(v, e);
                }}
              >
                {bottomMenus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={bottomTab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component({
                      setFieldValue: formik.setFieldValue,
                      setFieldTouched: formik.setFieldTouched,
                      gate: 'g3'
                    })}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
            <Box mt={5} mb={5}>
              <Decision
                {...{
                  id,
                  user,
                  detail,
                  isEdit,
                  handleOpenEdit,
                  handleCloseEdit,
                  handleSaveDraft,
                  handleApproval,
                  handleCancel,
                  handleReactivate,
                  handleCreateG4,
                  handleCreateBenefit,
                  handleRemind,
                  handleAccessPermissionClick,
                  canCreateG4,
                  canReactivate,
                  canEdit,
                  canCancel,
                  canSubmit,
                  canSaveDraft,
                  canApprove,
                  canCreateBenefit,
                  canManageAccess,
                  showRemind,
                  canRemind,
                  setPromptAction
                }}
                {...formik}
              />
            </Box>
            <Prompt
              when={promptAction.needed
                && (canSubmit || isEdit)
                && !deepEqual(formik.initialValues, formik.values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

PrototypeView.propTypes = {
  detail: PropTypes.object,
  registration: PropTypes.object,
  requirement: PropTypes.object,
  study: PropTypes.object,
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  })
};

export default PrototypeView;
