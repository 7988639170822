import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  isVisible,
  isLoading,
  isEditable,
  setEditable,
  canSubmit,
  setIsOpen,
  setFieldValue,
  handleDelete,
  submitForm,
  values
}) => {
  const classes = useStyles();
  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };
  const isSubmit = values.action === 'submit';
  const isEdit = values.action === 'update';

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {
        isVisible && !isEditable && isEdit && (
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={() => setEditable()}
            className={clsx(classes.button, classes.label)}
          >
            Edit
          </Button>
        )
      }
      {
        isVisible && isEditable && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog={`Are you sure you want to ${isEdit ? 'update' : 'submit'} ?`}
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={!canSubmit || isLoading}
            onConfirm={() => handleSubmit(isEdit ? 'update' : 'submit')}
            className={clsx(classes.button, classes.label)}
          >
            {isEdit ? 'Update' : 'Submit'}
          </ButtonConfirmation>
        )
      }
      {
        isEditable && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isLoading}
            onClick={() => setIsOpen(false)}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel
          </Button>
        )
      }
      {
        isVisible && !isEditable && !isSubmit && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog={`Are you sure you want to delete ?`}
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onConfirm={() => handleDelete.mutate(values)}
            className={clsx(classes.button, classes.label)}
          >
            Delete
          </ButtonConfirmation>
        )
      }
      {
        isLoading && (
          <CircularProgress color="secondary" style={{ marginLeft: 'auto' }} />
        )
      }
    </Box>
  );
};

Decision.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  setEditable: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleDelete: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
};

export default Decision;
