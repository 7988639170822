import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import {
  useStoreActions,
  useStoreState,
} from 'easy-peasy';
import { useMutation, useQuery } from 'react-query';
import {
  Box,
  Checkbox,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DescriptionTwoTone, EditTwoTone } from '@material-ui/icons';
import clsx from 'clsx';

import api from 'src/api';
import {
  ORGANIZATION_LIST,
  YEAR_BUILT,
} from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import DataTable from 'src/components/DataTable';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import ButtonConfirmation from 'src/components/ButtonConfirmation';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  },
  documentTitle: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  boxFilter: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
}));

const entries = [10, 20, 50, 100, 200];

const FormAssessment = () => {
  const classes = useStyles();
  const { level } = useParams();
  const { user } = useStoreState((state) => state.auth);
  // eslint-disable-next-line max-len
  const innovationConventionCurrentPage = useStoreState((state) => state.innovationConvention.currentPage);
  const setNavigation = useStoreActions((action) => action.navigation.setPrevious);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const isLevelSite = level.toLowerCase() === 'site';
  const isAdmin = user?.roles?.some((role) => role.name === 'Administrator' || role.name === 'Admin Site');
  const isJudgeITM = user?.isJudgeITM;
  const organizationName = user?.organizationName;
  const currentYear = new Date().getFullYear();
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(innovationConventionCurrentPage);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [isUnregistered, setIsUnregistered] = useState(false);
  const [filter, setFilter] = useState({ year: '', site: '', multiSite: [] });
  const judgesTableHeaders = [
    { key: 'action', label: 'ASSES' },
    { key: 'year', label: 'YEAR' },
    { key: 'initiativeName', label: 'INITIATIVE NAME' },
    { key: 'site', label: 'SITE' },
    { key: 'requestNo', label: 'REQUEST NO' },
    { key: 'submitter', label: 'SUBMITTER' },
    { key: 'status', label: 'STATUS' }
  ];
  const adminTableHeaders = [
    { key: 'action', label: 'ASSES' },
    { key: 'year', label: 'YEAR' },
    { key: 'initiativeName', label: 'INITIATIVE NAME' },
    { key: 'judgeName', label: 'JUDGE NAME' },
    { key: 'site', label: 'SITE' },
    { key: 'submitter', label: 'SUBMITTER' },
    { key: 'requestNo', label: 'REQUEST NO' },
    { key: 'siteScore', label: 'SITE SCORE' },
    ...!isLevelSite
      ? [{ key: 'judgeScore', label: 'ITM SCORE' }]
      : [],
    { key: 'status', label: 'STATUS' },
  ];

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response.data.data;
    }, {
      initialData: [],
    }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_LIST, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.organizationlist(query);

      return response.data.data;
    }, {
      initialData: [],
    }
  );

  const { data: assessments, refetch: refetchAssessment } = useQuery(['assessment-list', {
    page: currentPage,
    pageSize,
    filter,
    search,
    isUnregistered
  }], async () => {
    const query = {
      page: currentPage,
      pageSize,
      filters: isAdmin
        ? `${filter.site && `site==${filter.site},`}${filter.year && `Year==${filter.year},`}${search && `search==${search},`}ShowUnreg==${isUnregistered}`
        : `${search && `search==${search}`}`
    };

    const { data: response } = await api.assessment.getList(query);
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response.totalData === 0
        ? response.totalData
        : showing.show,
      to: response.totalData <= pageSize
        ? response.totalData
        : showing.to
    });

    return response?.data.length
      ? response?.data.filter(
        (item) => (isAdmin
          ? item.viewType === 'Admin'
          : item.viewType === 'Judge')
      )
      : [];
  }, {
    enabled: isLevelSite && !!filter.site && !!filter.year,
    initialData: []
  });

  const { data: assessmentsITM, refetch: refetchITMAssessment } = useQuery(['assessment-list-itm', {
    page: currentPage,
    pageSize,
    filter,
    search,
  }], async () => {
    const query = {
      page: currentPage,
      pageSize,
      filters: isAdmin
        ? `${filter.multiSite.length ? `site==${filter.multiSite.join('|')},` : ''}${filter.year && `Year==${filter.year},`}${search && `search==${search}`}`
        : `${search && `search==${search}`}`
    };

    // TODO :: integrate with API
    const { data: response } = await api.assessment.getITMList(query);
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response.totalData === 0
        ? response.totalData
        : showing.show,
      to: response.totalData <= pageSize
        ? response.totalData
        : showing.to
    });

    return response?.data.length
      ? response?.data.filter(
        (item) => (isAdmin
          ? item.viewType === 'Admin'
          : item.viewType === 'Judge')
      )
      : [];
  }, {
    enabled: !isLevelSite && !!filter.year,
    initialData: []
  });

  const generateStatusColor = (status) => {
    switch (true) {
      case status && status.toLowerCase().includes('not'):
        return '#D0C9D6';
      default:
        return '#06a137';
    }
  };

  const setDefaultFilter = () => {
    if (years.length > 0 && years.some((val) => Number(val.id) === currentYear)) {
      setFilter((prevState) => ({ ...prevState, year: currentYear }));
    }

    if (sites.length > 0 && isLevelSite) {
      const site = sites.find(
        (val) => val.name.toLowerCase() === organizationName.toLowerCase()
      );
      const siteId = site ? site.id : null;

      if (siteId) {
        setFilter((prevState) => ({ ...prevState, site: siteId }));
      }
    }
  };

  const onFilterChange = (key, value) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const site = sites.find((val) => val.id === filter.site);
  const siteName = site?.name || '';

  const onDeleteSiteItem = (val) => {
    const filteredItems = filter.multiSite.filter((item) => item !== val);
    setFilter((prevState) => ({ ...prevState, multiSite: filteredItems }));
  };

  const gotoDetail = (id, mode, judgeId) => {
    setNavigation(`/app/innovation-convention-assessment/${level}`);

    const searchParam = judgeId ? `?judgeId=${judgeId}` : '';

    return `/app/innovation-convention-assessment/${level}/${id}/${mode}${searchParam}`;
  };

  const startConvention = useMutation((form) => (
    isLevelSite
      ? api.assessment.startConvention(form)
      : api.assessment.startConventionITMLevel(form)
  ), {
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        setNotif({
          message: 'Start Convention success',
          option: {
            variant: 'success'
          }
        });
        isLevelSite ? refetchAssessment() : refetchITMAssessment();
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || 'Start Convention failed',
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const handleStartConvention = () => {
    const newForm = {
      year: filter.year,
      ...isLevelSite ? { siteId: filter.site } : {},
    };
    startConvention.mutate(newForm);
  };

  useEffect(() => {
    setDefaultFilter();
  }, [level, years, sites]);

  return (
    <Page
      title={`Innovation Convention Level ${isLevelSite ? 'Site' : 'ITM'}`}
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path={`Innovation Convention Level ${isLevelSite ? 'Site' : 'ITM'}`} />
        {
          (isAdmin || (!isLevelSite && isJudgeITM)) && (
            <Grid container spacing={4} className={classes.boxFilter}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                  <Typography className={classes.titleFilter}>
                    Filter
                  </Typography>
                </Grid>
                {isLevelSite && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <FormItem
                      id="isUnregistered"
                      label="Show Unreg"
                    >
                      <Checkbox
                        checked={isUnregistered}
                        onChange={(ev) => setIsUnregistered(ev.target.checked)}
                        style={{ justifyContent: 'flex-start' }}
                      />
                    </FormItem>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormItem
                  label="YEAR"
                  id="year"
                >
                  <StyledSelect
                    name="year"
                    placeholder="Select year"
                    value={filter.year}
                    options={years}
                    onChange={(ev) => onFilterChange('year', ev.target.value)}
                  />
                </FormItem>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormItem
                  label="SITE"
                  id="site"
                >
                  {
                    isLevelSite ? (
                      <StyledSelect
                        name="site"
                        placeholder="Select site"
                        value={filter.site}
                        options={sites}
                        onChange={(ev) => onFilterChange('site', ev.target.value)}
                      />
                    ) : (
                      <StyledMultiSelect
                        name="site"
                        placeholder="Select site"
                        value={filter.multiSite}
                        options={sites}
                        onChange={(ev) => onFilterChange('multiSite', ev.target.value)}
                        handleDelete={(value) => onDeleteSiteItem(value)}
                      />
                    )
                  }
                </FormItem>
              </Grid>
            </Grid>
          )
        }
        {
          isAdmin
            ? (
              <DataTable
                isCustom
                isVisible
                tableHeader={adminTableHeaders}
                tableData={isLevelSite ? assessments : assessmentsITM}
                leftComp={(
                  <ButtonConfirmation
                    title="Confirmation"
                    textDialog={`Are you sure you want to start convention on ${filter.year} ${isLevelSite ? `for site ${siteName}` : ''} ?`}
                    textConfirm="Ok"
                    textCancel="Cancel"
                    variant="contained"
                    color="secondary"
                    onConfirm={() => handleStartConvention()}
                    className={clsx(classes.button, classes.label)}
                    disabled={
                      // isStartConvension always have same value every data,
                      // so we use first data to check
                      isLevelSite
                        ? assessments.length === 0 || assessments[0]?.isStartConvension
                        : assessmentsITM.length === 0 || assessmentsITM[0]?.isStartConvension
                    }
                  >
                    Start Convention
                  </ButtonConfirmation>
                )}
                actionSlot={(data) => (
                  <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <IconButton
                        component={RouterLink}
                        to={gotoDetail(data.registrationId, 'view', data.judgeId)}
                        style={{ padding: 0, marginRight: 10 }}
                      >
                        <Tooltip title="View" placement="top" arrow>
                          <DescriptionTwoTone style={{ color: '#737AF1' }} />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <IconButton
                        component={RouterLink}
                        to={gotoDetail(data.registrationId, 'edit', data.judgeId)}
                        style={{ padding: 0, marginRight: 10 }}
                        disabled={!data.canEdit}
                      >
                        <Tooltip title="Edit" placement="top" arrow>
                          <EditTwoTone style={{ color: data.isStartConvension ? '#737AF1' : '#D6D8DA' }} />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {...{
                  entries,
                  showEntries,
                  setShowEntries,
                  pageSize,
                  setPageSize,
                  setSearch,
                  totalData,
                  currentPage,
                  setCurrentPage,
                  totalPage
                }}
              >
                {({ key, data }) => {
                  switch (key) {
                    case 'itmScore': {
                      return (
                        <Typography>
                          {data[key] || 0}
                        </Typography>
                      );
                    }
                    case 'status': {
                      return (
                        <Box style={{
                          backgroundColor: generateStatusColor(data[key]),
                          borderRadius: 14,
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10
                        }}
                        >
                          <Typography style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                            {data[key]}
                          </Typography>
                        </Box>
                      );
                    }
                    default: {
                      return <Typography>{data[key]}</Typography>;
                    }
                  }
                }}
              </DataTable>
            )
            : (
              <DataTable
                isCustom
                isVisible
                tableHeader={judgesTableHeaders}
                tableData={isLevelSite ? assessments : assessmentsITM}
                leftComp={(<></>)}
                actionSlot={(data) => (
                  <Grid container>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <IconButton
                        component={RouterLink}
                        to={gotoDetail(data.registrationId, 'view')}
                        style={{ padding: 0, marginRight: 10 }}
                      >
                        <Tooltip title="View" placement="top" arrow>
                          <DescriptionTwoTone style={{ color: '#737AF1' }} />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <IconButton
                        component={RouterLink}
                        to={gotoDetail(data.registrationId, 'edit')}
                        style={{ padding: 0, marginRight: 10 }}
                        disabled={!data.isStartConvension}
                      >
                        <Tooltip title="Edit" placement="top" arrow>
                          <EditTwoTone style={{ color: data.isStartConvension ? '#737AF1' : '#D6D8DA' }} />
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {...{
                  entries,
                  showEntries,
                  setShowEntries,
                  pageSize,
                  setPageSize,
                  setSearch,
                  totalData,
                  currentPage,
                  setCurrentPage,
                  totalPage
                }}
              >
                {({ key, data }) => {
                  switch (key) {
                    case 'itmScore': {
                      return <></>;
                    }
                    case 'status': {
                      return (
                        <Box style={{
                          backgroundColor: generateStatusColor(data[key]),
                          borderRadius: 14,
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10
                        }}
                        >
                          <Typography style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                            {data[key]}
                          </Typography>
                        </Box>
                      );
                    }
                    default: {
                      return <Typography>{data[key]}</Typography>;
                    }
                  }
                }}
              </DataTable>
            )
        }
      </Container>
    </Page>
  );
};

export default FormAssessment;
