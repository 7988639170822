import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import { uniqBy } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FileDownload from 'js-file-download';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowDropDown,
  Close,
  DescriptionTwoTone
} from '@material-ui/icons';

import Page from 'src/components/Page';
import DataTable from 'src/components/DataTable';
import Toolbar from 'src/views/myDocument/Toolbar';

import api from 'src/api';
import {
  BENEFIT_CATEGORIES,
  CATEGORIES,
  ORGANIZATION_ALL,
  STAGE_LIST,
  YEAR_BUILT
} from 'src/api/backendUrl';

import Filter from './Filter';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  },
  documentTitle: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  }
}));

const entries = [10, 20, 50, 100, 200];

const InnovationRepository = () => {
  const classes = useStyles();

  const innovationRepoFilter = useStoreState((state) => state.innovationRepo.filter);
  const innovationRepoCurrentPage = useStoreState((state) => state.innovationRepo.currentPage);
  const setNotification = useStoreActions((action) => action.notif.setNotif);
  const setInnovationRepoFilter = useStoreActions((action) => action.innovationRepo.setFilter);
  const setInnovationRepoCurrentPage = useStoreActions(
    (action) => action.innovationRepo.setCurrentPage
  );
  const setNavigation = useStoreActions((action) => action.navigation.setPrevious);

  const [isFilterShown, setIsFilterShown] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    yearOptions: [],
    stageOptions: [],
    siteOptions: [],
    categoryOptions: [],
    benefitCategoryOptions: []
  });
  const [filter, setFilter] = useState({
    year: [],
    stage: [],
    site: [],
    category: [],
    benefitCategory: []
  });
  const [filterParams, setFilterParams] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(innovationRepoCurrentPage);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});

  const groupStage = (data) => {
    let transformedObj = {};
    const transformedArray = [];
    const ideaBlastData = data.filter((item) => item.name.includes('Idea Blast'));
    const nonIdeaBlastData = data.filter((item) => !item.name.includes('Idea Blast')
      && (Number(item.name.substring(1, 2)) > 2));

    ideaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          ...stage,
          name: stage?.name?.substring(3)
        }
      };

      transformedArray.push(transformedObj);
    });

    nonIdeaBlastData.forEach((stage) => {
      transformedObj = {
        ...transformedObj,
        ...{
          id: nonIdeaBlastData
            .filter((item) => item.name.includes(stage?.name?.substring(0, 2)))
            .map((item) => item.id)
            .join('|'),
          name: stage?.name?.split('(')[0]
        }
      };

      transformedArray.push(transformedObj);
    });

    return uniqBy(transformedArray, 'name');
  };

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_ALL, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.organizationAll(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: categories } = useQuery(
    [CATEGORIES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.categories(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: stages } = useQuery(
    [STAGE_LIST, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.stageList(query);

      return response?.data?.data.length
        ? groupStage(response.data.data)
        : [];
    }, { initialData: [] }
  );

  const { data: benefitCategories } = useQuery(
    [BENEFIT_CATEGORIES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const response = await api.masterData.benefitCategories(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const fetchInnovationRepos = async () => {
    setIsFetching(true);

    const query = {
      Filters: filterParams,
      page: currentPage,
      pageSize
    };

    const { data: response } = await api.innovationRepo.list({
      ...search && { filters: `search==${search}` },
      ...query
    });

    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response?.totalData === 0
        ? response.totalData
        : showing.show,
      to: response?.totalData <= pageSize
        ? response.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData(response?.data || []);
  };

  const createParamValue = (value) => value.join('|');

  const generateParams = () => new Promise((resolve, reject) => {
    const paramValues = [];
    let paramName = '';

    Object.keys(filter).forEach((key) => {
      if (Array.isArray(filter[key]) && filter[key].length) {
        switch (key) {
          case 'stage': {
            paramName = 'InnovationGateStatuses.StageId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'site': {
            paramName = 'OrganizationId';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'benefitCategory': {
            paramName = 'SearchBenefit';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'year': {
            paramName = 'YearBuilt';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'category': {
            paramName = 'Category';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    resolve(paramValues);
    reject();
  });

  useEffect(() => {
    if (innovationRepoFilter) {
      setFilter(innovationRepoFilter);
    }
  }, []);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      yearOptions: years,
      siteOptions: sites,
      categoryOptions: categories,
      stageOptions: stages,
      benefitCategoryOptions: benefitCategories
    });
  }, [years, sites, categories, stages, benefitCategories]);

  useEffect(() => {
    const setParams = async () => {
      const paramData = await generateParams();
      setCurrentPage(1);
      setFilterParams(paramData.join(','));
    };

    setInnovationRepoFilter(filter);

    setParams()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [filter]);

  useEffect(() => {
    setCurrentPage(1);

    fetchInnovationRepos()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [search]);

  useEffect(() => {
    setInnovationRepoCurrentPage(currentPage);

    fetchInnovationRepos()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [filterParams, pageSize, currentPage]);

  const tableHeaders = [
    { key: 'action', label: '' },
    { key: 'yearBuilt', label: 'Year' },
    { key: 'site', label: 'Site' },
    { key: 'registrationNumber', label: 'Request No' },
    { key: 'initiative', label: 'Initiative Name' },
    { key: 'submitter', label: 'Submitter' },
    { key: 'category', label: 'Category' },
    { key: 'documents', label: 'Documents' },
    { key: 'stage', label: 'Stage' },
    { key: 'benefitCategory', label: 'Benefit Category' },
  ];

  const clearFilter = (key) => {
    setFilter({
      ...filter,
      [key]: ''
    });
  };

  const resetFilter = () => {
    setFilter({
      year: [],
      stage: [],
      site: [],
      category: [],
      benefitCategory: []
    });
    setCurrentPage(1);
  };

  const renderDeleteIcon = (key, value) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {
          value && (
            <Close
              size="small"
              style={{
                color: 'red',
                fontSize: 16,
                zIndex: 1,
                cursor: 'pointer'
              }}
              onClick={() => clearFilter(key)}
            />
          )
        }
        <ArrowDropDown size="small" style={{ color: '#3F3356', marginRight: 7 }} />
      </Box>
    );
  };

  const handleOpenDocument = (data) => {
    setIsModalOpen(true);
    setSelectedDoc(data);
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    const query = {
      Filters: filterParams,
      page: currentPage,
      pageSize
    };

    try {
      const response = await api.innovationRepo.exportExcel({
        ...search && { filters: `search==${search}` },
        ...query
      }, 'blob');

      const fileName = response.headers['content-disposition'].split('\'');

      FileDownload(response.data, fileName[2]);
      setIsDownloading(false);
    } catch (data) {
      setNotification({
        message: data?.data?.Message || data?.data?.message?.messageEng || 'Download failed',
        option: {
          variant: 'error'
        }
      });

      setIsDownloading(false);
    }
  };

  const gotoDetail = (id) => {
    setNavigation('/app/innovation-repository');

    return `/app/registration/${id}`;
  };

  return (
    <Page
      title="Innovation Repository"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Innovation Repository" />
        <Box className={classes.card} my={5}>
          <Grid container spacing={4}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography
                  className={classes.titleFilter}
                  onClick={() => setIsFilterShown(!isFilterShown)}
                >
                  Filter
                </Typography>
                {
                  isFilterShown
                    ? (
                      <ArrowDropDown
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(false)}
                      />
                    )
                    : (
                      <ArrowLeft
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(true)}
                      />
                    )
                }
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {
                isFetching && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignSelf="end"
                      alignItems="center"
                      marginRight={1}
                      height="100%"
                    >
                      <CircularProgress size={24} style={{ alignSelf: 'center' }} />
                    </Box>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          {
            isFilterShown && (
              <Box paddingX={5}>
                <Filter
                  {...{
                    ...filterOptions,
                    values: { ...filter },
                    renderDeleteIcon,
                    setFilter
                  }}
                />
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 15, marginTop: 5 }}
                >
                  <Grid
                    item
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isFetching}
                      style={{ alignSelf: 'self-end' }}
                      onClick={resetFilter}
                    >
                      Reset Filter
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Box>
        <DataTable
          isCustom
          isVisible
          tableHeader={tableHeaders}
          tableData={documentData}
          leftComp={isDownloading
            ? (
              <CircularProgress size={24} />
            )
            : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload()}
              >
                Download
              </Button>
            )}
          actionSlot={(data) => (
            <Grid container>
              <IconButton
                component={RouterLink}
                to={gotoDetail(data.g0Id)}
                style={{ padding: 0, marginRight: 10 }}
              >
                <Tooltip title="Open" placement="top" arrow>
                  <DescriptionTwoTone style={{ color: '#737AF1' }} />
                </Tooltip>
              </IconButton>
            </Grid>
          )}
          {...{
            entries,
            showEntries,
            setShowEntries,
            pageSize,
            setPageSize,
            setSearch,
            totalData,
            currentPage,
            setCurrentPage,
            totalPage
          }}
        >
          {({ key, data }) => {
            if (key === 'documents') {
              return (
                <Typography
                  className={classes.documentTitle}
                  onClick={() => handleOpenDocument(data)}
                >
                  {data[key]}
                </Typography>
              );
            }

            return (
              <Typography>{data[key]}</Typography>
            );
          }}
        </DataTable>
      </Container>

      <DocumentDialog
        detail={selectedDoc}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </Page>
  );
};

export default InnovationRepository;
