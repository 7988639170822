import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  delete: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    }
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  canSubmit,
  disabled,
  viewOnly,
  isSubmitting,
  dirty,
  handleSaveDraft = () => {},
  handleSubmit = () => {},
  showEdit,
  isEditing,
  handleEdit,
  handleUpdateAssessment,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackNav = () => {
    if (location.key === 'default') {
      navigate('/app/innovation-view');
    } else {
      navigate(-1);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {!viewOnly && (
        <ButtonConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to submit ?"
          textConfirm="Ok"
          textCancel="Cancel"
          variant="contained"
          color="secondary"
          disabled={!canSubmit || disabled}
          onConfirm={() => handleSubmit()}
          className={clsx(classes.button, classes.label)}
        >
          Ok
        </ButtonConfirmation>
      )}
      {!viewOnly && (
        <ButtonConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to save as draft ?"
          textConfirm="Ok"
          textCancel="Cancel"
          variant="outlined"
          color="secondary"
          disabled={disabled}
          onConfirm={() => handleSaveDraft()}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Save as Draft
        </ButtonConfirmation>
      )}
      {dirty
        ? (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to close ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={handleBackNav}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </ButtonConfirmation>
        )
        : (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleBackNav}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </Button>
        )}
      {
        showEdit && !isEditing &&
        (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleEdit}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Edit
          </Button>
        )
      }
      {
        isEditing &&
        (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleUpdateAssessment}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Update Assessment
          </Button>
        )
      }
    </Box>
  );
};

Decision.propTypes = {
  canSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
  handleSaveDraft: PropTypes.func,
  handleSubmit: PropTypes.func,
  showEdit: PropTypes.bool,
  isEditing: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleUpdateAssessment: PropTypes.func,
};

export default Decision;
