import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid
} from '@material-ui/core';
import CommentAdvice from './CommentAdvice';

const AdviceView = (props) => {
  const { canApprove } = props;
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CommentAdvice disabled={!canApprove} {...props} />
      </Grid>
    </Grid>
  );
};

AdviceView.propTypes = {
  canApprove: PropTypes.bool,
  canAssign: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isIdeaBlast: PropTypes.bool,
};

export default AdviceView;
