import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { RATE_LIST } from 'src/api/backendUrl';
import api from 'src/api';
import moment from 'moment';
import IDRInvestment from './IDRInvestment';
import RateInvestment from './RateInvestment';
import USDInvestment from './USDInvestment';
import InitiativeName from './InitiativeName';
import BenefitCategory from './BenefitCategory';
import DirectBenefit from './DirectBenefit';
import Attachment from './Attachment';
import IndirectYearlyBasisRecuring from './IndirectYearlyBasisRecuring';
import IndirectBenefit from './IndirectBenefit';
import DirectYearlyBasisRecuring from './DirectYearlyBasisRecuring';
import Solution from './Solution';
import FinalResult from './FinalResult';
import ActualTimePlan from './ActualTimePlan';
import ActionDone from './ActionDone';
import InitiativeDescription from './InitiativeDesciption';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textTransform: 'uppercase',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    marginTop: theme.spacing(3),
  }
}));

const EstimateView = (props) => {
  const classes = useStyles();
  const { isImplementation, canSubmit } = props;
  const { data: rate } = useQuery(RATE_LIST, async () => {
    const { data: response } = await api.masterData.rateList();
    const rateData = response?.data;
    const ytdRate = rateData?.find((d) => moment(d.createdAt).isSame(new Date(), 'year'));
    return ytdRate;
  }, { initialData: {} });
  const disabledEditImplementation = !isImplementation || !canSubmit;

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>estimate investment</Typography>
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <IDRInvestment disabled {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <RateInvestment disabled rate={rate} {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <USDInvestment disabled rate={rate} {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InitiativeName disabled {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InitiativeDescription disabled {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FinalResult disabled={disabledEditImplementation} {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <BenefitCategory disabled {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActionDone disabled={disabledEditImplementation} {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ActualTimePlan disabled={disabledEditImplementation} {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Solution disabled={!canSubmit} {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <DirectBenefit disabled={disabledEditImplementation} {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <DirectYearlyBasisRecuring disabled {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <IndirectBenefit disabled={disabledEditImplementation} {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <IndirectYearlyBasisRecuring disabled {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment disabled={disabledEditImplementation} {...props} />
      </Grid>
    </Grid>
  );
};

EstimateView.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  isImplementation: PropTypes.bool.isRequired,
};

export default EstimateView;
