import { action } from 'easy-peasy';

export default {
  initiative: '',
  detail: {},
  setAssessment: action((state, payload) => {
    state.initiative = payload.initiative;
    state.detail = payload.detail;
  }),
  reset: action((state) => {
    state.initiative = '';
    state.detail = {};
  })
};
