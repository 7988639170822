import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold',
  },
  chartTypography: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '2%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
  },
  chartContainer: {
    padding: '1%',
    borderRadius: '24px',
  },
}));

const ProjectDataChart = ({
  series = [{
    name: '',
    data: []
  }],
  labels = [],
}) => {
  const classes = useStyles();
  const barWidth = 50;
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <Box display="flex" flexDirection="column" className={classes.formContainer}>
      <Typography className={classes.title}>
        Project Data
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.chartContainer}>
        <ReactApexChart
          className={classes.chartTypography}
          series={series}
          type="bar"
          height={`${barWidth * series[0].data.length + 60.144}px`}
          options={
            {
              labels,
              colors: ['#737AF1'],
              tooltip: {
                x: { show: false },
                y: {
                  formatter(v,) {
                    return v.toLocaleString('en-US', { currency: 'USD' });
                  },
                  title: {
                    formatter(v, x) {
                      return x.w.config.labels[x.dataPointIndex];
                    },
                  }
                }
              },
              dataLabels: { enabled: false },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  colors: { ranges: [{ color: '#737AF1' }] },
                  horizontal: true,
                }
              },
              grid: {
                xaxis: { lines: { show: true } },
                yaxis: { lines: { show: false } },
              },
              xaxis: {
                labels: {
                  formatter(value) {
                    return formatter.format(value);
                  }
                },
                axisBorder: { show: false },
              },
              yaxis: {
                axisBorder: { show: false },
              }
            }
          }
        />
      </Box>
    </Box>
  );
};

ProjectDataChart.propTypes = {
  series: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
};

export default ProjectDataChart;
