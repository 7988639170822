import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import MvpResult from './MvpResult';
import PlannedFeatureMvp from './PlannedFeatureMvp';
import ActualFeaturesMvp from './ActualFeaturesMvp';
import ActualImplementationScopeMvp from './ActualImplementationScopeMvp';
import ActualBenefitCategory from './ActualBenefitCategory';
import Attachment from './Attachment';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const SecondView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>MVP</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MvpResult {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedFeatureMvp
          {...props}
          disabled
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActualFeaturesMvp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActualImplementationScopeMvp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ActualBenefitCategory {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment {...props} />
      </Grid>
    </Grid>
  );
};

SecondView.propTypes = {
  values: PropTypes.object.isRequired,
};

export default SecondView;
