import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const NoRegHaki = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'noRegHAKI';
  return (
    <FormItem
      required
      id={key}
      label="No. Reg. HAKI"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        name={key}
        type="text"
        placeholder="Enter no. reg. HAKI"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

NoRegHaki.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default NoRegHaki;
