import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';

import NavItem from './NavItem';

const items = (
  isAdmin,
  isAdminOnly,
  isJudgeSite,
  isJudgeITM
) => [
  {
    icon: '/static/icons/ic_dashboard.svg',
    href: '/app/dashboard',
    title: 'Dashboard',
  },
  {
    icon: '/static/icons/ic_innovation.svg',
    title: 'Innovation Home',
    children: [
      {
        href: '/app/registration',
        title: 'Registration',
      }, {
        href: '/app/my-document',
        title: 'My Document',
      }, {
        href: '/app/my-activity',
        title: 'My Activity',
      }, {
        href: '/app/innovation-view',
        title: 'Innovation View',
      }, {
        href: '/app/innovation-repository',
        title: 'Innovation Repository',
      },
      isAdmin ? {
        href: '/app/innovation-involvement',
        title: 'Innovation Involvement',
      } : {}
    ]
  },
  {
    icon: '/static/icons/ic_innovation_convention.svg',
    title: 'ITMNovation Convention',
    children: [
      {
        ...(isAdmin || isJudgeSite || isJudgeITM) && {
          title: 'Level Site',
          children: [
            {
              href: '/app/innovation-convention-assessment/site',
              title: 'Form Assessment'
            },
            {
              href: '/app/innovation-convention-assessment-summary/site',
              title: 'Summary Result'
            }
          ]
        }
      },
      {
        ...(isAdminOnly || isJudgeITM) && {
          title: 'Level ITM',
          children: [
            {
              href: '/app/innovation-convention-assessment/itm',
              title: 'Form Assessment'
            },
            {
              href: '/app/innovation-convention-assessment-summary/itm',
              title: 'Summary Result'
            }
          ]
        }
      }
    ]
  },
  isAdmin && {
    icon: '/static/icons/ic_setting.svg',
    title: 'Settings',
    children: [
      {
        href: '/app/setting/user-management',
        title: 'User Management',
      },
      {
        href: '/app/setting/reminder-history',
        title: 'Reminder History'
      },
      ...isAdminOnly
        ? [{
          href: '/app/setting/benefit-category',
          title: 'Benefit Category'
        }, {
          href: '/app/setting/assessment-question',
          title: 'ITMNovation'
        }]
        : [],
      {
        title: 'Idea Blast',
        children: [{
          href: '/app/setting/idea-blast/reward-summary',
          title: 'Reward Summary',
        }, {
          href: '/app/setting/idea-blast/score',
          title: 'Score',
        }]
      },
    ]
  },
];

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: drawerWidth,
    backgroundColor: theme.palette.secondary.main,
  },
  desktopDrawer: {
    position: 'fixed',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
    width: drawerWidth,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(12) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12) + 1,
    },
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: theme.spacing(8)
  },
  toggle: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    fontSize: theme.spacing(1),
    color: '#fff'
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden'
  }
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  isDrawerOpen,
  toggleDrawer,
  openDrawer,
  closeDrawer,
  toggleActive,
  setToggle
}) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useStoreState((state) => state.auth.user);
  const { logout, setLastRoute } = useStoreActions((actions) => actions.auth);

  const isAdmin = user?.roles?.some((role) => role.name === 'Administrator' || role.name === 'Admin Site');
  const isAdminOnly = user?.roles?.some((role) => role.name === 'Administrator');
  const isJudgeSite = user?.isJudge;
  const isJudgeITM = user?.isJudgeITM;
  const menus = items(isAdmin, isAdminOnly, isJudgeSite, isJudgeITM)
    ?.filter(Boolean)
    .map((item) => {
      if (item.children && item.children.length) {
        return {
          ...item,
          children: item.children.filter((child) => !isEmpty(child))
        };
      }

      return item;
    });

  const loggingOut = () => {
    setLastRoute('/app/innovation-view');
    logout();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.container}
    >
      <Box py={2}>
        <List>
          <ListItem className={classes.toggleButton}>
            {isDrawerOpen && !openMobile && (
              <IconButton className={classes.toggle} onClick={() => setToggle(!toggleActive)}>
                {toggleActive ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
              </IconButton>
            )}
          </ListItem>
          {menus.map((item) => (
            <NavItem
              href={item.href || location.pathname}
              key={item.title}
              title={item.title}
              submenu={item.children}
              icon={item.icon}
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              openMobile={openMobile}
            />
          ))}
          {openMobile && (
            <NavItem
              href="/logout"
              key="logout"
              title="Logout"
              openMobile={openMobile}
              onClick={loggingOut}
            />
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isDrawerOpen,
              [classes.drawerClose]: !isDrawerOpen,
            })
          }}
          open
          variant="permanent"
          onMouseEnter={!toggleActive ? openDrawer : () => {}}
          onMouseLeave={!toggleActive ? closeDrawer : () => {}}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  toggleActive: PropTypes.bool,
  setToggle: PropTypes.func,
  openDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
  toggleDrawer: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
  isDrawerOpen: false
};

export default NavBar;
