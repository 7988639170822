import * as Yup from 'yup';
import { isEmpty } from 'lodash';

export const initialValues = (detail, registration, prototype) => {
  return {
    g5OnetimeBenefit: detail?.g5OnetimeBenefit || 0,
    g5OnetimeCost: detail?.g5OnetimeCost || 0,
    g5RecurBenefit: detail?.g5RecurBenefit || 0,
    g5RecurCost: detail?.g5RecurCost || 0,
    mmpFundingCapex: detail?.mmpFundingCapex || 0,
    mmpFundingOpex: detail?.mmpFundingOpex || 0,
    scaleUpMilestone: detail?.scaleUpMilestone || '',
    plannedFeaturesMmp: prototype?.plannedFeaturesMmp || '',
    actualFeaturesMmp: detail?.actualFeaturesMmp || '',
    actualImplementationScopeMmp: detail?.actualImplementationScopeMmp || '',
    mmpResult: detail?.mmpResult || '',
    attachments: detail?.g5ScaleUpAttachments || [],
    teamMembers: registration?.innovationTeamMembers?.length
      ? registration.innovationTeamMembers.map((item) => ({ id: item.id, name: item.memberName }))
      : [],
    dataArchitects: detail?.dataArchitects || [],
    action: '',
    submitter: detail?.submitter || {},
    organizationId: detail?.organizationId || '',
    facilitator: detail?.facilitator || {},
    newTeamMembers: detail?.newTeamMembers || [],
    objective: detail?.objective || '',
    projectScope: detail?.projectScope || ''
  };
};

export const validation = Yup.object().shape({
  g5OnetimeBenefit: Yup.number()
    .min(0, 'G5 Total Annual Net One Time Benefit should be 0 or more')
    .required('G3 Total Annual One Time Benefit is required'),
  g5OnetimeCost: Yup.number()
    .min(0, 'G5 Total Annual Net One Time Cost should be 0 or more')
    .required('G5 Total Annual One Time Cost is required'),
  g5RecurBenefit: Yup.number()
    .min(0, 'G5 Total Annual Net Recurring Benefit should be 0 or more')
    .required('G5 Total Annual Net Recurring Benefit is required'),
  g5RecurCost: Yup.number()
    .min(0, 'G5 Total Annual Recurring Cost should be 0 or more')
    .required('G5 Total Annual Recurring Cost is required'),
  mmpFundingCapex: Yup.number()
    .min(0, 'MMP Funding Capex should be 0 or more')
    .required('MMP Funding Capex is required'),
  mmpFundingOpex: Yup.number()
    .min(0, 'MMP Funding Opex should be 0 or more')
    .required('MMP Funding Opex is required'),
  scaleUpMilestone: Yup.string(),
  plannedFeaturesMmp: Yup.string(),
  actualFeaturesMmp: Yup.string().required('Actual Features MMP is required'),
  actualImplementationScopeMmp: Yup.string().required('Actual Implementation Scope MMP is required'),
  mmpResult: Yup.string().required('MMP Result is required'),
  attachments: Yup.array().min(1, 'Attachments is required'),
  dataArchitects: Yup.array().min(1, 'Solution Architect is required'),
  submitter: Yup.object(),
  organizationId: Yup.string(),
  facilitator: Yup.object(),
  newTeamMembers: Yup.array(),
  objective: Yup.string().when(['submitter', 'organizationId', 'facilitator', 'newTeamMembers'], {
    is: (submitter, organizationId, facilitator, newTeamMembers) => !isEmpty(submitter)
      || !isEmpty(organizationId)
      || !isEmpty(facilitator)
      || (Boolean(newTeamMembers) && !isEmpty(newTeamMembers)),
    then: Yup.string().required('New Objective is required')
  }),
  projectScope: Yup.string().when(['submitter', 'organizationId', 'facilitator', 'newTeamMembers'], {
    is: (submitter, organizationId, facilitator, newTeamMembers) => !isEmpty(submitter)
      || !isEmpty(organizationId)
      || !isEmpty(facilitator)
      || (Boolean(newTeamMembers) && !isEmpty(newTeamMembers)),
    then: Yup.string().required('New Scope is required')
  })
});
