import { createMuiTheme, colors } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';
import {
  SFProDisplaySemiBold,
  SFProTextMedium,
  SFProTextRegular,
  SFProTextSemiBold,
  PTSansBold,
  PTSansRegular
} from './fonts';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#A2A6F6',
      light: '#EDEEFD',
    },
    secondary: {
      main: '#737AF1'
    },
    background: {
      main: '#A2A6F6'
    },
    textDescription: {
      main: '#1A051D'
    },
    transparent: {
      main: fade(colors.common.white, 0.5)
    },
    activePath: {
      main: '#FDDA5E'
    },
    disabledForm: {
      main: '#F1F0F6'
    },
    danger: {
      main: '#FC5346',
      dark: '#b03a31',
    },
    accent: {
      main: '#01AABD'
    }
  },
  shadows,
  typography,
  spacing: 6,
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: colors.common.white
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [SFProDisplaySemiBold, SFProTextMedium, SFProTextRegular, SFProTextSemiBold, PTSansBold, PTSansRegular],
      },
    },
    MuiAutocomplete: {
      popup: { zIndex: 1300 },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      }
    },
  }
});

export default theme;
