import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

const Role = ({
  roleOptions = [],
  values,
  setFilter
}) => {
  const key = 'role';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  const handleOnDelete = (val) => {
    const filteredItems = values[key].filter((item) => item !== val);
    setFilter({
      ...values,
      [key]: filteredItems
    });
  };

  return (
    <FormItem
      id={key}
      label="Role"
    >
      <StyledMultiSelect
        name={key}
        valueFrom="name"
        placeholder="Select role"
        value={values[key]}
        options={roleOptions}
        onChange={handleOnChange}
        handleDelete={handleOnDelete}
      />
    </FormItem>
  );
};

Role.propTypes = {
  roleOptions: PropTypes.array,
  values: PropTypes.object,
  setFilter: PropTypes.func
};

export default Role;
