import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  FormHelperText,
  InputLabel
} from '@material-ui/core';
import clsx from 'clsx';

const StyledLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.textDescription.main,
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    textAlign: 'left',
    textTransform: 'uppercase',
    width: '100%'
  },
}))((props) => <InputLabel {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    marginBottom: theme.spacing(1),
  },
  helper: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main
  },
  required: {
    color: theme.palette.error.main
  },
  labelMargin: {
    marginBottom: 10
  }
}));

const FormItem = ({
  required = false,
  label,
  showLabel = true,
  normalize = false,
  id,
  error,
  helperText,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {showLabel && (
        <StyledLabel
          shrink
          required={required}
          htmlFor={id}
          className={normalize ? classes.labelMargin : ''}
        >
          {label}
        </StyledLabel>
      )}
      {children}
      <FormHelperText className={clsx(classes.helper, {
        [classes.error]: error
      })}
      >
        {helperText}
      </FormHelperText>
    </Box>
  );
};

FormItem.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  normalize: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  children: PropTypes.node,
};

export default FormItem;
