import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FormItem from 'src/components/FormItem';
import UserSelector from 'src/components/UserSelector';
import { isValidationRequired } from 'src/utils/validation';

const RequestedFor = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  validation
}) => {
  const key = 'requestedFor';
  const { submittedById } = values;

  const changeCompany = (val) => {
    setFieldValue('company', val, true);
    setFieldTouched('company', true, false);
  };

  const handleChange = (val) => {
    if (isEmpty(val)) {
      setFieldValue(key, val, true);
      setFieldTouched(key, true, false);

      changeCompany(submittedById?.company?.name);
      return;
    }

    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
    changeCompany(val.company);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Requested For"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <UserSelector
        userId={submittedById?.id}
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter name/email/department"
      />
    </FormItem>
  );
};

RequestedFor.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object
};

export default RequestedFor;
