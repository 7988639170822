import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, Prompt } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import * as Yup from 'yup';
import clsx from 'clsx';
import deepEqual from 'deep-equal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useMutation, useQueryClient } from 'react-query';

import {
  Box,
  makeStyles,
} from '@material-ui/core';

import { Form, Formik } from 'formik';

import api from 'src/api';

import convertToBase64 from 'src/utils/files';

import EstimateView from './EstimateView';
import GeneralView from './GeneralView';
import Decision from './Decision';
import { initialValues, validation } from './config';
import AdviceView from './AdviceView';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  additionalForm: {
    borderRadius: '24px'
  },
}));

const RegistrationView = ({
  handleChangeTab,
  detail,
  approval,
  handleAccessPermissionClick
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const user = useStoreState((state) => state.auth.user);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [isEdit, setIsEdit] = useState(false);
  const [canRemind, setCanRemind] = useState(false);
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });
  const init = {
    fullname: user.name,
    ...user
  };

  if (!_.isEmpty(detail)
    && (detail?.category !== 'Individual' && _.isEmpty(detail?.facilitator))
  ) {
    setNotif({
      message: 'Scrum Master/Facilitator is empty',
      option: {
        variant: detail?.innovationStatus === 'Waiting Superior Approval'
          ? 'warning'
          : 'error'
      }
    });
  }

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (data?.data?.status === 20001) {
          setNotif({
            message: data?.data?.message || 'There is different assignment composition of each Committee. Please check and confirm among Committee',
            option: {
              variant: 'warning'
            }
          });
          queryClient.invalidateQueries(['detail-registration', id]);
        } else {
          if (onSuccess) {
            data.status < 300 && onSuccess(data?.data);
            return;
          }

          setPromptAction({ needed: false, action: actionMessage });
          setNotif({
            message: `${actionMessage} success`,
            option: {
              variant: 'success'
            }
          });
        }
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotif({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };

  const yupValidation = Yup.object().shape(validation).fields;

  const submit = useMutation((form) => api.registration.create(form), mutateOption('Submit'));
  const saveDraft = useMutation((form) => api.registration.draft(form), mutateOption('Save Draft'));
  const editAdmin = useMutation((form) => api.registration.edit(id, form), mutateOption('Edit'));
  const submitDraft = useMutation((form) => api.registration.submitdraft(id, form), mutateOption('Submit'));
  const updateDraft = useMutation((form) => api.registration.updatedraft(id, form), mutateOption('Save Draft'));
  const approve = useMutation((form) => api.registration.approve(id, form), mutateOption('Approve'));
  const approveIdeaBlast = useMutation((form) => api.registration.approveIdeaBlast(id, form), mutateOption('Approve'));
  const sendback = useMutation((form) => api.registration.sendback(id, form), mutateOption('Sent Back'));
  const cancel = useMutation(() => api.registration.cancel(id), mutateOption('Cancel'));
  const reactivate = useMutation((form) => api.registration.reactivate(id, form), mutateOption('Reactivate'));
  const createg1 = useMutation((responseId) => api.registration.createG1(responseId || id),
    mutateOption('Create G1', () => handleChangeTab(1)));
  const reject = useMutation((form) => api.registration.reject(id, form), mutateOption('Reject'));
  const submitIpod = useMutation((form) => api.registration.create(form),
    mutateOption('Submit Ipod', (data) => {
      navigate(`/app/registration/${data.id}`);
      createg1.mutate(data.id);
    }));

  const generateForm = async (form) => {
    const {
      innovationAttachments,
      innovationStatus,
      innovationBenefits,
      adminSite,
      submittedById,
      requestedFor,
      promotor,
      facilitatorUser,
      ...rest
    } = form;

    const base64files = await convertToBase64(innovationAttachments);

    const newForm = {
      ...rest,
      innovationBenefits: _.uniq(innovationBenefits.map((d) => ({ benefitCategoryId: d }))),
      submittedById: submittedById.id,
      innovationAttachments: base64files,
      requestedFor: requestedFor?.empId || requestedFor?.userAd || '',
      promotorUser: promotor?.empId || promotor?.userAd || '',
      facilitatorUser: facilitatorUser?.empId || facilitatorUser?.userAd || ''
    };
    if (rest?.category === 'Project Team'
      || rest?.category === 'IPod'
      || rest?.category === 'IT Demand') {
      const {
        actionPlan,
        scheduledPlan,
        solution,
        ...TeamForm
      } = newForm;

      return TeamForm;
    }

    const {
      isCoach,
      isTechnology,
      ...IndividualForm
    } = newForm;

    return IndividualForm;
  };

  const handleSubmitForm = async (form) => {
    const newForm = await generateForm(form);

    switch (true) {
      case newForm.category === 'IPod': {
        submitIpod.mutate(newForm);
        break;
      }
      case detail.innovationStatus === 'Draft': {
        submitDraft.mutate(newForm);
        break;
      }
      case detail.innovationStatus === 'Send Back': {
        const sentBackForm = { ...newForm, id };
        submit.mutate(sentBackForm);
        break;
      }
      default: {
        submit.mutate(newForm);
        break;
      }
    }
  };

  const handleEditAdminForm = async (form) => {
    const newForm = await generateForm(form);
    editAdmin.mutate(newForm);
  };

  const handleSaveDraft = async (form) => {
    const newForm = await generateForm(form);
    if (detail.innovationStatus === 'Draft'
      || detail.innovationStatus === 'Send Back') {
      updateDraft.mutate(newForm);
      return;
    }

    saveDraft.mutate(newForm);
  };

  const handleApproval = async (form) => {
    const nonIdeaBlast = form.category === 'Project Team'
      || form.category === 'IPod'
      || form.category === 'IT Demand';
    const newForm = {
      Comment: form.Comment || '',
      RoleId: form.RoleId
    };

    /**
     * isCustomFlow === true: same as before
     * isCustomFlow === false: call `approve` API even if category is `idea-blast`
     * */
    if (approval?.isCustomFlow) {
      nonIdeaBlast
        ? approve.mutate(newForm)
        : approveIdeaBlast.mutate({ Comment: form.Comment || '' });

      return;
    }

    approve.mutate(newForm);
  };

  const handleSentBack = async (form) => {
    const newForm = {
      Comment: form.Comment || '',
      AssignmentGroup: form.AssignmentGroup,
    };
    sendback.mutate(newForm);
  };

  const handleReactivate = async () => {
    reactivate.mutate();
  };

  const handleCancel = async () => { cancel.mutate(); };

  const handleCreateG1 = async () => { createg1.mutate(); };

  const handleReject = async (form) => {
    const newForm = {
      Comment: form.Comment
    };
    reject.mutate(newForm);
  };

  const handleOpenEdit = () => setIsEdit(true);

  const handleCloseEdit = () => setIsEdit(false);

  const isCanValidateOnMount = !isEmpty(detail) && detail.innovationStatus === 'Draft';

  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  useEffect(() => {
    setCanRemind(!detail?.innovationGateStatuses?.notificationSent);
  }, [detail?.innovationGateStatuses?.notificationSent]);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={initialValues({ id, init, detail })}
      initialErrors={Yup.object().shape(validation)}
      isInitialValid={() => Yup.object()
        .shape(validation)
        .isValidSync(initialValues({ id, init, detail }))}
      validationSchema={Yup.object().shape(validation)}
      onSubmit={(value, actions) => {
        const {
          action,
          rate,
          registration_number,
          ...form
        } = value;

        actions.setSubmitting(true);
        switch (action) {
          case 'submit':
            handleSubmitForm(form);
            break;
          case 'edit':
            handleEditAdminForm(form);
            break;
          default:
            break;
        }
        actions.setSubmitting(false);
      }}
    >
      {({
        initialValues: initialData,
        errors,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        submitForm,
        isSubmitting,
        handleSubmit,
        isValid,
        dirty
      }) => {
        const isG0 = id && detail?.innovationGateStatuses?.stages?.name?.includes('G0');
        const isG1 = id && (detail?.innovationGateStatuses?.stages?.name?.includes('G1'));
        const isG1Draft = isG1 && (detail?.innovationGateStatuses?.status?.includes('Draft'));
        const isIdeaBlast = values.category === 'Individual';
        const isPO = detail?.userPermissions?.some((item) => item.id === user?.id);
        const isAdmin = user?.roles?.findIndex((d) => d.name === 'Administrator') > -1;
        const isRoleAdminSite = user?.roles?.findIndex((d) => d.name === 'Admin Site') > -1;
        const isAdminApp = isAdmin && !user.designation;
        const isPIC = user?.roles?.filter((d) => ['DCOE', 'IT', 'Infinity'].indexOf(d.name) > -1);
        const isAdminPIC = !!isPIC.length
          && isPIC.findIndex((d) => d?.name === detail?.roles?.name) > -1;
        const isAdminSite = isRoleAdminSite
          && user?.cluster?.some((item) => item.id === detail?.organizationId);
        const isPromotor = user.empId === detail?.promotor?.empId
          || user.id === detail?.promotor?.id
          || user.username === detail?.promotor?.username;
        const isDirectSuperior = user.id === detail?.superiorId;
        // Status Project
        const isDraft = ['Draft', 'Send Back'].indexOf(detail?.innovationStatus) > -1;
        const isWaitingSuperior = detail?.innovationStatus === 'Waiting Superior Approval';
        const isWaitingAdmin = detail?.innovationStatus === 'Waiting Admin Acknowledge';
        const isWaitingPromotor = detail?.innovationStatus === 'Waiting Promotor/Sponsor Approval';
        const isCompleted = detail?.innovationStatus === 'Completed';
        const isCancelled = detail?.innovationStatus === 'Cancelled';
        const canSubmit = id
          ? isG0 && (
            (isDraft && isPO)
            || (isEdit && (isAdminApp || isAdminSite))
            || (isAdminPIC && isEdit && (isWaitingAdmin || isDraft))
            || (isIdeaBlast && !isDraft && !isCompleted && (isAdminSite || isPO)))
          : values.innovationStatus === '';
        const canEdit = isG0
          && !isEdit
          && !isDraft
          && !isCompleted
          && (isIdeaBlast
            ? (isAdminApp || isAdminSite)
            : (isAdminApp || isAdminSite || (isAdminPIC && (isWaitingAdmin || isDraft))
            )
          );
        const canApprove = approval?.isCanApprove;
        const canSentBack = approval?.isCanApprove;
        const showAdvice = isG0 && !isDraft && values.innovationStatus !== '';
        const canAssign = isG0 && isWaitingSuperior && isDirectSuperior && canApprove;
        const canCancel = isG0 && !isCancelled && isPO;
        const canReactivate = isG0 && isCancelled
        && (isAdmin || (isIdeaBlast ? isAdminSite : isAdminPIC));
        const canCreateG1 = isG0 && isCompleted && isPO;
        const canReject = (isWaitingSuperior && isDirectSuperior)
          || (isWaitingPromotor && isPromotor)
          || (isRoleAdminSite && isWaitingAdmin);
        const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
        const isWaitingApproval = detail?.innovationStatus?.includes('Waiting');
        const showRemind = (isAdminSite || isAdmin)
          && ((isG0 && (isWaitingApproval || isCompleted)) || isG1Draft);

        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <GeneralView
                disabled={!canSubmit}
                isDraft={detail?.innovationStatus === 'Draft' || values.innovationStatus === ''}
                validation={yupValidation}
                {...{
                  id,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
              <EstimateView
                disabled={!canSubmit}
                validation={yupValidation}
                {...{
                  id,
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }}
              />
            </Box>
            {showAdvice && (
              <Box className={clsx(classes.formContainer, classes.additionalForm)}>
                <AdviceView
                  validation={yupValidation}
                  {...{
                    canApprove,
                    canAssign,
                    isAdmin,
                    isIdeaBlast,
                    id,
                    detail,
                    errors,
                    handleBlur,
                    handleChange,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    values
                  }}
                />
              </Box>
            )}
            <Box mt={5} mb={5}>
              <Decision {...{
                isAdmin,
                isDirectSuperior,
                isDraft,
                isIdeaBlast,
                isWaitingSuperior,
                canEdit,
                canSubmit,
                canApprove,
                canSentBack,
                canCancel,
                canCreateG1,
                canReactivate,
                canReject,
                canManageAccess,
                isEdit,
                handleOpenEdit,
                handleCloseEdit,
                handleReactivate,
                handleSaveDraft,
                handleApproval,
                handleSentBack,
                handleCancel,
                handleCreateG1,
                handleReject,
                handleAccessPermissionClick,
                id,
                detail,
                values,
                submitForm,
                isSubmitting,
                isValid,
                setFieldValue,
                dirty,
                showRemind,
                canRemind,
                handleRemind,
                setPromptAction
              }}
              />
            </Box>
            <Prompt
              when={promptAction.needed
                && (canSubmit || canEdit)
                && !deepEqual(initialData, values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

RegistrationView.propTypes = {
  handleChangeTab: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  detail: PropTypes.object,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  })
};

export default RegistrationView;
