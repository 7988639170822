import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isUndefined } from 'lodash';

import { isValidationRequired } from 'src/utils/validation';

import StyledInput from 'src/components/StyledInput';
import FormItem from 'src/components/FormItem';

const NewObjective = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'objective';

  const customValidation = () => {
    if (isUndefined(values)) {
      return false;
    }

    const {
      submitter,
      organizationId,
      facilitator,
      newTeamMembers
    } = values;

    return !isEmpty(submitter)
      || !isEmpty(organizationId)
      || !isEmpty(facilitator)
      || !isEmpty(newTeamMembers);
  };

  return (
    <FormItem
      required={isValidationRequired(
        key,
        validation,
        values,
        customValidation
      )}
      label="New Objective"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter New Objective"
      />
    </FormItem>
  );
};

NewObjective.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default NewObjective;
