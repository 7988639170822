import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import Year from './Year';
import Site from './Site';
import Role from './Role';

const Filter = (props) => {
  const {
    yearOptions,
    siteOptions,
    roleOptions
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Year yearOptions={yearOptions} {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Site siteOptions={siteOptions} {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <Role roleOptions={roleOptions} {...props} />
      </Grid>
    </Grid>
  );
};

Filter.propTypes = {
  yearOptions: PropTypes.array,
  siteOptions: PropTypes.array,
  roleOptions: PropTypes.array,
};

export default Filter;
