import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

const Category = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
}) => {
  const key = 'category';
  const categoryOptions = [
    { label: 'Individual (Idea Blast)', value: 'Individual' },
    { label: 'Project Team (INFINITY)', value: 'Project Team' },
  ];
  return (
    <FormItem
      label="Category"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        placeholder="Select category"
        labelKey="label"
        valueKey="value"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={categoryOptions}
      />
    </FormItem>
  );
};

Category.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Category;
