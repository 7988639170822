import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  Fade,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  subItem: {
    paddingLeft: theme.spacing(5),
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 25px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(2),
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.common.white,
    overflow: 'hidden'
  },
  active: {
    backgroundColor: theme.palette.activePath.main,
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  main: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0 20px 20px 0',
    marginRight: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  parent: {
    backgroundColor: 'inherit',
    marginRight: 10,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  mainmenu: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Expand = ({ openSubMenu }) => {
  return openSubMenu
    ? <ExpandLess style={{ color: '#fff', marginLeft: 5 }} />
    : <ExpandMore style={{ color: '#fff', marginLeft: 5 }} />;
};

const NavItem = ({
  className,
  href = '',
  submenu,
  icon,
  title,
  openMobile,
  isDrawerOpen,
  toggleDrawer = () => {},
  onClick = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [subActive, setSubActive] = useState();

  const handleToggle = () => {
    setOpen(!open);
    !isDrawerOpen && toggleDrawer();
    if (!open) setSubActive();
  };

  const handleToggleSub = (key) => {
    subActive === key
      ? setSubActive()
      : setSubActive(key);
  };

  const isOpen = (key) => subActive === key;

  const canAccess = (isDrawerOpen || openMobile);

  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          end
          activeClassName={classes.active}
          className={clsx(
            classes.button,
            classes.mainmenu, {
              [classes.main]: submenu && title === 'Inovation Home',
              [classes.parent]: submenu && title !== 'Inovation Home'
            }
          )}
          component={submenu ? Button : RouterLink}
          to={href}
          onClick={submenu ? handleToggle : onClick}
        >
          {icon && <img className={classes.icon} src={icon} alt="icon" />}
          <Fade in={canAccess}>
            <Box
              maxHeight="24px"
              display="flex"
            >
              <span className={classes.title}>
                {title}
              </span>
              {submenu && canAccess && <Expand openSubMenu={open} />}
            </Box>
          </Fade>
        </Button>
      </ListItem>
      {submenu && canAccess && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {submenu.map((sub) => (
              <React.Fragment key={sub.title}>
                <ListItem
                  key={sub.title}
                  className={clsx(classes.item, className)}
                  disableGutters
                >
                  <Button
                    className={classes.button}
                    onClick={() => (sub.children
                      ? handleToggleSub(sub.title)
                      : (sub?.onClick && sub?.onClick())
                    )}
                    {...sub?.href && {
                      end: true,
                      activeClassName: clsx({ [classes.active]: !sub.children }),
                      component: RouterLink,
                      to: sub?.href
                    }}
                  >
                    <Fade in={canAccess && open}>
                      <Box
                        maxHeight="24px"
                        display="flex"
                      >
                        <span className={clsx(classes.title, classes.subItem)}>
                          {sub.title}
                        </span>
                        {sub.children && <Expand openSubMenu={isOpen(sub.title)} />}
                      </Box>
                    </Fade>
                  </Button>
                </ListItem>
                {isOpen(sub.title) && (
                  <Collapse in={isOpen(sub.title)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {sub?.children?.map((subsub) => (
                        <ListItem
                          key={subsub.title}
                          className={clsx(classes.item, className)}
                          disableGutters
                        >
                          <Button
                            end
                            activeClassName={classes.active}
                            className={classes.button}
                            component={RouterLink}
                            to={subsub?.href || ''}
                            onClick={subsub.onClick}
                          >
                            <Fade in={canAccess && open}>
                              <Box
                                maxHeight="24px"
                                display="flex"
                              >
                                <span className={clsx(classes.title, classes.subItem)}>
                                  {subsub.title}
                                </span>
                              </Box>
                            </Fade>
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  submenu: PropTypes.array,
  isDrawerOpen: PropTypes.bool,
  openMobile: PropTypes.bool,
  onClick: PropTypes.func,
  toggleDrawer: PropTypes.func
};

Expand.propTypes = {
  openSubMenu: PropTypes.bool
};

export default NavItem;
