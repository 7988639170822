const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const getNewFiles = (files) => Promise.all(files.map(async (d) => {
  if (d.node) {
    return { node: d.node };
  }
  const converted = await toBase64(d.file);
  return { node: converted, name: d?.name };
}));

const convertToBase64 = async (files = []) => {
  const convertedFiles = await getNewFiles(files);
  return convertedFiles;
};

export default convertToBase64;
