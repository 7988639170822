import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const PopUpClone = ({
  isDialogOpen,
  isSubmitting,
  years,
  selectedYear,
  handleSubmit,
  handleClose
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [year, setYear] = useState();

  /**
   * clone years cannot be the same or more than current year
   * */
  const yearOptions = years.filter(
    (item) => {
      return moment(item.id, 'YYYY').isBefore(moment(), 'years');
    }
  );

  const handleChange = (ev) => {
    setYear(ev.target.value);
  };

  useEffect(() => {
    const defaultYear = moment().subtract(1, 'years').format('YYYY');
    setYear(defaultYear);
  }, []);

  return (
    <Dialog
      fullWidth
      aria-labelledby="assessment-clone-dialog"
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle id="assessment-clone-dialog">
        <Typography>
          Clone Assessment Question
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormItem
              key="year"
              label="Year to Clone"
            >
              <StyledSelect
                name="year"
                placeholder="Select Year"
                value={year}
                options={yearOptions}
                onChange={handleChange}
              />
            </FormItem>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonConfirmation
          disabled={isSubmitting}
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog={`Are you sure you want to clone the question level Site from ${year} and append it to ${selectedYear}`}
          textConfirm="Yes"
          textCancel="No"
          onConfirm={() => handleSubmit(year)}
        >
          {
            isSubmitting && (
              <CircularProgress
                size={12}
                style={{ marginRight: 5 }}
              />
            )
          }
          Clone
        </ButtonConfirmation>
        <Button
          disabled={isSubmitting}
          variant="outlined"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopUpClone.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  years: PropTypes.array.isRequired,
  selectedYear: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PopUpClone;
