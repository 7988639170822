const header = [
  { field: 'Fullname', width: 200, sort: 'fullname' },
  { field: 'Username', width: 200, sort: 'username' },
  { field: 'Email', width: 200, sort: 'email' },
  { field: 'Company', width: 200, sort: 'Company.Name' },
  { field: 'Organization', width: 200, sort: 'organizationName' },
  { field: 'Role', width: 200, sort: 'createdAt' },
  { field: 'Action', width: 200, sort: 'action' },
];
export default header;
