import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import { SnackbarProvider } from 'notistack';
import { useStoreState, useStoreRehydrated, useStoreActions } from 'easy-peasy';
import Loading from 'src/components/Loading';
import Notifier from 'src/components/Notifier';
import { QueryClient, QueryClientProvider } from 'react-query';

function WaitForStateRehydration({ children }) {
  const isRehydrated = useStoreRehydrated();
  return isRehydrated ? children : null;
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 2000
    },
  },
});

const App = () => {
  const location = useLocation();
  const { token, user, lastRoute } = useStoreState((state) => state.auth);
  const { setLastRoute } = useStoreActions((state) => state.auth);

  const isAdmin = user?.roles?.some((role) => role.name === 'Administrator' || role.name === 'Admin Site');
  const routing = useRoutes(routes(token, isAdmin, lastRoute));

  useEffect(() => {
    if (location.pathname.includes('/app')) {
      setLastRoute(location.pathname);
    }
  }, [location, setLastRoute]);

  return (
    <WaitForStateRehydration>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <GlobalStyles />
            {routing}
            <Loading />
            <Notifier />
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </WaitForStateRehydration>
  );
};

export default App;
