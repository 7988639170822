import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { RATE_LIST } from 'src/api/backendUrl';
import api from 'src/api';
import moment from 'moment';
import IDRInvestment from './IDRInvestment';
import RateInvestment from './RateInvestment';
import USDInvestment from './USDInvestment';
import InitiativeName from './InitiativeName';
import InitiativeDescription from './InitiativeDesciption';
import BenefitCategory from './BenefitCategory';
import DirectBenefit from './DirectBenefit';
import Attachment from './Attachment';
import IndirectYearlyBasisRecuring from './IndirectYearlyBasisRecuring';
import IndirectBenefit from './IndirectBenefit';
import DirectYearlyBasisRecuring from './DirectYearlyBasisRecuring';
import ActionPlan from './ActionPlan';
import CurrentCondition from './CurrentCondition';
import SchedulePlan from './SchedulePlan';
import Solution from './Solution';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    textTransform: 'uppercase',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    marginTop: theme.spacing(3),
  }
}));

const EstimateView = (props) => {
  const classes = useStyles();
  const { values } = props;
  const { data: rate } = useQuery(RATE_LIST, async () => {
    const { data: response } = await api.masterData.rateList();
    const rateData = response?.data;
    const ytdRate = rateData?.find((d) => moment(d.createdAt).isSame(new Date(), 'year'));
    return ytdRate;
  }, { initialData: {} });

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>estimate investment</Typography>
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <IDRInvestment {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <RateInvestment rate={rate} {...props} />
      </Grid>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <USDInvestment rate={rate} {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InitiativeName {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InitiativeDescription {...props} />
      </Grid>
      { values.category === 'Individual'
        && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CurrentCondition {...props} />
          </Grid>
        )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <BenefitCategory {...props} />
      </Grid>
      { values.category === 'Individual'
        && (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ActionPlan {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <SchedulePlan {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Solution {...props} />
            </Grid>
          </>
        )}
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <DirectBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <DirectYearlyBasisRecuring {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <IndirectBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <IndirectYearlyBasisRecuring {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment {...props} />
      </Grid>
    </Grid>
  );
};

EstimateView.propTypes = {
  values: PropTypes.object.isRequired,
};

export default EstimateView;
