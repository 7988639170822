import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import useStyles from './styles';
import stageName from './helpers';

const ChatList = ({
  category,
  messages,
  metaData,
  user,
  isGettingOldMessage,
  getLastMessageOnScrollTop
}) => {
  const classes = useStyles();
  const container = React.useRef();

  const getStageName = (index = 0) => (category === 'Individual'
    ? stageName?.individual[index] : stageName?.team[index]);

  React.useEffect(() => {
    // Init scroll to bottom
    if (messages?.length > 5 && container.current && !isGettingOldMessage) {
      container.current.scrollTop = container?.current?.scrollHeight;
    }
    const handleScroll = (e) => {
      if (messages?.length >= 30 && e?.srcElement?.scrollTop < 10) {
        getLastMessageOnScrollTop && getLastMessageOnScrollTop();
      }
    };
    container.current.addEventListener('scroll', handleScroll);
    return () => container.current.removeEventListener('scroll', handleScroll);
  }, [messages, container.current]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Chat</Typography>
      <Box className={classes.containerChat} ref={container}>
        {messages?.map((d, i) => {
          const prevDate = moment(messages[i - 1]?.createdAt).format('MMM D, YYYY');
          const currDate = moment(d?.createdAt).format('MMM D, YYYY');
          const currTime = moment(d?.createdAt).format('hh:mm');
          const currStage = d?.data || '';
          const prevStage = messages[i - 1]?.data || '';
          const metas = metaData?.find((meta) => meta.username === d?._sender?.userId);
          const from = `${d?._sender.nickname || ''} ${metas ? ` [${metas?.role}]` : ''}`
          return (
            <React.Fragment key={d?.messageId}>
              { (!!currStage && prevStage !== currStage) && (
                <Box className={classes.stage}>
                  {getStageName(currStage)}
                </Box>
              )}
              { (i === 0 || prevDate !== currDate) && (
                <Box className={classes.dateChat}>
                  {moment(d?.createdAt).format('MMM D, YYYY')}
                </Box>
              )}
              {messages[i - 1]?._sender?.userId !== d?._sender?.userId && (
              <Box
                className={clsx({
                  [classes.chatFromOther]: d?._sender?.userId !== user.username,
                  [classes.chatFromSelf]: d?._sender?.userId === user.username
                })}
              >
                <Typography
                  className={classes.sender}
                >
                  {from}
                </Typography>
              </Box>
            )}
              <Box
                className={clsx(classes.chat, {
                  [classes.chatFromOther]: d?._sender?.userId !== user.username,
                  [classes.chatFromSelf]: d?._sender?.userId === user.username,
                  [classes.chatBoxFromOther]: d?._sender?.userId !== user.username,
                  [classes.chatBoxFromSelf]: d?._sender?.userId === user.username
                })}
              >
                <Typography className={classes.chatMessage}>
                  {d?.message}
                </Typography>
                <Typography className={classes.time}>
                  {currTime}
                </Typography>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

ChatList.propTypes = {
  category: PropTypes.string,
  messages: PropTypes.array,
  metaData: PropTypes.array,
  user: PropTypes.object,
  isGettingOldMessage: PropTypes.bool,
  getLastMessageOnScrollTop: PropTypes.func
};

export default ChatList;
