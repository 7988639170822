import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

export const initialValues = ({
  id,
  init,
  detail = {},
}) => {
  const {
    registrationNumber,
    innovationAreas,
    innovationBenefits = [],
    innovationTypes,
    classifications,
    themeBanks,
    user,
    initiative,
    initiativeDescription,
    requested,
    category,
    innovationStatus,
    company,
    organizationId,
    isCoach,
    innovationTeamMembers,
    isTechnology,
    promotor,
    payment,
    diBenefit,
    diYearlyRecurring,
    inBenefit,
    inYearlyRecurring,
    innovationAttachments,
    registrationApprovals
  } = detail;
  const benefits = id ? _.uniq(innovationBenefits?.map((d) => d.benefitCategoryId)) : [];
  return {
    // general form
    registrationNumber: registrationNumber || '',
    innovationAreaId: innovationAreas?.id || '',
    innovationTypeId: innovationTypes?.id || '',
    classificationId: classifications?.id || '',
    themeBankId: themeBanks?.id || '',
    category: category || '',
    innovationStatus: innovationStatus || '',
    submittedById: id ? user : init,
    requestedFor: requested || {},
    company: company || '',
    organizationId: organizationId || '',
    isCoach: isCoach || '',
    innovationTeamMembers: innovationTeamMembers || [],
    isTechnology: isTechnology || '',
    promotor: promotor || {},
    // estimate investment
    payment: payment || 0,
    initiative: initiative || '',
    initiativeDescription: initiativeDescription || '',
    currentCondition: detail?.currentCondition || '',
    innovationBenefits: benefits,
    actionPlan: detail?.actionPlan || '',
    scheduledPlan: detail?.scheduledPlan
      ? moment(detail?.scheduledPlan).toDate() : moment().toDate(),
    finalResult: detail?.finalResult || '',
    actionDone: detail?.actionDone || '',
    actualTimePlan: detail?.actualTimePlan
      ? moment(detail?.actualTimePlan).toDate() : moment().toDate(),
    solution: detail?.solution || '',
    diBenefit: diBenefit || 0,
    diYearlyRecurring: diYearlyRecurring || '',
    inBenefit: inBenefit || 0,
    inYearlyRecurring: inYearlyRecurring || '',
    innovationAttachments: innovationAttachments || [],
    action: '',
    // Committee assignment
    committees: registrationApprovals?.filter((d) => d.user && d.roles?.name === 'Committee Site')?.map((d) => d.user) || []
  };
};

export const validationIdeaBlast = {
  // general form
  innovationAreaId: Yup.string().required('Innovation Area is required'),
  innovationTypeId: Yup.string().required('Innovation Type is required'),
  classificationId: Yup.string().required('Classification is required'),
  themeBankId: Yup.string().required('Theme Bank is required'),
  category: Yup.string().oneOf(['Individual', 'Project Team'], 'Category is required').required('Category is required'),
  innovationTeamMembers: Yup.array().when('category', {
    is: (category) => category === 'Individual',
    then: Yup.array().min(0).max(3, 'Team member cannot more than 3 person')
  })
    .min(1, 'Team member is required'),
  company: Yup.string().required('Company is required'),
  organizationId: Yup.string().required('Site is required'),
  isCoach: Yup.string().when('category', {
    is: 'Project Team',
    then: Yup.string().required('Choose one of option available')
  }),
  isTechnology: Yup.string().when('category', {
    is: 'Project Team',
    then: Yup.string().required('Choose one of option available')
  }),
  promotor: Yup.object().shape({
    empId: Yup.string().required('Promotor is required')
  }),
  // estimate investment
  payment: Yup.number().required('Estimate Investment is required'),
  initiative: Yup.string().required('Initiative Name is required'),
  currentCondition: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Current Condition is required')
  }),
  innovationBenefits: Yup.array().min(1, 'Benefit Category is required'),
  actionPlan: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Action Plan is required')
  }),
  scheduledPlan: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Schedule Plan is required')
  }),
  solution: Yup.string().when('category', {
    is: 'Individual',
    then: Yup.string().required('Solution is required')
  }),
  diBenefit: Yup.number().required('Estimate Direct Benefit is required'),
  diYearlyRecurring: Yup.string().required('Choose one of option available'),
  inBenefit: Yup.number().required('Estimate Indirect Benefit is required'),
  inYearlyRecurring: Yup.string().required('Choose one of option available'),
  innovationAttachments: Yup.array().min(1, 'Attachment is required')
};

export const validationImplementation = {
  finalResult: Yup.string().required('Current Condition is required'),
  actionDone: Yup.string().required('Action Plan is required'),
  actualTimePlan: Yup.string().required('Schedule Plan is required'),
  solution: Yup.string().required('Solution is required'),
};
