import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowDropDown,
  DescriptionTwoTone
} from '@material-ui/icons';

import Page from 'src/components/Page';
import DataTable from 'src/components/DataTable';
import Toolbar from 'src/views/myDocument/Toolbar';

import api from 'src/api';
import {
  INNOVATION_INVOLVEMENTS_ROLES,
  ORGANIZATION_LIST,
  YEAR_BUILT
} from 'src/api/backendUrl';

import Filter from './Filter';
import DetailProjectDialog from './DetailProjectDialog';
import ProjectDataChart from './ProjectDataChart';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  },
  documentTitle: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  }
}));

const entries = [10, 20, 50, 100, 200];

const InnovationInvolvement = () => {
  const classes = useStyles();

  const innovationInvolvementFilter = useStoreState((state) => state.innovationInvolvement.filter);
  const innovationInvolvementCurrentPage = useStoreState(
    (state) => state.innovationInvolvement.currentPage
  );
  const setInnovationInvolvementFilter = useStoreActions(
    (action) => action.innovationInvolvement.setFilter
  );
  const setInnovationInvolvementCurrentPage = useStoreActions(
    (action) => action.innovationInvolvement.setCurrentPage
  );

  const [isFilterShown, setIsFilterShown] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    yearOptions: [],
    siteOptions: [],
    roleOptions: [],
  });
  const [filter, setFilter] = useState({
    year: [],
    site: [],
    role: [],
  });
  const [filterParams, setFilterParams] = useState('');
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(innovationInvolvementCurrentPage);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [chartData, setChartData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const currentYear = new Date().getFullYear();

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.yearBuilt(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_LIST, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.masterData.organizationlist(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const { data: roles } = useQuery(
    [INNOVATION_INVOLVEMENTS_ROLES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [, query] = queryKey;
      const response = await api.innovationInvolvement.roles(query);

      return response.data.data;
    }, { initialData: [] }
  );

  const fetchInnovatioInvolvementChart = async () => {
    setIsFetching(true);

    const query = {
      Filters: filterParams
    };

    const { data: response } = await api.innovationInvolvement.chart({
      ...query
    });

    setChartData(response?.data.memberProjects || []);

    setIsFetching(false);
  };

  const fetchInnovatioInvolvementList = async () => {
    setIsFetching(true);

    const query = {
      Filters: `${filterParams}${search ? `,search==${search}` : ''}`,
      page: currentPage,
      pageSize
    };

    const { data: response } = await api.innovationInvolvement.list({
      ...query
    });

    const data = response?.data || {};

    setTotalData(data?.totalData || 0);
    setTotalPage(data?.totalPage || 0);
    setShowing({
      show: data?.totalData === 0
        ? data.totalData
        : showing.show,
      to: data?.totalData <= pageSize
        ? data.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData(data?.data || []);
  };

  const createParamValue = (value) => value.join(';');

  const generateParams = () => new Promise((resolve, reject) => {
    const paramValues = [];
    let paramName = '';

    Object.keys(filter).forEach((key) => {
      if (Array.isArray(filter[key]) && filter[key].length) {
        switch (key) {
          case 'site': {
            paramName = 'Site';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'year': {
            paramName = 'Year';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          case 'role': {
            paramName = 'Role';
            paramValues.push(`${paramName}==${createParamValue(filter[key])}`);
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    resolve(paramValues);
    reject();
  });

  useEffect(() => {
    if (innovationInvolvementFilter) {
      if (!innovationInvolvementFilter.year.length) {
        setFilter({ ...innovationInvolvementFilter, year: [currentYear.toString()] });
      } else {
        setFilter(innovationInvolvementFilter);
      }
    } else {
      setFilter((prevState) => ({ ...prevState, year: [currentYear.toString()] }));
    }
  }, []);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      yearOptions: years,
      siteOptions: sites,
      roleOptions: roles,
    });
  }, [years, sites, roles]);

  useEffect(() => {
    const setParams = async () => {
      const paramData = await generateParams();
      setCurrentPage(1);
      setFilterParams(paramData.join(','));
    };

    setInnovationInvolvementFilter(filter);

    setParams();
  }, [filter]);

  useEffect(() => {
    fetchInnovatioInvolvementChart();
  }, [filterParams]);

  useEffect(() => {
    setCurrentPage(1);

    fetchInnovatioInvolvementList();
  }, [search]);

  useEffect(() => {
    setInnovationInvolvementCurrentPage(currentPage);

    fetchInnovatioInvolvementList();
  }, [filterParams, pageSize, currentPage]);

  const roleHeaders = [
    { key: 'productOwner', label: 'Product Owner (PO)', filterLabel: 'Product Owner' },
    { key: 'sponsor', label: 'Sponsor', filterLabel: 'Sponsor' },
    { key: 'scrumMaster', label: 'Scrum Master (SM)', filterLabel: 'Scrum Master' },
    { key: 'teamMember', label: 'Team Member', filterLabel: 'Team Member' },
    { key: 'techLead', label: 'Technology Lead', filterLabel: 'Technology Lead' },
  ];

  const filterRoles = (roleList) => (!filter.role.length
    ? roleList
    : roleList.filter((role) => filter.role.includes(role.filterLabel)));

  const tableHeaders = [
    { key: 'action', label: '' },
    { key: 'year', label: 'Year' },
    { key: 'name', label: 'Name' },
    { key: 'site', label: 'Site' },
    ...filterRoles(roleHeaders),
    { key: 'ongoing', label: 'On Going Project' },
    { key: 'completed', label: 'Completed Project' },
  ];
  const unmodifiedKeys = ['action', 'year', 'name', 'site'];

  const handleOpenDocument = (data) => {
    setIsModalOpen(true);
    setSelectedUser(data);
  };

  return (
    <Page
      title="Innovation Involvement"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Innovation Involvement" />
        <Box className={classes.card} my={5}>
          <Grid container spacing={4}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography
                  className={classes.titleFilter}
                  onClick={() => setIsFilterShown(!isFilterShown)}
                >
                  Filter
                </Typography>
                {
                  isFilterShown
                    ? (
                      <ArrowDropDown
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(false)}
                      />
                    )
                    : (
                      <ArrowLeft
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setIsFilterShown(true)}
                      />
                    )
                }
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              {
                isFetching && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignSelf="end"
                      alignItems="center"
                      marginRight={1}
                      height="100%"
                    >
                      <CircularProgress size={24} style={{ alignSelf: 'center' }} />
                    </Box>
                  </Box>
                )
              }
            </Grid>
          </Grid>
          {
            isFilterShown && (
              <Box paddingX={5}>
                <Filter
                  {...{
                    ...filterOptions,
                    values: { ...filter },
                    setFilter
                  }}
                />
              </Box>
            )
          }
        </Box>
        <Box className={classes.card} my={5}>
          <ProjectDataChart
            series={[{
              data: chartData.map((item) => item.total),
            }]}
            labels={chartData.map((item) => item.name)}
          />
        </Box>
        <DataTable
          isCustom
          isVisible
          tableHeader={tableHeaders}
          tableData={documentData}
          leftComp={<></>}
          actionSlot={(data) => (
            <Grid container>
              <IconButton
                onClick={() => handleOpenDocument(data)}
                style={{ padding: 0, marginRight: 10 }}
              >
                <Tooltip title="Open" placement="top" arrow>
                  <DescriptionTwoTone style={{ color: '#737AF1' }} />
                </Tooltip>
              </IconButton>
            </Grid>
          )}
          {...{
            entries,
            showEntries,
            setShowEntries,
            pageSize,
            setPageSize,
            setSearch,
            totalData,
            currentPage,
            setCurrentPage,
            totalPage
          }}
        >
          {({ key, data }) => {
            return (
              <Typography>
                {unmodifiedKeys.includes(key) ? data[key] : `${data[key]} Project`}
              </Typography>
            );
          }}
        </DataTable>
      </Container>

      <DetailProjectDialog
        detail={selectedUser}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </Page>
  );
};

export default InnovationInvolvement;
