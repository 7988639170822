import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { useQuery, useMutation } from 'react-query';
import { useStoreActions } from 'easy-peasy';
import SwipeableViews from 'react-swipeable-views';

import api from 'src/api';

import Page from 'src/components/Page';
import TabMenu from 'src/components/TabMenu';
import TeamMember from 'src/views/registration/TeamMember';

import Toolbar from './Toolbar';
import TabPanel from './Tabpanel';
import HistoryApprovalView from './HistoryApprovalView';
import { getMenus, getOtherMenus } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    },
    paddingRight: theme.spacing(1),
  },
}));

const Registration = () => {
  const { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const [tab, setTab] = useState(0);
  const [otherTab, setOtherTab] = useState(1);
  const [isManageAccessOpen, setIsManageAccessOpen] = useState(false);
  const { data: detail, refetch } = useQuery(['detail-registration', id], async () => {
    setLoading(true);

    const { data } = await api.registration.view(id);
    const { data: workFlowData } = await api.workFlow.get(id);

    setLoading(false);

    return {
      ...data,
      ...workFlowData
    };
  }, {
    enabled: !!id,
    initialData: {}
  });
  const redirectG = detail?.innovationGateStatuses?.stages?.name?.charAt(1);

  const option = (current, opt) => {
    return {
      enabled: !!id
        && (Array.isArray(current)
          ? current.some((item) => item === tab)
          : current === tab)
        && (opt ? opt?.enabledOpt : true),
      initialData: {},
      onSettled: (data) => {
        const isError = data?.status > 300;
        if (tab === current && isError) {
          setNotif({
            message: data?.message?.messageEng || 'Failed get data from server',
            option: {
              variant: 'error'
            }
          });
        }
      },
      ...opt
    };
  };

  const { data: registration } = useQuery(['detail-registration', id], async () => {
    const { data } = await api.registration.view(id);
    const { data: workFlowData } = await api.workFlow.get(id);

    return {
      ...data,
      ...workFlowData
    };
  }, option(0));

  const { data: registrationApproval } = useQuery(['registration-approval', id], async () => {
    const { data } = await api.registration.approval(id);
    return data;
  }, option(0, {
    initialData: []
  }));

  const { data: implementation } = useQuery(['detail-implementation', id], async () => {
    const { data } = await api.implementation.view(id);
    return data;
  }, option(1, {
    enabledOpt: registration?.category === 'Individual' && tab <= Number(redirectG || 0)
  }));

  const { data: implementationApproval } = useQuery(['implementation-approval', id], async () => {
    const { data } = await api.implementation.approval(id);
    return data;
  }, option(1, {
    enabledOpt: registration?.category === 'Individual' && tab <= Number(redirectG || 0),
    initialData: []
  }));

  const { data: logHistory } = useQuery(['log-history', id], async () => {
    const { data } = await api.logHistory.list(id, { sorts: 'updatedAt' });
    return data;
  }, option([0, 1, 2, 3, 5], {
    initialData: []
  }));

  const { data: requirement } = useQuery(['detail-requirement', id], async () => {
    const { data } = await api.requirement.view(id);
    return data;
  }, option([1, 2, 3], {
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: requirementApproval } = useQuery(['requirement-approval', id], async () => {
    const { data } = await api.requirement.approval(id);
    return data;
  }, option(1, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));
  const { data: study } = useQuery(['detail-study', id], async () => {
    const { data } = await api.study.view(id);
    return data;
  }, option([2, 3], {
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: studyApproval } = useQuery(['study-approval', id], async () => {
    const { data } = await api.study.approval(id);
    return data;
  }, option(2, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: prototype } = useQuery(['detail-prototype', id], async () => {
    const { data } = await api.prototype.view(id);
    return data;
  }, option([3, 4, 5], {
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: prototypeApproval } = useQuery(['prototype-approval', id], async () => {
    const { data } = await api.prototype.approval(id);
    return data;
  }, option(3, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: g4implementation } = useQuery(['detail-g4implement', id], async () => {
    const { data } = await api.g4implementation.view(id);
    return data;
  }, option(4, {
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: g4implementationApproval } = useQuery(['g4implement-approval', id], async () => {
    const { data } = await api.g4implementation.approval(id);
    return data;
  }, option(4, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: scaleUp } = useQuery(['detail-scale-up', id], async () => {
    const { data } = await api.scaleUp.view(id);
    return data;
  }, option(5, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: scaleUpApproval } = useQuery(['scale-up-approval', id], async () => {
    const { data } = await api.scaleUp.approval(id);
    return data;
  }, option(5, {
    initialData: [],
    enabledOpt: registration?.category !== 'Individual' && tab <= Number(redirectG || 0),
  }));

  const { data: benefitCategory } = useQuery(['benefit-category', id], async () => {
    const { data } = await api.benefitCategory.list(id);
    return data;
  }, option([3, 4, 5, 6], { initialData: [] }));

  const {
    data: accessPermissions,
    refetch: accessPermissionsRefetch,
  } = useQuery(['innovation-involvements', id], async () => {
    const { data } = await api.innovationInvolvement.permissions(id);
    return data.data;
  }, option([0, 1, 2, 3, 4, 5, 6, 7], { initialData: [] }));

  const benefitCategoryApproval = [];

  const { remove, data: impactTracking } = useQuery(['impact-tracking', id], async () => {
    const { data } = await api.impactTracking.view(id);
    return data
      ? data.data
      : {};
  }, option([1, 2, 3, 4, 5, 6, 7], {
    initialData: {},
    enabledOpt: registration?.category !== 'Individual'
  }));

  const saveTeamPermission = useMutation(
    (form) => api.innovationInvolvement.putPermissions(form)
  );

  const handleChangeTab = (event, newValue) => { setTab(newValue); };
  const handleChangeOtherTab = (event, newValue) => { setOtherTab(newValue); };
  const handleManualChangeTab = (newValue) => { setTab(newValue); };
  const handleAccessPermissionClick = () => { setIsManageAccessOpen(true); };
  const handleSaveAccessPermission = (data) => { saveTeamPermission.mutate(data); };

  useEffect(() => {
    setTab(Number(redirectG || 0));
  }, [redirectG]);

  useEffect(() => {
    if (id) {
      remove();
      refetch();
    }
  }, [tab, id]);

  useEffect(() => {
    switch (true) {
      case saveTeamPermission.isSuccess: {
        setIsManageAccessOpen(false);
        accessPermissionsRefetch();

        setNotif({
          message: 'Permission Access changed successfully',
          option: {
            variant: 'success'
          }
        });
        break;
      }
      case saveTeamPermission.isError: {
        setIsManageAccessOpen(false);

        setNotif({
          message: saveTeamPermission?.data?.message?.messageEng || 'Permission Access failed to change',
          option: {
            variant: 'error'
          }
        });
        break;
      }
      default:
        break;
    }
  }, [
    saveTeamPermission.isSuccess,
    saveTeamPermission.isError
  ]);

  const menus = getMenus({
    registration,
    registrationApproval,
    implementation,
    implementationApproval,
    requirement,
    requirementApproval,
    study,
    studyApproval,
    prototype,
    prototypeApproval,
    scaleUp,
    scaleUpApproval,
    benefitCategory,
    impactTracking,
    g4implementation,
    g4implementationApproval,
    currentGate: Number(redirectG || 0),
  },
  handleManualChangeTab,
  handleAccessPermissionClick);

  const otherMenus = getOtherMenus({
    registration,
    logHistory
  });

  const historyApproval = [
    registrationApproval?.data,
    requirementApproval?.data,
    studyApproval?.data,
    prototypeApproval?.data,
    g4implementationApproval?.data,
    scaleUpApproval?.data,
    benefitCategoryApproval?.data
  ];

  const ideaBlastApproval = [
    registrationApproval?.data,
    implementationApproval?.data
  ];

  const isApprovalIdeaBlast = registration?.category === 'Individual';

  const isHistoryApprovalShown = () => {
    if (isApprovalIdeaBlast || (historyApproval[tab] && historyApproval[tab].length)) {
      // `registration tab` is `0`, so we can't use falsy
      return tab === 0
        ? menus[tab].component?.props?.detail?.innovationStatus !== 'Draft'
        : menus[tab].component?.props?.detail?.gateStatus !== 'Draft';
    }

    return false;
  };

  return (
    <Page
      className={classes.root}
      title={menus[tab]?.pathName}
    >
      <Container maxWidth={false}>
        <Toolbar path={menus[tab]?.pathName} detail={detail} />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item lg={9} md={12}>
              <TabMenu tab={tab} setTab={handleChangeTab} menus={menus} />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeTab}
              >
                {menus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={tab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <TabMenu tab={otherTab} setTab={handleChangeOtherTab} menus={otherMenus} bright />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={otherTab}
                onChangeIndex={handleChangeOtherTab}
              >
                {otherMenus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={otherTab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component}
                  </TabPanel>
                ))}
              </SwipeableViews>
              {
                isHistoryApprovalShown() && (
                  <HistoryApprovalView
                    data={isApprovalIdeaBlast
                      ? ideaBlastApproval[tab]
                      : historyApproval[tab]}
                  />
                )
              }
            </Grid>
          </Grid>
          <TeamMember
            isDialogOpen={isManageAccessOpen}
            isSubmitting={saveTeamPermission.isLoading}
            handleClose={() => setIsManageAccessOpen(false)}
            handleConfirm={handleSaveAccessPermission}
            data={accessPermissions}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Registration;
