import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledInputAutoSize from 'src/components/StyledInputAutoSize';

const Comment = ({
  item,
  index,
  touched,
  errors,
  getIn,
  handleBlur,
  handleChange
}) => {
  const key = 'comment';
  const name = `benefits[${index}].${key}`;
  const touch = getIn(touched, name);
  const error = getIn(errors, name);

  return (
    <FormItem
      id={name}
      label=""
      error={touch && error ? (touch && error) : undefined}
      helperText={touch && error}
    >
      <StyledInputAutoSize
        name={name}
        rowsMin={4}
        type="text"
        placeholder="Enter comment"
        value={item[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touch && error)}
      />
    </FormItem>
  );
};

Comment.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getIn: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Comment;
