import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { useMutation, useQuery } from 'react-query';
import {
  useParams,
  useNavigate,
  Prompt
} from 'react-router-dom';
import { upperFirst, isEmpty, startCase } from 'lodash';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { Form, Formik, FieldArray } from 'formik';
import { useStoreState, useStoreActions } from 'easy-peasy';

import api from 'src/api';

import tableStyle from 'src/views/innovation/InnovationView/style';
import GeneralView from './GeneralView';
import BenefitView from './BenefitView';
import Decision from './Decision';
import { initialValues, validation } from './Config';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  head: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '10px',
    padding: '0 10px 0 10px'
  }
}));

const StyledCell = withStyles(() => ({
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 120
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  }
}))(TableCell);

const StyledRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const BenefitCategoryView = ({
  detail,
  handleAccessPermissionClick
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const user = useStoreState((state) => state.auth.user);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [isEdit, setIsEdit] = useState(false);
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });

  const { data: benefitCategories } = useQuery(['benefit-category', id], async () => {
    const { data } = await api.benefitCategory.list(id);
    return data;
  }, { initialData: detail?.benefitCategories });

  detail.benefitCategories = benefitCategories.length
    ? benefitCategories
    : detail?.benefitCategories;

  // Call Mutation (POST, PUT)
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
          return;
        }

        setPromptAction({ needed: false, action: actionMessage });
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const submit = useMutation((form) => api.benefitCategory.submit(form), mutateOption('Submit'));
  const verify = useMutation((form) => api.benefitCategory.verify(id, form), mutateOption('Verification'));
  const register = useMutation(() => api.benefitCategory.register(id), mutateOption('Registration'));
  const approve = useMutation(() => api.benefitCategory.approve(id), mutateOption('Approve'));
  const saveAsDraft = useMutation((form) => api.benefitCategory.submit(form), mutateOption('Save As Draft'));

  const generateForm = (data) => {
    switch (data.action) {
      case 'submit':
      case 'edit':
      case 'save as draft': {
        return {
          action: data.action === 'save as draft'
            ? startCase(data.action)
            : upperFirst(data.action),
          benefits: data.benefits.map((item) => ({
            id: item.id,
            description: item.description,
            verifiedBy: item.verifiedBy,
            department: item.department,
            comment: item.comment,
            amount: item.amount
          }))
        };
      }
      case 'verify': {
        const verifyData = data.benefits[data.rowIndex];
        return {
          id: verifyData.id,
          comment: verifyData.comment,
          verifiedBy: user.id
        };
      }
      default:
        return {};
    }
  };

  const handleSaveAsDraft = (data) => {
    saveAsDraft.mutate(generateForm(data));
  };

  const classes = useStyles();
  const tableClasses = tableStyle();

  const isSubmitter = detail?.registration?.userPermissions?.some((item) => item.id === user?.id);
  const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
  const isAdminSite = user?.roles.find((d) => d.name === 'Admin Site');
  const isStatusDraft = detail?.registration.benefitStatus === 'Draft';
  const isStatusCanEdit = (detail?.registration?.benefitStatus
    && detail?.registration.benefitStatus !== 'Draft'
    && detail?.registration.benefitStatus !== 'Completed');
  const isWaitingAdminApproval = detail?.registration.benefitStatus === 'Waiting Admin Approval';
  const isCanValidateOnMount = !isEmpty(detail) && detail?.registration?.benefitStatus === 'Draft';

  const canRegisterITMNovation = detail?.registration.canRegisterITMNovation;
  const canApprove = user.roles?.some((role) => role.name === 'Admin Site')
  && user.cluster?.some((org) => org.name === detail?.registration.organizations.name);
  const canManageAccess = Boolean(id && (isAdmin || isAdminSite));

  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={initialValues({ ...detail, isEdit })}
      initialErrors={validation(user.id)}
      isInitialValid={() => validation(user.id).isValidSync(initialValues({ ...detail, isEdit }))}
      validationSchema={validation(user.id)}
      onSubmit={(value, actions) => {
        const { action } = value;
        actions.setSubmitting(true);

        switch (action) {
          case 'submit':
          case 'edit':
            submit.mutate(generateForm(value));
            break;
          case 'register':
            register.mutate();
            break;
          case 'approve':
            approve.mutate();
            break;
          case 'verify':
            verify.mutate(generateForm(value));
            break;
          default:
            break;
        }

        actions.setSubmitting(false);
      }}
    >
      {({
        initialValues: initialData,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        submitForm,
        values,
        isSubmitting,
        isValid
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <GeneralView
                disabled
                {...{
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  values
                }}
              />
            </Box>
            <Box>
              <Grid container>
                <TableContainer component={Paper} className={tableClasses.tableContainerMd}>
                  <Table aria-label="simple-table" className={tableClasses.table}>
                    <TableHead className={tableClasses.header}>
                      <StyledRow>
                        <StyledCell>No</StyledCell>
                        <StyledCell>Benefit Type</StyledCell>
                        <StyledCell>Amount (USD)</StyledCell>
                        <StyledCell>Description</StyledCell>
                        <StyledCell>Attachment</StyledCell>
                        <StyledCell>Verified By</StyledCell>
                        <StyledCell>Department</StyledCell>
                        <StyledCell>Comment</StyledCell>
                        <StyledCell>Action</StyledCell>
                      </StyledRow>
                    </TableHead>
                    <TableBody className={tableClasses.header}>
                      <FieldArray
                        name="benefits"
                        component={BenefitView}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
            <Box my={5}>
              <Decision
                canSaveDraft={isSubmitter && isStatusDraft}
                canSubmit={isSubmitter && !isSubmitting && isValid}
                canEdit={isStatusCanEdit}
                canApprove={canApprove && isWaitingAdminApproval}
                {...{
                  isSubmitter,
                  isStatusDraft,
                  canRegisterITMNovation,
                  canManageAccess,
                  isEdit,
                  setFieldValue,
                  submitForm,
                  setIsEdit,
                  handleSaveAsDraft,
                  handleAccessPermissionClick,
                  setPromptAction,
                  values
                }}
              />
            </Box>
            <Prompt
              when={promptAction.needed
                && (isSubmitter || isStatusCanEdit)
                && !deepEqual(initialData, values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

BenefitCategoryView.propTypes = {
  detail: PropTypes.object,
  handleAccessPermissionClick: PropTypes.func
};

export default BenefitCategoryView;
