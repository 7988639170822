import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import StyledInput from 'src/components/StyledInput';

// eslint-disable-next-line import/named
import { validation } from './config';

const PopUp = ({
  isEdit,
  isDialogOpen,
  data,
  handleFormSubmit,
  handleClose
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const years = useMemo(() => [{ id: data.year, name: data.year }], [data]);
  const levels = useMemo(() => [{ id: data.level, name: data.level }], [data]);

  return (
    <Dialog
      fullWidth
      aria-labelledby="assessment-dialog"
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle id="assessment-dialog">
        <Typography>
          {
            isEdit
              ? 'Edit'
              : 'New'
          }
          {' '}
          Assessment Question
        </Typography>
      </DialogTitle>
      <Formik
        validateOnChange
        initialValues={data}
        isInitialValid={() => validation.isValidSync(data)}
        validationSchema={validation}
        onSubmit={handleFormSubmit}
      >
        {({
          isSubmitting,
          isValid,
          errors,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormItem
                      key="year"
                      label="Year"
                      error={Boolean(touched.year && errors.year)}
                      helperText={touched.year && errors.year}
                    >
                      <StyledSelect
                        disabled
                        name="year"
                        placeholder="Select Year"
                        value={values.year}
                        options={years}
                        error={Boolean(touched.year && errors.year)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormItem
                      key="level"
                      label="Level"
                      error={Boolean(touched.level && errors.level)}
                      helperText={touched.level && errors.level}
                    >
                      <StyledSelect
                        disabled
                        name="level"
                        placeholder="Select Level"
                        value={values.level}
                        options={levels}
                        error={Boolean(touched.level && errors.level)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormItem
                      key="descriptionEn"
                      label="Description (EN)"
                      error={Boolean(touched.descriptionEn && errors.descriptionEn)}
                      helperText={touched.descriptionEn && errors.descriptionEn}
                    >
                      <StyledInput
                        multiline
                        name="descriptionEn"
                        type="text"
                        placeholder="Enter Description in English"
                        maxrows={10}
                        value={values.descriptionEn}
                        error={Boolean(touched.descriptionEn && errors.descriptionEn)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormItem
                      key="descriptionId"
                      label="Description (ID)"
                      error={Boolean(touched.descriptionId && errors.descriptionId)}
                      helperText={touched.descriptionId && errors.descriptionId}
                    >
                      <StyledInput
                        multiline
                        name="descriptionId"
                        type="text"
                        placeholder="Enter Description in Indonesian"
                        maxrows={10}
                        value={values.descriptionId}
                        error={Boolean(touched.descriptionId && errors.descriptionId)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormItem
                      key="weight"
                      label="Weight"
                      error={Boolean(touched.weight && errors.weight)}
                      helperText={touched.weight && errors.weight}
                    >
                      <StyledInput
                        name="weight"
                        type="Number"
                        placeholder="Enter Weight"
                        value={values.weight}
                        error={Boolean(touched.weight && errors.weight)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormItem>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!isValid || isSubmitting}
                  onClick={submitForm}
                >
                  {
                    isSubmitting && (
                      <CircularProgress
                        size={12}
                        style={{ marginRight: 5 }}
                      />
                    )
                  }
                  {
                    isEdit
                      ? 'Save'
                      : 'Add'
                  }
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

PopUp.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    year: PropTypes.string,
    level: PropTypes.string,
    descriptionEn: PropTypes.string,
    descriptionId: PropTypes.string,
    weight: PropTypes.number
  }).isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default PopUp;
