import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';
import { IMPLEMENTATION_BENEFIT_CATEGORIES } from 'src/api/backendUrl';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from 'src/api';

const BenefitCategory = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
}) => {
  const key = 'innovationBenefits';
  const { id } = useParams();
  const { data } = useQuery(IMPLEMENTATION_BENEFIT_CATEGORIES, async () => {
    const { data: response } = await api.implementation.benefitCategories(id, { Sorts: 'name' });
    return response;
  }, { initialData: [], enabled: !!id });
  return (
    <FormItem
      label="Benefit Category"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledMultiSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        handleDelete={() => {}}
        value={(data && data.length)
          ? data.map((d) => d?.id)
          : []}
        placeholder="Select Category"
        options={data || []}
      />
    </FormItem>
  );
};

BenefitCategory.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default BenefitCategory;
