import React from 'react';
import {
  Container,
  makeStyles,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useMutation, useQuery } from 'react-query';
import api from 'src/api';
import Page from 'src/components/Page';
import Toolbar from 'src/components/Toolbar';
import {
  Delete, Edit, MeetingRoom
} from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ORGANIZATION_LIST } from 'src/api/backendUrl';
import header from './header';
import NumberFormatCustom from 'src/components/NumberFormat';
import StyledInput from 'src/components/StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    width: '100%',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const IdeaBlastScore = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLoading = useStoreState((states) => states.loading.isLoading);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [idToDelete, setDeleteId] = React.useState();
  const [open, setOpen] = React.useState(false);
  let editId, editRegisterRewards;

  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setDeleteId();
    setOpen(false);
  };

  const site = useQuery(ORGANIZATION_LIST, async () => {
    const { data: response } = await api.setting.ideaBlast.score.siteList();
    return response?.data;
  }, { initialData: [] });

  const mutateOption = (actionMessage) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
        site.refetch();
        handleClose();
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const resetScore = useMutation(() => api.setting.ideaBlast.score.delete(idToDelete), mutateOption('Reset score'));
  const updateRegister = useMutation((param) => api.setting.ideaBlast.score.editRegister({
    organizationId: param.id,
    value: param.value
  }), mutateOption('Update register rewards'));

  const handleResetScore = () => resetScore.mutate();
  const handleBlur = () => {
    if (editId) {
      updateRegister.mutate({ value: parseInt(editRegisterRewards), id: editId });
    }
  };
  const handleChange = (value, id) => {
    editId = id;
    editRegisterRewards = value;
  };

  return (
    <Page
      className={classes.root}
      title="Idea Blast Score"
    >
      <Container maxWidth={false}>
        <Toolbar path="Idea Blast Score" />
        <Grid container item style={{ marginTop: 20 }}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {header?.map((row, index) => {
                    return (
                      <StyledTableCell
                        key={row?.field}
                        isFirst={index === 0}
                        isLast={index === (header.length - 1)}
                      >
                        <Box
                          className={classes.sortContainer}
                        >
                          {row.field}
                        </Box>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.header}>
                {site?.data && site?.data?.map((row) => {
                  return (
                    <StyledTableRow key={row?.id} style={{ marginBottom: '5px' }}>
                      <StyledTableCell
                        align="left"
                        style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
                      >
                        {row?.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                      >
                        <StyledInput
                          name="registerReward"
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                          onBlur={handleBlur}
                          onChange={(e) => handleChange(e.target.value, row?.id)}
                          value={row?.value}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={
                                () => navigate(`/app/setting/idea-blast/score/${row?.id}`, {
                                  state: row
                                })
                              }
                            >
                              <Tooltip title="Detail Score" placement="top" arrow>
                                <MeetingRoom />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={
                                () => navigate(`/app/setting/idea-blast/score/${row?.id}/edit`, {
                                  state: row
                                })
                              }
                            >
                              <Tooltip title="Add/Edit Score" placement="top" arrow>
                                <Edit />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() => handleOpen(row?.id)}
                            >
                              <Tooltip title="Reset Score" placement="top" arrow>
                                <Delete />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset score ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleResetScore}
            disabled={isLoading}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            Ok
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default IdeaBlastScore;
