import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { upperFirst } from 'lodash';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStoreActions } from 'easy-peasy';
import { Formik, Form } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import api from 'src/api';
import Number from './Number';
import Demand from './Demand';
import BenefitCost from './BenefitCost';
import ReportDate from './ReportDate';
import Value from './Value';
import Purpose from './Purpose';
import Frequency from './Frequency';
import MetricType from './MetricType';
import StageGate from './StageGate';
import Decision from './Decision';
import { initialValues, validation } from './Config';
import Type from './Type';
import Remark from './Remark';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  }
}));

const ModalForm = ({
  isVisible,
  detail,
  isOpen,
  setIsOpen,
  setSucceed
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setNotif = useStoreActions((action) => action.notif.setNotif);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const { action, gate } = detail;

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setIsLoading(true); },
    onSettled: (data) => {
      setIsLoading(false);
      if (data && data.status < 300) {
        if (data?.data?.status === 20001) {
          setNotif({
            message: data?.data?.message || 'There is different assignment composition of each Committee. Please check and confirm among Committee',
            option: {
              variant: 'warning'
            }
          });
        } else {
          if (onSuccess) {
            data.status < 300 && onSuccess(data?.data);
          } else if (data.status < 300 && id) {
            if (location.key === 'default') {
              navigate('/app/innovation-view');
            } else {
              navigate(-1);
            }
          } else {
            navigate('/app/innovation-view');
          }
          setNotif({
            message: `${actionMessage} success`,
            option: {
              variant: 'success'
            }
          });
        }
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const generateForm = (data) => {
    switch (data.action) {
      case 'submit':
      case 'edit':
        return {
          ...(data.action === 'edit' && { id: data.id }),
          registrationId: id,
          benefitCostTypeId: data.benefitCost,
          purposeId: data.purpose,
          frequencyId: data.frequency,
          metricTypeId: data.metricType,
          stageGateId: data.stageGate,
          documentNumber: data.number,
          reportDate: moment(data.reportDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          value: data.value,
          remark: data.remark
        };
      default:
        return {};
    }
  };

  const changeFieldValue = ({
    oneTimeBenefit,
    oneTimeCosts,
    recurringBenefit,
    recurringCosts,
    fundOpex,
    fundCapex,
  }) => {
    const { setFieldValue, setFieldTouched } = detail;

    setFieldValue(`${gate}OnetimeBenefit`, oneTimeBenefit, false);
    setFieldTouched(`${gate}OnetimeBenefit`, true, false);

    setFieldValue(`${gate}OnetimeCost`, oneTimeCosts, false);
    setFieldTouched(`${gate}OnetimeCost`, true, false);

    setFieldValue(`${gate}RecurBenefit`, recurringBenefit, false);
    setFieldTouched(`${gate}RecurBenefit`, true, false);

    setFieldValue(`${gate}RecurCost`, recurringCosts, false);
    setFieldTouched(`${gate}RecurCost`, true, false);

    if (detail?.capexKey) {
      setFieldValue(`${detail.capexKey}`, fundCapex, false);
      setFieldTouched(`${detail.capexKey}`, true, false);
    }
    if (detail?.opexKey) {
      setFieldValue(`${detail.opexKey}`, fundOpex, false);
      setFieldTouched(`${detail.opexKey}`, true, false);
    }
  };

  const submit = useMutation(
    (form) => api.benefitCost.submit(form),
    mutateOption('Submit', (data) => {
      changeFieldValue(data);
      setIsOpen(false);
      setSucceed(true);
    })
  );

  const edit = useMutation(
    (form) => {
      const dataId = form.id;
      delete form.id;

      return api.benefitCost.edit(dataId, form);
    },
    mutateOption('Edit', (data) => {
      changeFieldValue(data);
      setIsOpen(false);
      setSucceed(true);
    })
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography variant="h3" className={classes.title}>
              { upperFirst(action) }
              {' '}
              Benefits and Costs
            </Typography>
            <IconButton style={{ marginLeft: 'auto' }} onClick={() => setIsOpen(false)}>
              <Close className={classes.close} />
            </IconButton>
          </Box>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          <Formik
            initialValues={initialValues(detail)}
            initialErrors={Yup.object().shape(validation(detail))}
            validationSchema={Yup.object().shape(validation(detail))}
            onSubmit={(values, actions) => {
              const { action } = values;
              actions.setSubmitting(true);

              switch (action) {
                case 'submit': {
                  submit.mutate(generateForm(values));
                  break;
                }
                case 'edit': {
                  edit.mutate(generateForm(values));
                  break;
                }
                default:
                  break;
              }

              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              isValid,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              submitForm,
              values
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={6}>
                      <Type
                        disabled={false}
                        validation={Yup.object().shape(validation(detail)).fields}
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <StageGate
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Number
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Demand
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <BenefitCost
                        type={`${values.type}`}
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Purpose
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Remark
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <MetricType
                        typeId={values.type}
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Frequency
                        typeId={values.type}
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Value
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <ReportDate
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          setFieldValue,
                          setFieldTouched,
                          values
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Decision
                      canSubmit={!isSubmitting && isValid}
                      {...{
                        isVisible,
                        isLoading,
                        setIsOpen,
                        setFieldValue,
                        submitForm,
                        values
                      }}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ModalForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSucceed: PropTypes.func.isRequired
};

export default ModalForm;
