import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, IconButton, makeStyles, Tooltip, Typography
} from '@material-ui/core';
import { MeetingRoom } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    padding: theme.spacing(1)
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
}));

const CommitteeAssesmentListView = ({
  detail,
  values,
  handleViewAssessment,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Committee Assesment Result</Typography>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Typography>Committee</Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography>Status</Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography>Point</Typography>
        </Grid>
      </Grid>
      {values?.map((d) => {
        return (
          <Grid key={d?.user?.fullname} container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={5} md={5} sm={5} xs={5}>
              <Typography>{d?.user?.fullname}</Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}>
              <IconButton onClick={() => handleViewAssessment(d?.user?.id)} size="small">
                <Tooltip title="Detail" placement="top" arrow>
                  <MeetingRoom />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Typography>{d?.assessmentStatus || '-'}</Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Typography>{d?.totalScore || 0}</Typography>
            </Grid>
          </Grid>
        );
      })}
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={7} md={7} sm={false} xs={false} />
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Typography>
            Final Score
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography>
            {detail?.totalScore || 0}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={7} md={7} sm={false} xs={false} />
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Typography>
            Class
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography>
            {detail?.class || '-'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

CommitteeAssesmentListView.propTypes = {
  values: PropTypes.array,
  detail: PropTypes.object,
  handleViewAssessment: PropTypes.func,
};

export default CommitteeAssesmentListView;
