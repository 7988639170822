import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import api from 'src/api';
import { BENEFIT_COST_TYPES } from 'src/api/backendUrl';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';

const BenefitCost = ({
  type,
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'benefitCost';
  const { data, refetch } = useQuery(BENEFIT_COST_TYPES, async () => {
    const response = await api.benefitCost.type({ type });
    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });
  const benefitCosts = data;
  const isDisabled = values.action === 'detail';

  useEffect(() => {
    refetch();
  }, [type]);

  return (
    <FormItem
      id={key}
      label={`${type}s`}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        name={key}
        placeholder={`Enter ${type}s`}
        options={benefitCosts}
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isDisabled}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

BenefitCost.propTypes = {
  type: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default BenefitCost;
