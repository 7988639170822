import { createStore } from 'easy-peasy'
import model from './model'
import { createLogger } from 'redux-logger'

// config log redux 
const middlewares = []
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger())
}

const store = createStore(model, {
  name: 'Innovation Store',
  version: 0.1,
  middleware: middlewares,
})

// hot reload store
if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept("./model", () => {
      store.reconfigure(model);
    });
  }
}

export default store;