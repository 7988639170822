/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

import NumberFormatCustom from 'src/components/CurrencyFormat';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const Amount = ({
  item,
  index,
  touched,
  errors,
  disabled,
  getIn,
  handleBlur,
  handleChange
}) => {
  const key = 'amount';
  const name = `benefits[${index}].${key}`;
  const touch = getIn(touched, name);
  const error = getIn(errors, name);

  return (
    <FormItem
      id={name}
      label=""
      error={Boolean(touch && error)}
      helperText={touch && error}
    >
      <StyledInput
        name={name}
        placeholder="Enter amount"
        value={item[key]}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touch && error)}
        inputProps={{
          decimalScale: 3
        }}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />
    </FormItem>
  );
};

Amount.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  getIn: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Amount;
