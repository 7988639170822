import React from 'react';

import {
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Submitter from './Submitter';
import Site from './Site';
import ScrumMaster from './ScrumMaster';
import TeamMember from './TeamMember';
import NewObjective from './NewObjective';
import NewScope from './NewScope';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const ChangeTeamView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Change Location or Team</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Alert severity="warning">
          <Typography>Leave Blank If No Changes. Do Not Fill</Typography>
        </Alert>
      </Grid>
      <Grid container item spacing={3} lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <Submitter {...props} />
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <Site {...props} />
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <ScrumMaster {...props} />
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12}>
          <TeamMember {...props} />
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <NewObjective {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <NewScope {...props} />
      </Grid>
    </Grid>
  );
};

export default ChangeTeamView;
