import React from 'react';
import HistoryView from './HistoryView';
import ChatView from './ChatView';
import RegistrationView from './RegistrationView';
import RequirementView from './G1RequirementView';
import StudyView from './G2StudyView';
import IdeaBlastView from './IdeaBlastView';
import PrototypeView from './G3PrototypeView';
import ScaleUpView from './G5ScaleUpView';
import ImplementationView from './ImplementationView';
import G4ImplementationView from './G4ImplementationView';
import BenefitCategoryView from './BenefitCategoryView';
import BenefitCostView from './BenefitCostView';
import WeeklyUpdateView from './WeeklyUpdate';
import HakiView from './HakiView';
import ImpactTracking from './ImpactTracking';

const getMenus = (
  detail,
  handleManualChangeTab,
  handleAccessPermissionClick
) => {
  const isFirstCreateOrDraft = !detail?.registration?.innovationStatus || detail?.registration?.innovationStatus === 'Draft';
  if (detail?.registration?.category === 'Individual') {
    return [
      {
        pathName: 'Registration Phase',
        name: 'Idea Blast - Register',
        component: detail?.registration?.innovationStatus !== 'Draft'
          ? (
            <IdeaBlastView
              detail={detail?.registration || {}}
              approval={detail?.registrationApproval}
              handleChangeTab={handleManualChangeTab}
              handleAccessPermissionClick={handleAccessPermissionClick}
            />
          )
          : (
            <RegistrationView
              detail={detail?.registration || {}}
              approval={detail?.registrationApproval}
              handleChangeTab={handleManualChangeTab}
              handleAccessPermissionClick={handleAccessPermissionClick}
            />
          )
      },
      {
        pathName: 'Implementation Phase',
        name: 'Idea Blast - Implementation',
        disable: detail?.currentGate < 1 && isFirstCreateOrDraft,
        component: <ImplementationView
          detail={detail?.implementation || {}}
          registration={detail?.registration || {}}
          approval={detail?.implementationApproval}
          handleChangeTab={handleManualChangeTab}
          handleAccessPermissionClick={handleAccessPermissionClick}
        />
      },
    ];
  }

  const benefitCategories = () => {
    if (detail?.currentGate === 3
      && detail?.registration?.innovationGateStatuses?.status === 'Completed'
      && !detail?.benefitCategory.length) {
      return detail?.registration?.innovationBenefits;
    }

    return detail?.benefitCategory;
  };

  return [
    {
      pathName: 'Registration Phase',
      name: 'Registration',
      component: <RegistrationView
        detail={detail?.registration || {}}
        approval={detail?.registrationApproval}
        handleChangeTab={handleManualChangeTab}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'Idea Identify',
      name: 'G1 Idea Identify',
      disable: detail?.currentGate < 1 && isFirstCreateOrDraft,
      component: <RequirementView
        detail={{
          ...detail?.requirement,
          initiative: detail?.registration?.initiative,
          tabName: 'G1',
          currentGate: detail?.currentGate
        }}
        registration={detail?.registration || {}}
        approval={detail?.requirementApproval}
        handleChangeTab={handleManualChangeTab}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'Idea Sizing',
      name: 'G2 Idea Sizing',
      disable: detail?.currentGate < 2 && isFirstCreateOrDraft,
      component: <StudyView
        detail={{
          ...detail?.study,
          initiative: detail?.registration?.initiative,
          tabName: 'G2',
          currentGate: detail?.currentGate
        }}
        requirement={detail?.requirement || {}}
        registration={detail?.registration || {}}
        approval={detail?.studyApproval}
        handleChangeTab={handleManualChangeTab}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'POC',
      name: 'G3 POC',
      disable: detail?.currentGate < 3 && isFirstCreateOrDraft,
      component: <PrototypeView
        detail={{
          ...detail?.prototype,
          initiative: detail?.registration?.initiative,
          tabName: 'G3',
          currentGate: detail?.currentGate
        }}
        registration={detail?.registration || {}}
        requirement={detail?.requirement || {}}
        study={detail?.study || {}}
        approval={detail?.prototypeApproval}
        handleChangeTab={handleManualChangeTab}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'MVP',
      name: 'G4 MVP',
      disable: detail?.currentGate < 4 && isFirstCreateOrDraft,
      component: <G4ImplementationView
        detail={{
          ...detail?.g4implementation,
          initiative: detail?.registration?.initiative,
          tabName: 'G4',
          currentGate: detail?.currentGate
        }}
        registration={detail?.registration || {}}
        requirement={detail?.requirement || {}}
        study={detail?.study || {}}
        prototype={detail?.prototype || {}}
        approval={detail?.g4implementationApproval}
        handleChangeTab={handleManualChangeTab}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'MMP',
      name: 'G5 MMP',
      disable: detail?.currentGate < 5 && isFirstCreateOrDraft,
      component: <ScaleUpView
        detail={{
          ...detail?.scaleUp,
          initiative: detail?.registration?.initiative,
          tabName: 'G5',
          currentGate: detail?.currentGate
        }}
        prototype={detail?.prototype}
        registration={detail?.registration || {}}
        approval={detail?.scaleUpApproval}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'Benefit Category',
      name: 'Benefit Category',
      disable: detail?.currentGate < 3 && !detail?.benefitStatus && isFirstCreateOrDraft,
      component: <BenefitCategoryView
        detail={{
          registration: detail?.registration,
          benefitCategories: benefitCategories()
        } || {}}
        handleAccessPermissionClick={handleAccessPermissionClick}
      />
    },
    {
      pathName: 'Impact Tracking',
      name: 'Impact Tracking',
      disable: detail?.currentGate < 1 && isFirstCreateOrDraft,
      component: <ImpactTracking
        detail={detail?.impactTracking}
        registration={detail?.registration}
      />
    }
  ];
};

const getOtherMenus = (detail) => [
  { name: 'Chat', component: <ChatView data={detail?.registration || {}} /> },
  { name: 'History', component: <HistoryView data={detail?.logHistory || []} isIdeaBlast={detail?.registration?.category === 'Individual'} /> },
];

const getBottomMenus = (props) => {
  return [
    ...props?.isValidForBenefitCost
      ? [
        {
          name: 'Benefit & Costs',
          component: ({ setFieldValue, setFieldTouched, gate }) => {
            return (
              <BenefitCostView
                required
                {...{
                  ...props?.detail,
                  ...props?.registration,
                  capexKey: props?.capexKey,
                  opexKey: props?.opexKey,
                  ...{ setFieldValue, setFieldTouched },
                  ...{ gate },
                }}
              />
            );
          },
        },
        {
          name: 'Weekly Update',
          component: ({ setFieldValue, setFieldTouched, gate }) => {
            return (
              <WeeklyUpdateView
                {...{
                  ...props?.detail,
                  ...props?.registration,
                  ...{ setFieldValue, setFieldTouched },
                  ...{ gate },
                }}
              />
            );
          },
        },
      ]
      : [],
    ...props?.detail?.currentGate >= 4
      ? [
        {
          name: 'HAKI',
          component: ({ setFieldValue, setFieldTouched, gate }) => {
            return (
              <HakiView
                {...{
                  ...props?.detail,
                  ...props?.registration,
                  ...{ setFieldValue, setFieldTouched },
                  ...{ gate },
                }}
              />
            );
          },
        },
      ]
      : [],
  ];
};

export { getMenus, getOtherMenus, getBottomMenus };
