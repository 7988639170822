import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import StyledMultiSelect from 'src/components/StyledMultiSelect';
import { BENEFIT_CATEGORIES } from 'src/api/backendUrl';
import { useQuery } from 'react-query';
import api from 'src/api';
import { isValidationRequired } from 'src/utils/validation';

const ActualBenefitCategory = ({
  disabled = false,
  handleBlur = () => {},
  handleChange = () => {},
  touched = {},
  errors = {},
  setFieldValue,
  setFieldTouched,
  values = {},
  validation
}) => {
  const key2 = 'actualBenefitDescription';
  const key = 'actualBenefitCategory1';
  const benefitOptions = useQuery(BENEFIT_CATEGORIES, async () => {
    const { data: response } = await api.masterData.benefitCategories({ Sorts: 'name' });
    return response?.data;
  }, { initialData: [] });
  const handleDeleteBenefit = (value) => {
    const newValue = values[key].filter((val) => val !== value);
    setFieldValue(key, newValue, true);
    setFieldTouched(key, true, false);
  };
  return (
    <FormItem
      required={isValidationRequired(key, validation, values, 'Project Team')}
      label="ACTUAL BENEFIT CATEGORY"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledMultiSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        handleDelete={(value) => handleDeleteBenefit(value)}
        value={values[key]}
        placeholder="Select Category"
        options={benefitOptions?.data || []}
      />
      <StyledInput
        error={Boolean(touched[key2] && errors[key2])}
        disabled={disabled}
        name={key2}
        type="text"
        multiline
        rows={4}
        placeholder="Enter Benefit Category Description"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key2]}
      />
    </FormItem>
  );
};

ActualBenefitCategory.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ActualBenefitCategory;
