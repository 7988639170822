import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Link
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Cancel, UnfoldMore, Search } from '@material-ui/icons';

import useStyles from 'src/views/innovation/InnovationView/style';

const DataTable = ({
  isVisible,
  isCustom = false,
  leftComp,
  leftCompNotVisible,
  entries,
  showEntries,
  setShowEntries,
  pageSize,
  setPageSize,
  setSearch,
  totalData,
  currentPage,
  setCurrentPage,
  totalPage,
  tableHeader,
  tableData,
  actionSlot,
  handleOnClick,
  children,
  hyperlinkPopup = false
}) => {
  const [innerSearch, setInnerSearch] = useState('');
  const classes = useStyles();
  const StyledCell = withStyles(() => ({
    head: {
      backgroundColor: '#EDEEFD',
      borderBottomWidth: 10,
      borderColor: '#F5F6F9',
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 120
    },
    body: {
      fontSize: 14,
      backgroundColor: 'white',
      borderTopWidth: 10,
      borderBottomWidth: 10,
      borderColor: '#F5F6F9'
    }
  }))(TableCell);
  const StyledRow = withStyles(() => ({
    root: {
      borderRadius: 5
    }
  }))(TableRow);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearch(innerSearch);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [innerSearch]);

  const calculatePagination = () => {
    const show = (Number(pageSize) * Number(currentPage)) - (Number(pageSize) - 1);
    const to = (Number(pageSize) * Number(currentPage));
    return `Showing ${totalData ? show : 0} to ${totalData ? to : 0} of ${totalData} data entries`;
  };

  const handleSearch = () => setSearch(innerSearch);
  const clearSearch = () => {
    setInnerSearch('');
    setSearch('');
  };
  const handleEnterSearch = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  };

  const handleShowEntries = (item) => {
    setShowEntries(null);
    setPageSize(item);
  };
  const openEntries = (ev) => setShowEntries(ev.currentTarget);
  const closeEntries = () => setShowEntries(null);
  const handlePagination = (event, page) => {
    setCurrentPage(page);
  };

  const handleDataFormat = (data, key) => {
    switch (true) {
      case key === 'reportDate':
        return moment(data[key]).format('DD MMMM YYYY');
      case key === 'value':
        return Number(data[key]).toLocaleString('en-US');
      case key === 'number':
        return (
          <Link onClick={() => handleOnClick(data)} underline="always">{data[key]}</Link>
        );
      case typeof data[key] === 'object':
        return data[key].name;
      default:
        return data[key];
    }
  };

  return (
    <Grid container style={{ marginTop: 30, marginBottom: 30, }}>
      <Grid container spacing={4} style={{ margin: 'auto' }}>
        {
          leftComp && (
            <Grid
              item
              lg={4}
              md={6}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {
                isVisible && (
                  <>
                    {leftComp}
                  </>
                )
              }
              {
                !isVisible && (
                  <>
                    {leftCompNotVisible}
                  </>
                )
              }
            </Grid>
          )
        }
        <Grid
          item
          lg={4}
          md={6}
          sm={12}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.innovationTitle}>
              Show
            </Typography>
            <Box className={classes.boxInputNumber} onClick={openEntries}>
              <div style={{
                display: 'flex',
                height: 28,
                marginRight: 2,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
                <UnfoldMore />
              </div>
              <div>
                <Typography className={classes.inputNumber}>
                  {pageSize}
                </Typography>
              </div>
            </Box>
            <Menu
              keepMounted
              anchorEl={showEntries}
              id="simple-menu"
              open={Boolean(showEntries)}
              onClose={closeEntries}
            >
              {
                entries.map((entry, index) => (
                  <MenuItem
                    key={`key-${index + 1}`}
                    onClick={() => handleShowEntries(entry)}
                  >
                    {entry}
                  </MenuItem>
                ))
              }
            </Menu>
            <Typography className={classes.innovationTitle}>
              Entries
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div className={classes.search}>
            <InputBase
              fullWidth
              name="search"
              placeholder="Search..."
              value={innerSearch}
              onChange={(ev) => setInnerSearch(ev.target.value)}
              inputProps={{ 'aria-label': 'search', color: '#D0C9D6' }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              endAdornment={(
                <>
                  {
                    innerSearch && (
                      <IconButton onClick={() => clearSearch()}>
                        <Cancel />
                      </IconButton>
                    )
                  }
                  <IconButton onClick={() => handleSearch()}>
                    <Search />
                  </IconButton>
                </>
              )}
              onKeyDown={handleEnterSearch}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 20 }}>
        <TableContainer component={Paper} className={classes.tableContainerMd}>
          <Table
            stickyHeader
            className={hyperlinkPopup ? classes.tableHyperlink : classes.table}
            aria-label="simple-table"
          >
            <TableHead className={classes.header}>
              <StyledRow>
                {
                  !!tableHeader.length
                    && tableHeader.map((item, index) => (
                      <StyledCell key={`th-${index + 1}`}>
                        {item.label}
                      </StyledCell>
                    ))
                }
              </StyledRow>
            </TableHead>
            <TableBody className={classes.header}>
              {
                !!tableData.length
                  && tableData.map((data, index) => (
                    <StyledRow
                      key={`tr-${index + 1}`}
                      style={{ marginBottom: '5px' }}
                    >
                      <>
                        {
                          tableHeader.length
                          && tableHeader.map(({ key }, headerIndex) => (
                            <StyledCell key={`td-${headerIndex + 1}`} align="left">
                              {
                                key === 'action' && actionSlot && (
                                  <>
                                    {actionSlot(data)}
                                  </>
                                )
                              }
                              {
                                isCustom
                                  ? children({ key, data, index })
                                  : handleDataFormat(data, key)
                              }
                            </StyledCell>
                          ))
                        }
                      </>
                    </StyledRow>
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container direction="row" alignContent="space-between" alignItems="center">
        <Grid
          item
          lg={4}
          md={4}
          sm={5}
          xs={12}
          className={classes.contentShowing}
        >
          <Typography className={classes.subtitle}>{calculatePagination()}</Typography>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={7}
          xs={12}
          className={classes.contentPagination}
        >
          <Pagination
            color="secondary"
            type="next"
            count={totalPage}
            page={currentPage}
            shape="rounded"
            onChange={handlePagination}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DataTable.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isCustom: PropTypes.bool,
  leftComp: PropTypes.any,
  leftCompNotVisible: PropTypes.any,
  entries: PropTypes.array.isRequired,
  showEntries: PropTypes.func,
  setShowEntries: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  totalData: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalPage: PropTypes.number.isRequired,
  tableHeader: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
  })).isRequired,
  tableData: PropTypes.array.isRequired,
  actionSlot: PropTypes.any,
  handleOnClick: PropTypes.func,
  hyperlinkPopup: PropTypes.bool,
  children: PropTypes.node
};

export default DataTable;
