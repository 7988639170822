import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

import Persona from './Persona';
import Attachment from './Attachment';
import CustomerJourney from './CustomerJourney';
import CustomerJourneyPoint from './CustomerJourneyPoint';
import TechnologyArchitecture from './TechnologyArchitecture';
import DevelopmentPlatform from './DevelopmentPlatform';
import FundingResource from './FundingResource';
import PocCompletionDate from './PocCompletionDate';
import SprintPlan from './SprintPlan';
import TemplateUserPersona from './TemplateUserPersona';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    backgroundColor: theme.palette.common.white,
  },
}));

const TopView = ({
  isDigital,
  studyId,
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.formContainerBtm}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Persona {...props} />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TemplateUserPersona
              {...{
                ...props,
                studyId
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomerJourneyPoint {...props} />
            <CustomerJourney
              {...{
                ...props,
                studyId
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} />
          {
            isDigital && (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TechnologyArchitecture
                    {...{
                      ...props,
                      studyId
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <DevelopmentPlatform {...props} />
                </Grid>
              </>
            )
          }
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FundingResource {...props} />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className={classes.formContainerBtm}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.title}>Idea Sizing</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <PocCompletionDate {...props} />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <SprintPlan {...props} />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box className={classes.formContainerBtm}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Attachment
              {...{
                ...props,
                studyId
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

TopView.propTypes = {
  studyId: PropTypes.string.isRequired,
  isDigital: PropTypes.bool
};

export default TopView;
