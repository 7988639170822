import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import { isValidationRequired } from 'src/utils/validation';

const Solution = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'solution';
  return (
    <FormItem
      required={isValidationRequired(key, validation, values, 'Individual')}
      label="Solution"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter solution"
      />
    </FormItem>
  );
};

Solution.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Solution;
