import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Header from 'src/components/Header';
import { useStoreState } from 'easy-peasy';
import StateChanger from './StateChanger';
import StatusView from './StatusView';

const useStyles = makeStyles((theme) => ({
  root: {},
  side: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      display: 'block'
    },
  }
}));

const Toolbar = ({
  className, path, detail, ...rest
}) => {
  const classes = useStyles();
  const user = useStoreState((state) => state.auth.user);
  // PO are creator and requestor
  const isPO = user?.id === detail?.user?.id
  || user?.name?.toLowerCase() === detail?.requestedFor?.toLowerCase();
  const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
  const isIdeaBlast = detail?.category === 'Individual';
  const currentStage = detail?.innovationGateStatuses?.stages;
  const stage = currentStage?.name && Number(currentStage?.name?.charAt(1));
  const isNotG0 = currentStage?.name && stage !== 0;
  const currentStatus = detail?.innovationGateStatuses?.status;
  const currentState = detail?.innovationGateStatuses?.states;
  const showState = currentState && ['N/A', 'Cancelled'].indexOf(currentState?.name) < 0 && !isIdeaBlast;
  // Only PO can edit state when status not completed or cancelled
  const canEdit = (isPO || isAdmin) && ['Completed', 'Cancelled'].indexOf(currentStatus) < 0;
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
      >
        <Grid container>
          <Grid item lg={6} md={4} sm={12}>
            <Header
              title={path}
              parentPath={[{ to: '/app/innovation-view', name: 'Innovation List' }]}
              currentPath={path}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={12} spacing={1} container className={classes.side}>
            { showState && (
              <StateChanger
                stage={stage}
                value={currentState?.id}
                disabled={!canEdit}
              />
            ) }
          </Grid>
          <Grid item lg={3} md={4} sm={12} spacing={1} container className={classes.side}>
            { isNotG0 && <StatusView status={currentStatus} /> }
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  detail: PropTypes.object
};

export default Toolbar;
