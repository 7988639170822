import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const TabPanel = ({
  children, currentTab, indexTab, ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={currentTab !== indexTab}
      id={`full-width-tabpanel-${indexTab}`}
      aria-labelledby={`full-width-tab-${indexTab}`}
      {...other}
    >
      {currentTab === indexTab && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  currentTab: PropTypes.any.isRequired,
  indexTab: PropTypes.any.isRequired,
};

export default TabPanel;
