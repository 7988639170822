import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const PopUp = ({
  isDialogOpen,
  isLoading,
  isSubmitting,
  data,
  handleClose,
  handleConfirm
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [judgeList, setJudgeList] = useState([]);

  const onJudgeChange = (isChecked, judgeId) => {
    setJudgeList((prevState) => {
      if (isChecked) {
        prevState.push(judgeId);
        return [...prevState];
      }

      return prevState.filter((id) => id !== judgeId);
    });
  };

  useEffect(() => {
    if (!isDialogOpen) {
      setJudgeList([]);
    }
  }, [isDialogOpen]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
    >
      <DialogTitle id="confirmation-dialog">
        <Box
          style={{
            display: 'flex',
            flexDirection: 'flex-row',
            alignItems: 'center'
          }}
        >
          {
            isLoading && (
              <CircularProgress size={24} style={{ marginRight: 10 }} />
            )
          }
          <Typography
            align="center"
            variant="h2"
          >
            Assign Judges
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {
            (data && Boolean(data.length)) && data.map((item, index) => (
              <Fragment key={`key_judges_${index + 1}`}>
                <Grid item sm={2} md={2} lg={2}>
                  <Checkbox onChange={(ev) => onJudgeChange(ev.target.checked, item.id)} />
                </Grid>
                <Grid
                  item
                  sm={10}
                  md={10}
                  lg={10}
                >
                  <Typography
                    align="left"
                    variant="h5"
                  >
                    {item.fullName}
                  </Typography>
                </Grid>
              </Fragment>
            ))
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isSubmitting || !judgeList.length}
          onClick={() => handleConfirm(judgeList)}
          color="secondary"
        >
          Assign
          {
            isSubmitting && (
              <CircularProgress size={14} style={{ marginLeft: 10 }} />
            )
          }
        </Button>
        <Button
          variant="outlined"
          disabled={isSubmitting}
          onClick={() => {
            setJudgeList([]);
            handleClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopUp.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  data: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

export default PopUp;
