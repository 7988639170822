import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const InitiativeDescription = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  values
}) => {
  const key = 'initiativeDescription';
  return (
    <FormItem
      label="Initiative Description"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        multiline
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        maxrows={10}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        type="text"
        placeholder="Enter initiative description"
      />
    </FormItem>
  );
};

InitiativeDescription.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
};

export default InitiativeDescription;
