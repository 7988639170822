import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { ArrowDropDown, Close } from '@material-ui/icons';

import StyledSelect from 'src/components/StyledSelect';

const SiteFilter = ({
  siteOptions = [],
  values,
  setFilter
}) => {
  const key = 'site';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  const clearFilter = () => {
    setFilter({
      ...values,
      [key]: ''
    });
  };

  const renderDeleteIcon = (value) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {
          value && (
            <Close
              size="small"
              style={{
                color: 'red',
                fontSize: 16,
                zIndex: 1,
                cursor: 'pointer'
              }}
              onClick={() => clearFilter(key)}
            />
          )
        }
        <ArrowDropDown size="small" style={{ color: '#3F3356', marginRight: 7 }} />
      </Box>
    );
  };

  return (
    <StyledSelect
      name={key}
      placeholder="Select site"
      value={values[key]}
      options={siteOptions}
      onChange={handleOnChange}
      IconComponent={() => renderDeleteIcon(values[key])}
    />
  );
};

SiteFilter.propTypes = {
  siteOptions: PropTypes.array,
  values: PropTypes.object.isRequired,
  setFilter: PropTypes.func
};

export default SiteFilter;
