const getProperties = (id, detail, user, registration, approval, isEdit) => {
  const isImplementation = id && registration?.innovationGateStatuses?.stages?.name?.includes('G1');
  const isPO = registration?.userPermissions?.some((item) => item.id === user?.id);
  const isAdmin = user?.roles?.findIndex((d) => d.name === 'Administrator') > -1;
  const isRoleAdminSite = user?.roles?.findIndex((d) => d.name === 'Admin Site') > -1;
  const isAdminApp = isAdmin && !user.designation;
  const isSameSite = user?.cluster?.some((item) => item.id === registration?.organizationId);
  const isAdminSite = isRoleAdminSite && isSameSite;
  const isCommittee = user?.roles?.find((d) => d.name === 'Committee Site');
  // Status Project
  const isDraft = detail?.gateStatus === 'Draft';
  const isSentBack = detail?.gateStatus === 'Send Back';
  const isWaitingSuperior = detail?.gateStatus === 'Waiting Superior Approval';
  const isWaitingReviewAdmin = detail?.gateStatus === 'Waiting Admin Verify';
  const isWaitingCommittee = detail?.gateStatus === 'Waiting Assessment Committee';
  const isWaitingAcknowledge = detail?.gateStatus === 'Waiting Admin Acknowledge';
  const isCompleted = detail?.gateStatus === 'Completed';
  const isWaitingConfirmation = detail?.gateStatus === 'Waiting Confirmation';
  const isCancelled = detail?.gateStatus === 'Cancelled';
  const committeeAssigned = approval?.data?.filter((d) => d.roles?.name === 'Committee Site');
  const isAssignedCommittee = committeeAssigned?.find((d) => d.userId === user.id);
  const getCommitteeScore = (userId) => {
    const Assignedcommittee = committeeAssigned?.find((d) => d.userId === userId);
    return Assignedcommittee?.questionAssessments;
  };
  const canSubmit = isImplementation && (
    ((isDraft || isSentBack) && isPO)
      || (isAdminApp && isEdit && !isCompleted)
      || (!isDraft && !isCompleted && isEdit && isAdminSite));
  const canEdit = (isImplementation
    && !isDraft
    && !isEdit
    && ((isAdminApp && !isCompleted) || (!isCompleted && isAdminSite)));
  const canReview = isImplementation && (isWaitingReviewAdmin && isAdminSite);
  const canAcknowledge = isImplementation && (isWaitingAcknowledge && isAdminSite);
  const canAssesment = isImplementation && (
    isWaitingCommittee && isCommittee && isAssignedCommittee && !isAssignedCommittee?.isApproved
  );
  const canViewAssessment = isImplementation && isWaitingCommittee && (
    isAdminSite
  );
  const canEditCommittee = isImplementation && (isWaitingCommittee && isAdminSite);
  const canCancel = isImplementation
    && !isCancelled
    && isPO
    && (isWaitingSuperior || isWaitingReviewAdmin);
  const canReactivate = isImplementation
    && isCancelled
    && (isAdmin || isAdminSite);
  const canConfirm = isImplementation
    && isWaitingConfirmation
    && isPO;
  const canViewConfirm = isImplementation && (
    (isPO && (isWaitingCommittee
        || isWaitingAcknowledge
        || isWaitingConfirmation)
    )
    || (isCompleted)
  );
  const canManageAccess = Boolean(id && (isAdmin || isAdminSite));
  const isWaitingApproval = isWaitingAcknowledge
    || isWaitingCommittee
    || isWaitingReviewAdmin
    || isWaitingConfirmation
    || isWaitingSuperior;

  return {
    isAdmin,
    isAdminSite,
    isCommittee,
    isDraft,
    isCompleted,
    isSentBack,
    isWaitingCommittee,
    isWaitingReviewAdmin,
    isImplementation,
    isWaitingAcknowledge,
    isWaitingConfirmation,
    isWaitingApproval,
    committeeAssigned,
    canSubmit,
    canEdit,
    canReview,
    canEditCommittee,
    canAcknowledge,
    canAssesment,
    canViewAssessment,
    canCancel,
    canReactivate,
    canConfirm,
    canViewConfirm,
    canManageAccess,
    getCommitteeScore,
  };
};

export default getProperties;
