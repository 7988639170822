import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';
import { isValidationRequired } from 'src/utils/validation';

const Company = ({
  handleBlur,
  handleChange,
  values,
  validation
}) => {
  const key = 'company';

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Company"
      id={key}
    >
      <StyledInput
        disabled
        type="text"
        placeholder="Enter company"
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
      />
    </FormItem>
  );
};

Company.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Company;
