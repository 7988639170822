import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';

import ButtonTextFieldConfirmation from 'src/components/ButtonTextFieldConfirmation';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  delete: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    }
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  }
}));

const Decision = ({
  canSubmit,
  canSaveDraft,
  canApprove,
  canCancel,
  canEdit,
  canReactivate,
  canCreateG3,
  canManageAccess,
  handleCreateG3,
  handleSaveDraft,
  handleApproval,
  handleReactivate,
  handleCancel,
  handleAccessPermissionClick,
  dirty,
  values,
  isValid,
  submitForm,
  isSubmitting,
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
  setFieldValue,
  showRemind,
  canRemind,
  handleRemind,
  setPromptAction
}) => {
  const classes = useStyles();

  const handleBackNav = () => setPromptAction({ needed: false, action: 'close' });

  const handleSubmit = (action) => {
    setFieldValue('action', action);
    submitForm();
  };

  const {
    action,
    ...form
  } = values;

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
    >
      {canSubmit && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to submit ?"
          textConfirm="Ok"
          textCancel="Cancel"
          disabled={isSubmitting || !isValid}
          onConfirm={() => handleSubmit('submit')}
          className={clsx(classes.button, classes.label)}
        >
          Submit
        </ButtonConfirmation>
      )}
      {isEdit && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to edit ?"
          textConfirm="Ok"
          textCancel="Cancel"
          disabled={isSubmitting || !isValid}
          onConfirm={() => handleSubmit('edit')}
          className={clsx(classes.button, classes.label)}
        >
          Save Edit
        </ButtonConfirmation>
      )}
      {isEdit && (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleCloseEdit}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Cancel Edit
        </Button>
      )}
      {canEdit && !isEdit && (
        <Button
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleOpenEdit}
          className={clsx(classes.button, classes.label)}
        >
          Edit
        </Button>
      )}
      {canCreateG3 && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to create G3 ?"
          textConfirm="Ok"
          textCancel="Cancel"
          disabled={isSubmitting}
          onConfirm={() => handleCreateG3()}
          className={clsx(classes.button, classes.label)}
        >
          Create G3
        </ButtonConfirmation>
      )}
      {canApprove && (
        <ButtonTextFieldConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to approve ?"
          variant="contained"
          color="secondary"
          textConfirm="Ok"
          textCancel="Cancel"
          onConfirm={(comment) => handleApproval(comment)}
          className={clsx(classes.button, classes.label)}
        >
          Approve
        </ButtonTextFieldConfirmation>
      )}
      {canSaveDraft && (
        <ButtonConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to save as draft ?"
          textConfirm="Ok"
          textCancel="Cancel"
          variant="outlined"
          color="secondary"
          disabled={isSubmitting}
          onConfirm={() => handleSaveDraft(form)}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Save as Draft
        </ButtonConfirmation>
      )}
      {canReactivate && (
        <ButtonTextFieldConfirmation
          title="Confirmation"
          variant="outlined"
          color="secondary"
          textDialog="Are you sure you want to reactivate ?"
          textConfirm="Ok"
          textCancel="Cancel"
          onConfirm={(comment) => handleReactivate(comment)}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Reactivate
        </ButtonTextFieldConfirmation>
      )}
      {canCancel && (
        <ButtonTextFieldConfirmation
          title="Confirmation"
          textDialog="Are you sure you want to cancel ?"
          textConfirm="Ok"
          textCancel="Cancel"
          variant="outlined"
          color="secondary"
          onConfirm={(comment) => handleCancel(comment)}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Cancel
        </ButtonTextFieldConfirmation>
      )}
      {dirty
        ? (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to close ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={handleBackNav}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </ButtonConfirmation>
        )
        : (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleBackNav}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </Button>
        )}
      {showRemind && (
        <ButtonConfirmation
          variant="contained"
          color="secondary"
          title="Confirmation"
          textDialog="Are you sure you want to send approval reminder email ?"
          textConfirm="Yes"
          textCancel="No"
          disabled={!canRemind}
          onConfirm={() => handleRemind()}
          className={clsx(classes.button, classes.label)}
        >
          Send Email Reminder
        </ButtonConfirmation>
      )}
      {canManageAccess && (
        <Button
          variant="outlined"
          color="secondary"
          disabled={isSubmitting}
          onClick={handleAccessPermissionClick}
          className={clsx(classes.button, classes.outlined, classes.label)}
        >
          Manage Access
        </Button>
      )}
    </Box>
  );
};

Decision.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool,
  canSaveDraft: PropTypes.bool,
  canApprove: PropTypes.bool,
  canCancel: PropTypes.bool,
  canEdit: PropTypes.bool,
  canReactivate: PropTypes.bool,
  canCreateG3: PropTypes.bool,
  canManageAccess: PropTypes.bool,
  dirty: PropTypes.bool,
  values: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleOpenEdit: PropTypes.func.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  handleCreateG3: PropTypes.func,
  handleSaveDraft: PropTypes.func,
  handleApproval: PropTypes.func,
  handleReactivate: PropTypes.func,
  handleCancel: PropTypes.func,
  handleAccessPermissionClick: PropTypes.func,
  showRemind: PropTypes.bool,
  canRemind: PropTypes.bool,
  handleRemind: PropTypes.func,
  setPromptAction: PropTypes.func
};

export default Decision;
