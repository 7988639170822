import React from 'react';
import {
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';

import G1TotalNet from './g1TotalNet';
import G1TotalRecurring from './g1TotalRecurring';
import G2TotalNet from './g2TotalNet';
import G2TotalRecurring from './g2TotalRecurring';
import G3TotalNet from './g3TotalNet';
import G3TotalRecurring from './g3TotalRecurring';
import G4TotalNet from './g4TotalNet';
import G4TotalRecurring from './g4TotalRecurring';
import G5TotalNet from './g5TotalNet';
import G5TotalRecurring from './g5TotalRecurring';

const useStyles = makeStyles(() => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  }
}));

const GateBenefitView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: 5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G1TotalNet {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G1TotalRecurring {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G2TotalNet {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G2TotalRecurring {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G3TotalNet {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G3TotalRecurring {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G4TotalNet {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G4TotalRecurring {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G5TotalNet {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <G5TotalRecurring {...props} />
      </Grid>
    </Grid>
  );
};

export default GateBenefitView;
