import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';
import { BENEFIT_CATEGORIES } from 'src/api/backendUrl';
import { useQuery } from 'react-query';
import api from 'src/api';

const BenefitCategory = ({
  disabled,
  handleBlur,
  handleChange,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  values
}) => {
  const key = 'innovationBenefits';
  const benefitOptions = useQuery(BENEFIT_CATEGORIES, async () => {
    const { data: response } = await api.masterData.benefitCategories({ Sorts: 'name' });
    return response?.data;
  }, { initialData: [] });
  const handleDeleteBenefit = (value) => {
    const newValue = values[key].filter((val) => val !== value);
    setFieldValue(key, newValue, true);
    setFieldTouched(key, true, false);
  };
  return (
    <FormItem
      label="Benefit Category"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledMultiSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        handleDelete={(value) => handleDeleteBenefit(value)}
        value={values[key]}
        placeholder="Select Category"
        options={benefitOptions?.data || []}
      />
    </FormItem>
  );
};

BenefitCategory.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default BenefitCategory;
