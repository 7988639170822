import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import {
  useParams,
  useNavigate,
  Prompt
} from 'react-router-dom';
import { useMutation } from 'react-query';
import { difference, isEmpty } from 'lodash';
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  useStoreState,
  useStoreActions,
  useStore
} from 'easy-peasy';
import SwipeableViews from 'react-swipeable-views';

import api from 'src/api';

import TabMenu from 'src/components/TabMenu';
import CostView from './CostView';
import GeneralView from './GeneralView';
import ChangeTeamView from './ChangeTeamView';
import Decision from './Decision';

import TabPanel from '../Tabpanel';
import { initialValues, validation } from './config';
import { getBottomMenus } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    borderRadius: '0 24px 24px 24px'
  },
  formContainerBtm: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 2.26%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px'
  }
}));

const ScaleUpView = ({
  detail,
  prototype,
  registration,
  approval,
  handleAccessPermissionClick
}) => {
  const classes = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const store = useStore();

  const user = useStoreState((state) => state.auth.user);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotification = useStoreActions((action) => action.notif.setNotif);
  const setFormikValues = useStoreActions((action) => action.formikValues.setFormikValues);
  const prevNavigation = useStoreState((state) => state.navigation.previous);

  const [bottomTab, setBottomTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);
  const [canRemind, setCanRemind] = useState(
    registration?.innovationGateStatuses?.notificationSent === false
  );
  const [promptAction, setPromptAction] = useState({ needed: true, action: null });

  const handleChangeBottomTab = (event, newValue) => {
    setBottomTab(newValue);
    setTabChanged(true);
  };

  const isUserCanApprove = () => {
    if (detail.gateStatus === undefined || detail.gateStatus === 'Draft') return false;

    let isUserCanApproveAsPromotorFacilitator = false;
    let approvalData = [];

    const promotorFacilitatorApproval = approval?.data.filter(
      (item) => item.roles.name.includes('Promotor')
        || item.roles.name.includes('Facilitator')
    );

    const roleApproval = difference(approval?.data, promotorFacilitatorApproval, 'roles.name');

    if (promotorFacilitatorApproval.length) {
      isUserCanApproveAsPromotorFacilitator = promotorFacilitatorApproval
        .some((item) => !item.isApproved
          && item.roles.approver.some((userApprover) => userApprover.id === user.id));
    }

    const isUserCanApproveByRole = roleApproval
      .some((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    approvalData = isUserCanApproveAsPromotorFacilitator
      ? promotorFacilitatorApproval.filter((item) => !item.isApproved)
      : roleApproval.filter((item) => !item.isApproved
        && item.roles.approver.some((userApprover) => userApprover.id === user.id));

    return {
      approvalValidation: isUserCanApproveAsPromotorFacilitator || isUserCanApproveByRole,
      approvalData
    };
  };

  const handleOpenEdit = () => setIsEdit(true);
  const handleCloseEdit = () => setIsEdit(false);
  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
          return;
        }

        setPromptAction({ needed: false, action: actionMessage });
        setNotification({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotification({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const saveAsDraft = useMutation(
    (form) => api.scaleUp.saveAsDraft(id, form), mutateOption('Save As Draft')
  );
  const submit = useMutation(
    (form) => api.scaleUp.create(id, form), mutateOption('Submit')
  );
  const approve = useMutation((form) => api.scaleUp.approve(id, form), mutateOption('Approve'));
  const sendback = useMutation((form) => api.scaleUp.sendback(id, form), mutateOption('Sent Back'));

  const transformArrayMember = (data) => {
    return data.map((item) => ({ id: item.id, name: item.name }));
  };
  const generateForm = (form) => {
    const {
      teamMembers,
      dataArchitects,
      submitter,
      facilitator,
      newTeamMembers,
      ...rest
    } = form;

    return {
      dataArchitects: transformArrayMember(dataArchitects),
      newTeamMembers: newTeamMembers.map((item) => ({
        id: item.id || item.empId,
        name: item.memberName || item.fullName || item.fullname
      })),
      submitterId: submitter?.id || submitter?.empId,
      facilitatorId: facilitator?.id || facilitator?.empId,
      ...rest
    };
  };
  const handleSaveAsDraftForm = (form) => {
    const body = generateForm(form);
    saveAsDraft.mutate(body);
  };
  const handleSubmitForm = (form) => {
    const body = generateForm(form);
    submit.mutate(body);
  };
  const handleApproval = (comment) => {
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const form = {
      registrationId: id,
      roleId: userRole.id,
      comment
    };

    approve.mutate(form);
  };
  const handleSentBack = async (comment) => {
    const { approvalData } = isUserCanApprove();
    const userRole = user?.roles.find(
      (role) => approvalData.some((item) => item.roles.id === role.id)
    );

    const form = {
      registrationId: id,
      roleId: userRole.id,
      comment
    };

    sendback.mutate(form);
  };
  const handleOnSubmit = (value, actions) => {
    actions.setSubmitting(true);
    const { action, ...form } = value;

    switch (action) {
      case 'submit':
        handleSubmitForm(form);
        break;
      default:
        break;
    }
    actions.setSubmitting(false);
  };

  const remind = useMutation(() => api.notification.manual(id), mutateOption('Reminder', () => {
    setNotification({
      message: 'Email Reminder sent successfully',
      option: {
        variant: 'success'
      }
    });
    setCanRemind(false);
  }));
  const handleRemind = () => {
    remind.mutate();
  };

  const isAdmin = user?.roles.find((d) => d.name === 'Administrator');
  const isAdminSite = user?.roles.find((d) => d.name === 'Admin Site');
  const isSameSite = user?.cluster?.some((item) => item.id === registration?.organizationId);
  const isAdminOfSite = isAdminSite && isSameSite;
  const isAdminApp = isAdmin && !user.designation;
  const isPIC = user?.roles?.filter((d) => ['DCOE', 'IT', 'Infinity'].indexOf(d.name) > -1);
  const isAdminPIC = !!isPIC.length
    && isPIC.findIndex((d) => d?.name === registration?.roles?.name) > -1;
  const isPO = registration?.userPermissions?.some((item) => item.id === user?.id);
  const isFormG5 = id && (registration?.innovationGateStatuses?.stages?.name?.includes('G5'));
  const isDraft = detail?.gateStatus?.includes('Draft');
  const isCancelled = detail?.gateStatus?.includes('Cancelled');
  const isCompleted = detail?.gateStatus?.includes('Completed');
  const isSendBack = detail?.gateStatus?.includes('Send Back');
  const isOnHold = registration?.innovationGateStatuses?.states?.name?.includes('On Hold');
  const isWaitingApproval = detail?.gateStatus?.includes('Waiting Approval');
  const isG5Completed = isFormG5 && (registration?.innovationGateStatuses?.status?.includes('Completed'));
  const isIPod = registration?.category === 'IPod';
  const canApprove = approval?.isCanApprove;
  const canSentBack = approval?.isCanApprove;
  const canEdit = (isFormG5
    && !isCancelled
    && ((!isCompleted && isAdminPIC && isEdit)
      || ((isDraft || isSendBack) && (isPO))
      || (isEdit && isAdminApp))
  );
  const canEditAdmin = isFormG5 && !isCancelled && !isOnHold && !isEdit
    && (isAdminApp || (isAdminPIC && !isCompleted) || isAdminOfSite)
    && !(isDraft || isSendBack);
  const canSaveAsDraft = isFormG5
    && (isDraft || isSendBack)
    && !isCancelled
    && (isPO);
  const canSubmit = isFormG5
    && (isDraft || isSendBack)
    && !isCancelled
    && !isOnHold
    && (isPO);
  const canManageAccess = Boolean(id && (isAdmin || isAdminSite));

  const isCanValidateOnMount = !isEmpty(detail)
    && (detail?.gateStatus === 'Draft' || detail?.gateStatus === 'Send Back');
  const isValidForBenefitCost = registration?.benefitCostActivate;

  const yupValidation = validation.fields;
  const showRemind = isFormG5
    && (isAdminOfSite || isAdmin)
    && (isWaitingApproval)
    && !isG5Completed;

  // current state isValidForBenefitCost is always true for IPoD category
  const bottomMenus = getBottomMenus(
    {
      ...{
        detail,
        registration,
        capexKey: 'mmpFundingCapex',
        opexKey: 'mmpFundingOpex',
        isValidForBenefitCost
      }
    }
  );

  useEffect(() => {
    if (!promptAction.needed) {
      navigate(promptAction.action === 'close'
        ? prevNavigation || '/app/innovation-view'
        : '/app/innovation-view');
    }
  }, [promptAction]);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnMount={isCanValidateOnMount}
      initialValues={tabChanged
        ? store.getState()?.formikValues?.formikValues
        : initialValues(detail, registration, prototype)}
      initialErrors={validation}
      isInitialValid={() => validation.isValidSync(
        tabChanged
          ? store.getState()?.formikValues?.formikValues
          : initialValues(detail, registration, prototype)
      )}
      validationSchema={validation}
      onSubmit={handleOnSubmit}
    >
      {({
        initialValues: initialData,
        isValid,
        isSubmitting,
        touched,
        errors,
        values,
        handleBlur,
        handleChange,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        submitForm
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <CostView
                disabled={!canEdit || isValidForBenefitCost}
                {...{
                  touched,
                  errors,
                  values,
                  canEdit,
                  handleBlur,
                  handleChange
                }}
              />
              {
                isIPod && (
                  <>
                    <Divider
                      className={classes.divider}
                      style={{ marginBottom: 15, marginTop: 15 }}
                    />
                    <ChangeTeamView
                      disabled={!canEdit}
                      validation={yupValidation}
                      {...{
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        setFieldTouched,
                        setFieldValue
                      }}
                    />
                  </>
                )
              }
              <GeneralView
                scaleUpId={detail?.id}
                disabled={!canEdit}
                validation={yupValidation}
                {...{
                  touched,
                  errors,
                  values,
                  handleBlur,
                  handleChange,
                  setFieldTouched,
                  setFieldValue
                }}
              />
            </Box>
            <Grid item style={{ marginTop: '2rem', marginBottom: '2rem' }}>
              <TabMenu
                tab={bottomTab}
                setTab={(v, e) => {
                  setFormikValues(values);
                  handleChangeBottomTab(v, e);
                }}
                menus={bottomMenus}
              />
              <SwipeableViews
                disabled
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={bottomTab}
                onChangeIndex={(v, e) => {
                  setFormikValues(values);
                  handleChangeBottomTab(v, e);
                }}
              >
                {bottomMenus.map((menu, index) => (
                  <TabPanel
                    key={menu.name}
                    currentTab={bottomTab}
                    indexTab={index}
                    dir={theme.direction}
                  >
                    {menu?.component({
                      setFieldValue,
                      setFieldTouched,
                      gate: 'g5'
                    })}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
            <Decision
              {...{
                isValid,
                isSubmitting,
                values,
                canSaveAsDraft,
                canSubmit,
                canApprove,
                canSentBack,
                canManageAccess,
                setFieldValue,
                submitForm,
                handleSaveAsDraftForm,
                handleApproval,
                handleSentBack,
                handleAccessPermissionClick,
                isEdit,
                canEditAdmin,
                handleOpenEdit,
                handleCloseEdit,
                showRemind,
                canRemind,
                handleRemind,
                setPromptAction
              }}
            />
            <Prompt
              when={promptAction.needed
                && (canSubmit || isEdit)
                && !deepEqual(initialData, values)}
              message="Changes detected, are you sure you want to leave this page?"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

ScaleUpView.propTypes = {
  detail: PropTypes.object,
  prototype: PropTypes.object,
  registration: PropTypes.object,
  approval: PropTypes.shape({
    isCanApprove: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    data: PropTypes.array
  }),
  handleAccessPermissionClick: PropTypes.func
};

export default ScaleUpView;
