import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const initialValues = ({ detail, registration }) => {
  const listOfBenefitIds = !isEmpty(registration?.innovationBenefits)
    ? registration?.innovationBenefits.map((item) => item.benefitCategoryId)
    : [];

  const listOfTeamMembers = !isEmpty(registration?.innovationTeamMembers)
    ? registration.innovationTeamMembers.map((item) => ({ id: item.id, name: item.memberName }))
    : [];

  return {
    mvpFundingCapex: detail?.mvpFundingCapex || 0,
    mvpFundingOpex: detail?.mvpFundingOpex || 0,
    g4RecurCost: detail?.g4RecurCost || 0,
    g4RecurBenefit: detail?.g4RecurBenefit || 0,
    g4OnetimeCost: detail?.g4OnetimeCost || 0,
    g4OnetimeBenefit: detail?.g4OnetimeBenefit || 0,
    mvpResult: detail?.mvpResult || '',
    plannedFeatureMvp: detail?.g3Prototypes?.plannedFeatureMvp || '',
    actualFeaturesMvp: detail?.actualFeaturesMvp || '',
    actualImplementationScopeMvp: detail?.actualImplementationScopeMvp || '',
    actualBenefitCategory1: detail?.actualBenefitCategories?.length
      ? detail.actualBenefitCategories.map((item) => item.benefitCategoryId)
      : listOfBenefitIds,
    actualBenefitDescription: detail?.actualBenefitDescription || '',
    g4ImplementationAttachments: detail?.supportingDocument?.length
      ? detail?.supportingDocument.map((item) => item)
      : [],
    mmpCompletionDate: detail?.mmpCompletionDate
      ? moment(detail?.mmpCompletionDate).toDate()
      : moment().toDate(),
    mmpMilestone: detail?.mmpMilestone || '',
    developerTeams: detail?.developerTeams?.length
      ? detail?.developerTeams.map((item) => ({ id: item.id, name: item.name }))
      : [],
    dataArchitects: detail?.dataArchitects?.length
      ? detail?.dataArchitects.map((item) => ({ id: item.id, name: item.name }))
      : [],
    teamMembers: listOfTeamMembers,
    category: registration?.category || '',
    action: '',
    submitter: detail?.submitter || {},
    organizationId: detail?.organizationId || '',
    facilitator: detail?.facilitator || {},
    newTeamMembers: detail?.newTeamMembers || [],
    objective: detail?.objective || '',
    projectScope: detail?.projectScope || ''
  };
};

export const validation = Yup.object().shape({
  mvpFundingCapex: Yup.number()
    .min(0, 'MVP Fund Capex must be higher than 0')
    .required('MVP Fund Capex is required'),
  mvpFundingOpex: Yup.number()
    .min(0, 'MVP Fund Opex must be higher than 0')
    .required('MVP Fund Opex is required'),
  g4RecurCost: Yup.number()
    .min(0, 'G4 Total Annual Net Recurring Cost should be 0 or more')
    .required('Total Annual Net Recurring Cost is required'),
  g4RecurBenefit: Yup.number()
    .min(0, 'G4 Total Annual Net Recurring Benefit should be 0 or more')
    .required('Total Annual Net Recurring Benefit is required'),
  g4OnetimeCost: Yup.number()
    .min(0, 'G4 Total Annual Net One Time Cost should be 0 or more')
    .required('Total Annual Net One Time Cost is required'),
  g4OnetimeBenefit: Yup.number()
    .min(0, 'G4 Total Annual Net One Time Benefit should be 0 or more')
    .required('Total Annual Net One Time Benefit is required'),
  mvpResult: Yup.string().required('MVP Result is required'),
  plannedFeatureMvp: Yup.string(),
  actualFeaturesMvp: Yup.string().required('Actual Feature is required'),
  actualImplementationScopeMvp: Yup.string().required('Funding Resource is required'),
  actualBenefitCategory1: Yup.array().when('category', {
    is: 'Project Team',
    then: Yup.array().required('Actual Benefit Category is required')
  }),
  actualBenefitDescription: Yup.string().when('category', {
    is: 'Project Team',
    then: Yup.string().required('Actual Benefit Category Description is required')
  }),
  g4ImplementationAttachments: Yup.array().required('Supporting Document is required'),
  mmpMilestone: Yup.string().required('Mmp Milestone is required is required'),
  developerTeams: Yup.array().required('Development Team is required'),
  dataArchitects: Yup.array(),
  submitter: Yup.object(),
  organizationId: Yup.string(),
  facilitator: Yup.object(),
  newTeamMembers: Yup.array(),
  objective: Yup.string().when(['submitter', 'organizationId', 'facilitator', 'newTeamMembers'], {
    is: (submitter, organizationId, facilitator, newTeamMembers) => !isEmpty(submitter)
        || !isEmpty(organizationId)
        || !isEmpty(facilitator)
        || (Boolean(newTeamMembers) && !isEmpty(newTeamMembers)),
    then: Yup.string().required('New Objective is required')
  }),
  projectScope: Yup.string().when(['submitter', 'organizationId', 'facilitator', 'newTeamMembers'], {
    is: (submitter, organizationId, facilitator, newTeamMembers) => !isEmpty(submitter)
      || !isEmpty(organizationId)
      || !isEmpty(facilitator)
      || (Boolean(newTeamMembers) && !isEmpty(newTeamMembers)),
    then: Yup.string().required('New Scope is required')
  })
});
