import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import NumberFormatCustom from 'src/components/NumberFormat';
import StyledInput from 'src/components/StyledInput';

import { isValidationRequired } from 'src/utils/validation';

const MvpFundingOpex = ({
  disabled = false,
  handleBlur = () => {},
  handleChange = () => {},
  touched = {},
  errors = {},
  values = {},
  validation
}) => {
  const key = 'mvpFundingOpex';

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Poc Funding Opex (USD)"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        error={Boolean(touched[key] && errors[key])}
        disabled={disabled}
        name={key}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
        placeholder="Enter amount"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
      />
    </FormItem>
  );
};

MvpFundingOpex.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default MvpFundingOpex;
