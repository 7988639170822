import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { useQuery } from 'react-query';
import { STRATEGIC_PLAN } from 'src/api/backendUrl';
import api from 'src/api';
import { isValidationRequired } from 'src/utils/validation';

const StrategicPlan = ({
  themeBankId,
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  id,
  validation
}) => {
  const key = 'strategicPlanId';
  const { data, refetch } = useQuery(STRATEGIC_PLAN, async () => {
    const response = await api.masterData.strategicPlan({
      themeBankId,
      ...id
        ? { id }
        : {}
    });
    return response?.data.errors
      ? []
      : response?.data;
  }, { initialData: [] });
  const strategicPlans = data;

  useEffect(() => { refetch(); }, [themeBankId]);
  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="ITM Strategic Plan"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        placeholder="Select Strategic Plan"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={strategicPlans}
      />
    </FormItem>
  );
};

StrategicPlan.propTypes = {
  disabled: PropTypes.bool,
  themeBankId: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  id: PropTypes.string,
  validation: PropTypes.object
};

export default StrategicPlan;
