import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';

const IpodView = (props) => {
  const {
    url,
    isActive = false
  } = props;

  return (
    <Box>
      {
        isActive && url
          ? (
            <iframe
              title="ipod-frame"
              src={url}
              style={{ width: '100%', minHeight: '1200px' }}
            />
          )
          : (
            <Box style={{
              display: 'flex',
              flexDirection: 'column'
            }}
            >
              <Typography
                style={{
                  alignSelf: 'center',
                  fontSize: 35,
                  fontWeight: 'bold'
                }}
              >
                No Data
              </Typography>
            </Box>
          )
      }
    </Box>
  );
};

IpodView.propTypes = {
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default IpodView;
