import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainerAssessment: {
    height: '500px',
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '2% 2%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px 24px 24px 24px',
    overflow: 'hidden'
  },
  formContainer: {
    height: '500px',
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '5.62% 6.46%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0 24px 24px 24px',
    overflow: 'hidden'
  },
  title: {
    color: '#1A051D',
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px'
  },
  subtitle: {
    color: '#1A051D',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    fontFamily: 'SFProText',
    lineHeight: '20px'
  },
  subContainer: {
    height: '500px',
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    marginTop: '10px',
    padding: 0
  },
  notfound: {
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  image: {
    width: 300,
    objectFit: 'contain',
  },
  gate: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.secondary.main,
    borderRadius: '24px',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px',
    marginBottom: '10px',
  },
  date: {
    color: '#949494',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px',
  },
  content: {
    color: 'white',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px',
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main,
    wordWrap: 'break-word',
    padding: '10px',
    marginBottom: '10px',
  },
  contentAssessment: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px',
    wordWrap: 'break-word',
    padding: '10px 0',
  },
  comment: {
    color: 'white',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
    fontFamily: 'SFProText',
  }
}));

const HistoryView = ({ data = [], isIdeaBlast, isAssessmentView = false }) => {
  const classes = useStyles();
  const filterData = data && data?.reduce((arr, item) => {
    if (isIdeaBlast) {
      item.gateDescription = 'Register';
    }
    const index = arr.findIndex(a => a[1].gateDescription === item.gateDescription)
    if (index < 0) {
      arr = [
        ...arr,
        [item.gateDescription,
        item]
      ]
    } else {
      arr[index].push(item)
    }
    return arr
  }, []);

  const container = React.useRef();

  React.useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container?.current?.scrollHeight;
    }
  }, [filterData, container.current]);

  return (
    <Box className={isAssessmentView ? classes.formContainerAssessment : classes.formContainer}>
      <Typography className={classes.title}>{isAssessmentView ? "Changes History" : "History"}</Typography>
      {data.length !== 0 ? 
        <Box className={classes.subContainer} ref={container}>
          {filterData.map((row, index) => {
          return(
            <div key={index}>
              { !isAssessmentView && (
                <Typography key={index} className={classes.gate}>
                  {row[0]}
                </Typography>
              )}
              {row.map((item, i) => {
                if (i > 0) {
                  return(
                    <div key={i} >
                      <Typography className={classes.date}>
                        {moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                      </Typography>
                      { !isAssessmentView && (
                        <Box className={classes.content}>
                          {item.createdBy} has {item.status.includes('Waiting') ? 'Created Document' : item.status}
                          {item.comment && 
                            <Typography className={classes.comment}>
                              and added comment: <br />
                              {item.comment}
                            </Typography>
                          }
                        </Box>
                      )}
                      { isAssessmentView && (
                        <>
                          <Typography className={classes.contentAssessment}>
                            {item?.users?.fullname} update Point {item?.description} from {item?.scoreFrom} to {item?.scoreTo}
                          </Typography>
                          <Divider style={{marginBottom: '10px'}} />
                        </>
                      )}
                    </div>
                  )
                }
              })}
            </div>
          )})}
        </Box>
      :
      <Box className={classes.notfound}>
        <img
          alt="not found"
          src="/static/images/notfound.svg"
          className={classes.image}
        />
        <Typography className={classes.subtitle}>
          Looks like there is no history for now..
        </Typography>
      </Box>}
    </Box>
  );
};

HistoryView.propTypes = {
  data: PropTypes.array,
  isIdeaBlast: PropTypes.bool
};

export default HistoryView;
