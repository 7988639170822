import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import StyledInput from 'src/components/StyledInput';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    width: '100%',
  },
  header: {
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px'
  },
  range: {
    backgroundColor: '#fce4c2',
    marginBottom: theme.spacing(2)
  },
  rate: {
    maxWidth: 150,
    whiteSpace: 'normal',
    verticalAlign: 'top',
    textAlign: 'center'
  },
  score: {
    maxWidth: 50,
    whiteSpace: 'normal',
    textAlign: 'center'
  }
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: '#EDEEFD',
    paddingTop: 10,
    paddingBottom: 10,
    border: '1px solid #BEB2C7'
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    border: '1px solid #BEB2C7'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const TableView = ({
  questions,
  handleChange,
  isSubmitting,
  disabled
}) => {
  const classes = useStyles();

  const getLengthOfSimiliar = (key, value) => {
    const sameValue = questions.filter((d) => d[key] === value);
    return sameValue.length;
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead className={classes.header}>
          <StyledTableRow>
            <StyledTableCell align="center" colSpan={2}>
              Criteria
              <br />
              <i>Kriteria</i>
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2} colSpan={3}>
              <Box>
                Score
                <br />
                <i>Nilai</i>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              <Box>
                POINT
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell align="center">
              Assessment
              <br />
              <i>Penilaian</i>
            </StyledTableCell>
            <StyledTableCell align="center">
              Description
              <br />
              <i>Uraian</i>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody className={classes.header}>
          {questions && questions?.map((row, i) => {
            const isSameWithPrevious = row?.valuation === questions[i - 1]?.valuation;
            return (
              <StyledTableRow key={row?.id}>
                {!isSameWithPrevious ? (
                  <StyledTableCell
                    align="center"
                    rowSpan={getLengthOfSimiliar('valuation', row?.valuation)}
                  >
                    {row?.valuation}
                  </StyledTableCell>
                ) : null}
                <StyledTableCell align="center">
                  {row?.clarification}
                </StyledTableCell>
                <StyledTableCell className={classes.rate}>
                  <Box className={classes.range}>
                    {row?.rate1}
                  </Box>
                  <Box>
                    {row?.description1}
                  </Box>
                </StyledTableCell>
                <StyledTableCell className={classes.rate}>
                  <Box className={classes.range}>
                    {row?.rate2}
                  </Box>
                  <Box>
                    {row?.description2}
                  </Box>
                </StyledTableCell>
                <StyledTableCell className={classes.rate}>
                  {row?.rate3 && (
                    <>
                      <Box className={classes.range}>
                        {row?.rate3}
                      </Box>
                      <Box>
                        {row?.description3}
                      </Box>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.score}>
                  <StyledInput
                    disabled={disabled}
                    error={isSubmitting && row?.score === 0}
                    name="score"
                    type="number"
                    value={row?.score}
                    onChange={(e) => handleChange(e, row?.questionId, row?.minScore, row?.maxScore)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableView.propTypes = {
  questions: PropTypes.array,
  handleChange: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default TableView;
