import React from 'react';
import {
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import StyledInput from './StyledInput';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1) + 2,
      paddingBottom: theme.spacing(1) + 2,
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: theme.spacing(1),
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '80vw'
    },
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    margin: 2,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  listbox: {
    maxHeight: 150,
    padding: 3,
  },
  inputBase: {
    // padding: theme.spacing(2),
  }
}));

const StyledAutoCompletePicker = ({
  options,
  isFetching,
  placeholder = '',
  onChange,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      classes={{
        paper: classes.paper,
        option: classes.option,
        popperDisablePortal: classes.popperDisablePortal,
        listbox: classes.listbox
      }}
      openOnFocus
      value={value || null}
      onChange={(event, newValue) => {
        onChange(newValue || null);
      }}
      loading={isFetching}
      noOptionsText="No User Found"
      renderOption={(option) => (
        <ListItemText
          primary={option.fullName || option.fullname}
          secondary={option.emailOffice || option.email}
        />
      )}
      options={options || []}
      getOptionSelected={(option) => option.fullName || option.fullname}
      getOptionLabel={(option) => option.fullName || option.fullname}
      renderInput={(params) => (
        <StyledInput
          {...params}
          fullWidth
          placeholder={placeholder}
          className={classes.inputBase}
        />
      )}
      {...rest}
    />
  );
};

StyledAutoCompletePicker.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  isFetching: PropTypes.bool,
};

export default StyledAutoCompletePicker;
