import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledInput from 'src/components/StyledInput';

const Remark = ({
  errors,
  touched,
  handleBlur,
  handleChange,
  values
}) => {
  const key = 'remark';
  const isDisabled = values.action === 'detail';

  return (
    <FormItem
      id={key}
      label="Remark"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        multiline
        name={key}
        type="text"
        placeholder="Enter remark"
        maxrows={10}
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isDisabled}
        error={Boolean(touched[key] && errors[key])}
      />
    </FormItem>
  );
};

Remark.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default Remark;
