import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bgContainer: {
    backgroundColor: fade(theme.palette.background.main, 0.2),
    borderRadius: 20,
    padding: 15
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  subTitle: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 'bold'
  },
  valueTitle: {
    color: theme.palette.common.black,
    fontSize: 24,
    fontWeight: 'bold'
  }
}));

const SummaryValueCaptureView = ({ values }) => {
  const classes = useStyles();

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  const addSpaceInAbbreviation = (value) => {
    return value.replace(/([A-Z])/g, ' $1');
  };

  return (
    <Grid container spacing={4} style={{ marginTop: 10 }}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.bgContainer}
        >
          <Typography className={classes.title}>Summary Project Completed</Typography>

          <Box
            display="flex"
            flexDirection="column"
            mt={5}
          >
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.card}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <img
                        src="/static/icons/ic_idea_blast.svg"
                        alt="idea blast icon"
                        style={{ alignSelf: 'flex-end' }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Typography
                        className={classes.valueTitle}
                      >
                        {formatter.format(values?.ideaBlast || 0)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    className={classes.subTitle}
                    style={{ alignSelf: 'center', marginTop: 15 }}
                  >
                    Idea Blast
                  </Typography>

                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.card}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <img
                        src="/static/icons/ic_digital.svg"
                        alt="digital icon"
                        style={{ alignSelf: 'flex-end' }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Typography
                        className={classes.valueTitle}
                      >
                        {formatter.format(values?.infinity || 0)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    className={classes.subTitle}
                    style={{ alignSelf: 'center', marginTop: 15 }}
                  >
                    Infinity
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.bgContainer}
        >
          <Typography className={classes.title}>Value Capture</Typography>

          <Box
            display="flex"
            flexDirection="column"
            mt={5}
          >
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.card}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <img
                        src="/static/icons/ic_investment.svg"
                        alt="investment icon"
                        style={{ alignSelf: 'flex-end' }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Typography
                        className={classes.valueTitle}
                      >
                        {addSpaceInAbbreviation(formatter.format(values?.investment || 0))}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    className={classes.subTitle}
                    style={{ alignSelf: 'center', marginTop: 15 }}
                  >
                    Investment
                  </Typography>

                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.card}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <img
                        src="/static/icons/ic_benefit_impact.svg"
                        alt="benefit impact icon"
                        style={{ alignSelf: 'flex-end' }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Typography
                        className={classes.valueTitle}
                      >
                        {addSpaceInAbbreviation(formatter.format(values?.benefitImpact || 0))}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    className={classes.subTitle}
                    style={{ alignSelf: 'center', marginTop: 15 }}
                  >
                    Benefit Impact
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

SummaryValueCaptureView.propTypes = {
  values: PropTypes.object
};

export default SummaryValueCaptureView;
