import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  },
  outlinedDanger: {
    border: `2px solid ${theme.palette.danger.main}`,
    color: theme.palette.danger.main,
    '&:hover': {
      border: `2px solid ${theme.palette.danger.main}`,
    }
  }
}));

const Decision = ({
  canSubmit,
  viewOnly,
  isAdminCanUnregister,
  dirty,
  handleSubmitOrSave = () => {},
  handleUnregister = () => {},
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { level } = useParams();
  const handleBackNav = () => {
    if (location.key === 'default') {
      navigate('/app/innovation-view');
    } else {
      navigate(`/app/innovation-convention-assessment/${level}`);
    }
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent={viewOnly && !isAdminCanUnregister ? 'end' : 'space-between'}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        {isAdminCanUnregister && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to unregister ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            onConfirm={() => handleUnregister()}
            className={clsx(classes.button, classes.outlinedDanger, classes.label)}
          >
            Unregister
          </ButtonConfirmation>
        )}
        {!viewOnly && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to save as draft ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="outlined"
            color="secondary"
            onConfirm={() => handleSubmitOrSave('Save Draft')}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Save as Draft
          </ButtonConfirmation>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        {!viewOnly && (
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={!canSubmit}
            onConfirm={() => handleSubmitOrSave('Submit')}
            className={clsx(classes.button, classes.label)}
          >
            Submit
          </ButtonConfirmation>
        )}
        {dirty
          ? (
            <ButtonConfirmation
              title="Confirmation"
              textDialog="Are you sure you want to close ?"
              textConfirm="Ok"
              textCancel="Cancel"
              variant="outlined"
              color="secondary"
              onConfirm={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </ButtonConfirmation>
          )
          : (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleBackNav}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Close
            </Button>
          )}
      </Box>
    </Box>
  );
};

Decision.propTypes = {
  canSubmit: PropTypes.bool,
  dirty: PropTypes.bool,
  viewOnly: PropTypes.bool,
  isAdminCanUnregister: PropTypes.bool,
  handleSubmitOrSave: PropTypes.func,
  handleUnregister: PropTypes.func
};

export default Decision;
