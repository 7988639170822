import MomentUtils from '@date-io/moment';
import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
  },
  input: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
  },
}));

const DatePicker = ({
  value, onChange, format = 'DD-MM-YYYY', ...rest
}) => {
  const handleDateChange = (date) => {
    onChange(date ? moment(date).toDate() : 'Invalid date');
  };
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        helperText=""
        InputAdornmentProps={{ position: 'start' }}
        inputProps={{ className: classes.input }}
        className={classes.root}
        disablePast={false}
        disableFuture={false}
        disableToolbar={false}
        variant="inline"
        format={format}
        fullWidth
        autoOk
        margin="dense"
        color="secondary"
        inputVariant="outlined"
        id="date-picker-inline"
        value={value
          ? moment(value, 'DD-MM-YYYY').toDate()
          : value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  format: PropTypes.string
};

export default DatePicker;
