import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { upperFirst } from 'lodash';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStoreActions } from 'easy-peasy';
import { Formik, Form } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import api from 'src/api';
import Number from './Number';
import State from './State';
import Demand from './Demand';
import ProductOwner from './ProductOwner';
import StageGate from './StageGate';
import Successes from './Successes';
import Challenges from './Challenges';
import Priorities from './Priorities';
import Interest from './Interest';
import Decision from './Decision';
import { initialValues, validation } from './Config';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  }
}));

const ModalForm = ({
  isVisible,
  isEditable,
  setEditable,
  detail,
  isOpen,
  setIsOpen,
  setSucceed
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const setNotif = useStoreActions((action) => action.notif.setNotif);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const { action, gate } = detail;

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setIsLoading(true); },
    onSettled: (data) => {
      setIsLoading(false);
      if (data && data.status < 300) {
        if (data?.data?.status === 20001) {
          setNotif({
            message: data?.data?.message || 'There is different assignment composition of each Committee. Please check and confirm among Committee',
            option: {
              variant: 'warning'
            }
          });
        } else {
          if (onSuccess) {
            data.status < 300 && onSuccess(data?.data);
          } else if (data.status < 300 && id) {
            if (location.key === 'default') {
              navigate('/app/innovation-view');
            } else {
              navigate(-1);
            }
          } else {
            navigate('/app/innovation-view');
          }
          setNotif({
            message: `${actionMessage} success`,
            option: {
              variant: 'success'
            }
          });
        }
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const generateForm = (data) => {
    const { 
      id,
      registrationId,
      state,
      stageGate,
      productOwner,
      successesWeek,
      challengesConcerns,
      nextPriorities,
      thingsOfInterest,
      ...rest
    } = data;
    
    switch (data.action) {
    case 'submit':
      return {
        registrationId: id,
        stateId: state,
        stageGateId: stageGate,
        productOwner: productOwner?.name,
        successesWeek,
        challengesConcerns,
        nextPriorities,
        thingsOfInterest
      };
    case 'update':
      return {
        id,
        registrationId,
        stateId: state,
        stageGateId: stageGate,
        productOwner: productOwner.name,
        successesWeek,
        challengesConcerns,
        nextPriorities,
        thingsOfInterest
      };
    default:
      return {};
    }
  };

  const submit = useMutation(
    (form) => api.weeklyUpdate.submit(form),
    mutateOption('Submit', (data) => {
      setIsOpen(false);
      setSucceed(true);
    })
  );

  const edit = useMutation(
    (form) => {
      const dataId = form.id;
      delete form.id;

      return api.weeklyUpdate.edit(dataId, form);
    },
    mutateOption('Edit', (data) => {
      setIsOpen(false);
      setSucceed(true);
    })
  );

  const handleDelete = useMutation(
    (form) => {
      const dataId = form.id;
      delete form.id;

      return api.weeklyUpdate.delete(dataId);
    },
    mutateOption('Delete', (data) => {
      setIsOpen(false);
      setSucceed(true);
    })
  );

  return (
    <Dialog
      disableEnforceFocus 
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography variant="h3" className={classes.title}>
              { upperFirst(action) }
              {' '}
              Weekly Update
            </Typography>
            <IconButton style={{ marginLeft: 'auto' }} onClick={() => setIsOpen(false)}>
              <Close className={classes.close} />
            </IconButton>
          </Box>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          <Formik
            initialValues={initialValues(detail)}
            initialErrors={Yup.object().shape(validation(detail))}
            validationSchema={Yup.object().shape(validation(detail))}
            onSubmit={(values, actions) => {
              const { action } = values;
              actions.setSubmitting(true);

              switch (action) {
              case 'submit': {
                submit.mutate(generateForm(values));
                break;
              }
              case 'update': {
                edit.mutate(generateForm(values));
                break;
              }
              default:
                break;
              }

              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              isValid,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              submitForm,
              values
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item lg={6}>
                      <Number
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <State
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Demand
                        {...{
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <ProductOwner
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          setFieldValue,
                          setFieldTouched,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <StageGate
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Successes
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Challenges
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Priorities
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Interest
                        {...{
                          isEditable,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          values
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Decision
                      canSubmit={!isSubmitting && isValid}
                      {...{
                        isVisible,
                        isLoading,
                        isEditable,
                        setEditable,
                        setIsOpen,
                        setFieldValue,
                        handleDelete,
                        submitForm,
                        values
                      }}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ModalForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSucceed: PropTypes.func.isRequired
};

export default ModalForm;
