import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import GateFilter from './GateFilter';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15,
    height: '100%'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const BarChart = ({
  isHasFilter = true,
  title,
  chartOption,
  chartSeries,
  filter,
  setFilter,
  filterOptions
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.card}
    >
      <Box
        display="flex"
        flexDirection="row"
        padding={5}
      >
        <Grid container spacing={4}>
          <Grid item lg={isHasFilter ? 8 : 12} md={isHasFilter ? 8 : 12} sm={12} xs={12}>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          {
            isHasFilter && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <GateFilter
                  values={filter}
                  gateOptions={filterOptions}
                  setFilter={setFilter}
                />
              </Grid>
            )
          }
        </Grid>
      </Box>
      <Chart
        options={chartOption}
        series={chartSeries}
        type="bar"
      />
    </Box>
  );
};

BarChart.propTypes = {
  isHasFilter: PropTypes.bool,
  title: PropTypes.string.isRequired,
  chartOption: PropTypes.object.isRequired,
  chartSeries: PropTypes.array.isRequired,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  filterOptions: PropTypes.array
};

export default BarChart;
