import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import HRISSelector from 'src/components/HRISSelector';

const UserHRISPicker = ({
  checkbox,
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  values
}) => {
  const key = 'user';
  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };
  const error = errors[key];
  const message = error?.id;
  return (
    <FormItem
      label="User"
      id={key}
      error={Boolean(touched[key] && message)}
      helperText={touched[key] && message}
    >
      <HRISSelector
        setFieldValue={setFieldValue}
        disabled={disabled}
        value={values[key]}
        checkbox={checkbox}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Search name/email"
        error={Boolean(touched[key] && message)}
      />
    </FormItem>
  );
};

UserHRISPicker.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default UserHRISPicker;
