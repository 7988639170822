import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RegistrationNumber from './RegistrationNumber';
import Status from './Status';
import InnovationArea from './InnovationArea';
import SubmittedBy from './SubmittedBy';
import InnovationType from './InnovationType';
import RequestedFor from './RequestedFor';
import Company from './Company';
import ThemeBank from './ThemeBank';
import Site from './Site';
import Category from './Category';
import TeamMember from './TeamMember';
import TechnologySupport from './TechnologySupport';
import Promotor from './Promotor';
import StrategicPlan from './StrategicPlan';
import FacilitatorOrScrumMaster from './FacilitatorOrScrumMaster';
import DemandType from './DemandType';

const GeneralView = (props) => {
  const { values, isDraft } = props;
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RegistrationNumber {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Status {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InnovationArea {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <SubmittedBy {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InnovationType {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RequestedFor {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ThemeBank {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Company {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <StrategicPlan {...props} themeBankId={values.themeBankId} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Site {...props} requestedFor={values.requestedFor} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Category {...props} disabled={!isDraft} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <TeamMember {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Promotor isIndividual={values?.category === 'Individual'} {...props} />
      </Grid>
      { values.category === 'IT Demand'
        && (
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <DemandType {...props} />
          </Grid>
        )}
      { values.category !== 'Individual'
        && (
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <FacilitatorOrScrumMaster {...props} />
          </Grid>
        )}
      { values.category !== 'Individual'
        && (
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TechnologySupport {...props} />
          </Grid>
        )}
    </Grid>
  );
};

GeneralView.propTypes = {
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isDraft: PropTypes.bool,
};

export default GeneralView;
