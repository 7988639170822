import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  InputBase
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
  Search,
  Cancel,
  Edit
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      backgroundColor: '#EDEEFD',
      color: '#1A051D',
      fontWeight: 'bold',
    },
  },
  innovationTitle: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 13,
    lineHeight: '18px',
    fontWeight: 'normal',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 5
  },
  subtitle: {
    color: '#3F3356',
    fontFamily: 'SFProText',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '18px',
    marginTop: 10,
    textAlign: 'left'
  },
  boxInputNumber: {
    marginLeft: 10,
    borderRadius: 8,
    border: '1px solid #D7D0DC',
    marginRight: 10,
    paddingRight: 10,
    height: 30,
    minWidth: 40,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  inputNumber: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'right',
    margin: 'auto'
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  contentShowing: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  contentPagination: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  button: {
    padding: 10,
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    width: '100%',
    margin: 'auto'
  },
  search: {
    width: '100%',
    minWidth: 120,
    margin: 'auto'
  },
  searchIcon: {
    color: theme.palette.common.black,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderRadius: 18,
    paddingRight: `calc(0.5em + ${theme.spacing(1)}px)`,
    border: '2px solid #D7D0DC',
  },
  inputInput: {
    paddingLeft: `calc(0.5em + ${theme.spacing(1)}px)`,
    width: '100%',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const TableList = ({
  data,
  header = [
    { field: 'test' },
    { field: 'test2' },
  ],
  search,
  setSearch,
  limitOption = [20, 50, 100, 500, 1000],
  currentPage,
  pageSize = 20,
  totalPage,
  totalData,
  showing,
  handleSort = () => {},
  handleLimitItems = () => {},
  onChangePagination = () => {},
  handleClearSearch = () => {},
  handleSearch = () => {},
  handleEnterSearch = () => {},
  handleAddUser = () => {},
  handleEditUser = () => {},
}) => {
  const classes = useStyles();

  const showSort = (sort) => {
    switch (sort) {
      case true:
        return <ArrowDropDown style={{ fontSize: 24, cursor: 'pointer' }} />;
      case false:
        return <ArrowDropUp style={{ fontSize: 24, cursor: 'pointer' }} />;
      default:
        return <div style={{ width: 24 }} />;
    }
  };

  const [showEntries, setShowEntries] = useState(null);
  const handleOpen = (event) => { setShowEntries(event.currentTarget); };
  const handleClose = () => { setShowEntries(null); };
  return (
    <Grid container style={{ marginTop: 30, marginBottom: 30, }}>
      <Grid container spacing={4} style={{ margin: 'auto', }}>
        <Grid item lg={2} md={12} sm={12} xs={12} style={{}}>
          <Button
            className={classes.button}
            color="primary"
            size="large"
            type="submit"
            variant="outlined"
            fullWidth
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} style={{ margin: 'auto' }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.innovationTitle}>
              Show
            </Typography>
            <Box className={classes.boxInputNumber} onClick={handleOpen}>
              <div style={{
                display: 'flex', height: 28, marginRight: 2, flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <UnfoldMore />
              </div>
              <div>
                <Typography className={classes.inputNumber}>
                  {pageSize}
                </Typography>
              </div>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={showEntries}
              keepMounted
              open={Boolean(showEntries)}
              onClose={handleClose}
            >
              {limitOption?.map((limit) => (
                <MenuItem
                  key={limit}
                  onClick={() => {
                    handleLimitItems(limit);
                    setShowEntries(null);
                  }}
                >
                  {limit}
                </MenuItem>
              ))}
            </Menu>
            <Typography className={classes.innovationTitle}>
              Entries
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} md={12} sm={12} xs={12} style={{ padding: 10 }}>
          <div className={classes.search}>
            <InputBase
              name="search"
              placeholder="Search…"
              fullWidth
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={search}
              endAdornment={(
                <>
                  { search
                    && (
                      <IconButton style={{ padding: 0 }} onClick={handleClearSearch}>
                        <Cancel />
                      </IconButton>
                    )}
                  <IconButton style={{ padding: 0 }} onClick={handleSearch}>
                    <Search />
                  </IconButton>
                </>
              )}
              inputProps={{ 'aria-label': 'search', color: '#D0C9D6' }}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleEnterSearch}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container item style={{ marginTop: 20 }}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead className={classes.header}>
              <StyledTableRow>
                {header?.map((row, index) => {
                  return (
                    <StyledTableCell
                      key={row?.field}
                      isFirst={index === 0}
                      isLast={index === (header.length - 1)}
                    >
                      <Box
                        className={classes.sortContainer}
                        onClick={() => handleSort(row.sort)}
                      >
                        {row.field}
                        {showSort(row?.isSort)}
                      </Box>
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            </TableHead>
            <TableBody className={classes.header}>
              {data && data.map((row) => {
                return (
                  <StyledTableRow key={row?.id} style={{ marginBottom: '5px' }}>
                    <StyledTableCell
                      align="left"
                      style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
                    >
                      {row?.fullname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.username}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.email}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.company?.name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.organizations?.name || row?.organizationName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box flexDirection="column" display="flex">
                        {row?.roles?.map((d) => (
                          <Box key={d.id}>{d.name}</Box>
                        ))}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box flexDirection="row" display="flex">
                        <IconButton
                          onClick={() => handleEditUser(row)}
                          style={{ padding: 0, marginRight: 10 }}
                        >
                          <Tooltip title="Edit Role" placement="top" arrow>
                            <Edit />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          className={classes.contentShowing}
          lg={6}
          md={6}
          sm={5}
          xs={12}
          style={{ marginTop: 20 }}
        >
          <Typography className={classes.subtitle}>
            {`Showing ${showing?.show || 0} to ${showing?.to || 0} of ${totalData || 0} data entries`}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.contentPagination}
          lg={6}
          md={6}
          sm={7}
          xs={12}
          container
          style={{ marginTop: 20, justifyContent: 'flex-end' }}
        >
          <Pagination
            onChange={onChangePagination}
            className={classes.pagination}
            color="secondary"
            type="next"
            count={totalPage}
            page={currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

TableList.propTypes = {
  data: PropTypes.array,
  header: PropTypes.array,
  limitOption: PropTypes.array,
  handleSort: PropTypes.func,
  onChangePagination: PropTypes.func,
  handleLimitItems: PropTypes.func,
  setSearch: PropTypes.func,
  pageSize: PropTypes.number,
  totalPage: PropTypes.number,
  totalData: PropTypes.number,
  currentPage: PropTypes.number,
  search: PropTypes.string,
  showing: PropTypes.object,
  handleClearSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  handleEnterSearch: PropTypes.func,
  handleAddUser: PropTypes.func,
  handleEditUser: PropTypes.func,
};

export default TableList;
