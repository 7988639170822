import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FormItem from './FormItem';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textInput: {
    borderRadius: 15,
    marginTop: 5
  }
}));

const ButtonTextFieldConfirmation = ({
  title,
  required = false,
  message,
  isSubmitting = false,
  onConfirm,
  textDialog = '',
  textConfirm = 'Yes',
  textCancel = 'No',
  children,
  ...buttonProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [touched, setTouched] = useState(null);
  const [disable, setDisable] = useState(isSubmitting);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!text.length && required) {
      setDisable(true);
      setError(true);
      return;
    }

    setDisable(false);
    setError(false);
  }, [text]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setDisable(true);
    onConfirm && onConfirm(text);
    setOpen(false);
    setDisable(false);
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => handleClickOpen()}
      >
        {children}
      </Button>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{textDialog}</DialogContentText>
          <FormItem
            label="Comment"
            error={Boolean(error && touched)}
            helperText={error && touched ? 'Comment is required.' : ''}
          >
            <TextField
              className={classes.textInput}
              name="comment"
              variant="outlined"
              multiline
              rows={4}
              value={text}
              error={Boolean(error && touched)}
              onChange={(e) => setText(e.target.value)}
              onBlur={(e) => setTouched(e)}
            />
          </FormItem>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={disable || error}
            onClick={handleConfirm}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            {textConfirm}
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClose}
          >
            {textCancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ButtonTextFieldConfirmation.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  textDialog: PropTypes.string,
  textConfirm: PropTypes.string,
  textCancel: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default ButtonTextFieldConfirmation;
