import React from 'react';

import {
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import OneTimeBenefit from './OneTimeBenefit';
import OneTimeCost from './OneTimeCost';
import RecurringBenefit from './RecurringBenefit';
import RecurringCost from './RecurringCost';
import MmpFundingCapex from './MmpFundingCapex';
import MmpFundingOpex from './MmpFundingOpex';
import Guidance from './Guidance';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const CostView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Planned Benefit & Cost</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <RecurringCost {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <RecurringBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <OneTimeCost {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <OneTimeBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MmpFundingCapex {...props} />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <MmpFundingOpex {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Guidance />
      </Grid>
    </Grid>
  );
};

export default CostView;
