import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import RegistrationNumber from './RegistrationNumber';
import Status from './Status';
import InnovationArea from './InnovationArea';
import SubmittedBy from './SubmittedBy';
import InnovationType from './InnovationType';
import RequestedFor from './RequestedFor';
import Classification from './Classification';
import Company from './Company';
import ThemeBank from './ThemeBank';
import Site from './Site';
import Category from './Category';
import DigitalCoach from './DigitalCoach';
import TeamMember from './TeamMember';
import TechnologySupport from './TechnologySupport';
import Promotor from './Promotor';

const GeneralView = (props) => {
  const { values } = props;
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RegistrationNumber {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Status {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InnovationArea {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <SubmittedBy {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InnovationType {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RequestedFor {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Classification {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Company {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ThemeBank {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Site {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Category {...props} disabled />
      </Grid>
      { values.category === 'Project Team'
        && (
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <DigitalCoach {...props} />
          </Grid>
        )}
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <TeamMember {...props} />
      </Grid>
      { values.category === 'Project Team'
        && (
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <TechnologySupport {...props} />
          </Grid>
        )}
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Promotor {...props} />
      </Grid>
    </Grid>
  );
};

GeneralView.propTypes = {
  values: PropTypes.object.isRequired,
};

export default GeneralView;
