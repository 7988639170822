import React, { useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Tooltip,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 15,
    boxShadow: '20px 20px 20px 0 rgba(233, 234, 241, 0.62)',
    padding: 20
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  guidance: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 25,
    fontWeight: 'bold'
  },
  description: {
    color: theme.palette.common.black,
    fontSize: 15
  }
}));

const Guidance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const handleOnCloseTooltip = () => {
    setIsOpen(false);
  };

  const handleOnOpenTooltip = () => {
    setIsOpen(true);
  };

  return (
    <Box>
      <ClickAwayListener onClickAway={handleOnCloseTooltip}>
        <CustomTooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="top-start"
          onClose={handleOnCloseTooltip}
          open={isOpen}
          title={(
            <Box display="flex" flexDirection="column">
              <Typography className={classes.title}>Guidance</Typography>
              <Box mt={5}>
                {/* TODO : Clarify guidance description */}
                <Typography className={classes.description}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </Box>
            </Box>
          )}
        >
          <Typography onClick={handleOnOpenTooltip} className={classes.guidance}>
            Guidance
          </Typography>
        </CustomTooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default Guidance;
