import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Paper,
  withStyles,
} from '@material-ui/core';

import api from 'src/api';
import AlertConfirmation from 'src/components/AlertConfirmation';
import Page from 'src/components/Page';
import NumberFormatCustom from 'src/components/NumberFormat';
import StyledInput from 'src/components/StyledInput';
import StyledAutoCompletePicker from 'src/components/StyledAutoCompletePicker';
import Toolbar from 'src/views/myDocument/Toolbar';
import Decision from './Decision';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  tableHeaderContainer: {
    boxShadow: 'none',
    width: '100%',
  },
}));

const StyledCell = withStyles(() => ({
  head: {
    backgroundColor: '#737AF1',
    borderBottomWidth: 4,
    borderLeft: '4px solid white',
    borderColor: 'white',
    color: 'white',
    fontWeight: 600,
    paddingTop: 6,
    paddingBottom: 6,
  },
  body: {
    fontSize: 14,
    backgroundColor: 'rgb(205, 198, 225, 0.25)',
    borderTopWidth: 4,
    borderBottomWidth: 4,
    borderLeft: '4px solid white',
    borderColor: 'white',
    paddingTop: 12,
    paddingBottom: 12,
  }
}))(TableCell);

const FormView = () => {
  const classes = useStyles();
  const { id, level, mode } = useParams();
  const [params] = useSearchParams();
  const judgeId = params.get('judgeId');
  const navigate = useNavigate();

  const user = useStoreState((state) => state.auth.user);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const isAdmin = user?.roles.some((role) => role.name === 'Administrator');
  const isAdminSite = user?.roles.some((role) => role.name === 'Admin Site');
  const isViewOnly = mode.toLocaleLowerCase() === 'view';
  const isSiteLevel = level.toLocaleLowerCase() === 'site';

  const [questions, setQuestions] = useState([]);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [judges, setJudges] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [selectedJudge, setSelectedJudge] = useState(null);
  const [isAdminCanViewAll, setIsAdminCanViewAll] = useState(false);
  const [openAlert, setOpenAlert] = useState({ open: false, message: '' });

  const canSubmit = !questions?.filter((d) => d.grade === 0 || !d.grade)?.length > 0
    && !questions?.filter((d) => !d.description || d.description?.length === 0)?.length > 0;

  const calculateScore = (value, maxValue, weight) => {
    if (!value) return 0;

    const score = (Number(value) / Number(maxValue)) * Number(weight);
    return Math.round(score * 10) / 10;
  };

  const mappingAssessmentItem = (currentAssessment) => {
    /* mapping status, max, min into `assessmentDetail`
     * mapping innovationAssessmentItems into `questions`
     */
    setAssessmentDetail((prevState) => ({
      ...prevState,
      status: currentAssessment?.status,
      max: currentAssessment?.maxGrade,
      min: currentAssessment?.minGrade
    }));

    const formattedQuestions = currentAssessment?.innovationAssessmentItems.map((item) => {
      return {
        ...item,
        grade: !item?.grade || item?.grade === 0 ? '' : item?.grade,
        itemDescription: item?.convensionAssessmentItems?.itemDescription,
        weight: item?.convensionAssessmentItems?.weight,
        score: calculateScore(
          item?.grade || null,
          currentAssessment?.maxGrade,
          item?.convensionAssessmentItems?.weight,
        ),
      };
    });
    setQuestions(formattedQuestions);
  };

  const fetchAssessmentItems = async () => {
    setLoading(true);

    try {
      const { data: { data } } = isSiteLevel
        ? await api.assessment.items(id)
        : await api.assessment.itemsITMLevel(id);
      const { datas: assessmentItems, ...detail } = data;
      setAssessmentDetail({ ...detail, assessmentItems });

      // Get judges list
      if (isAdmin || isAdminSite) {
        const { data: { data: judgesData } } = isSiteLevel
          ? await api.assessment.judges({ Filters: `registrationid==${id}` })
          : await api.assessment.judgesITMLevel({ Filters: `registrationid==${id}` });
        setJudges(judgesData);
      }

      // find assessment by user if user is valid judges
      const currentAssessment = detail?.isValidJudges
        ? assessmentItems.find((d) => d.judgeId === user?.id)
        : undefined;

      // needed for judges dropdown UI and unregister button
      const isAdminInViewMode = ((isAdmin || isAdminSite) && mode.toLocaleLowerCase() === 'view')
        || ((isAdmin || isAdminSite) && !detail?.isValidJudges)
        || ((isAdmin || isAdminSite) && detail?.isValidJudges && currentAssessment?.status === 'Assessed');
      setIsAdminCanViewAll(isAdminInViewMode);

      // needed to display data for judges only or judges who haven't completed their assessment
      if (!isAdminInViewMode) {
        mappingAssessmentItem(currentAssessment);
      }

      /* handle for edit mode
       * navigate to view mode if doesn't meet the specified conditions
       * or if user is admin, filter judges who haven't completed their assessment
       * and set default selected judges
      */
      if (!isViewOnly) {
        const handleJudgesFilter = () => {
          setJudges((prevState) => {
            const filteredJudgesList = prevState.filter((judge) => judge.status.includes('Not Assessed'));
            if (filteredJudgesList.length === 1) {
              setSelectedJudge(filteredJudgesList[0]);
            }
            return filteredJudgesList;
          });
        };

        if (detail?.isValidJudges) {
          if (currentAssessment?.status === 'Assessed') {
            if (isAdmin) {
              handleJudgesFilter();
            } else {
              navigate(`/app/innovation-convention-assessment/${level}/${id}/view`);
            }
          }
        } else if (isAdmin) {
          handleJudgesFilter();
        } else {
          navigate(`/app/innovation-convention-assessment/${level}/${id}/view`);
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setNotif({
        message: err?.data?.Message || err?.data?.message?.messageEng || 'Failed to get data from the server',
        option: {
          variant: 'error'
        }
      });
    }
  };

  const handleSubmitOrSave = async (action) => {
    setLoading(true);

    let currentJudgeId;
    if (isAdminCanViewAll && selectedJudge?.id) {
      currentJudgeId = selectedJudge.id;
    } else if (assessmentDetail?.isValidJudges && assessmentDetail?.status !== 'Assessed') {
      currentJudgeId = user?.id;
    }

    const currentAssessment = assessmentDetail?.assessmentItems
      .find((d) => d.judgeId === currentJudgeId);
    const hasResponse = !!currentAssessment?.assessmentId;

    const data = {
      convensionAssessmentItems: questions.map((item) => {
        const {
          convensionAssessmentItems, innovationAssessmentItems, max, min, score, ...newItem
        } = item;
        if (isSiteLevel) {
          newItem.id = hasResponse ? newItem.id : newItem.assessmentItemId;
        }
        return newItem;
      }),
      maxGrade: assessmentDetail?.maxGrade,
      totalScore: questions.reduce((acc, item) => acc + item.score, 0),
      registrationId: id,
      judgeId: currentJudgeId,
      assessmentId: currentAssessment?.assessmentId || null,
      isDraft: action === 'Save Draft',
    };

    try {
      let response = {};
      if (isSiteLevel) {
        response = !hasResponse
          ? await api.assessment.post({ ...data })
          : await api.assessment.put(currentAssessment.assessmentId, { ...data });
      } else {
        response = await api.assessment.submitAssessmentITMLevel({ ...data });
      }

      setLoading(false);
      if (response.status < 300) {
        navigate(`/app/innovation-convention-assessment/${level}`);
        setNotif({
          message: `${action === 'Submit'
            ? 'You have successfuly submit form assessment'
            : 'You have successfuly save form assessment as draft'}`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: response?.data?.Message || response?.data?.message?.messageEng
          || `${action === 'Submit'
            ? 'Failed to submit form assessment'
            : 'Failed to save form assessment as draft'}`,
          option: {
            variant: 'error'
          }
        });
      }
    } catch (err) {
      setLoading(false);
      setNotif({
        message: err?.data?.Message || err?.data?.message?.messageEng
        || `${action === 'Submit'
          ? 'Failed to submit form assessment'
          : 'Failed to save form assessment as draft'}`,
        option: {
          variant: 'error'
        }
      });
    }
  };

  const handleUnregister = async () => {
    setLoading(true);

    try {
      const response = await api.assessment.unregister(assessmentDetail.registrationId);

      setLoading(false);
      if (response.status < 300) {
        navigate(`/app/innovation-convention-assessment/${level}`);
        setNotif({
          message: 'You have successfuly unregistered form assessment',
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: response?.data?.Message || response?.data?.message?.messageEng || 'Failed to unregister form assessment',
          option: {
            variant: 'error'
          }
        });
      }
    } catch (err) {
      setLoading(false);
      setNotif({
        message: err?.data?.Message || err?.data?.message?.messageEng || 'Failed to unregister form assessment',
        option: {
          variant: 'error'
        }
      });
    }
  };

  const handleChange = (e, key, questionId, min, max) => {
    setDirty(true);
    const newValue = questions.map((d) => {
      if (d.assessmentItemId === questionId) {
        if (!e.target.value) {
          return {
            ...d,
            [key]: null,
            score: key === 'grade' ? 0 : d.score
          };
        }
        return {
          ...d,
          [key]: key === 'grade' ? Number(e.target.value) : e.target.value,
          score: key === 'grade' ? calculateScore(Number(e.target.value), max, d.weight) : d.score
        };
      }
      return d;
    });
    setQuestions(newValue);
  };

  const handleFormattingValue = (e, questionId, min, max) => {
    if (e.target.value) {
      const value = Number(e.target.value);
      const grade = value >= min && value <= max ? value : '';
      const newValue = questions.map((d) => (d.assessmentItemId === questionId ? {
        ...d,
        grade,
        score: calculateScore(grade, max, d.weight)
      } : d));
      setQuestions(newValue);

      if (value < min || value > max) {
        setOpenAlert({
          open: true,
          message: `Out of range input is not accepted. Only input within the range of ${min} to ${max} is accepted.`
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey) {
      return;
    }

    const regex = /^[0-9.]*$/;
    if (!(regex.test(e.key) || e.key.length > 1)) {
      setOpenAlert({
        open: true,
        message: 'Input is limited to numbers and a dot (.) for the decimal separator.'
      });
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert({
      open: false,
      message: ''
    });
  };

  useEffect(() => {
    if (id) {
      fetchAssessmentItems();
    }
  }, [id, mode]);

  useEffect(() => {
    if (judgeId && judges.length > 0) {
      const defaultJudge = judges.find((d) => d.id === judgeId);
      setSelectedJudge(defaultJudge);
    }
  }, [judgeId, judges]);

  useEffect(() => {
    if (isAdminCanViewAll) {
      const currentAssessment = assessmentDetail?.assessmentItems
        .find((d) => d.judgeId === selectedJudge?.id);

      mappingAssessmentItem(currentAssessment);
    }
  }, [selectedJudge]);

  return (
    <>
      <Page
        className={classes.root}
        title="Form Assessment"
      >
        <Container maxWidth={false}>
          <Toolbar path="Form Assessment" />
          {assessmentDetail?.status && (
            <Box display="flex" justifyContent="flex-end">
              <Box
                style={{
                  backgroundColor: assessmentDetail?.status === 'Assessed' ? '#44D7B6' : '#D0C9D6',
                  borderRadius: 14,
                  padding: 5,
                  paddingRight: 15,
                  paddingLeft: 15,
                  width: 'fit-content'
                }}
              >
                <Typography style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                  {assessmentDetail?.status}
                </Typography>
              </Box>
            </Box>
          )}
          <Box className={classes.card} mt={5}>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={10} xs={10}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  p={4}
                  pb={0}
                >
                  <TableContainer component={Paper} className={classes.tableHeaderContainer}>
                    <Table sx={{ minWidth: 700 }} aria-label="form assessment detail header">
                      <TableHead>
                        <TableRow>
                          <StyledCell align="center" colSpan={2}>
                            Assessment Form for the 14th ITMNovation Convention site level
                            {' '}
                            {`(${assessmentDetail?.organization?.code || '-'})`}
                          </StyledCell>
                        </TableRow>
                        <TableRow>
                          <StyledCell align="center">Judges Name</StyledCell>
                          <StyledCell align="center">Team Name</StyledCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <StyledCell align="center">
                            {isAdminCanViewAll ? (
                              <StyledAutoCompletePicker
                                placeholder="Select Judges"
                                value={selectedJudge}
                                onChange={(newVal) => setSelectedJudge(newVal)}
                                options={judges}
                              />
                            ) : (
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                }}
                              >
                                {user?.name}
                              </Typography>
                            )}
                          </StyledCell>
                          <StyledCell align="center">
                            <Typography
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                              }}
                            >
                              {assessmentDetail?.initiative}
                            </Typography>
                          </StyledCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  p={4}
                >
                  <TableContainer component={Paper} className={classes.tableHeaderContainer}>
                    <Table sx={{ minWidth: 700 }} aria-label="form assessment detail content">
                      <TableHead>
                        <TableRow>
                          <StyledCell align="center">No.</StyledCell>
                          <StyledCell align="center">Item Assessment</StyledCell>
                          <StyledCell align="center">Bobot</StyledCell>
                          <StyledCell align="center">Nilai 6-10</StyledCell>
                          <StyledCell align="center" width="250">Description</StyledCell>
                          <StyledCell align="center">Score</StyledCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {questions?.map((item, index) => (
                          <TableRow key={item.assessmentItemId}>
                            <StyledCell align="center">{index + 1}</StyledCell>
                            <StyledCell align="left">{item?.itemDescription}</StyledCell>
                            <StyledCell align="center">
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px'
                                }}
                              >
                                {item?.weight}
                              </Typography>
                            </StyledCell>
                            <StyledCell align="center">
                              {((isAdminCanViewAll && !selectedJudge) || isViewOnly) ? (
                                <Typography
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px'
                                  }}
                                >
                                  {item?.grade || '-'}
                                </Typography>
                              ) : (
                                <StyledInput
                                  name="value"
                                  inputProps={{
                                    decimalScale: 1
                                  }}
                                  // eslint-disable-next-line react/jsx-no-duplicate-props
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  style={{
                                    marginTop: '4px',
                                  }}
                                  height="20px"
                                  placeholder="Enter"
                                  value={item?.grade}
                                  onChange={
                                    (e) => handleChange(e, 'grade', item.assessmentItemId, assessmentDetail.min, assessmentDetail.max)
                                  }
                                  onBlur={
                                    (e) => handleFormattingValue(
                                      e,
                                      item.assessmentItemId,
                                      assessmentDetail.min,
                                      assessmentDetail.max
                                    )
                                  }
                                  onKeyDown={(e) => handleKeyDown(e)}
                                />
                              )}
                            </StyledCell>
                            <StyledCell align={(isAdminCanViewAll && !selectedJudge) || isViewOnly ? 'left' : 'center'}>
                              {((isAdminCanViewAll && !selectedJudge) || isViewOnly) ? (
                                <Typography
                                  style={{
                                    fontSize: '14px'
                                  }}
                                >
                                  {item?.description || '-'}
                                </Typography>
                              ) : (
                                <StyledInput
                                  name="description"
                                  placeholder="Enter"
                                  multiline
                                  style={{
                                    marginTop: '4px',
                                  }}
                                  value={item?.description}
                                  onChange={(e) => handleChange(e, 'description', item.assessmentItemId)}
                                />
                              )}
                            </StyledCell>
                            <StyledCell align="center">
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                }}
                              >
                                {item?.score}
                              </Typography>
                            </StyledCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={5} mb={5}>
            <Decision
              viewOnly={(isAdminCanViewAll && !selectedJudge) || isViewOnly}
              isAdminCanUnregister={isAdminCanViewAll}
              handleSubmitOrSave={handleSubmitOrSave}
              handleUnregister={handleUnregister}
              dirty={dirty}
              canSubmit={canSubmit}
            />
          </Box>
        </Container>
      </Page>
      <AlertConfirmation
        title="Oops!"
        textDialog={openAlert.message}
        textConfirm="Ok"
        hideCancelButton
        showTextInput={false}
        onConfirm={handleCloseAlert}
        open={openAlert.open}
      />
    </>
  );
};

export default FormView;
