import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import InnovationView from 'src/views/innovation/InnovationView';
import RegistrationView from 'src/views/registration';
import InnovationRepository from './views/innovationRepository';
import MyDocument from './views/myDocument';
import MyActivity from './views/myActivity';
import AssessmentView from './views/registration/AssessmentView';
import Dashboard from './views/dashboard';
import SettingLayout from './layouts/SettingLayout';
import UserManagement from './views/setting/UserManagement';
import IdeaBlastScore from './views/setting/IdeaBlast/Score';
import IdeaBlastScoreDetail from './views/setting/IdeaBlast/Score/Detail';
import IdeaBlastScoreEdit from './views/setting/IdeaBlast/Score/Edit';
import GeneralSearch from './views/generalSearch';
import RewardSummary from './views/setting/IdeaBlast/RewardSummary';
import ReminderHistory from './views/setting/ReminderHistory';
import BenefitCategory from './views/setting/BenefitCategory';
import InnovationConventionAssessment from './views/innovationConvention/formAssessment';
import InnovationConventionAssessmentDetail from './views/innovationConvention/formAssessment/detail';
import InnovationConventionAssessmentSummary from './views/innovationConvention/summary';
import InnovationInvolvement from './views/innovationInvolvement';
import AssessmentQuestion from './views/setting/AssessmentQuestion';

const routes = (token, isAdmin, lastRoute) => {
  const defaultRoute = '/app/innovation-view';

  return [
    {
      path: 'app',
      element: token ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'registration', element: <RegistrationView /> },
        { path: 'registration/:id', element: <RegistrationView /> },
        { path: 'my-document', element: <MyDocument /> },
        { path: 'my-activity', element: <MyActivity /> },
        { path: 'innovation-view', element: <InnovationView /> },
        { path: 'innovation-repository', element: <InnovationRepository /> },
        { path: 'innovation-involvement', element: <InnovationInvolvement /> },
        { path: 'innovation-convention-assessment/:level', element: <InnovationConventionAssessment /> },
        { path: 'innovation-convention-assessment/:level/:id/:mode', element: <InnovationConventionAssessmentDetail /> },
        { path: 'innovation-convention-assessment-summary/:level', element: <InnovationConventionAssessmentSummary /> },
        { path: 'form-assessment/:id', element: <AssessmentView /> },
        { path: 'search', element: <GeneralSearch /> },
        {
          ...isAdmin && {
            path: 'setting',
            element: <SettingLayout />,
            children: [
              { path: 'user-management', element: <UserManagement /> },
              { path: 'reminder-history', element: <ReminderHistory /> },
              { path: 'benefit-category', element: <BenefitCategory /> },
              { path: 'assessment-question', element: <AssessmentQuestion /> },
              {
                path: 'inoc',
                children: [
                  { path: 'administrator', element: <></> },
                  { path: 'category', element: <></> },
                  { path: 'infinity-method', element: <></> },
                  { path: 'person', element: <></> },
                  { path: 'document-locking', element: <></> },
                  { path: 'mail-template', element: <></> },
                  { path: 'workflow', element: <></> },
                ]
              },
              {
                path: 'idea-blast',
                children: [
                  { path: 'reward-summary', element: <RewardSummary /> },
                  { path: 'score', element: <IdeaBlastScore /> },
                  { path: 'score/:id', element: <IdeaBlastScoreDetail /> },
                  { path: 'score/:id/edit', element: <IdeaBlastScoreEdit /> },
                  { path: 'score-desc', element: <></> },
                  { path: 'admin', element: <></> },
                  { path: 'committee', element: <></> },
                ]
              },
            ]
          }
        },
        { path: '*', element: <Navigate to={token ? defaultRoute : '/404'} /> }
      ]
    },
    {
      path: '/',
      element: !token ? <MainLayout /> : <Navigate to={lastRoute} />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: '/', element: <Navigate to={defaultRoute} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '404', element: <NotFoundView /> },
  ];
};

export default routes;
