import React from 'react';
import PropTypes from 'prop-types';

import { NAME_LIST } from 'src/api/backendUrl';

import FormItem from 'src/components/FormItem';
import Selector from 'src/components/Selector';

import { isValidationRequired } from 'src/utils/validation';

const ScrumMaster = ({
  disabled,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  values,
  validation
}) => {
  const key = 'facilitator';

  const creator = values?.submitter;
  const excludeCreator = creator?.empId ? `empId!=${creator?.empId || ''}` : `userAd!=${creator?.userAd || ''}`;

  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Scrum Master"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <Selector
        error={Boolean(touched[key] && errors[key])}
        disabled={disabled}
        name={key}
        type="text"
        placeholder="Enter scrum master"
        value={values[key]}
        onBlur={handleBlur}
        onChange={handleChange}
        apiMasterData="nameList"
        keyApi={NAME_LIST}
        excludeQuery={`,${excludeCreator}`}
      />
    </FormItem>
  );
};

ScrumMaster.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default ScrumMaster;
