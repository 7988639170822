import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, makeStyles, Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    padding: theme.spacing(1)
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
}));

const CommitteeAssignmentScoreView = ({
  detail,
  processed,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Committee Assignment & Score</Typography>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>Final Score</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>Status</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>Class</Typography>
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>{detail?.totalScore || 0}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>{processed ? 'Processed' : '-'}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography>{detail?.class || '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

CommitteeAssignmentScoreView.propTypes = {
  detail: PropTypes.object,
  processed: PropTypes.bool,
};

export default CommitteeAssignmentScoreView;
