import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import {
  Box,
  Grid,
  makeStyles
} from '@material-ui/core';

import { useQuery } from 'react-query';

import api from 'src/api';
import { ORGANIZATION_ALL, STAGE_GATES } from 'src/api/backendUrl';

import { green, yellow } from '@material-ui/core/colors';
import Filter from './Filter';
import DonutChart from '../DonutChart';
import ProjectDetail from '../ProjectDetail';
import SummaryValueCaptureView from './SummaryValueCaptureView';
import BarChart from './BarChart';
import HorizontalBarChart from '../HorizontalBarChart';
import Ecii from './Ecii';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 6%',
    marginTop: '2.36%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
    textAlign: 'left'
  },
}));

const GeneralView = (props) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({
    category: '',
    gate: '',
    site: ''
  });

  const [filterOptions, setFilterOptions] = useState({
    gateOptions: []
  });

  const [siteFilterOptions, setSiteFilterOptions] = useState({
    siteOptions: []
  });
  const [ideaBlast, setIdeaBlast] = useState(
    {
      key: 'ideaBlast',
      title: 'Project Status Idea Blast',
      chartOption: {
        chart: {
          id: 'idea-blast-chart',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35px',
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          style: {
            colors: ['#000']
          },
          offsetY: -20
        }
      },
      chartSeries: [
        {
          name: '',
          data: [
            {
              x: 'Registration',
              y: 0,
              fillColor: yellow.A700
            },
            {
              x: 'Registration Completed',
              y: 0,
              fillColor: green.A700
            },
            {
              x: 'Implementation',
              y: 0,
              fillColor: yellow.A700
            },
            {
              x: 'Implementation Completed',
              y: 0,
              fillColor: green.A700
            }
          ]
        }
      ]
    }
  );
  const [infinity, setInfinity] = useState({
    key: 'infinity',
    title: 'Project Status Infinity',
    chartOption: {
      chart: {
        id: 'infinity-chart',
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 25,
          columnWidth: '35px',
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        style: {
          colors: ['#000']
        },
        offsetY: -20
      }
    },
    chartSeries: [
      {
        name: '',
        data: [
          {
            x: 'On Progress',
            y: 0,
            fillColor: yellow.A700
          },
          {
            x: 'Completed',
            y: 0,
            fillColor: green.A700
          }
        ]
      }
    ]
  });
  const [projectBenefitData, setProjectBenefitData] = useState({
    projectBenefitIdeaBlast: {
      series1: [{
        name: '',
        data: []
      }],
      series2: [{
        name: '',
        data: []
      }],
      labels1: [],
      labels2: [],
      title: '',
      rate: '',
    },
    projectBenefitInfinity: {
      series1: [{
        name: '',
        data: []
      }],
      series2: [{
        name: '',
        data: []
      }],
      labels1: [],
      labels2: [],
      title: '',
      rate: '',
    },
  });
  const [projectDetailData, setProjectDetailData] = useState({
    projectDetail1: {
      title: '',
      total: 0,
      subtitle: '',
      tableHeader: [{ key: '', label: '' }],
      tableData: []
    },
    projectDetail2: {
      title: '',
      total: 0,
      subtitle: '',
      tableHeader: [{ key: '', label: '' }],
      tableData: []
    },
  });
  const [summaryCapture, setSummaryCapture] = useState({
    ideaBlast: 0,
    infinity: 0,
    investment: 0,
    benefitImpact: 0
  });
  const [benefitDonutData, setBenefitDonutData] = useState({
    benefitDonut1: {
      series: [],
      labels: [],
      title: '',
    },
    benefitDonut2: {
      series: [],
      labels: [],
      title: '',
    },
    benefitDonut3: {
      series: [],
      labels: [],
      title: '',
    },
  });

  const {
    values,
    renderDeleteIcon,
    setIsFetching
  } = props;
  const { year } = values;

  const { data: stageGates } = useQuery(
    [STAGE_GATES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.masterData.stageGates(query);

      return response?.data;
    }, { initialData: [] }
  );

  const { data: sites } = useQuery(
    [ORGANIZATION_ALL, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.masterData.organizationAll(query);

      return response?.data;
    }, { initialData: [] }
  );

  const { data: summary, refetch: summaryReFetch } = useQuery(
    ['summary-project', { year, category: filter.category }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.summary(query);

      return response?.data;
    },
    { initialData: { labelCategories: [] } }
  );

  const { data: valueCapture, refetch: valueCaptureReFetch } = useQuery(
    ['value-capture', { year, category: filter.category }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      setIsFetching(Boolean(query.year));
      const { data: response } = await api.dashboard.valueCapture(query);

      setIsFetching(false);
      return response?.data;
    },
    { initialData: { labelCategories: [] } }
  );

  const { data: projectStatus, refetch: projectStatusReFetch } = useQuery(
    ['project-status', { year, category: filter.category, gate: filter.gate }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.projectStatus(query);

      return response?.data;
    }, { initialData: [] }
  );

  const { data: benefit, refetch: benefitReFetch } = useQuery(
    ['benefit-type', { year, category: filter.category }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.benefitType(query);

      return response?.data;
    },
    { initialData: { labelCategories: [] } }
  );

  const { data: projectBenefit, refetch: projectBenefitReFetch } = useQuery(
    ['project-benefit', { year, category: filter.category }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.projectBenefit(query);

      return response?.data;
    },
    { initialData: { labelCategories: [] } }
  );

  const { data: ecii, refetch: eciiReFetch } = useQuery(
    ['ecii', { year, siteId: filter.site }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.ecii(query);

      return response;
    }, { initialData: [] }
  );

  const { data: projectDetail, refetch: projectDetailReFetch } = useQuery(
    ['project-detail', { year, category: filter.category }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.projectDetails(query);
      return response?.data;
    }, { initialData: [] }
  );

  const handleEmptySeries = (item, series, index) => {
    if (item) {
      if (series?.length) {
        return series[0]?.data[index];
      }

      return 0;
    }

    return 0;
  };

  useEffect(() => {
    setSiteFilterOptions({
      ...siteFilterOptions,
      siteOptions: sites
    });
  }, [sites]);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      gateOptions: stageGates
    });
  }, [stageGates]);

  useEffect(() => {
    let summaryData = {};
    let valueCaptureData = {};

    summary.labelCategories.forEach((item, index) => {
      summaryData = {
        ...summaryData,
        [item
          ? camelCase(item)
          : camelCase('infinity')]: handleEmptySeries(item, summary?.series, index)
      };
    });

    valueCapture.labelCategories.forEach((item, index) => {
      valueCaptureData = {
        ...valueCaptureData,
        [camelCase(item)]: valueCapture?.series.length
          ? valueCapture?.series[0]?.data[index]
          : 0
      };
    });

    setSummaryCapture({
      ...summaryCapture,
      ...summaryData,
      ...valueCaptureData
    });
  }, [summary, valueCapture]);

  useEffect(() => {
    if (benefit && benefit.length === 3) {
      setBenefitDonutData({
        benefitDonut1: {
          series: benefit[0]?.series[0]?.data,
          title: benefit[0]?.series[0]?.name,
          labels: benefit[0]?.labelCategories
        },
        benefitDonut2: {
          series: benefit[1]?.series[0]?.data,
          title: benefit[1]?.series[0]?.name,
          labels: benefit[1]?.labelCategories
        },
        benefitDonut3: {
          series: benefit[2]?.series[0]?.data,
          title: benefit[2]?.series[0]?.name,
          labels: benefit[2]?.labelCategories
        },
      });
    }
  }, [benefit]);

  useEffect(() => {
    if (projectStatus.length) {
      const ideaBlastProject = projectStatus
        .find(
          (item) => item.series[0].name.toLowerCase().includes(ideaBlast.title.toLowerCase())
        );

      const infinityProject = projectStatus
        .find(
          (item) => item.series[0].name.toLowerCase().includes(infinity.title.toLowerCase())
        );

      const ideaBlastChartSeries = ideaBlastProject.labelCategories.map((item, index) => ({
        ...ideaBlast.chartSeries[0].data[index],
        x: item,
        y: ideaBlastProject.series[0].data[index]
      }));

      const infinityChartSeries = infinityProject.labelCategories.map((item, index) => ({
        ...infinity.chartSeries[0].data[index],
        x: item,
        y: infinityProject.series[0].data[index]
      }));

      setIdeaBlast({
        ...ideaBlast,
        chartSeries: [{
          name: '',
          data: ideaBlastChartSeries
        }]
      });

      setInfinity({
        ...infinity,
        chartSeries: [{
          name: '',
          data: infinityChartSeries
        }]
      });
    }
  }, [projectStatus]);

  useEffect(() => {
    const tableHeaderArr = [
      {
        key: 'site',
        label: 'SITE'
      },
      {
        key: 'registration',
        label: 'REG'
      },
      {
        key: 'gate1',
        label: 'G#1'
      },
      {
        key: 'gate2',
        label: 'G#2'
      },
      {
        key: 'gate3',
        label: 'G#3'
      },
      {
        key: 'gate4',
        label: 'G#4'
      },
      {
        key: 'gate5',
        label: 'G#5'
      },
      {
        key: 'completed',
        label: 'COMPLETED G#5'
      },
    ];
    if (projectDetail?.series && projectDetail.series.length === 2) {
      const isCompleted = projectDetail?.series[1].withCompleted;
      setProjectDetailData({
        projectDetail1: {
          title: projectDetail?.series[0].projectName,
          total: projectDetail?.series[0].totalProject,
          subtitle: 'IDEA BLAST',
          tableHeader: [
            {
              key: 'site',
              label: 'SITE'
            },
            {
              key: 'registration',
              label: 'REG'
            },
            {
              key: 'registrationCompleted',
              label: 'REG. COMPLETED'
            },
            {
              key: 'implementation',
              label: 'IMPLEMENT'
            },
            {
              key: 'implementationCompleted',
              label: 'IMPLEMENT COMPLETED'
            },
          ],
          tableData: projectDetail?.series[0].data,
        },
        projectDetail2: {
          title: projectDetail?.series[1].projectName,
          total: projectDetail?.series[1].totalProject,
          subtitle: 'PROJECT',
          tableHeader: isCompleted ? tableHeaderArr : tableHeaderArr.slice(0, -1),
          tableData: projectDetail?.series[1].data,
        }
      });
    }
  }, [projectDetail]);

  useEffect(() => {
    if (projectBenefit && projectBenefit.length === 2) {
      setProjectBenefitData({
        projectBenefitIdeaBlast: {
          series1: [{
            name: '',
            data: projectBenefit[0].series[0].data.slice(0, 1),
          }],
          series2: [{
            name: '',
            data: projectBenefit[0].series[0].data.slice(1),
          }],
          labels1: projectBenefit[0].labelCategories.slice(0, 1),
          labels2: projectBenefit[0].labelCategories.slice(1),
          title: projectBenefit[0].series[0].name,
          rate: projectBenefit[0].series[0].rate
        },
        projectBenefitInfinity: {
          series1: [{
            name: '',
            data: projectBenefit[1].series[0].data.slice(0, 1),
          }],
          series2: [{
            name: '',
            data: projectBenefit[1].series[0].data.slice(1),
          }],
          labels1: projectBenefit[1].labelCategories.slice(0, 1),
          labels2: projectBenefit[1].labelCategories.slice(1),
          title: projectBenefit[1].series[0].name,
          rate: projectBenefit[0].series[0].rate
        },
      });
    }
  }, [projectBenefit]);

  useEffect(() => {
    summaryReFetch();
    valueCaptureReFetch();
    projectStatusReFetch();
    benefitReFetch();
    projectBenefitReFetch();
    eciiReFetch();
    projectDetailReFetch();
  }, [filter]);

  return (
    <Box>
      <Filter
        {...{
          values: filter,
          renderDeleteIcon,
          setFilter
        }}
      />

      <SummaryValueCaptureView
        {...{
          values: { ...summaryCapture }
        }}
      />

      <Box mt={5}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <BarChart
              isHasFilter={false}
              {...ideaBlast}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <BarChart
              {...{
                ...infinity,
                filter,
                setFilter,
                filterOptions: filterOptions.gateOptions
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{ marginTop: 15 }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Ecii
              {...{
                setFilter,
                data: ecii,
                values: filter,
                siteOptions: siteFilterOptions.siteOptions
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {benefitDonutData?.benefitDonut1?.series && (
            <Grid item md={4} xs={12}>
              <Box display="flex" flexDirection="column" className={classes.formContainer}>
                <DonutChart
                  series={benefitDonutData?.benefitDonut1?.series}
                  labels={benefitDonutData?.benefitDonut1?.labels}
                  title={benefitDonutData?.benefitDonut1?.title}
                />
              </Box>
            </Grid>
          )}
          {benefitDonutData?.benefitDonut2?.series && (
            <Grid item md={4} xs={12}>
              <Box display="flex" flexDirection="column" className={classes.formContainer}>
                <DonutChart
                  series={benefitDonutData?.benefitDonut2?.series}
                  labels={benefitDonutData?.benefitDonut2?.labels}
                  title={benefitDonutData?.benefitDonut2?.title}
                />
              </Box>
            </Grid>
          )}
          {benefitDonutData?.benefitDonut3?.series && (
            <Grid item md={4} xs={12}>
              <Box display="flex" flexDirection="column" className={classes.formContainer}>
                <DonutChart
                  series={benefitDonutData?.benefitDonut3?.series}
                  labels={benefitDonutData?.benefitDonut3?.labels}
                  title={benefitDonutData?.benefitDonut3?.title}
                />
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <HorizontalBarChart
              series1={projectBenefitData?.projectBenefitIdeaBlast?.series1 || [{ name: '', data: [] }]}
              series2={projectBenefitData?.projectBenefitIdeaBlast?.series2 || [{ name: '', data: [] }]}
              labels1={projectBenefitData?.projectBenefitIdeaBlast?.labels1 || []}
              labels2={projectBenefitData?.projectBenefitIdeaBlast?.labels2 || []}
              title={projectBenefitData?.projectBenefitIdeaBlast?.title}
              rate={projectBenefitData?.projectBenefitIdeaBlast?.rate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <HorizontalBarChart
              series1={projectBenefitData?.projectBenefitInfinity?.series1 || [{ name: '', data: [] }]}
              series2={projectBenefitData?.projectBenefitInfinity?.series2 || [{ name: '', data: [] }]}
              labels1={projectBenefitData?.projectBenefitInfinity?.labels1 || []}
              labels2={projectBenefitData?.projectBenefitInfinity?.labels2 || []}
              title={projectBenefitData?.projectBenefitInfinity?.title}
              rate={projectBenefitData?.projectBenefitInfinity?.rate}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <ProjectDetail {...{
              title: projectDetailData?.projectDetail1?.title,
              total: projectDetailData?.projectDetail1?.total,
              subtitle: projectDetailData?.projectDetail1?.subtitle,
              tableHeader: projectDetailData?.projectDetail1?.tableHeader,
              tableData: projectDetailData?.projectDetail1?.tableData,
            }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <ProjectDetail {...{
              title: projectDetailData?.projectDetail2?.title,
              total: projectDetailData?.projectDetail2?.total,
              subtitle: projectDetailData?.projectDetail2?.subtitle,
              tableHeader: projectDetailData?.projectDetail2?.tableHeader,
              tableData: projectDetailData?.projectDetail2?.tableData,
            }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

GeneralView.propTypes = {
  renderDeleteIcon: PropTypes.func,
  setIsFetching: PropTypes.func,
  values: PropTypes.object
};

export default GeneralView;
