import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useQuery } from 'react-query';

import api from 'src/api';
import {
  COMPANIES,
  ORGANIZATION_LIST,
  ROLES
} from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Toolbar from 'src/components/Toolbar';
import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

import header from './header';
import TableList from './TableList';
import FormView from './FormView';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },

  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    marginLeft: 10,
  },
  boxFilter: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
  },
}));

const UserManagement = () => {
  const classes = useStyles();
  const [headerTable, setHeaderTable] = useState(header);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    company: [],
    site: [],
    role: []
  });
  const [sorts, setSorts] = useState('-createdAt');
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState();

  const { data: companies } = useQuery(COMPANIES, async () => {
    const { data: response } = await api.masterData.companies({ sorts: 'name' });
    return response?.data;
  }, { initialData: [] });

  const { data: organization } = useQuery(ORGANIZATION_LIST, async () => {
    const { data: response } = await api.masterData.organizationlist({ sorts: 'name' });
    return response?.data;
  }, { initialData: [] });

  const { data: roles } = useQuery(ROLES, async () => {
    const { data: response } = await api.masterData.roles({ Sorts: 'name' });
    return response?.data;
  }, { initialData: [] });

  const sortTable = (sort) => {
    if (sort !== 'action') {
      headerTable.forEach((element) => {
        if (element.sort === sort) {
          element.isSort = !element.isSort;
          if (element.isSort === true) {
            setSorts(sort);
          } else {
            setSorts(`-${sort}`);
          }
        } else {
          element.isSort = null;
        }
      });
      setHeaderTable([...headerTable]);
    }
  };

  const changePagination = (event, page) => {
    let first = 1;
    let last = pageSize * page;
    for (let i = 1; i < pageSize * page; i += pageSize) {
      first = i;
    }
    if (totalData > last) {
      last = pageSize * page;
    } else {
      last = totalData;
    }
    setShowing({
      show: first,
      to: last
    });
    setCurrentPage(page);
  };

  const handleLimitChange = (item) => {
    setCurrentPage(1);
    setShowing({
      show: 1,
      to: item
    });
    setPageSize(item);
  };

  const {
    data,
    refetch,
  } = useQuery(['user-existing-list', {
    page: currentPage,
    pageSize,
    sorts,
    filter
  }], async () => {
    const query = {
      page: currentPage,
      pageSize,
      sorts,
      filters: `${filter.role.length > 0 ? `userrole==${filter.role.join('|')},` : ''}${filter.site.length > 0 ? `OrganizationName==${filter.site.join('|')},` : ''}${filter.company.length > 0 ? `Company.Name==${filter.company.join('|')},` : ''}${search && `search==${search}`}`
    };
    const { data: response } = await api.setting.user.list(query);
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response.totalData === 0 ? response.totalData : showing.show,
      to: response.totalData <= pageSize ? response.totalData : showing.to
    });
    return response?.data;
  }, {
    enabled: true
  });

  const handleClearSearch = () => {
    setSearch('');
  };

  const handleEnterSearch = (ev) => {
    if (ev.key === 'Enter') {
      refetch();
    }
  };

  const handleAddUser = () => {
    setEditUser();
    setOpen(true);
  };

  const handleEditUser = (user) => {
    setEditUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFilter = (key, value) => {
    const isExist = filter[key].findIndex((d) => d === value) > -1;
    if (isExist) {
      const newValue = filter[key].filter((d) => d !== value);
      setFilter({
        ...filter,
        [key]: newValue
      });
    } else {
      setFilter({
        ...filter,
        [key]: [...filter[key], value]
      });
    }
  };

  const handleDeleteFilter = (key, value) => {
    const newValue = filter[key].filter((d) => d !== value);
    setFilter({
      ...filter,
      [key]: newValue
    });
  };

  useEffect(() => {
    if (search === '') {
      refetch();
    }
  }, [search, pageSize, refetch]);

  return (
    <Page
      className={classes.root}
      title="User Management"
    >
      <Container maxWidth={false}>
        <Toolbar path="User Management" />
        <Grid container spacing={4} className={classes.boxFilter}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.titleFilter}>
              Filter
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormItem
              label="COMPANY"
              id="company"
            >
              <StyledMultiSelect
                name="company"
                placeholder="Select company"
                valueKey="name"
                onChange={(e, newValue) => handleChangeFilter('company', newValue.props.children)}
                value={filter.company}
                options={companies || []}
                handleDelete={(value) => handleDeleteFilter('company', value)}
              />
            </FormItem>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormItem
              label="ORGANIZATION"
              id="organization"
            >
              <StyledMultiSelect
                name="organization"
                valueKey="name"
                placeholder="Select organization"
                onChange={(e, newValue) => handleChangeFilter('site', newValue.props.children)}
                value={filter.site}
                options={organization || []}
                handleDelete={(value) => handleDeleteFilter('site', value)}
              />
            </FormItem>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormItem
              label="ROLE"
              id="role"
            >
              <StyledMultiSelect
                name="role"
                valueKey="name"
                placeholder="Select role"
                onChange={(e, newValue) => handleChangeFilter('role', newValue.props.children)}
                value={filter.role}
                options={roles || []}
                handleDelete={(value) => handleDeleteFilter('role', value)}
              />
            </FormItem>
          </Grid>
        </Grid>
        <TableList
          search={search}
          setSearch={setSearch}
          header={headerTable}
          data={data}
          totalData={totalData}
          currentPage={currentPage}
          onChangePagination={changePagination}
          totalPage={totalPage}
          showing={showing}
          pageSize={pageSize}
          handleSort={sortTable}
          handleLimitItems={handleLimitChange}
          handleSearch={() => refetch()}
          handleEnterSearch={handleEnterSearch}
          handleClearSearch={handleClearSearch}
          handleAddUser={handleAddUser}
          handleEditUser={handleEditUser}
        />
        <FormView
          open={open}
          handleClose={handleClose}
          user={editUser}
          refetch={refetch}
        />
      </Container>
    </Page>
  );
};

export default UserManagement;
