import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { fade, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useStoreState, useStoreActions } from 'easy-peasy';
import getInitials from 'src/utils/getInitials';
import {
  Box,
  ListItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    marginRight: theme.spacing(2),
  },
  avatarText: {
    margin: theme.spacing(1),
    width: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.1)
    }
  },
  avatarSmall: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  name: {
    textTransform: 'capitalize',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '18px'
  },
  roleName: {
    textTransform: 'uppercase',
    color: '#6b5f6e',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: '13px'
  },
  icon: {
    marginLeft: theme.spacing(1),
    paddingBottom: 10
  }
}));

const NavAuth = () => {
  const classes = useStyles();
  const user = useStoreState((state) => state.auth.user);
  const { logout, setLastRoute } = useStoreActions((actions) => actions.auth);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const loggingOut = (event) => {
    setLastRoute('/app/innovation-view');
    logout();
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <ListItem
        button
        dense
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        className={classes.avatarText}
      >
        <ListItemIcon>
          <Avatar className={classes.avatarSmall}>{getInitials(user?.username)}</Avatar>
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.name, secondary: classes.roleName }}
          primary={user?.name}
          secondary={user?.roles?.length > 1 ? 'Multirole' : user?.roles && user?.roles[0]?.name}
        />
        <Box className={classes.icon}>
          {open ? <ArrowDropUp /> : <ArrowDropDown /> }
        </Box>
      </ListItem>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {user?.roles?.length > 1 && (
                    <MenuItem disabled>
                      <ListItemText primary={user?.roles?.map((d) => d.name).join(', ')} />
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Avatar className={classes.avatarSmall}>
                        {getInitials(user?.username)}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary={user?.username} secondary={user?.email} />
                  </MenuItem>
                  <Divider variant="middle" />
                  <MenuItem onClick={loggingOut}>
                    <ListItemText inset primary="Logout" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
              <Box style={{ display: 'flex', width: '100%', padding: '0 1rem 0.25rem 0' }}>
                <Typography
                  style={{
                    marginLeft: 'auto',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#737AF1'
                  }}
                >
                  v.
                  {' '}
                  {process.env.REACT_APP_VERSION}
                </Typography>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default NavAuth;
