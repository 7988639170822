import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

const InnovationType = ({
  innovationTypeOptions = [],
  values,
  setFilter
}) => {
  const key = 'innovationType';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  const handleOnDelete = (val) => {
    const filteredItems = values[key].filter((item) => item !== val);
    setFilter({
      ...values,
      [key]: filteredItems
    });
  };

  return (
    <FormItem
      id={key}
      label="Innovation Type"
    >
      <StyledMultiSelect
        name={key}
        placeholder="Select Innovation Type"
        value={values[key]}
        options={innovationTypeOptions}
        onChange={handleOnChange}
        handleDelete={handleOnDelete}
      />
    </FormItem>
  );
};

InnovationType.propTypes = {
  innovationTypeOptions: PropTypes.array,
  values: PropTypes.object,
  setFilter: PropTypes.func
};

export default InnovationType;
