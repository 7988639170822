import React from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logo: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: 17,
    lineHeight: '22px',
    textAlign: 'left',
  },
  image: {
    height: '26px',
    marginRight: 7,
    objectFit: 'contain',
  }
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <img
          alt="icon"
          src="/static/images/logo_inoc.png"
          className={classes.image}
          {...props}
        />
      </ListItemIcon>
      <ListItemText
        classes={{ primary: classes.logo }}
        primary="ITM Innovation Center"
      />
    </ListItem>
  );
};

export default Logo;
