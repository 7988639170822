import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import {
  Box,
  Grid
} from '@material-ui/core';

import api from 'src/api';
import data from '../chartData';

import ProjectStatusView from './ProjectStatusView';
import ProjectByAging from './ProjectByAging';
import ProjectTypeArea from './ProjectTypeArea';

const ITDemandView = (props) => {
  const [chartData, setChartData] = useState(data);

  const {
    values,
    url,
    isActive = false,
    setIsFetching
  } = props;
  const { year } = values;

  const {
    remove: removeProjectStatus,
    data: projectStatus,
    refetch: reFetchProjectStatus
  } = useQuery(
    ['project-status', { year, category: 'IT Demand' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: responseProjectStatus } = await api.dashboard.projectStatusIT({ year });
      const { data: responseValueCapture } = await api.dashboard.valueCapture(query);

      return {
        projectStatus: {
          linkPortofolio: '',
          values: [
            {
              icon: '/static/icons/ic_projectstatus_queue.svg',
              alt: 'project status queue icon',
              value: responseProjectStatus.data.series[0].data[0],
              subtext: 'Queue',
            },
            {
              icon: '/static/icons/ic_projectstatus_inprogress.svg',
              alt: 'project status in progress icon',
              value: responseProjectStatus.data.series[0].data[1],
              subtext: 'In Progress',
            },
            {
              icon: '/static/icons/ic_projectstatus_completed.svg',
              alt: 'project status completed icon',
              value: responseProjectStatus.data.series[0].data[2],
              subtext: 'Completed',
            },
          ]
        },
        valueCapture: {
          values: [
            {
              icon: '/static/icons/ic_investment.svg',
              alt: 'value capture investment icon',
              value: responseValueCapture.data.series[0].data[0],
              subtext: 'Investment',
            },
            {
              icon: '/static/icons/ic_benefit_impact.svg',
              alt: 'value capture benefit icon',
              value: responseValueCapture.data.series[0].data[1],
              subtext: 'Benefit Impact',
            },
          ]
        }
      };
    },
    { initialData: null }
  );

  const {
    remove: removeProjectGateAging,
    data: projectGateByAging,
    refetch: reFetchProjectGateAging
  } = useQuery(
    ['project-gate-by-aging', { year }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.projectGateAging(query);

      return response?.data;
    },
    { initialData: { series: [], labelCategories: [] } }
  );

  const {
    remove: removeProjectTypeArea,
    data: projectTypeArea,
    refetch: reFetchProjectTypeArea
  } = useQuery(
    ['project-type-area', { year }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.dashboard.projectTypeArea(query);

      return {
        series1: [{
          name: response?.data[1]?.labelCategories[0],
          data: [response?.data[1]?.series[0].data[0]],
        }, {
          name: response?.data[1]?.labelCategories[1],
          data: [response?.data[1]?.series[0].data[1]],
        }],
        series2: [{
          name: response?.data[0]?.labelCategories[0],
          data: [response?.data[0]?.series[0].data[0]],
        }, {
          name: response?.data[0]?.labelCategories[1],
          data: [response?.data[0]?.series[0].data[1]],
        }, {
          name: response?.data[0]?.labelCategories[2],
          data: [response?.data[0]?.series[0].data[2]],
        }]
      };
    },
    { initialData: { series1: [], series2: [] } }
  );

  const reFetchAllData = async () => {
    setIsFetching(true);

    await reFetchProjectStatus();
    await reFetchProjectGateAging();
    await reFetchProjectTypeArea();

    setIsFetching(false);
  };

  useEffect(() => {
    reFetchAllData()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);

    return () => {
      removeProjectStatus();
      removeProjectTypeArea();
      removeProjectGateAging();
    };
  }, []);

  useEffect(() => {
    if (projectStatus) {
      setChartData({
        ...chartData,
        projectStatus: projectStatus.projectStatus,
        valueCapture: projectStatus.valueCapture,
      });
    }
  }, [projectStatus]);

  useEffect(() => {
    if (projectGateByAging) {
      setChartData({
        ...chartData,
        projectGateByAging,
      });
    }
  }, [projectGateByAging]);

  useEffect(() => {
    if (projectTypeArea) {
      setChartData({
        ...chartData,
        projectTypeArea,
      });
    }
  }, [projectTypeArea]);

  return (
    <Box>
      <ProjectStatusView
        year={year}
        projectStatus={chartData?.projectStatus}
        valueCapture={chartData?.valueCapture}
      />

      <Box mt={5}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProjectByAging
              projectGateByAging={chartData?.projectGateByAging}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ProjectTypeArea
              projectTypeArea={chartData?.projectTypeArea}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={5}>
        {
          isActive && url
            ? (
              <iframe
                title="itDemand-frame"
                src={url}
                style={{ width: '100%', minHeight: '550px' }}
              />
            )
            : (<></>)
        }
      </Box>
    </Box>
  );
};

ITDemandView.propTypes = {
  values: PropTypes.object,
  url: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsFetching: PropTypes.func.isRequired
};

export default ITDemandView;
