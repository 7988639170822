import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(225deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%) `,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    height: '100%',
    backgroundImage: `url(${'static/images/background.png'})`,
    backgroundSize: 'contain'
  },
  margin: {
    margin: theme.spacing(1),
  },
  icon: {
    height: 24,
    width: 24,
  },
  mainImage: {
    height: '30vw',
  },
  logoImage: {
    height: '90px',
  },
  containerLogo: {
    marginTop: '10.38%',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  form: {
    backgroundColor: 'rgba(255, 255, 255, 0.68)',
    borderRadius: 20,
    padding: 60,
    marginRight: '2.34%',
    marginLeft: '2.34%',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      marginRight: '5.34%',
      marginLeft: '5.34%',
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  title: {
    color: '#1A051D',
    fontFamily: 'SFProDisplay',
    fontSize: theme.typography.pxToRem(22),
    lineHeight: '34px',
  },
  subtitle: {
    color: '#3F3356',
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
  },
  signin: {
    color: '#1A051D',
    fontFamily: 'SFProDisplay',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '16px',
  },
  caption: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
  },
  input: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '20px',
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '18px',
  },
  error: {
    backgroundColor: '#FFECEC',
    border: '1px solid #E02020',
    borderRadius: 8,
    minHeight: 50,
    height: 'auto',
    paddingLeft: '4.92%',
    paddingRight: '4.92%',
    color: '#E02020',
    fontFamily: 'SFProText',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  }
}));

export default useStyles;
