import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toUpper, orderBy } from 'lodash';

import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import api from 'src/api';
import { FRAMES, YEAR_BUILT } from 'src/api/backendUrl';

import Page from 'src/components/Page';
import Header from 'src/components/Header';

import { ArrowDropDown, Close } from '@material-ui/icons';
import YearFilter from './YearFilter';
import GeneralView from './generalView';
import IpodView from './ipodView';
import ITDemandView from './itDemandView';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    padding: 5
  },
  tabContainer: {
    backgroundColor: fade(theme.palette.background.main, 0.2),
    borderRadius: 5,
    padding: 5
  },
  activeTab: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    padding: 10
  },
  inactiveTitle: {
    color: theme.palette.secondary.main,
  },
  activeTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const [tabTitle, setTabTitle] = useState('general');
  const [filter, setFilter] = useState({
    year: ''
  });
  const [isFetching, setIsFetching] = useState(false);

  const tabs = [
    { label: 'General', value: 'general' },
    { label: 'IPod', value: 'IPod' },
    { label: 'IT Demand', value: 'IT Demand' },
  ];

  const { data: years } = useQuery(
    [YEAR_BUILT, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.masterData.yearBuilt(query);

      return orderBy(response?.data, 'name', 'desc');
    }, { initialData: [] }
  );

  const { data: frames } = useQuery(
    [FRAMES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.masterData.frames(query);

      return response?.data;
    }, { initialData: [] }
  );

  useEffect(() => {
    if (years && years.length) {
      setFilter({
        year: years[0].id
      });
    }
  }, [years]);

  const clearFilter = (key) => {
    setFilter({
      ...filter,
      [key]: ''
    });
  };

  const renderDeleteIcon = (key, value) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {
          value && (
            <Close
              size="small"
              style={{
                color: 'red',
                fontSize: 16,
                zIndex: 1,
                cursor: 'pointer'
              }}
              onClick={() => clearFilter(key)}
            />
          )
        }
        <ArrowDropDown size="small" style={{ color: '#3F3356', marginRight: 7 }} />
      </Box>
    );
  };

  const getFrameByCode = (code) => {
    return frames && frames.length
      ? frames.find((item) => item.code.toLowerCase().includes(code.toLowerCase()))
      : {
        url: null,
        isActive: false
      };
  };

  return (
    <Page
      title="Dashboard"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Box display="flex" flexDirection="column" mb={isFetching ? 5 : 0}>
          <Grid container>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Header
                title="Dashboard"
                currentPath={tabs.find((item) => item.value === tabTitle).label}
              />
            </Grid>

            <Grid
              container
              item
              lg={2}
              md={2}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              {
                tabTitle !== 'IPod' && (
                  <YearFilter
                    {...{
                      yearOptions: years,
                      values: { ...filter },
                      setFilter
                    }}
                  />
                )
              }
            </Grid>
          </Grid>
        </Box>

        {
          isFetching && (
            <LinearProgress />
          )
        }
        <Box className={classes.tabContainer} mb={5} mt={isFetching ? 0 : 5}>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 10
            }}
          >
            {
              tabs.map((item, index) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  key={`tab-${index + 1}`}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  className={item.value === tabTitle ? classes.activeTab : ''}
                  onClick={() => setTabTitle(item.value)}
                >
                  <Typography
                    style={{ alignSelf: 'center' }}
                    className={item.value === tabTitle
                      ? classes.activeTitle
                      : classes.inactiveTitle}
                  >
                    {toUpper(item.label)}
                  </Typography>
                </Grid>
              ))
            }
          </Grid>
        </Box>
        {
          tabTitle === 'general' && (
            <GeneralView
              {...{
                values: { ...filter },
                renderDeleteIcon,
                setFilter,
                setIsFetching
              }}
            />
          )
        }
        {
          tabTitle === 'IPod' && (
            (frames && frames.length) && (
              <IpodView
                {...getFrameByCode('ipod')}
              />
            )
          )
        }
        {
          tabTitle === 'IT Demand' && (
            <ITDemandView
              {...{
                ...getFrameByCode('itdemand_workload'),
                values: { ...filter },
                renderDeleteIcon,
                setFilter,
                setIsFetching
              }}
            />
          )
        }
      </Container>
    </Page>
  );
};

export default Dashboard;
