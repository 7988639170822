import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold',
  },
  donutChart: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '3.62% 6% 0 6%',
    marginTop: '2.36%',
    marginBottom: '2.36%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '24px',
  },
  chartContainer: {
    padding: '3.62% 6% 0 0',
    backgroundColor: '#EDEEFD',
    borderRadius: '24px',
    marginTop: '2.36%',
    marginBottom: '5%',
  },
  chartContainer2: {
    padding: '3.62% 6% 0 0',
    borderRadius: '24px',
  },
}));

const HorizontalBarChart = ({
  series1 = [{
    name: '',
    data: []
  }], 
  series2 = [{
    name: '',
    data: []
  }], 
  labels1 = [], 
  labels2 = [], 
  title = "",
  rate = "",
}) => {
  const classes = useStyles();
  const barWidth = 50;
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <Box display="flex" flexDirection="column" className={classes.formContainer} >
        <Typography className={classes.title}>{title}{rate ? ` (${rate.toUpperCase()})` : ""}</Typography>
        <Box display="flex" flexDirection="column" className={classes.chartContainer} >
          <ReactApexChart className={classes.donutChart} series={series1} type="bar" height={`${barWidth + 60.144}px`} options={
            {
              labels: labels1,
              tooltip: {
                x: { show: false },
                y: {
                  formatter: function (v, x) {
                    return v.toLocaleString("en-US", {currency:"USD"});
                  },
                  title: {
                    formatter: function (v, x) {
                      return x.w.config.labels[x.dataPointIndex];
                    }
                  }
                }
              },
              dataLabels: {
                formatter: function (val, opts) {
                    return val.toLocaleString("en-US", {currency:"USD"});
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  colors: { ranges: [{ color: '#FF8E00' }] },
                  horizontal: true
                }
              },
              xaxis: {
                labels: {
                  formatter: function(value, timestamp, opts) {
                    return formatter.format(value);
                  }
                }
              }
            }
          }/>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.chartContainer2} >
          <ReactApexChart className={classes.donutChart} series={series2} type="bar" height={`${barWidth * series2[0].data.length + 60.144}px`} options={
            {
              labels: labels2,
              tooltip: {
                x: { show: false },
                y: {
                  formatter: function (v, x) {
                    return v.toLocaleString("en-US", {currency:"USD"});
                  },
                  title: {
                    formatter: function (v, x) {
                      return x.w.config.labels[x.dataPointIndex];
                    }
                  }
                }
              },
              dataLabels: {
                formatter: function (val, opts) {
                    return val.toLocaleString("en-US", {currency:"USD"});
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  colors: { ranges: [{ color: '#FF8E00' }] },
                  horizontal: true
                }
              },
              xaxis: {
                labels: {
                  formatter: function(value, timestamp, opts) {
                    return formatter.format(value);
                  }
                }
              }
            }
          }/>
        </Box>
    </Box>
  );
};

HorizontalBarChart.propTypes = {
  series1: PropTypes.array.isRequired,
  series2: PropTypes.array.isRequired,
  labels1: PropTypes.array.isRequired,
  labels2: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default HorizontalBarChart;
