import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { NavLink as RouterLink } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DescriptionTwoTone } from '@material-ui/icons';

import api from 'src/api';

import Page from 'src/components/Page';
import Toolbar from 'src/views/myDocument/Toolbar';
import DataTable from 'src/components/DataTable';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1)
  }
}));

const entries = [10, 20, 50, 100, 200];

const ReminderHistory = () => {
  const classes = useStyles();

  const setNavigation = useStoreActions((action) => action.navigation.setPrevious);

  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [showing, setShowing] = useState({
    show: 1,
    to: pageSize
  });
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const tableHeaders = [
    { key: 'action', label: 'Action' },
    { key: 'no', label: 'No' },
    { key: 'createdAt', label: 'Date Time' },
    { key: 'type', label: 'Type' },
    { key: 'actionBy', label: 'Action By' },
    { key: 'recepient', label: 'Recipient' },
    { key: 'initiative', label: 'Initiative Name' },
    { key: 'gate', label: 'Gate' }
  ];

  const fetchLogHistoryReminder = async () => {
    setIsFetching(true);

    const query = {
      page: currentPage,
      pageSize
    };
    const { data: response } = await api.logHistory.reminder({
      ...search && { filters: `search==${search}` },
      ...query
    });
    setTotalData(response?.totalData || 0);
    setTotalPage(response?.totalPage || 0);
    setShowing({
      show: response?.totalData === 0
        ? response?.totalData
        : showing.show,
      to: response?.totalData <= pageSize
        ? response?.totalData
        : showing.to
    });

    setIsFetching(false);
    setDocumentData((response?.data && response?.data?.length)
      ? response?.data
      : []);
  };

  const gotoDetail = (id) => {
    setNavigation('/app/setting/reminder-history');

    return `/app/registration/${id}`;
  };

  useEffect(() => {
    fetchLogHistoryReminder()
      .then(/* Nothing here is expected */)
      .catch(/* Nothing here is expected */);
  }, [search, pageSize, currentPage]);

  return (
    <Page
      title="Reminder History"
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar path="Reminder History" />
        <Box my={5}>
          <DataTable
            isCustom
            isVisible
            tableHeader={tableHeaders}
            tableData={documentData}
            leftComp={isFetching
              ? (
                <CircularProgress size={24} />
              )
              : <></>}
            actionSlot={(data) => (
              <Grid container>
                <IconButton
                  component={RouterLink}
                  to={gotoDetail(data.id)}
                  style={{ padding: 0, marginRight: 10 }}
                >
                  <Tooltip title="Open" placement="top" arrow>
                    <DescriptionTwoTone style={{ color: '#737AF1' }} />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}
            {...{
              entries,
              showEntries,
              setShowEntries,
              pageSize,
              setPageSize,
              setSearch,
              totalData,
              currentPage,
              setCurrentPage,
              totalPage
            }}
          >
            {({ key, data }) => {
              switch (key) {
                case 'createdAt': {
                  return <Typography>{moment(data[key]).format('DD MMM YYYY HH:mm')}</Typography>;
                }
                default: {
                  return <Typography>{data[key]}</Typography>;
                }
              }
            }}
          </DataTable>
        </Box>
      </Container>
    </Page>
  );
};

export default ReminderHistory;
