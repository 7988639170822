import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import UserSelector from 'src/components/UserSelector';

import { isValidationRequired } from 'src/utils/validation';

const Submitter = ({
  disabled,
  values,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  validation
}) => {
  const key = 'submitter';

  const handleChange = (val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      id={key}
      label="Submitter"
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <UserSelector
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values[key]}
        placeholder="Enter name/email/department"
      />
    </FormItem>
  );
};

Submitter.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
};

export default Submitter;
