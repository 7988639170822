import { isFunction } from 'lodash';

const isValidationConditional = (key, validation) => validation[key]?._deps.length;

const conditionalValidation = (
  key,
  validation,
  values,
  valueValidation,
  isEqualOp = true
) => {
  const validationKey = validation[key]?._deps[0];

  if (isFunction(valueValidation)) {
    return valueValidation();
  }

  return isEqualOp
    ? values[validationKey] === valueValidation
    : values[validationKey] !== valueValidation;
};

const checkFields = (validation, key) => {
  if (validation[key]?.fields) {
    return validation[key]?.fields[validation[key]?._nodes[0]]?._exclusive?.required
      || validation[key]?.fields[validation[key]?._nodes[0]]?._exclusive?.min;
  }

  return validation[key]?._exclusive?.required || validation[key]?._exclusive?.min;
};

// eslint-disable-next-line import/prefer-default-export
export const isValidationRequired = (
  key,
  validation,
  values,
  valueValidation,
  isEqualOp = true
) => {
  if (validation[key]?.type === 'array') {
    return isValidationConditional(key, validation)
      ? conditionalValidation(key, validation, values, valueValidation, isEqualOp)
      : checkFields(validation, key);
  }

  return isValidationConditional(key, validation)
    ? conditionalValidation(key, validation, values, valueValidation, isEqualOp)
    : checkFields(validation, key);
};
