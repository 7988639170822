import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textinput: {

  }
}));

const ButtonConfirmation = ({
  title,
  message,
  isSubmitting = false,
  onConfirm,
  textDialog = '',
  textConfirm = 'Yes',
  textCancel = 'No',
  children,
  ...buttonProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [disable, setDisable] = React.useState(isSubmitting);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setDisable(true);
    onConfirm && onConfirm();
    setOpen(false);
    setDisable(false);
  };

  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => handleClickOpen()}
      >
        {children}
      </Button>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby={textConfirm}
      >
        <DialogTitle id={textConfirm}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {textDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={disable}
            onClick={handleConfirm}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            {textConfirm}
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button variant="outlined" disabled={disable} onClick={handleClose} color="secondary">
            {textCancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ButtonConfirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isSubmitting: PropTypes.bool,
  textConfirm: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  textDialog: PropTypes.string,
  textCancel: PropTypes.string,
  children: PropTypes.node,
};

export default ButtonConfirmation;
