import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { useQuery } from 'react-query';
import { CATEGORIES } from 'src/api/backendUrl';
import api from 'src/api';
import { isValidationRequired } from 'src/utils/validation';

const Category = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  setFieldValue,
  id,
  validation
}) => {
  const key = 'category';
  const { data, refetch } = useQuery(CATEGORIES, async () => {
    const response = await api.masterData.categories({
      ...id
        ? { id }
        : {}
    });
    return response?.data.errors
      ? []
      : response?.data?.data;
  }, { initialData: [] });
  const categoryOptions = data;

  useEffect(() => { refetch(); }, []);
  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Category"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        placeholder="Select category"
        labelKey="name"
        valueKey="key"
        onBlur={handleBlur}
        onChange={(e, val) => {
          const temp = categoryOptions.filter((el) => el.key === e.target.value);
          if (temp.length > 0) {
            setFieldValue('classificationId', temp[0].classificationId);
          }
          handleChange(e, val);
        }}
        value={values[key]}
        options={categoryOptions}
      />
    </FormItem>
  );
};

Category.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.any,
  id: PropTypes.string,
  validation: PropTypes.object
};

export default Category;
