import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme, IconButton, withStyles, TableBody
} from '@material-ui/core';
import { Close, GetAppTwoTone } from '@material-ui/icons';

import api from 'src/api';
import tableStyle from 'src/views/innovation/InnovationView/style';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  }
}));

const DocumentDialog = ({
  detail,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const tbStyle = tableStyle();
  const StyledCell = withStyles(() => ({
    head: {
      backgroundColor: '#EDEEFD',
      borderBottomWidth: 10,
      borderColor: '#F5F6F9',
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 120
    },
    body: {
      fontSize: 14,
      backgroundColor: 'white',
      borderTopWidth: 10,
      borderBottomWidth: 10,
      borderColor: '#F5F6F9'
    }
  }))(TableCell);
  const StyledRow = withStyles(() => ({
    root: {
      borderRadius: 5
    }
  }))(TableRow);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFetching, setIsFetching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState({ action: 'selectAll', value: false });
  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);

  const tableHeaders = [
    { key: 'action', label: '' },
    { key: 'no', label: 'No' },
    { key: 'name', label: 'Documents' },
    { key: 'gate', label: 'Gate' },
  ];

  const { g0Id } = detail;

  const { data: documents, refetch } = useQuery(
    ['innovation-repository/documents', { id: g0Id }],
    async ({ queryKey }) => {
      setIsFetching(true);
      const [key, query] = queryKey;
      const { data: response } = await api.innovationRepo.documents(query);

      setIsFetching(false);
      return isEmpty(detail)
        ? []
        : response?.data;
    }, { initialData: [] }
  );

  useEffect(() => {
    refetch();
  }, [detail]);

  useEffect(() => {
    const transformedDocuments = documents.map((item, index) => ({
      ...item,
      no: (index + 1),
      isChecked: false
    }));
    const selectedArray = transformedDocuments.map((item) => item.isChecked);

    setTableData(transformedDocuments);
    setSelected(selectedArray);
  }, [documents]);

  useEffect(() => {
    if (selected.length && isSelectAll.action === 'selectAll') {
      const checkboxSelected = selected.map((checkbox) => {
        checkbox = isSelectAll.value;
        return checkbox;
      });

      const selectedAllTableData = tableData.map((item) => ({
        ...item,
        isChecked: isSelectAll.value
      }));

      setTableData(selectedAllTableData);
      setSelected(checkboxSelected);
    }
  }, [isSelectAll]);

  const handleSelectAll = (ev) => {
    setIsSelectAll({ action: 'selectAll', value: ev.target.checked });
  };

  const handleSingleSelect = (ev, item, index) => {
    item.isChecked = ev.target.checked;
    const newSelected = selected.map((checkbox, checkboxIndex) => {
      if (checkboxIndex === index) {
        checkbox = ev.target.checked;
      }

      return checkbox;
    });

    setSelected(newSelected);
    setIsSelectAll({ action: 'checkbox', value: newSelected.every((checkbox) => checkbox) });
  };

  const handleMultiDownload = async () => {
    try {
      setIsDownloading(true);

      const selectedItems = tableData
        .filter((checkbox) => checkbox.isChecked)
        .map((item) => item.url);

      const response = await api.innovationRepo.multiDownload(selectedItems, 'arraybuffer');
      const fileName = response.headers['content-disposition'].split('\'');

      FileDownload(response.data, fileName[2]);

      setIsSelectAll({ action: 'selectAll', value: false });
      setIsDownloading(false);
      setIsOpen(false);
    } catch (err) {
      setIsDownloading(false);
    }
  };

  const selectAllComponent = () => {
    if (isDownloading) {
      return (<CircularProgress size={24} />);
    }

    return (Boolean(selected.length) && (
      <FormControlLabel
        control={(
          <Checkbox
            checked={isSelectAll.value}
            onChange={handleSelectAll}
          />
        )}
        label="Select All"
      />
    ));
  };

  const actionComponent = (item, trIndex) => {
    if (isDownloading) {
      return (<CircularProgress size={24} />);
    }

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Checkbox
          checked={selected[trIndex]}
          onChange={(ev) => handleSingleSelect(ev, item, trIndex)}
        />

        <Link
          href={item.url}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 15,
          }}
        >
          <GetAppTwoTone
            style={{
              color: '#737AF1',
              cursor: 'pointer',
            }}
          />
        </Link>
      </Box>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography
              variant="h3"
              className={classes.title}
            >
              Document(s) List
            </Typography>
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsOpen(false)}
            >
              <Close className={classes.close} />
            </IconButton>
          </Box>
        </Box>
        <Box my={5}>
          {
            isFetching && (
              <LinearProgress />
            )
          }
          {
            isDownloading
              ? (
                <CircularProgress size={24} style={{ marginBottom: 15 }} />
              )
              : selected.some((checkbox) => checkbox) && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    marginBottom: 15,
                    color: '#ffffff'
                  }}
                  onClick={handleMultiDownload}
                >
                  Download
                  {' '}
                  {selected.filter((checkbox) => checkbox).length}
                  {' '}
                  selected
                </Button>
              )
          }
          <Grid container>
            <TableContainer
              component={Paper}
              className={tbStyle.tableContainerMd}
              style={{ maxHeight: '30rem' }}
            >
              <Table
                stickyHeader
                className={tbStyle.table}
                aria-label="simple-table"
              >
                <TableHead className={tbStyle.header}>
                  <StyledRow>
                    {
                      tableHeaders.map((item, index) => (
                        <StyledCell
                          key={`th-${index + 1}`}
                          style={item.key === 'action'
                            ? { width: '1rem' }
                            : {}}
                        >
                          {
                            item.key === 'action'
                              ? (selectAllComponent())
                              : item.label
                          }
                        </StyledCell>
                      ))
                    }
                  </StyledRow>
                </TableHead>
                <TableBody className={tbStyle.header}>
                  {
                    tableData.map((item, trIndex) => (
                      <StyledRow
                        key={`tr-${trIndex + 1}`}
                        style={{ marginBottom: '5px' }}
                      >
                        <>
                          {
                            tableHeaders.map(({ key }, thIndex) => (
                              <StyledCell
                                key={`td-${thIndex + 1}`}
                                style={key === 'action'
                                  ? { width: '1rem' }
                                  : {}}
                              >
                                {
                                  key === 'action'
                                    ? (actionComponent(item, trIndex))
                                    : item[key]
                                }
                              </StyledCell>
                            ))
                          }
                        </>
                      </StyledRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DocumentDialog.propTypes = {
  detail: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default DocumentDialog;
