import { action } from 'easy-peasy';

export default {
  filter: null,
  filterParams: 'InnovationGateStatusesStatus!=draft',
  tmpFilter: null,
  currentPage: 1,
  sort: '-updatedAt',
  pageSize: 10,
  setFilter: action((state, payload) => {
    state.filter = payload;
  }),
  setFilterParams: action((state, payload) => {
    state.filterParams = payload;
  }),
  setTmpFilter: action((state, payload) => {
    state.tmpFilter = payload;
  }),
  setCurrentPage: action((state, payload) => {
    state.currentPage = payload;
  }),
  setSort: action((state, payload) => {
    state.sort = payload;
  }),
  setPageSize: action((state, payload) => {
    state.pageSize = payload;
  })
};
