import { persist } from 'easy-peasy';
import authModel from './auth';
import loadingModel from './loading';
import notifModel from './notif';
import chatModel from './chat';
import assessmentModel from './assessment';
import formikValues from './formikValues';
import innovationModel from './innovation';
import innovationRepoModel from './innovationRepo';
import innovationInvolvementModel from './innovationInvolvement';
import innovationConventionModel from './innovationConvention';
import myActivityModel from './myActivity';
import myDocumentModel from './myDocument';
import navigationModel from './navigation';
import ideaBlastRewardModel from './ideaBlastReward';

export default {
  auth: persist(authModel, { storage: 'localStorage', deny: ['error'] }),
  loading: loadingModel,
  notif: notifModel,
  chat: chatModel,
  assessment: persist(assessmentModel, { storage: 'localStorage', deny: ['error'] }),
  formikValues: persist(formikValues, { storage: 'localStorage', deny: ['error'] }),
  innovation: persist(innovationModel, { storage: 'localStorage', deny: ['error'] }),
  innovationRepo: persist(innovationRepoModel, { storage: 'localStorage', deny: ['error'] }),
  innovationInvolvement: persist(innovationInvolvementModel, { storage: 'localStorage', deny: ['error'] }),
  innovationConvention: persist(innovationConventionModel, { storage: 'localStorage', deny: ['error'] }),
  myActivity: persist(myActivityModel, { storage: 'localStorage', deny: ['error'] }),
  myDocument: persist(myDocumentModel, { storage: 'localStorage', deny: ['error'] }),
  navigation: persist(navigationModel, { storage: 'localStorage', deny: ['error'] }),
  ideaBlastReward: persist(ideaBlastRewardModel, { storage: 'localStorage', deny: ['error'] })
};
