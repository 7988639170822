import React from 'react';
import {
  Backdrop,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = ({ open }) => {
  const classes = useStyles();
  const isLoading = useStoreState((state) => state.loading.isLoading);

  return (
    <Backdrop className={classes.backdrop} open={open ?? isLoading}>
      <CircularProgress
        color="inherit"
        size={40}
        thickness={4}
      />
    </Backdrop>
  );
};

Loading.propTypes = {
  open: PropTypes.bool,
};

export default Loading;
