import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

export const initialValues = ({
  id,
  init,
  detail = {},
  registration = {},
  approval = [],
}) => {
  const {
    registrationNumber,
    innovationAreas,
    innovationBenefits = [],
    innovationTypes,
    classifications,
    themeBanks,
    user,
    initiative,
    initiativeDescription,
    requested,
    category,
    company,
    organizationId,
    isCoach,
    innovationTeamMembers,
    isTechnology,
    promotor,
    payment,
    diBenefit,
    diYearlyRecurring,
    inBenefit,
    inYearlyRecurring,
  } = registration;
  const benefits = id ? _.uniq(innovationBenefits?.map((d) => d.benefitCategoryId)) : [];
  return {
    // general form
    registrationNumber: registrationNumber || '',
    innovationAreaId: innovationAreas?.id || '',
    innovationTypeId: innovationTypes?.id || '',
    classificationId: classifications?.id || '',
    themeBankId: themeBanks?.id || '',
    category: category || '',
    innovationStatus: detail?.gateStatus || '',
    submittedById: id ? user : init,
    requestedFor: requested || {},
    company: company || '',
    organizationId: organizationId || '',
    isCoach: isCoach || '',
    innovationTeamMembers: innovationTeamMembers || [],
    isTechnology: isTechnology || '',
    promotor: promotor || {},
    // estimate investment
    payment: payment || 0,
    initiative: initiative || '',
    initiativeDescription: initiativeDescription || '',
    innovationBenefits: benefits,
    finalResult: detail?.finalResult || '',
    actionDone: detail?.actionDone || '',
    actualTimePlan: detail?.actualTimePlan
      ? moment(detail?.actualTimePlan).toDate() : moment().toDate(),
    solution: detail?.solution || '',
    diBenefit: detail?.diBenefit || diBenefit || 0,
    diYearlyRecurring: diYearlyRecurring || '',
    inBenefit: detail?.inBenefit || inBenefit || 0,
    inYearlyRecurring: inYearlyRecurring || '',
    attachments: detail?.ideaBlastImplementationAttachments || [],
    action: '',
    // Committee assignment
    committees: approval?.data?.filter((d) => d.roles?.name === 'Committee Site')?.map((d) => d.user) || []
  };
};

export const validationImplementation = {
  finalResult: Yup.string().required('Final Result is required'),
  actionDone: Yup.string().required('Action Done is required'),
  actualTimePlan: Yup.string().required('Actual Time Plan is required'),
  solution: Yup.string().required('Solution is required'),
};
