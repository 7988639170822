import React from 'react';

import {
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import RecurringCost from './RecurringCost';
import RecurringBenefit from './RecurringBenefit';
import OneTimeCost from './OneTimeCost';
import OneTimeBenefit from './OneTimeBenefit';
import PocFundCapex from './PocFundCapex';
import PocFundOpex from './PocFundOpex';
import Guidance from './Guidance';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const BottomView = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Planned Benefits & Costs</Typography>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RecurringCost {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RecurringBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <OneTimeCost {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <OneTimeBenefit {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <PocFundCapex {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <PocFundOpex {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Guidance content="lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet lorem ipsum dolar simet" />
      </Grid>
    </Grid>
  );
};

export default BottomView;
