import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import FormItem from 'src/components/FormItem';
import UserSelector from 'src/components/UserSelector';
import store from 'src/store';
import SelectMultiFreeText from 'src/components/SelectMultiFreeText';

const ProductOwner = ({
  isEditable,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
}) => {
  const key = 'productOwner';

  const handleChange = (ev, val) => {
    setFieldValue(key, val, true);
    setFieldTouched(key, true, false);
  };

  const handleDelete = (value) => {
    const newValues = values[key].filter((item) => item.memberName !== value);
    setFieldValue(key, newValues, true);
    setFieldTouched(key, true, false);
  };

  return (
    <FormItem
      label="Product Owner"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <SelectMultiFreeText
        alwaysShowOpsi={true}
        multiple={false}
        name={key}
        disabled={!isEditable}
        value={values[key]}
        teamMembers={values.productOwnerOpsi}
        error={Boolean(touched[key] && errors[key])}
        placeholder="Enter Product Owner"
        onBlur={handleBlur}
        onChange={handleChange}
        handleDelete={handleDelete}
      />
    </FormItem>
  );
};

ProductOwner.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ProductOwner;
