import React from 'react';
import PropTypes from 'prop-types';

import api from 'src/api';

import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { useQuery } from 'react-query';
import { CATEGORIES } from 'src/api/backendUrl';

const Category = ({
  values,
  renderDeleteIcon,
  setFilter
}) => {
  const key = 'category';

  const { data: categories } = useQuery(
    [CATEGORIES, { sorts: 'name' }],
    async ({ queryKey }) => {
      const [key, query] = queryKey;
      const { data: response } = await api.masterData.categories(query);

      const transformedData = [{ id: '', key: '', name: 'All Categories' }];
      transformedData.push(...response?.data);

      return transformedData;
    }, { initialData: [{ id: '', key: '', name: 'All Categories' }] }
  );

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  return (
    <FormItem
      id={key}
      label="Category"
    >
      <StyledSelect
        name={key}
        placeholder="Select category"
        valueKey="key"
        value={values[key]}
        options={categories}
        onChange={handleOnChange}
        IconComponent={() => renderDeleteIcon(key, values[key])}
      />
    </FormItem>
  );
};

Category.propTypes = {
  values: PropTypes.object,
  renderDeleteIcon: PropTypes.func,
  setFilter: PropTypes.func
};

export default Category;
