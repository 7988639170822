import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, LinearProgress } from '@material-ui/core';

import api from 'src/api';
import convertToBase64 from 'src/utils/files';

import FormItem from 'src/components/FormItem';
import StyledFilePicker from 'src/components/StyledFilePicker';
import { isValidationRequired } from 'src/utils/validation';

const Attachment = ({
  disabled = false,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  g4implementId,
  validation
}) => {
  const key = 'g4ImplementationAttachments';
  const [isUploading, setIsUploading] = useState(false);

  const uploadFiles = async (attachments) => {
    const body = attachments.map((item) => ({
      ...item,
      codeAttach: 'G4attachment'
    }));
    const { data } = await api.g4implementation.uploadFiles(g4implementId, body);
    return data;
  };

  const deleteFile = async (attachment) => {
    await api.g4implementation.deleteFile(attachment.id);
  };

  const handleFilePicked = async (value) => {
    setIsUploading(true);
    const convertedFiles = await convertToBase64(value);
    const attachments = await uploadFiles(convertedFiles);
    setIsUploading(false);

    const newAttachment = _.unionBy(attachments, values[key], 'name');
    setFieldValue(key, newAttachment, true);
    setFieldTouched(key, true, false);
  };

  const handleFileRemove = async (value) => {
    setIsUploading(true);
    await deleteFile(value);
    setIsUploading(false);

    const newValue = values[key].filter((val) => val.node !== value.node);
    setFieldValue(key, newValue);
    setFieldTouched(key, true);
  };

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="ATTACHMENT"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledFilePicker
        error={Boolean(touched[key] && errors[key])}
        files={values[key]}
        disabled={disabled || isUploading}
        onFilePicked={handleFilePicked}
        onFileRemoved={handleFileRemove}
      />
      {
        isUploading && (
          <Box>
            <LinearProgress />
          </Box>
        )
      }
    </FormItem>
  );
};

Attachment.propTypes = {
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  g4implementId: PropTypes.string
};

export default Attachment;