import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const StyledInput = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
    '& .MuiInputBase-root': {
      borderRadius: 6,
      color: '#1A051D',
      fontFamily: 'SFProText',
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: '20px',
      '& .MuiInputBase-input': {
        height: (props) => (props.height ? props.height : '')
      },
    },
  },
}))((props) => (
  <TextField
    fullWidth
    margin="dense"
    type="text"
    variant="outlined"
    {...props}
  />
));

export default StyledInput;
