import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import { InsertDriveFile, Edit, Delete } from '@material-ui/icons';

import api from 'src/api';
import { BENEFIT_COST } from 'src/api/backendUrl';
import DataTable from 'src/components/DataTable';
import IconButtonConfirmation from 'src/components/IconButtonConfirmation';
import ModalForm from './ModalForm';

const entries = [20, 50, 100, 500, 1000];

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100%'
    }
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  required: {
    color: theme.palette.error.main
  }
}));

const BenefitCostView = (props) => {
  const classes = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSucceed, setSucceed] = useState(false);
  const [showEntries, setShowEntries] = useState(null);
  const [pageSize, setPageSize] = useState(entries[0]);
  const [search, setSearch] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [detail, setDetail] = useState({ ...props });

  const user = useStoreState((state) => state.auth.user);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const {
    tabName,
    currentGate,
    submittedById,
    requested,
    gate
  } = detail;

  const isUserValid = submittedById === user.id
    || requested?.id === user.id;
  const isVisible = (currentGate - Number(tabName.charAt(1))) <= 1 && isUserValid;

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (data?.data?.status === 20001) {
          setNotif({
            message: data?.data?.message || 'There is different assignment composition of each Committee. Please check and confirm among Committee',
            option: {
              variant: 'warning'
            }
          });
        } else {
          if (onSuccess) {
            data.status < 300 && onSuccess(data?.data);
          } else if (data.status < 300 && id) {
            if (location.key === 'default') {
              navigate('/app/innovation-view');
            } else {
              navigate(-1);
            }
          } else {
            navigate('/app/innovation-view');
          }
          setNotif({
            message: `${actionMessage} success`,
            option: {
              variant: 'success'
            }
          });
        }
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const changeFieldValue = ({
    oneTimeBenefit,
    oneTimeCosts,
    recurringBenefit,
    recurringCosts,
    fundOpex,
    fundCapex,
  }) => {
    const { setFieldValue, setFieldTouched } = detail;

    setFieldValue(`${gate}OnetimeBenefit`, oneTimeBenefit, false);
    setFieldTouched(`${gate}OnetimeBenefit`, true, false);

    setFieldValue(`${gate}OnetimeCost`, oneTimeCosts, false);
    setFieldTouched(`${gate}OnetimeCost`, true, false);

    setFieldValue(`${gate}RecurBenefit`, recurringBenefit, false);
    setFieldTouched(`${gate}RecurBenefit`, true, false);

    setFieldValue(`${gate}RecurCost`, recurringCosts, false);
    setFieldTouched(`${gate}RecurCost`, true, false);

    if (detail?.capexKey) {
      setFieldValue(`${detail.capexKey}`, fundCapex, false);
      setFieldTouched(`${detail.capexKey}`, true, false);
    }
    if (detail?.opexKey) {
      setFieldValue(`${detail.opexKey}`, fundOpex, false);
      setFieldTouched(`${detail.opexKey}`, true, false);
    }
  };

  const deleteData = useMutation(
    (dataId) => api.benefitCost.delete(dataId),
    mutateOption('Delete', (data) => {
      changeFieldValue(data);
      setSucceed(true);
    })
  );

  const { data, refetch } = useQuery(BENEFIT_COST, async () => {
    const response = await api.benefitCost.list(id, {
      Filters: `StageGateName@=*${tabName},search==${search}`,
      PageSize: pageSize,
      Page: currentPage
    });

    setTotalData(response?.data.totalData);
    setTotalPage(response?.data.totalPage);
    setSucceed(false);
    return response?.data.errors
      ? []
      : response?.data.data;
  }, { initialData: [] });
  useEffect(() => { refetch(); }, [isSucceed, pageSize, currentPage, search]);

  const tableData = data;
  const tableHeader = [
    {
      key: 'action',
      label: 'Action'
    },
    {
      key: 'documentNumber',
      label: 'Number'
    },
    {
      key: 'benefitCostType',
      label: 'Benefit/Costs'
    },
    {
      key: 'metricType',
      label: 'Metric Type'
    },
    {
      key: 'purpose',
      label: 'Purpose'
    },
    {
      key: 'frequency',
      label: 'Frequency'
    },
    {
      key: 'reportDate',
      label: 'Reporting Date'
    },
    {
      key: 'value',
      label: 'Value (USD)'
    }
  ];

  const showCreate = () => {
    setDetail({ ...props, action: 'create' });
    setIsOpen(true);
  };

  const showDetail = (action, item) => {
    setDetail({ ...detail, ...item, action });
    setIsOpen(true);
  };

  return (
    <Box className={classes.formContainer}>
      <Typography className={classes.title} style={{ marginBottom: 15 }}>
        Benefit & Costs
        {' '}
        {detail.required && (
          <span className={classes.required}>*</span>
        )}
      </Typography>
      <DataTable
        leftComp={(
          <Button
            variant="contained"
            color="secondary"
            disabled={currentGate < Number(tabName.charAt(1))}
            onClick={() => showCreate()}
          >
            Create
          </Button>
        )}
        tableHeader={tableHeader}
        tableData={tableData}
        actionSlot={(item) => (
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={3}>
              <IconButton onClick={() => showDetail('detail', item)}>
                <InsertDriveFile color="secondary" />
              </IconButton>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={3}>
              {
                isVisible && (
                  <IconButton onClick={() => showDetail('edit', item)}>
                    <Edit color="secondary" />
                  </IconButton>
                )
              }
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={3}>
              {
                isVisible && (
                  <IconButtonConfirmation
                    title="Confirmation"
                    textDialog="Are you sure you want to delete ?"
                    textConfirm="Ok"
                    textCancel="Cancel"
                    onConfirm={() => deleteData.mutate(item.id)}
                  >
                    <Delete color="error" />
                  </IconButtonConfirmation>
                )
              }
            </Grid>
          </Grid>
        )}
        {...{
          isVisible,
          entries,
          showEntries,
          setShowEntries,
          pageSize,
          setPageSize,
          setSearch,
          totalData,
          currentPage,
          setCurrentPage,
          totalPage
        }}
      />
      <ModalForm
        {...{
          isVisible,
          detail,
          isOpen,
          setIsOpen,
          setSucceed
        }}
      />
    </Box>
  );
};

export default BenefitCostView;
