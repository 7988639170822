import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { upperFirst } from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useStoreActions } from 'easy-peasy';
import { Formik, Form } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import api from 'src/api';
import { HAKI } from 'src/api/backendUrl';
import NoRegHaki from './NoRegHaki';
import IssuedDate from './IssuedDate';
import ExpiredDate from './ExpiredDate';
import Decision from './Decision';
import Attachment from './Attachment';
import { initialValues, validation } from './Config';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  }
}));

const ModalForm = ({
  detail,
  isOpen,
  setIsOpen,
  setSucceed
}) => {
  const { id } = useParams();
  const setNotif = useStoreActions((action) => action.notif.setNotif);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const { action, innovationGateStatuses, itemData } = detail;

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setIsLoading(true); },
    onSettled: (data) => {
      setIsLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
        }
        setNotif({
          message: `${data?.data?.message?.messageEng}`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const generateForm = (data) => {
    switch (data.action) {
      case 'submit':
      case 'edit':
        return {
          registrationId: id,
          stageId: innovationGateStatuses?.stageId,
          noRegHAKI: data.noRegHAKI,
          issuedDate: moment(data.issuedDate).format('YYYY-MM-DD'),
          expiredDate: moment(data.expiredDate).format('YYYY-MM-DD'),
          attachments: data.attachments,
        };
      default:
        return {};
    }
  };

  const submit = useMutation(
    (form) => api.haki.submit(form),
    mutateOption('Submit', () => {
      setIsOpen(false);
      setSucceed(true);
    })
  );

  const edit = useMutation(
    (form) => api.haki.edit(itemData.id, form),
    mutateOption('Edit', () => {
      setIsOpen(false);
      setSucceed(true);
    })
  );

  const { data: hakiDetail, isFetching } = useQuery(
    [`${HAKI}/details`, { itemData }],
    async () => {
      const { data: response } = await api.haki.detail(itemData.id);

      return { ...response?.data, action } || {};
    }, {
      initialData: {},
      enabled: action === 'edit',
    }
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography variant="h3" className={classes.title}>
              { upperFirst(action) }
              {' '}
              Project HAKI
            </Typography>
            <IconButton style={{ marginLeft: 'auto' }} onClick={() => setIsOpen(false)}>
              <Close className={classes.close} />
            </IconButton>
          </Box>
          <Divider style={{ margin: '15px 0 15px 0' }} />
          {
            isFetching ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
                marginTop={24}
                marginBottom={24}
              >
                <CircularProgress size={24} style={{ alignSelf: 'center' }} />
              </Box>
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues(hakiDetail)}
                initialErrors={Yup.object().shape(validation())}
                isInitialValid={() => Yup.object()
                  .shape(validation())
                  .isValidSync(initialValues(hakiDetail))}
                validationSchema={Yup.object().shape(validation())}
                onSubmit={(values, actions) => {
                  const { action } = values;
                  actions.setSubmitting(true);

                  switch (action) {
                    case 'submit': {
                      submit.mutate(generateForm(values));
                      break;
                    }
                    case 'edit': {
                      edit.mutate(generateForm(values));
                      break;
                    }
                    default:
                      break;
                  }

                  actions.setSubmitting(false);
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  isValid,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  submitForm,
                  values,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <NoRegHaki
                            {...{
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              values
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <IssuedDate
                            {...{
                              errors,
                              touched,
                              handleBlur,
                              setFieldValue,
                              setFieldTouched,
                              values,
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <ExpiredDate
                            {...{
                              errors,
                              touched,
                              handleBlur,
                              setFieldValue,
                              setFieldTouched,
                              values,
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Attachment
                            {...{
                              errors,
                              touched,
                              handleBlur,
                              setFieldValue,
                              setFieldTouched,
                              values,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box mt={5}>
                        <Decision
                          canSubmit={!isSubmitting && isValid}
                          {...{
                            isLoading,
                            setIsOpen,
                            setFieldValue,
                            submitForm,
                            values
                          }}
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            )
          }
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ModalForm.propTypes = {
  detail: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSucceed: PropTypes.func.isRequired
};

export default ModalForm;
