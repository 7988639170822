import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import Category from './Category';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: fade(theme.palette.background.main, 0.2),
    borderRadius: 20,
    padding: 15
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const Filter = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Filter</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box className={classes.filterContainer}>
          <Category {...props} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Filter;
