import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useStoreActions } from 'easy-peasy';

import {
  Box,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '24px',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    padding: 25
  },
  labelTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  title: {
    color: theme.palette.common.black,
    fontWeight: 'bold'
  },
  labelSubtitle: {
    color: theme.palette.secondary.main
  }
}));

const CardView = (props) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const setNotification = useStoreActions((action) => action.notif.setNotif);

  const {
    initiative,
    user,
    innovationGateStatuses,
    yearBuilt,
    registrationNumber,
    organizations,
    category
  } = props;

  const gotoDetail = () => {
    if (innovationGateStatuses?.registrationId) {
      navigate(`/app/registration/${innovationGateStatuses.registrationId}`);

      return;
    }

    setNotification({
      message: 'Cannot find document id, please call your administrator',
      option: {
        variant: 'error'
      }
    });
  };

  const generateStatusColor = (benefitStatus) => {
    switch (true) {
      case benefitStatus === 'Draft':
        return '#D0C9D6';
      case benefitStatus?.includes('Admin'):
        return '#6236FF';
      case benefitStatus?.includes('Waiting'):
        return '#44D7B6';
      case benefitStatus === 'Completed':
        return '#06A137';
      case benefitStatus === 'Send Back':
        return '#F59842';
      case benefitStatus === 'Cancelled':
        return '#F54242';
      case benefitStatus === 'Rejected':
        return '#FA8072';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <Box
      className={classes.card}
      onClick={() => gotoDetail()}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <img
          src="/static/images/bulb.png"
          alt="bulb"
          style={{ width: '24px', height: '24px', marginRight: 10 }}
        />

        <Grid container spacing={4}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 5 }}>
              <Typography className={classes.labelTitle}>
                Initiative Name:
                &nbsp;
              </Typography>
              <Typography className={classes.title}>{initiative}</Typography>
              <Divider flexItem orientation="vertical" style={{ marginLeft: 15, marginRight: 15 }} />
              <Typography className={classes.labelTitle}>
                Submitter Name:
                &nbsp;
              </Typography>
              <Typography className={classes.title}>{user?.fullname}</Typography>
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 5 }}>
              <Grid container item lg md sm xs direction="row">
                <Typography className={classes.labelSubtitle}>
                  Stage:
                  &nbsp;
                </Typography>
                <Typography>{innovationGateStatuses?.stages?.name}</Typography>
              </Grid>

              <Grid container item lg md sm xs direction="row">
                <Typography className={classes.labelSubtitle}>
                  Year:
                  &nbsp;
                </Typography>
                <Typography>{yearBuilt}</Typography>
              </Grid>

              <Grid container item lg md sm xs direction="row">
                <Typography className={classes.labelSubtitle}>
                  Request No:
                  &nbsp;
                </Typography>
                <Typography>{registrationNumber}</Typography>
              </Grid>

              <Grid container item lg md sm xs direction="row">
                <Typography className={classes.labelSubtitle}>
                  Area:
                  &nbsp;
                </Typography>
                <Typography>{organizations?.name}</Typography>
              </Grid>

              <Grid container item lg md sm xs direction="row">
                <Typography className={classes.labelSubtitle}>
                  Category:
                  &nbsp;
                </Typography>
                <Typography>{category}</Typography>
              </Grid>

              <Grid
                container
                item
                lg={3}
                md={3}
                sm={3}
                xs={3}
                direction="row"
                style={{ alignItems: 'flex-start' }}
              >
                <Typography className={classes.labelSubtitle}>
                  Status:
                  &nbsp;
                </Typography>
                <Box
                  style={{
                    backgroundColor: generateStatusColor(innovationGateStatuses?.status),
                    borderRadius: 14,
                    padding: 5,
                    paddingRight: 10,
                    paddingLeft: 10
                  }}
                >
                  <Typography
                    style={{
                      color: '#FFFFFF',
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                  >
                    {innovationGateStatuses?.status}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

CardView.propTypes = {
  initiative: PropTypes.string,
  user: PropTypes.object,
  innovationGateStatuses: PropTypes.object,
  yearBuilt: PropTypes.string,
  registrationNumber: PropTypes.string,
  organizations: PropTypes.object,
  category: PropTypes.string
};

export default CardView;
