import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledSelect from 'src/components/StyledSelect';
import { useQuery } from 'react-query';
import { THEME_BANKS } from 'src/api/backendUrl';
import api from 'src/api';
import { isValidationRequired } from 'src/utils/validation';

const ThemeBank = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  id,
  validation
}) => {
  const key = 'themeBankId';
  const themeBank = useQuery(THEME_BANKS, async () => {
    const { data: response } = await api.masterData.themeBanks({
      Sorts: 'name',
      ...id
        ? { id }
        : {}
    });
    return response?.data;
  }, { initialData: [] });

  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Theme Bank"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledSelect
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        placeholder="Select theme"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={themeBank?.data || []}
      />
    </FormItem>
  );
};

ThemeBank.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  id: PropTypes.string,
  validation: PropTypes.object
};

export default ThemeBank;
