import { action } from 'easy-peasy';

export default {
  filter: null,
  currentPage: 1,
  setFilter: action((state, payload) => {
    state.filter = payload;
  }),
  setCurrentPage: action((state, payload) => {
    state.currentPage = payload;
  })
};
