import {
  thunk,
  action,
  actionOn,
  computed
} from 'easy-peasy';
import api from '../../api';

const initialState = {
  token: undefined,
  refreshToken: undefined,
  user: {},
  error: undefined,
  lastRoute: '/',
  beforeLastRoute: '/'
};

export default {
  ...initialState,
  setToken: action((state, payload) => {
    state.token = payload.token;
    state.refreshToken = payload.refreshToken;
    state.error = initialState.error;
  }),
  setNewToken: action((state, payload) => {
    state.token = payload.token;
  }),
  setError: action((state, payload) => {
    state.error = payload.error;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setLastRoute: action((state, payload) => {
    if (state.lastRoute !== payload && state.beforeLastRoute !== state.lastRoute) {
      state.beforeLastRoute = state.lastRoute;
    }
    state.lastRoute = payload;
  }),
  canAccessMenu: computed((state) => (menu) => state.user?.role?.permissions?.indexOf(menu) > -1),
  login: thunk(async (actions, payload, { getStoreActions }) => {
    const { username, password } = payload;
    getStoreActions().loading.setLoading(true);

    try {
      const loginRes = await api.login(username, password);
      if (loginRes.ok) {
        const { data, message } = loginRes.data;
        const { token, refreshToken } = data;
        actions.setToken({ token, refreshToken });
        actions.setUser(data);
        actions.setError({ error: initialState.error });
        getStoreActions().notif.setNotif({
          message: message.messageEng,
          option: {
            variant: 'success'
          }
        });
      } else {
        const errorMessage = loginRes?.data?.message?.messageEng;
        actions.setError({ error: errorMessage });
        if (!errorMessage) {
          getStoreActions().notif.setNotif({
            message: 'Network Error',
            option: {
              variant: 'error'
            }
          });
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error({ error });
      }
    } finally {
      getStoreActions().loading.setLoading(false);
    }
  }),
  logout: thunk(async (actions, payload, { getState, getStoreActions }) => {
    try {
      getStoreActions().loading.setLoading(true);
      // Clear all filter
      // innovation view
      getStoreActions().innovation.setFilter(null);
      getStoreActions().innovation.setTmpFilter(null);
      getStoreActions().innovation.setFilterParams('InnovationGateStatusesStatus!=draft');
      getStoreActions().innovation.setSort('-updatedAt');
      getStoreActions().innovation.setPageSize(10);

      // innovation repo
      getStoreActions().innovationRepo.setFilter(null);

      // my activity
      getStoreActions().myActivity.setFilter(null);

      // my document
      getStoreActions().myDocument.setFilter(null);

      const { id } = getState().user;
      await api.logout(id);

      getStoreActions().loading.setLoading(false);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error({ error });
      }
    }
  }),
  onLogout: actionOn((actions) => actions.logout,
    (state) => {
      state.token = initialState.token;
      state.refreshToken = initialState.refreshToken;
      state.user = initialState.user;
    }),
};
