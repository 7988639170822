import React from 'react';
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      paddingTop: theme.spacing(1) + 2,
      paddingBottom: theme.spacing(1) + 2,
    },
  },
  formControl: {
    marginTop: theme.spacing(1) + 2,
    '& .MuiSelect-root.Mui-disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
  },
  placeholder: {
    color: '#D0C9D6',
  },
  item: {
    margin: 12,
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '20px',
    '&.Mui-selected': {
      backgroundColor: '#EDEEFD',
      '&:hover': {
        backgroundColor: '#EDEEFD'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const StyledSelect = ({
  error,
  options,
  labelKey = 'name',
  valueKey = 'id',
  placeholder = '',
  containerClass,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth
      error={error}
      variant="outlined"
      className={clsx(classes.formControl, containerClass)}
    >
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          disableAutoFocusItem: true
        }}
        margin="dense"
        displayEmpty
        value={rest.value}
        className={clsx(classes.root, {
          [classes.placeholder]: rest.value === ''
        })}
        {...rest}
      >
        <MenuItem style={{ visibility: 'hidden', height: 0, padding: 0 }} value="" disabled>
          {placeholder}
        </MenuItem>
        {options?.map((option) => (
          <MenuItem
            className={classes.item}
            key={option[valueKey]}
            value={option[valueKey]}
          >
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

StyledSelect.propTypes = {
  containerClass: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  error: PropTypes.bool,
};

export default StyledSelect;
