import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledRadio from 'src/components/StyledRadio';

const DigitalCoach = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
}) => {
  const key = 'isCoach';
  const DigitalCoachOptions = [
    { value: 'Yes' },
    { value: 'No' },
    { value: 'Maybe' },
  ];
  return (
    <FormItem
      label="Need Digital Coach?"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledRadio
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={DigitalCoachOptions}
      />
    </FormItem>
  );
};

DigitalCoach.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default DigitalCoach;
