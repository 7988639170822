import React, { useRef } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Link
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: ({ error }) => ({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(1) + 2}px ${theme.spacing(2)}px`,
    border: error ? '1px solid #f44336' : '1px solid #D7D0DC',
    borderRadius: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  button: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#FBFBFB',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '18px',
  },
  file: {
    color: '#D0C9D6',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  },
  fileExist: {
    color: '#1A051D',
  },
  files: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(1) + 3}px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(1)
  },
  filename: {
    color: '#4895EF',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  },
  remove: {
    color: theme.palette.danger.main,
    height: theme.spacing(1),
    width: theme.spacing(1),
  }
}));

const StyledFilePicker = ({
  error,
  files = [],
  onFilePicked,
  onFileRemoved,
  noWrap = false,
  isInputVisible = true,
  ...rest
}) => {
  const classes = useStyles({ error });
  const ref = useRef();
  const handleFileSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const filePicked = event.target.files;
    const savedFiles = Array.from(filePicked).map((targetfile, index) => {
      const path = URL.createObjectURL(targetfile);
      return {
        node: undefined,
        key: index,
        file: targetfile,
        name: targetfile.name,
        path,
      };
    });
    onFilePicked(savedFiles);
  };
  const handleOpenFile = () => {
    ref.current.click();
  };
  return (
    <Grid container spacing={2}>
      {
        isInputVisible && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              className={clsx(classes.root)}
              onClick={handleOpenFile}
            >
              <Typography className={clsx(classes.file)}>
                no file selected
              </Typography>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                component="label"
                size="small"
                disabled={rest?.disabled}
              >
                CHOOSE
              </Button>
            </Box>
          </Grid>
        )
      }
      <input
        ref={ref}
        accept="image/*,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.rar,.txt,"
        hidden
        multiple
        onChange={(e) => handleFileSelected(e)}
        type="file"
        {...rest}
      />
      {files.length > 0 && files.map((fileitem) => (
        <Grid key={fileitem.id || fileitem.name} item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.files}>
            <Typography noWrap={noWrap} className={classes.filename}>
              {fileitem?.url
                ? (
                  <Link href={fileitem?.url}>
                    {fileitem?.file?.name || fileitem?.name || fileitem?.attachFile || fileitem?.id}
                  </Link>
                )
                : fileitem?.file?.name || fileitem?.name || fileitem?.attachFile || fileitem?.id}
            </Typography>
            {!rest?.disabled && (
              <IconButton onClick={() => onFileRemoved(fileitem)} className={classes.remove}>
                <Cancel />
              </IconButton>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

StyledFilePicker.propTypes = {
  files: PropTypes.array.isRequired,
  onFilePicked: PropTypes.func,
  onFileRemoved: PropTypes.func,
  noWrap: PropTypes.bool,
  isInputVisible: PropTypes.bool,
  error: PropTypes.bool,
};

export default StyledFilePicker;
