import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    height: '100%',
  },
  container: {
    padding: theme.spacing(5),
    backgroundColor: '#F5F6F9',
  },
  title: {
    color: '#1A051D',
    fontFamily: 'SFProDisplay',
    fontSize: 22,
    lineHeight: '28px',
  },
  subtitle: {
    color: '#3F3356',
    fontFamily: 'SFProText',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '18px',
    marginTop: 10,
    textAlign: 'left'
  },
  button: {
    padding: 10,
    fontFamily: 'SFProText',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
    width: '100%',
    margin: 'auto'
  },
  error: {
    backgroundColor: '#FFECEC',
    border: '1px solid #E02020',
    borderRadius: 8,
    height: 46,
    paddingLeft: '4.92%',
    paddingRight: '4.92%',
    color: '#E02020',
    fontFamily: 'SFProText',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginTop: 10,
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      margin: 10
    },

    minWidth: 233,
    maxWidth: 233,
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center'
  },
  innovationValue: {
    color: '#1A051D',
    fontFamily: 'PTSans',
    fontSize: 21,
    lineHeight: '41px',
    fontWeight: 'bold',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  innovationTitle: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 13,
    lineHeight: '18px',
    fontWeight: 'normal',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 5
  },
  boxFilter: {
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.36)',
    marginBottom: 30,
    margin: 'auto',
    width: '100%',
  },
  titleFilter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    alignSelf: 'left',
    cursor: 'pointer',
    marginLeft: 10,
  },
  filter: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
    alignSelf: 'left',
    marginBottom: 10,
  },
  formControl: {
    width: '100%'
  },
  search: {
    width: '100%',
    minWidth: 120,
    margin: 'auto'
  },
  searchIcon: {
    color: theme.palette.common.black,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    borderRadius: 18,
    paddingRight: `calc(0.5em + ${theme.spacing(1)}px)`,
    border: '2px solid #D7D0DC',
  },
  inputInput: {
    paddingLeft: `calc(0.5em + ${theme.spacing(1)}px)`,
    width: '100%',
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  tableContainerMd: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    maxHeight: '40rem',
    '&::-webkit-scrollbar': {
      height: '14px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px',
  },
  tableHyperlink: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '0 16px',
    },
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      backgroundColor: '#EDEEFD',
      color: '#1A051D',
      fontWeight: 'bold',
    },
  },
  inputNumber: {
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'right',
    margin: 'auto'
  },
  contentSearch: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center'
  },
  boxInputNumber: {
    marginLeft: 10,
    borderRadius: 8,
    border: '1px solid #D7D0DC',
    marginRight: 10,
    paddingRight: 10,
    height: 30,
    minWidth: 40,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },
  contentPagination: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  contentShowing: {
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  },
  contentSummary: {
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
  }
}));

export default useStyles;
