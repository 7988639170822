import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import _ from 'lodash';
import { StoreProvider } from 'easy-peasy';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';

global._ = _;

ReactDOM.render((
  <BrowserRouter>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
