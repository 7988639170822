import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledInputAutoSize from 'src/components/StyledInputAutoSize';

const Description = ({
  item,
  index,
  touched,
  errors,
  disabled,
  getIn,
  handleBlur,
  handleChange
}) => {
  const key = 'description';
  const name = `benefits[${index}].${key}`;
  const touch = getIn(touched, name);
  const error = getIn(errors, name);

  return (
    <FormItem
      id={name}
      label=""
      error={Boolean(touch && error)}
      helperText={touch && error}
    >
      <StyledInputAutoSize
        name={name}
        rowsMin={4}
        type="text"
        placeholder="Enter description"
        value={item[key]}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(touch && error)}
      />
    </FormItem>
  );
};

Description.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  getIn: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Description;
