import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid, makeStyles, Typography
} from '@material-ui/core';
import FormItem from 'src/components/FormItem';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    padding: theme.spacing(1)
  },
}));

const CommitteeSuggestionListView = ({
  values,
}) => {
  const classes = useStyles();
  const totalData = values?.length || 0;
  const grid = 12 / totalData;
  const gest = values?.map((d) => d?.ideaBlastSuggestions?.length || 1);
  const maxSuggest = Math.max(...gest);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography>Committee suggestion</Typography>
      </Grid>
      {values?.map((d, i) => {
        const suggestions = d?.ideaBlastSuggestions;
        const suggestion = suggestions.length > 0
          ? suggestions.map((s) => `• ${s?.benefitCategories?.name}`)
          : ['-'];
        const label = `Committee #${i + 1}`;
        return (
          <Grid key={label} item lg={grid} md={grid} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormItem
                label={label}
              >
                <Typography className={classes.value}>
                  {d?.user?.fullname}
                </Typography>
              </FormItem>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormItem
                label="Suggestion Benefit Category"
                id="suggestion"
              >
                <Box style={{ height: maxSuggest * 36 }}>
                  {suggestion.map((suggest) => (
                    <Typography key={suggest} className={classes.value}>
                      {suggest}
                    </Typography>
                  ))}
                </Box>
              </FormItem>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography align="center">
                {d?.isApproved ? 'Processed' : '-'}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

CommitteeSuggestionListView.propTypes = {
  values: PropTypes.array,
};

export default CommitteeSuggestionListView;
