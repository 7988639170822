import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme, IconButton,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  LinearProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import api from 'src/api';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontWeight: 'bold'
  },
  close: {
    color: theme.palette.danger.main
  },
  documentTitle: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    }
  }
}));

const StyledCell = withStyles(() => ({
  head: {
    backgroundColor: '#737AF1',
    color: 'white',
    borderBottomWidth: 10,
    borderBottomColor: '#F5F6F9',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderRightColor: '#FFFFFF',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 120
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  }
}))(TableCell);

const tableHeaders = [
  { key: 'no', label: 'No' },
  { key: 'regisNumber', label: 'No. Registration' },
  { key: 'initiativeName', label: 'Initiative Name' },
  { key: 'category', label: 'Category' },
  { key: 'role', label: 'Role' },
  { key: 'noRegHAKI', label: 'No. Reg. HAKI' },
  { key: 'issuedDate', label: 'Issued Date' },
  { key: 'expiredDate', label: 'Expired Date' },
];

const DocumentDialog = ({
  detail,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const userId = detail?.userId || '';
  const { data: tableData, isFetching } = useQuery(
    ['innovation-involvements/projects', { id: userId }],
    async () => {
      const { data: response } = await api.innovationInvolvement.detail(userId);

      return response?.data || [];
    }, {
      initialData: [],
      enabled: !!userId
    }
  );

  // open project detail in new tab
  const handleOpenProject = (data) => {
    const url = `/app/registration/${data.id}`;
    window.open(url, '_blank');
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      fullScreen={fullScreen}
      open={isOpen}
      className={classes.round}
      PaperProps={{
        style: { borderRadius: '25px' }
      }}
    >
      <DialogContent>
        <Box>
          <Box className={classes.head}>
            <Typography
              variant="h3"
              className={classes.title}
            >
              Detail Project
            </Typography>
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={() => setIsOpen(false)}
            >
              <Close className={classes.close} />
            </IconButton>
          </Box>
        </Box>
        <Box my={5}>
          {
            isFetching && (
              <LinearProgress />
            )
          }
          <Grid container>
            <TableContainer
              component={Paper}
              style={{ maxHeight: '30rem' }}
            >
              <Table
                stickyHeader
                aria-label="simple-table"
              >
                <TableHead>
                  <TableRow>
                    {
                      tableHeaders.map((item, index) => (
                        <StyledCell
                          key={`th-${index + 1}`}
                        >
                          { item.label }
                        </StyledCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    tableData.map((item, trIndex) => (
                      <TableRow
                        key={`tr-${trIndex + 1}`}
                        style={{ marginBottom: '5px' }}
                      >
                        <>
                          {
                            tableHeaders.map(({ key }, thIndex) => (
                              <StyledCell
                                key={`td-${thIndex + 1}`}
                                style={key === 'action'
                                  ? { width: '1rem' }
                                  : {}}
                              >
                                { key === 'no' ? (trIndex + 1) : '' }
                                { key === 'regisNumber' ? (
                                  <Typography
                                    className={classes.documentTitle}
                                    onClick={() => handleOpenProject(item)}
                                  >
                                    {item[key]}
                                  </Typography>
                                ) : '' }
                                { (key !== 'no' && key !== 'regisNumber') ? (item[key] || '-') : '' }
                              </StyledCell>
                            ))
                          }
                        </>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DocumentDialog.propTypes = {
  detail: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default DocumentDialog;
