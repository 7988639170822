import React from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

import PocMilestonesInformation from './PocMilestonesInformation';
import PocResult from './PocResult';
import Risk from './Risk';
import DevOpsTools from '../ThirdView/DevOpsTools';
import ProductBacklog from '../ThirdView/ProductBacklog';
import Attachment from '../ThirdView/Attachment';
import MvpCompletionDate from '../ThirdView/MvpCompletionDate';
import MvpMilestone from '../ThirdView/MvpMilestone';
import MvpTimeframe from '../ThirdView/MvpTimeframe';
import PlannedFeatureMvp from '../ThirdView/PlannedFeatureMvp';
import PlannedImplementationScopeMvp from '../ThirdView/PlannedImplementationScopeMvp';
import MmpTimeframe from '../ThirdView/MmpTimeframe';
import PlannedFeaturesMmp from '../ThirdView/PlannedFeaturesMmp';
import PlannedImplementationScopeMmp from '../ThirdView/PlannedImplementationScopeMmp';
import ScaleUpCompletionDate from '../ThirdView/ScaleUpCompletionDate';
import ScaleUpTimeframe from '../ThirdView/ScaleUpTImeframe';
import ScaleUpFeature from '../ThirdView/ScaleUpFeature';
import ScaleImplementationScope from '../ThirdView/ScaleImplementationScope';
import ScrumMaster from '../ThirdView/ScrumMaster';
import DeveloperTeam from '../SecondView/DeveloperTeam';
import TechnologyLead from '../SecondView/TechnologyLead';
import DataScientist from '../SecondView/DataScientist';
import Solution from '../SecondView/Solution';
import DataEngineer from '../SecondView/DataEngineer';
import UiUxLead from '../SecondView/UiUxLead';
import DataVisualization from '../SecondView/DataVisualization';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderStyle: 'dashed',
    borderWidth: 0.5,
    borderColor: 'silver'
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  }
}));

const FirstView = ({ isDigital, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>POC</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PocMilestonesInformation {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PocResult {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Risk {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ProductBacklog {...props} />
      </Grid>
      {
        isDigital && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DevOpsTools {...props} />
          </Grid>
        )
      }
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Attachment {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>MVP</Typography>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MvpCompletionDate {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <MvpMilestone {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MvpTimeframe {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedFeatureMvp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedImplementationScopeMvp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>MMP</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MmpTimeframe {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedFeaturesMmp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PlannedImplementationScopeMmp {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Scale Up</Typography>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ScaleUpCompletionDate {...props} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <ScaleUpTimeframe {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ScaleUpFeature {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ScaleImplementationScope {...props} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title}>Roles</Typography>
      </Grid>
      {
        !isDigital && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ScrumMaster {...props} />
          </Grid>
        )
      }
      {
        isDigital && (
          <Grid container item spacing={3}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <ScrumMaster {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <DeveloperTeam {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <TechnologyLead {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <DataScientist {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Solution {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <DataEngineer {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <UiUxLead {...props} />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <DataVisualization {...props} />
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

FirstView.propTypes = {
  isDigital: PropTypes.bool
};

export default FirstView;
