import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';

import StyledInput from 'src/components/StyledInput';

const Company = ({
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
}) => {
  const key = 'company';

  return (
    <FormItem
      label="Company"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledInput
        disabled
        type="text"
        placeholder="Enter company"
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
      />
    </FormItem>
  );
};

Company.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Company;
