import React, {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles,
  Paper,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import StyledSelect from 'src/components/StyledSelect';
import clsx from 'clsx';
import ButtonConfirmation from 'src/components/ButtonConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15,
    position: 'flex',
    flexDirection: 'column'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
}));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: theme.palette.secondary.main,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    color: '#FFFFFF',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const TeamMember = ({
  isDialogOpen,
  isSubmitting,
  handleClose,
  handleConfirm,
  data
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [innerData, setInnerData] = useState([]);

  const permissions = [
    { id: 'View', name: 'View' },
    { id: 'Edit', name: 'Edit' }
  ];
  const headers = [
    { key: 'fullname', title: 'Name' },
    { key: 'memberType', title: 'Role' },
    { key: 'permission', title: 'Access' }
  ];

  const onHandlePermissionChange = (dataIndex, value) => {
    setInnerData((prevState) => {
      return prevState.map((prevValue, index) => {
        if (index === dataIndex) {
          return {
            ...prevValue,
            permission: value
          };
        }

        return { ...prevValue };
      });
    });
  };

  const onHandleSave = () => {
    const transformedInnerData = innerData.map(({ id, ...rest }) => ({ ...rest }));

    handleConfirm(transformedInnerData);
  };

  useEffect(() => {
    if (data && data.length) {
      setInnerData(data);
    }
  }, [data]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="team-member-dialog"
      >
        <DialogTitle id="team-member-dialog">
          <Typography
            align="center"
            variant="h2"
          >
            Manage Access
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
          >
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {
                    headers.map((head, headIndex) => (
                      <StyledTableCell
                        align="center"
                        key={head?.key}
                        isFirst={headIndex === 0}
                        isLast={headIndex === (headers.length - 1)}
                      >
                        {head.title}
                      </StyledTableCell>
                    ))
                  }
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.header}>
                {
                  innerData.map((row, rowIndex) => (
                    <StyledTableRow
                      key={`key-row-${rowIndex + 1}`}
                      style={{ marginBottom: '5px' }}
                    >
                      {
                        headers.map((head, headIndex) => (
                          <StyledTableCell
                            key={`key-row-data-${headIndex + 1}`}
                            align="center"
                          >
                            {
                              head.key === 'permission'
                                ? (
                                  <StyledSelect
                                    disabled={row?.memberType === 'Product Owner'}
                                    name="permission"
                                    placeholder="Select Access"
                                    value={row[head.key]}
                                    options={permissions}
                                    onChange={
                                      (ev) => onHandlePermissionChange(rowIndex, ev.target.value)
                                    }
                                  />
                                )
                                : (
                                  <>
                                    {row[head.key]}
                                  </>
                                )
                            }
                          </StyledTableCell>
                        ))
                      }
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <ButtonConfirmation
            isSubmitting={isSubmitting}
            title="Confirmation"
            textDialog="Are you sure you want to Save ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
            onConfirm={onHandleSave}
            className={clsx(classes.button, classes.label)}
          >
            Save
          </ButtonConfirmation>
          <Button
            variant="outlined"
            disabled={isSubmitting}
            onClick={() => {
              handleClose();
            }}
            color="secondary"
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TeamMember.propTypes = {
  isDialogOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  data: PropTypes.array
};

export default TeamMember;
