import { action } from 'easy-peasy'

export default {
  message: undefined,
  option: { variant: 'default' },
  dismissed: false,
  setNotif: action((state, payload) => {
    state.message = payload.message
    state.option = payload.option || { variant: 'default' }
    state.dismissed = false
  }),
  setDismissed: action((state, payload) => {
    state.dismissed = payload
  })
}