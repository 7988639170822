import React from 'react';
import PropTypes from 'prop-types';
import {
  TextareaAutosize,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    '&:disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
    borderRadius: 6,
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    padding: 10,
    width: '100%'
  },
  errorContainer: {
    borderColor: theme.palette.danger.main,
    borderRadius: 6,
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    padding: 10,
    width: '100%'
  }
}));

const StyledInputAutoSize = (props) => {
  const classes = useStyles();
  const { error } = props;

  return (
    <TextareaAutosize
      margin="dense"
      type="text"
      variant="outlined"
      className={error ? classes.errorContainer : classes.container}
      {...props}
    />
  );
};

StyledInputAutoSize.propTypes = {
  error: PropTypes.bool
};

export default StyledInputAutoSize;
