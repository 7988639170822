import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Edit, Delete, DescriptionTwoTone } from '@material-ui/icons';

import api from 'src/api';
import IconButtonConfirmation from 'src/components/IconButtonConfirmation';
import useStyles from 'src/views/innovation/InnovationView/style';
import { HAKI } from 'src/api/backendUrl';
import ModalForm from './ModalForm';

const useFormStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexGrow: '1 1 auto',
    flexDirection: 'column',
    padding: '2rem',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100vw'
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '100%'
    }
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
}));

const HakiView = (props) => {
  const classes = useStyles();
  const classessForm = useFormStyles();
  const StyledCell = withStyles(() => ({
    head: {
      backgroundColor: '#EDEEFD',
      borderBottomWidth: 10,
      borderColor: '#F5F6F9',
      paddingTop: 10,
      paddingBottom: 10,
    },
    body: {
      fontSize: 14,
      backgroundColor: 'white',
      borderTopWidth: 10,
      borderBottomWidth: 10,
      borderColor: '#F5F6F9'
    }
  }))(TableCell);
  const StyledRow = withStyles(() => ({
    root: {
      borderRadius: 5
    }
  }))(TableRow);

  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isSucceed, setSucceed] = useState(false);
  const [detail, setDetail] = useState({ ...props });

  const user = useStoreState((state) => state.auth.user);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const isAdmin = user?.roles?.some((role) => role.name === 'Administrator');
  const {
    gate,
    currentGate,
    userPermissions
  } = detail;
  const isPO = userPermissions?.some((item) => item.id === user?.id);
  const canCreate = (isPO || isAdmin) && currentGate >= 4 && currentGate === Number(gate.charAt(1));
  const canEditOrDelete = isAdmin && currentGate >= 4 && currentGate === Number(gate.charAt(1));

  const { data: tableData, isFetching, refetch } = useQuery(
    HAKI,
    async () => {
      const response = await api.haki.list(id);

      setSucceed(false);
      return response?.data.errors ? [] : response?.data.data;
    }, { initialData: [] }
  );

  useEffect(() => {
    refetch();
  }, [isSucceed]);

  // update detail when props value change
  useEffect(() => {
    setDetail({ ...props });
  }, [props]);

  const tableHeader = [
    ...canEditOrDelete
      ? [{ key: 'action', label: 'Action' }]
      : [],
    {
      key: 'noRegHAKI',
      label: 'No. Reg. HAKI'
    },
    {
      key: 'issuedDate',
      label: 'Issued Date'
    },
    {
      key: 'expiredDate',
      label: 'Expired Date'
    },
    {
      key: 'attachments',
      label: 'Attachment'
    },
    {
      key: 'status',
      label: 'Status'
    },
  ];

  const generateStatusColor = (status) => {
    switch (true) {
      case status && status.toLowerCase().includes('expired'):
        return '#F54242';
      default:
        return '#06a137';
    }
  };

  const showCreate = () => {
    setDetail({ ...props, action: 'create' });
    setIsOpen(true);
  };

  const showEdit = (itemData) => {
    setDetail({ ...props, itemData, action: 'edit' });
    setIsOpen(true);
  };

  const handleDownload = (attachments) => {
    attachments.forEach((file) => {
      window.open(file?.url, '_blank');
    });
  };

  const mutateOption = (actionMessage, onSuccess) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        if (onSuccess) {
          data.status < 300 && onSuccess();
        }
        setNotif({
          message: `${data?.data?.message?.messageEng}`,
          option: {
            variant: 'success'
          }
        });
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });

  const deleteData = useMutation(
    (itemId) => api.haki.delete(itemId),
    mutateOption('Delete', () => {
      setSucceed(true);
    })
  );

  return (
    <Box className={classessForm.formContainer}>
      <Typography className={classessForm.title} style={{ marginBottom: 15 }}>
        HAKI
      </Typography>
      {canCreate && (
        <Grid container spacing={4} style={{ margin: 'auto', marginTop: 15 }}>
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => showCreate()}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      )}
      {
        isFetching && (
          <Box mt={4}>
            <LinearProgress />
          </Box>
        )
      }
      <Grid container style={{ marginTop: isFetching ? 0 : 20 }}>
        {tableData.length || isFetching ? (
          <TableContainer component={Paper} className={classes.tableContainerMd}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple-table"
            >
              <TableHead className={classes.header}>
                <StyledRow>
                  {
                    tableHeader.map((item, index) => (
                      <StyledCell key={`th-${index + 1}`}>
                        {item.label}
                      </StyledCell>
                    ))
                  }
                </StyledRow>
              </TableHead>
              <TableBody className={classes.header}>
                {tableData && tableData?.map((item) => {
                  return (
                    <StyledRow key={item?.id} style={{ marginBottom: '5px' }}>
                      {canEditOrDelete && (
                        <StyledCell
                          align="left"
                        >
                          <Grid container>
                            <Grid item lg={4} md={4} sm={4} xs={3}>
                              {
                                item?.isCurrentStage && (
                                  <IconButton onClick={() => showEdit(item)}>
                                    <Edit color="secondary" />
                                  </IconButton>
                                )
                              }
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={3}>
                              {
                                item?.isCurrentStage && (
                                  <IconButtonConfirmation
                                    title="Confirmation"
                                    textDialog="Are you sure you want to delete this registration's HAKI ?"
                                    textConfirm="Ok"
                                    textCancel="Cancel"
                                    onConfirm={() => deleteData.mutate(item.id)}
                                  >
                                    <Delete color="error" />
                                  </IconButtonConfirmation>
                                )
                              }
                            </Grid>
                          </Grid>
                        </StyledCell>
                      )}
                      <StyledCell
                        align="left"
                      >
                        {item?.noRegHAKI}
                      </StyledCell>
                      <StyledCell
                        align="left"
                      >
                        {moment(item?.issuedDate).format('DD/MM/YYYY')}
                      </StyledCell>
                      <StyledCell
                        align="left"
                      >
                        {moment(item?.expiredDate).format('DD/MM/YYYY')}
                      </StyledCell>
                      <StyledCell
                        align="left"
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleDownload(item?.attachments)}
                        >
                          <Tooltip
                            title={item?.attachments?.length ? (
                              <List
                                sx={{ width: '100%', maxWidth: 360 }}
                                aria-label="attachments"
                                dense
                              >
                                {item?.attachments?.map((attachment) => (
                                  <ListItem key={attachment?.id}>
                                    <Typography style={{ fontSize: 12, color: 'white' }}>
                                      {attachment?.name}
                                    </Typography>
                                  </ListItem>
                                ))}
                              </List>
                            ) : ''}
                            placement="top"
                            arrow
                          >
                            <DescriptionTwoTone style={{ color: '#737AF1' }} />
                          </Tooltip>
                        </IconButton>
                      </StyledCell>
                      <StyledCell
                        align="left"
                      >
                        <Box style={{
                          backgroundColor: generateStatusColor(item?.status),
                          borderRadius: 14,
                          padding: 5,
                          paddingRight: 10,
                          paddingLeft: 10
                        }}
                        >
                          <Typography style={{ color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                            {item?.status}
                          </Typography>
                        </Box>
                      </StyledCell>
                    </StyledRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <Typography className={classessForm.title} style={{ marginBottom: 8 }}>
              Data List Empty
            </Typography>
            <Typography>No data list displayed at the moment. Please create data first.</Typography>
          </Box>
        )}
      </Grid>
      <ModalForm
        {...{
          detail,
          isOpen,
          setIsOpen,
          setSucceed
        }}
      />
    </Box>
  );
};

export default HakiView;
