import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import HyperlinkPopup from '../HyperlinkPopup';

const useStyles = makeStyles((theme) => ({
  bgContainer: {
    backgroundColor: fade(theme.palette.background.main, 0.2),
    borderRadius: 20,
    padding: 15
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    padding: 15
  },
  clickableCard: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    cursor: 'pointer',
    padding: 15
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 'bold'
  },
  subTitle: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 'bold'
  },
  valueTitle: {
    color: theme.palette.common.black,
    fontSize: 24,
    fontWeight: 'bold'
  }
}));

const ProjectStatusView = ({
  year,
  projectStatus,
  valueCapture
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState({ title: '' });

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  const addSpaceInAbbreviation = (value) => {
    return value.replace(/([A-Z])/g, ' $1');
  };

  const openHyperPopup = (title) => {
    setDetail({ title });
    setIsOpen(true);
  };

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: 10 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.bgContainer}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.title}>Project Status</Typography>
              </Grid>
              <Grid item xs={6} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Link href={projectStatus?.linkPortofolio} underline="none" target="_blank">
                  {`${'Detail in IT Portofolio >'}`}
                </Link>
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="column"
              mt={5}
            >
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box
                    onClick={() => openHyperPopup('project status - queue')}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.clickableCard}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <img
                          src={projectStatus?.values[0]?.icon}
                          alt={projectStatus?.values[0]?.alt}
                          style={{ alignSelf: 'flex-end' }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.valueTitle}
                        >
                          {formatter.format(projectStatus?.values[0]?.value || 0)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      className={classes.subTitle}
                      style={{ alignSelf: 'center', marginTop: 15 }}
                    >
                      {projectStatus?.values[0]?.subtext}
                    </Typography>

                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box
                    onClick={() => openHyperPopup('project status - in progress')}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.clickableCard}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <img
                          src={projectStatus?.values[1]?.icon}
                          alt={projectStatus?.values[1]?.alt}
                          style={{ alignSelf: 'flex-end' }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.valueTitle}
                        >
                          {formatter.format(projectStatus?.values[1]?.value || 0)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      className={classes.subTitle}
                      style={{ alignSelf: 'center', marginTop: 15 }}
                    >
                      {projectStatus?.values[1]?.subtext}
                    </Typography>

                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box
                    onClick={() => openHyperPopup('project status - completed')}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.clickableCard}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <img
                          src={projectStatus?.values[2]?.icon}
                          alt={projectStatus?.values[2]?.alt}
                          style={{ alignSelf: 'flex-end' }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.valueTitle}
                        >
                          {formatter.format(projectStatus?.values[2]?.value || 0)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      className={classes.subTitle}
                      style={{ alignSelf: 'center', marginTop: 15 }}
                    >
                      {projectStatus?.values[2]?.subtext}
                    </Typography>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.bgContainer}
          >
            <Typography className={classes.title}>Value Capture</Typography>

            <Box
              display="flex"
              flexDirection="column"
              mt={5}
            >
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.card}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <img
                          src={valueCapture?.values[0]?.icon}
                          alt={valueCapture?.values[0]?.alt}
                          style={{ alignSelf: 'flex-end' }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.valueTitle}
                        >
                          {addSpaceInAbbreviation(
                            formatter.format(valueCapture?.values[0]?.value || 0)
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      className={classes.subTitle}
                      style={{ alignSelf: 'center', marginTop: 15 }}
                    >
                      {valueCapture?.values[0]?.subtext}
                    </Typography>

                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.card}
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <img
                          src={valueCapture?.values[1]?.icon}
                          alt={valueCapture?.values[1]?.alt}
                          style={{ alignSelf: 'flex-end' }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.valueTitle}
                        >
                          {addSpaceInAbbreviation(
                            formatter.format(valueCapture?.values[1]?.value || 0)
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      className={classes.subTitle}
                      style={{ alignSelf: 'center', marginTop: 15 }}
                    >
                      {valueCapture?.values[1]?.subtext}
                    </Typography>

                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <HyperlinkPopup
        {...{
          year,
          detail,
          isOpen,
          setIsOpen
        }}
      />
    </>
  );
};

ProjectStatusView.propTypes = {
  year: PropTypes.string,
  projectStatus: PropTypes.object,
  valueCapture: PropTypes.object,
  values: PropTypes.object
};

export default ProjectStatusView;
