import * as Yup from 'yup';
import { isEmpty } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const validation = Yup.object().shape({
  year: Yup.string(),
  site: Yup.string(),
  descriptionEn: Yup.string()
    .test({
      name: 'descriptionEn',
      // eslint-disable-next-line object-shorthand, func-names
      test: function (value) {
        const { descriptionId } = this.parent;

        if (isEmpty(value) && isEmpty(descriptionId)) {
          return this.createError({
            path: 'descriptionEn',
            message: 'Description (EN) is required'
          });
        }

        return true;
      }
    }),
  descriptionId: Yup.string()
    .test({
      name: 'descriptionId',
      // eslint-disable-next-line object-shorthand, func-names
      test: function (value) {
        const { descriptionEn } = this.parent;

        if (isEmpty(value) && isEmpty(descriptionEn)) {
          return this.createError({
            path: 'descriptionId',
            message: 'Description (ID) is required'
          });
        }

        return true;
      }
    }),
  weight: Yup.number()
    .moreThan(0, 'Weight cannot be zero or less')
    .required('Weight cannot be empty')
});
