import store from 'src/store';
import _ from 'lodash';
import { handleParam, handleQuery } from './handler';
import httpInstance from './httpInstance';

export const request = async (config) => {
  const token = store?.getState().auth.token;
  config.headers = {
    ...config.headers,
    'Cache-Control': 'no-store',
    Authorization: `Bearer ${token}`
  };

  return httpInstance.request(_.pick(config, ['url', 'method', 'data', 'headers', 'onUploadProgress', 'responseType']));
};

export const get = async (url, opts = {}) => {
  const options = {
    method: 'get',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    headers: opts.headers,
    ...opts.responseType && { responseType: opts.responseType }
  };

  return request(options);
};

export const post = async (url, body, opts = {}) => {
  const options = {
    method: 'post',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers,
    ...opts.responseType && { responseType: opts.responseType },
    ...opts.onUploadProgress && { onUploadProgress: opts.onUploadProgress }
  };

  return request(options);
};

export const _delete = async (url, body, opts = {}) => {
  const options = {
    method: 'delete',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers,
  };

  return request(options);
};

export const put = async (url, body, opts = {}) => {
  const options = {
    method: 'put',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers,
  };

  return request(options);
};
