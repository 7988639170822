import React from 'react';
import {
  Chip,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: ({ value }) => ({
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
    borderRadius: 6,
    '& .MuiSelect-select': {
      ...value && value?.length > 0 && {
        paddingTop: theme.spacing(1) + 1,
        paddingBottom: theme.spacing(1) + 1,
      }
    },
  }),
  formControl: {
    marginTop: theme.spacing(1) + 2,
    '& .MuiSelect-root.Mui-disabled': {
      backgroundColor: '#F1F0F6',
      color: '#BEB2C7',
    },
  },
  placeholder: {
    color: '#D0C9D6',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '20px',
  },
  item: {
    margin: 12,
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '20px',
    '&.Mui-selected': {
      backgroundColor: '#EDEEFD',
      '&:hover': {
        backgroundColor: '#EDEEFD'
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  selected: {
    backgroundColor: '#EDEEFD',
    '&:hover': {
      backgroundColor: '#EDEEFD'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 4,
    color: '#6979F8',
    backgroundColor: '#EDEEFD',
    fontFamily: 'SFProText',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: '13px',
    paddingTop: 2,
    paddingBottom: 2,
    height: 'auto'
  },
}));

const StyledMultiSelect = ({
  error,
  options,
  valueFrom = null,
  labelKey = 'name',
  valueKey = 'id',
  handleDelete = () => {},
  value,
  placeholder = '',
  ...rest
}) => {
  const classes = useStyles({ value });
  return (
    <FormControl
      fullWidth
      error={error}
      variant="outlined"
      className={classes.formControl}
    >
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          disableAutoFocusItem: true
        }}
        multiple
        margin="dense"
        displayEmpty
        className={classes.root}
        value={value}
        renderValue={(selected) => (
          <div className={classes.chips}>
            { selected?.length < 1
              ? <Typography className={classes.placeholder}>{placeholder}</Typography>
              : selected?.map(
                (val) => (
                  <Chip
                    key={val}
                    label={options?.find((d) => d.id === val || d[valueKey] === val || (valueFrom ? d[valueFrom] === val : false))?.name || ''}
                    size="small"
                    className={classes.chip}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    disabled={rest?.disabled}
                    deleteIcon={<Close color="primary" />}
                    {...rest?.disabled ? {} : { onDelete: () => handleDelete(val) }}
                  />
                )
              )}
          </div>
        )}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem
            className={clsx(classes.item, {
              [classes.selected]: value?.indexOf(valueKey ? option[valueKey] : option.id) > -1
            })}
            key={`${option[valueKey]}${option[labelKey]}`}
            value={valueFrom ? option[valueFrom] : option.id}
          >
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

StyledMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string,
  valueFrom: PropTypes.string,
  valueKey: PropTypes.string,
  value: PropTypes.array,
  handleDelete: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

export default StyledMultiSelect;
