// Auth
export const LOGIN = '/auth/login';
export const LOGOUT = '/auth/logout';
export const REFRESH_TOKEN = '/auth/refresh';
// Form
export const APPROVE = 'approve';
export const SENTBACK = 'send-back';
export const CANCEL = 'cancel';
export const REMINDER = 'reminder';
export const SAVE_DRAFT = 'save-draft';
export const SUBMIT_DRAFT = 'submit-draft';
export const EDIT_SUBMIT = 'edit-submit';
export const REACTIVATE_FORM = 'reactivate-form';
export const REGISTRATION_APPROVAL = 'g0-approval-history';
// Chat
export const SENDBIRD = 'sendbird';
// Idea Blast
export const APPROVE_IDEA_BLAST = 'approve-idea-blast';
export const ADD_COMMITTEE = 'add-committee';
export const CREATE_IMPLEMENTATION = 'create-implementation';
// Implementation
export const IMPLEMENTATION = 'idea-blast-implementation';
export const IMPLEMENTATION_BENEFIT_CATEGORIES = 'benefit-categories-suggestion';
export const IMPLEMENTATION_SAVE_DRAFT = `${IMPLEMENTATION}/save-draft`;
export const IMPLEMENTATION_EDIT_DRAFT = `${IMPLEMENTATION}/edit-draft`;
export const APPROVAL_HISTORY = 'approval-history';
export const APPROVE_IMPLEMENTATION = 'approve-idea-blast-implementation';
export const SAVE_DRAFT_ASSESSMENT = 'save-assessment-as-draft';
export const EDIT_ASSESSMENT = 'edit-Assessment';
export const CONFIRMATION = 'confirmation';
export const IMPLEMENTATION_REWARD = `${IMPLEMENTATION}/rewards`;
export const IMPLEMENTATION_EXPORT_REWARD = `${IMPLEMENTATION}/rewards-export`;
// Registration
export const REGISTRATION = '/registration-g0';
export const REGISTRATION_SAVE_DRAFT = `${REGISTRATION}/save-as-draft`;
export const REGISTRATION_EDIT_DRAFT = `${REGISTRATION}/edit-draft`;
export const SUMMARY = '/registration-g0/summaries';
export const REGISTRATION_DOWNLOAD = `${REGISTRATION}/excel`;
export const CREATE_G1 = 'create-g1';
// Requirement
export const REQUIREMENT = '/requirement-g1';
export const SUBMIT_G1 = 'submit-g1';
export const G1_APPROVAL_HISTORY = 'g1-approval-history';
export const CREATE_G2 = 'create-g2';
export const APPROVE_CONVENTIONAL = 'approve-conventional';
// Study
export const STUDY = '/study-g2';
export const SUBMIT_G2 = 'submit-g2';
export const STUDY_SAVE_DRAFT = 'edit-draft';
export const G2_APPROVAL_HISTORY = 'g2-approval-history';
export const CREATE_G3 = 'create-g3';
// Prototype
export const PROTOTYPE = '/prototype-g3';
export const SUBMIT_G3 = 'submit-g3';
export const G3_APPROVAL_HISTORY = 'g3-approval-history';
export const CREATE_G4 = 'create-g4';
// Implementation
export const G4_IMPLEMENTATION = '/implement-g4';
export const SUBMIT_G4 = 'submit-g4';
export const G4_APPROVAL_HISTORY = 'g4-approval-history';
export const CREATE_G5 = 'create-g5';
// Master Data
export const MASTER_DATA = '/master-data';
export const COMPANIES = `${MASTER_DATA}/companies`;
export const INNOVATION_AREAS = `${MASTER_DATA}/innovation-areas`;
export const INNOVATION_TYPES = `${MASTER_DATA}/innovation-types`;
export const CLASSIFICATIONS = `${MASTER_DATA}/classifications`;
export const THEME_BANKS = `${MASTER_DATA}/theme-banks`;
export const INNOVATION_STATUSES = `${MASTER_DATA}/innovation-statuses`;
export const BENEFIT_CATEGORIES = `${MASTER_DATA}/benefit-categories`;
export const ALL_BENEFIT_CATEGORY = `${MASTER_DATA}/all-benefit-categories`;
export const NAME_LIST = `${MASTER_DATA}/name-list`;
export const ROLE_LIST = `${MASTER_DATA}/role-list`;
export const ROLES = `${MASTER_DATA}/roles`;
export const ORGANIZATION = `${MASTER_DATA}/organization-list`;
export const ORGANIZATION_LIST = `${MASTER_DATA}/organization-list`;
export const ORGANIZATION_ALL = `${MASTER_DATA}/all-organization-list`;
export const RATE_LIST = `${MASTER_DATA}/rate-list`;
export const COMMITTEE_LIST = 'committee-list';
export const STAGE_LIST = `${MASTER_DATA}/stage-list`;
export const STAGE_GATES = `${MASTER_DATA}/stage-gates`;
export const STATE = `${MASTER_DATA}/state-list`;
export const PROMOTOR_LIST = `${MASTER_DATA}/promotor-list`;
export const FACILITATOR_LIST = `${MASTER_DATA}/facilitator-list`;
export const SITE_IT_LIST = `${MASTER_DATA}/site-it-list`;
export const SITE_CI_LIST = `${MASTER_DATA}/site-ci-list`;
export const SITE_FINANCE_LIST = `${MASTER_DATA}/site-finance-list`;
export const COUNTRY_FINANCE_LIST = `${MASTER_DATA}/country-finance-list`;
export const ADMIN_SITE_LIST = `${MASTER_DATA}/admin-site-list`;
export const YEAR_BUILT = `${MASTER_DATA}/year-built`;
export const QUESTIONS = `${MASTER_DATA}/questions`;
export const GUIDANCE = `${MASTER_DATA}/guidance`;
export const COMMITTEE_LIST_REGISTER = `${MASTER_DATA}/committee-list-registration`;
export const COMMITTEE_LIST_IMPLEMENTATION = `${MASTER_DATA}/committee-list-implementation`;
export const STRATEGIC_PLAN = `${MASTER_DATA}/strategic-plan`;
export const REQUESTED_FOR = `${MASTER_DATA}/requested-list`;
export const CATEGORIES = `${MASTER_DATA}/categories`;
export const DIGITAL_COACH = `${MASTER_DATA}/digital-coach`;
export const BENEFIT_COST_TYPES = `${MASTER_DATA}/benefit-cost-types`;
export const BENEFIT_COST_PURPOSES = `${MASTER_DATA}/purposes`;
export const BENEFIT_COST_FREQUENCIES = `${MASTER_DATA}/frequencies`;
export const BENEFIT_COST_METRIC_TYPES = `${MASTER_DATA}/metric-types`;
export const BENEFIT_COST_STAGE_GATE = `${MASTER_DATA}/stage-gates`;
export const DEMAND_TYPES = `${MASTER_DATA}/demand-types`;
export const FRAMES = `${MASTER_DATA}/iframes`;
//
export const CHANGE_STATE = 'edit-state';
// LOG HISTORY
export const LOG_HISTORY = 'log-history';
export const SCORE_LOG_HISTORY = 'score-log-history';
export const LOG_HISTORY_REMINDER = `${LOG_HISTORY}/reminder`;
// MY ACTIVITY
export const INNOVATION_VIEW = 'innovation-view';
export const MY_ACTIVITY = `${INNOVATION_VIEW}/my-activity`;
export const MY_ACTIVITY_DOWNLOAD = `${MY_ACTIVITY}/export-excel`;
export const MY_DOCUMENT = `${INNOVATION_VIEW}/my-document`;
export const MY_DOCUMENT_DOWNLOAD = `${MY_DOCUMENT}/export-excel`;
// DASHBOARD
export const DASHBOARD = '/dashboard/get';
export const DASHBOARD_IT = '/dashboard/it-demand';
export const PROJECT_STATUS_G0 = `${DASHBOARD}/project-status-g0`;
export const PROJECT_STATUS_G1_COMPLETE = `${DASHBOARD}/project-status-g1-complete`;
export const BENEFIT_CATEGORY = `${DASHBOARD}/benefit-category`;
export const BENEFIT_IMPACT = `${DASHBOARD}/benefit-impact`;
export const BENEFIT_TYPE = `${DASHBOARD}/benefit-type`;
export const TOTAL_INVESTMENT = `${DASHBOARD}/total-investment`;
export const PROJECT_DETAILS = `${DASHBOARD}/project-details`;
export const ECII = `${DASHBOARD}/ecii`;
export const IB_STATUS_OVERVIEW = `${DASHBOARD}/ideablast/status-overview`;
export const IB_BENEFIT_IMPACT = `${DASHBOARD}/ideablast/benefit-impact`;
export const PROJECT_BENEFIT = `${DASHBOARD}/project-benefit`;
export const PROJECT_STATUS_IT_DEMAND = `${DASHBOARD_IT}/project-status`;
export const PROJECT_STATUS_IT_DEMAND_DETAIL = `${DASHBOARD_IT}/project-status-detail`;
export const EXPORT_PROJECT_STATUS_IT_DEMAND = `${DASHBOARD_IT}/export-project-status`;
export const PROJECT_GATE_AGING = `${DASHBOARD}/project-gate-aging`;
export const PROJECT_GATE_AGING_DETAIL = `${DASHBOARD_IT}/project-gate-aging-detail`;
export const EXPORT_PROJECT_GATE_AGING = `${DASHBOARD_IT}/export-project-gate-aging`;
export const PROJECT_TYPE_AREA = `${DASHBOARD_IT}/project-type-area`;
export const PROJECT_TYPE_AREA_DETAIL = `${DASHBOARD_IT}/project-type-area-detail`;
export const EXPORT_PROJECT_TYPE_AREA = `${DASHBOARD_IT}/export-project-type-area`;
export const PROJECT_STATUS_ALL = `${DASHBOARD}/project-status-all`;
export const VALUE_CAPTURE = `${DASHBOARD}/value-capture`;
// USER
// USER MANAGEMENT
export const USER = '/users';
export const ADD_USER = `${USER}/add-user-role`;
export const EDIT_USER_ROLE = 'edit-user-role';
export const USER_HRIS = `${USER}/hris-user-list`;
export const USER_BANPU = `${USER}/ad-user-banpu-th`;
// IDEA BLAST SCORE
export const SCORE = '/score';
export const SCORE_SITE_LIST = `${SCORE}/organization-list`;
export const SCORE_EDIT_REGISTER = `${SCORE}/edit-register-reward`;
export const ADD_SCORE = 'add-score';
export const EDIT_SCORE = 'edit-score';
export const DELETE_SCORE = 'delete-score';
// REGISTRATION BENEFIT CATEGORY
export const REG_BENEFIT_CATEGORY = 'benefit-category';
// BENEFIT COST
export const BENEFIT_COST = 'benefit-cost';
// SCALE UP
export const SCALE_UP = '/scale-up-g5';
export const SUBMIT_G5 = 'submit-g5';
export const SCALE_UP_SAVE_AS_DRAFT = 'save-as-draft';
export const G5_APPROVAL_HISTORY = 'g5-approval-history';
// WEEKLY UPDATE
export const WEEKLY_UPDATE = 'weekly-update';
export const WEEKLY_UPDATE_GET = 'weekly-updatebyId';
export const WEEKLY_UPDATE_MULTIPLE = 'weekly-update-multiple';
export const EXCEL = 'excel';
// IMPACT TRACKING
export const IMPACT_TRACKING = '/impact-tracking';
// INNOVATION REPOSITORY
export const INNOVATION_REPO = '/innovation-repository';
// NOTIFICATION
export const NOTIFICATION = '/notification';
export const MANUAL = `${NOTIFICATION}/manual`;
export const ICON = `${NOTIFICATION}/icon`;
export const READ_NOTIFICATION = `${NOTIFICATION}/read-notification`;
export const WORK_FLOW = '/workflows';
// ITMNOVATION ASSESSMENT
export const ITMNOVATION = '/itmnovation-assessments';
export const ASSESSMENT_LIST = `${ITMNOVATION}/list`;
export const ASSESSMENT_ITM_LEVEL_LIST = `${ITMNOVATION}/itm-level-list`;
export const START_CONVENTION = `${ITMNOVATION}/start-convension`;
export const ASSESSMENT_JUDGES = '/assessment-judges';
// INNOVATION INVOLVEMENT
export const INNOVATION_INVOLVEMENTS = '/innovation-involvements';
export const INNOVATION_INVOLVEMENTS_ROLES = `${INNOVATION_INVOLVEMENTS}/roles`;
// HAKI
export const HAKI = '/hakis';
