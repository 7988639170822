import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '2.36%',
    backgroundImage: 'linear-gradient(135deg, #FFFFFF 0%, #F6F6F8 100%)',
    borderRadius: '0 24px 24px 24px',
    boxShadow: '17px 20px 40px 0 rgba(233, 234, 241, 0.62)',
  },
  container: {
    display: 'block',
    marginBottom: '50px',
    padding: '10px 12px 10px 10px',
  },
  containerChat: {
    display: 'flex',
    height: '400px',
    overflowY: 'scroll',
    flexDirection: 'column',
  },
  containerInput: {
    position: 'fixed',
    width: '100%',
    padding: '15px 10px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0 0 24px 24px',
    bottom: 0,
  },
  title: {
    color: '#1A051D',
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 700,
    fontFamily: 'SFProText',
    lineHeight: '22px'
  },
  input: {
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#FCFCFC',
    border: '1px solid #ECEBED',
    borderRadius: '10px',
    color: '#1A051D',
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '18px'
  },
  stage: {
    alignSelf: 'center',
    width: '90%',
    backgroundColor: '#EDEEFD',
    borderRadius: '10px',
    textAlign: 'center',
    padding: '4px 8px',
    margin: 4,
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '16px',
  },
  dateChat: {
    alignSelf: 'center',
    padding: '5px 10px',
    backgroundColor: '#FFF',
    borderRadius: '9.5px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.07)',
    color: 'rgba(26, 5, 29, 0.50)',
    fontSize: theme.typography.pxToRem(11),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '13px',
    marginTop: 5,
    marginBottom: 5
  },
  chat: {
    display: 'inline-block',
    margin: 3,
    height: 'auto',
    padding: '8px 12px',
    color: '#FFF',
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '18px',
    borderRadius: '8px',
    textAlign: 'right'
  },
  chatFromOther: {
    alignSelf: 'flex-start',
  },
  chatBoxFromOther: {
    backgroundColor: '#737AF1',
    color: '#FFF',
  },
  chatFromSelf: {
    alignSelf: 'flex-end'
  },
  chatBoxFromSelf: {
    backgroundColor: '#FBC80E',
    color: 'rgba(26, 5, 29, 1)',
  },
  sender: {
    marginRight: 6,
    marginLeft: 6,
    marginTop: 6,
    color: '#000',
    fontSize: theme.typography.pxToRem(11),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '13px',
  },
  chatMessage: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(11),
    fontFamily: 'SFProText',
    fontWeight: 500,
    lineHeight: '13px',
    paddingRight: 5,
    textAlign: 'left'
  },
  time: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(9),
    fontFamily: 'SFProText',
    fontWeight: 400,
    lineHeight: '13px',
    right: 0
  }
}));

export default useStyles;
