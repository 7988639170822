import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from 'src/api';
import Page from 'src/components/Page';
import Toolbar from 'src/components/Toolbar';
import _ from 'lodash';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Delete } from '@material-ui/icons';
import NumberFormatCustom from 'src/components/CurrencyFormat';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ButtonConfirmation from 'src/components/ButtonConfirmation';
import header from './header';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    width: '100%',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  header: {
    borderTopLeftRadius: 20,
    whiteSpace: 'nowrap',
    color: '#1A051D',
    fontFamily: 'SFProText',
    fontSize: '13px',
    lineHeight: '18px',
    textAlign: 'left'
  },
  tableContainer: {
    boxShadow: 'none',
    overflowX: 'scroll',
    backgroundColor: '#F5F6F9',
    paddingBottom: 20,
    width: '100%',
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)',
      backgroundColor: '#F5F6F9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8D8D8',
      borderRadius: '5px'
    }
  },
  table: {
    border: 0,
    backgroundColor: '#F5F6F9',
    borderRadius: '20px'
  },
  button: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1),
    color: '#FBFBFB',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  outlined: {
    border: '2px solid #6979F8',
    color: '#6979F8',
    '&:hover': {
      border: '2px solid #6979F8',
    }
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: 'SFProText',
    lineHeight: '18px',
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderTopLeftRadius: ({ isFirst }) => (isFirst ? 10 : 0),
    borderBottomLeftRadius: ({ isFirst }) => (isFirst ? 17 : 0),
    borderTopRightRadius: ({ isLast }) => (isLast ? 10 : 0),
    borderBottomRightRadius: ({ isLast }) => (isLast ? 17 : 0)
  },
  head: {
    backgroundColor: '#EDEEFD',
    borderBottomWidth: 10,
    borderColor: '#F5F6F9',
    paddingTop: 10,
    paddingBottom: 10
  },
  body: {
    fontSize: 14,
    backgroundColor: 'white',
    borderTopWidth: 10,
    borderBottomWidth: 10,
    borderColor: '#F5F6F9'
  },
}))(({ isFirst, isLast, ...props }) => (<TableCell {...props} />));

const StyledTableRow = withStyles(() => ({
  root: {
    borderRadius: 5
  }
}))(TableRow);

const Edit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isLoading = useStoreState((states) => states.loading.isLoading);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const [scores, setScores] = React.useState([]);

  const mutateOption = (actionMessage) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
        navigate('/app/setting/idea-blast/score');
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const updateScore = useMutation((form) => api.setting.ideaBlast.score.edit(id, form), mutateOption('Save score'));

  const { data: detail } = useQuery(['score-organization-detail', id], async () => {
    const { data: response } = await api.setting.ideaBlast.score.detail(id);
    const generatedResponse = response.map((d) => ({
      ...d,
      class: d.class || '',
      prize: d.prize || 0
    }));
    const data = _.sortBy(generatedResponse, ['class']);
    setScores(data);
    return data;
  }, { initialData: [] });

  const handleAdd = () => {
    setScores([...scores, {
      id: uuid(),
      minScore: 0,
      maxScore: 0,
      prize: 0,
      class: '',
      organizationId: id
    }]);
  };
  const handleClear = () => {
    setScores([{
      id: uuid(),
      minScore: 0,
      maxScore: 0,
      prize: 0,
      class: '',
      organizationId: id
    }]);
  };

  const handleEdit = (idScore, key, value) => {
    const newScore = scores.map((d) => (d.id === idScore ? {
      ...d,
      [key]: value
    } : d));
    setScores(newScore);
  };

  const handleDelete = (idScore) => {
    const newScore = scores.filter((d) => d.id !== idScore);
    setScores(newScore);
  };

  const handleSave = () => {
    const newForm = {
      scores: scores.map((d) => {
        const { id: idScore, ...rest } = d;
        return rest;
      })
    };
    updateScore.mutate(newForm);
  };
  const canSubmit = scores.length > 0 && scores?.filter((d) => {
    return Object.values(d).some((o) => !o);
  })?.length === 0;

  return (
    <Page
      className={classes.root}
      title="Idea Blast Score"
    >
      <Container maxWidth={false}>
        <Toolbar
          title="Idea Blast Score"
          path={state?.name || detail[0]?.organizations?.name}
          parentPath={[{ to: '/app/setting/idea-blast/score', name: 'Idea Blast Score' }]}
        />
        <Grid container item style={{ marginTop: 20 }}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead className={classes.header}>
                <StyledTableRow>
                  {header?.map((row, index) => {
                    return (
                      <StyledTableCell
                        align="center"
                        key={row?.field}
                        isFirst={index === 0}
                        isLast={index === (header.length - 1)}
                      >
                        <Box
                          className={classes.sortContainer}
                        >
                          {row.field}
                        </Box>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </TableHead>
              <TableBody className={classes.header}>
                {scores && scores?.map((row) => {
                  return (
                    <StyledTableRow key={row?.id} style={{ marginBottom: '5px' }}>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
                      >
                        <TextField
                          type="number"
                          value={row.minScore}
                          onChange={(e) => handleEdit(row.id, 'minScore', e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                      >
                        <TextField
                          type="number"
                          value={row.maxScore}
                          onChange={(e) => handleEdit(row.id, 'maxScore', e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        <TextField
                          type="text"
                          value={row.class}
                          onChange={(e) => handleEdit(row.id, 'class', e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        <TextField
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                          value={row.prize}
                          onChange={(e) => handleEdit(row.id, 'prize', e.target.value)}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ borderTopRightRadius: 25, borderBottomRightRadius: 25 }}
                      >
                        <IconButton size="small" onClick={() => handleDelete(row.id)}>
                          <Tooltip title="Delete score" placement="top" arrow>
                            <Delete />
                          </Tooltip>
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="flex-end" alignItems="center" width="inherit">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleAdd}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setScores(detail)}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Reset
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClear}
              className={clsx(classes.button, classes.outlined, classes.label)}
            >
              Clear
            </Button>
          </Box>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <ButtonConfirmation
            title="Confirmation"
            textDialog="Are you sure you want to submit ?"
            textConfirm="Ok"
            textCancel="Cancel"
            variant="contained"
            color="secondary"
            onConfirm={handleSave}
            disabled={isLoading || !canSubmit}
            className={clsx(classes.button, classes.label)}
          >
            Save
          </ButtonConfirmation>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/app/setting/idea-blast/score')}
            className={clsx(classes.button, classes.outlined, classes.label)}
          >
            Close
          </Button>
        </Grid>
      </Container>
    </Page>
  );
};

export default Edit;
