import moment from 'moment';
import * as Yup from 'yup';

const initialValues = (detail) => {
  const {
    id,
    action,
    noRegHAKI,
    issuedDate,
    expiredDate,
    attachments,
  } = detail;

  return {
    id,
    action,
    noRegHAKI: noRegHAKI || '',
    issuedDate: issuedDate
      ? moment(issuedDate).toDate()
      : '',
    expiredDate: expiredDate
      ? moment(expiredDate).toDate()
      : '',
    attachments: attachments || [],
  };
};

const validation = () => {
  return {
    issuedDate: Yup.string()
      .test(
        {
          name: 'issuedDate',
          // eslint-disable-next-line object-shorthand, func-names
          test: function (value) {
            const { expiredDate } = this.parent;

            switch (true) {
              case !value:
                return this.createError({
                  path: 'issuedDate',
                  message: 'Issued Date is required'
                });
              case (Boolean(value) && moment(value).isSameOrAfter(moment(expiredDate), 'days')):
                return this.createError({
                  message: `Issued Date should be before ${moment(expiredDate).format('DD/MM/YYYY')}`,
                  path: 'issuedDate'
                });
              default:
                return true;
            }
          }
        }
      ),
    expiredDate: Yup.string()
      .test(
        {
          name: 'expiredDate',
          // eslint-disable-next-line object-shorthand, func-names
          test: function (value) {
            const { issuedDate } = this.parent;

            switch (true) {
              case !value:
                return this.createError({
                  path: 'expiredDate',
                  message: 'Expired Date is required'
                });
              case (Boolean(value) && moment(value).isSameOrBefore(moment(issuedDate), 'days')):
                return this.createError({
                  message: `Expired Date should be after ${moment(issuedDate).format('DD/MM/YYYY')}`,
                  path: 'expiredDate'
                });
              default:
                return true;
            }
          }
        }
      ),
    noRegHAKI: Yup.string().required('No. Reg. HAKI is required'),
    attachments: Yup.array().min(1, 'Attachments is required'),
  };
};

export { initialValues, validation };
