import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@material-ui/core';

import api from 'src/api';
import convertToBase64 from 'src/utils/files';

import FormItem from 'src/components/FormItem';
import StyledFilePicker from 'src/components/StyledFilePicker';
import _ from 'lodash';

const Attachment = ({
  isInputVisible,
  item,
  index,
  touched,
  errors,
  getIn,
  setFieldValue,
  setFieldTouched,
  disabled
}) => {
  const key = 'innovationBenefitAttachments';
  const name = `benefits[${index}].${key}`;
  const touch = getIn(touched, key);
  const error = getIn(errors, key);

  const [isUploading, setUploading] = useState(false);

  const uploadFiles = async (attachments) => {
    const body = attachments.map((attachment) => ({ ...attachment, id: item.id }));
    const { data } = await api.benefitCategory.uploadFiles(item.id, body);
    return data;
  };

  const deleteFile = async (attachment) => {
    await api.benefitCategory.deleteFile(attachment.id);
  };

  const handleFilePicked = async (value) => {
    setUploading(true);
    const convertedFiles = await convertToBase64(value);
    const attachments = await uploadFiles(convertedFiles);
    setUploading(false);

    const newAttachment = _.unionBy(attachments, item[key], 'name');
    setFieldValue(name, newAttachment, true);
    setFieldTouched(name, true, false);
  };

  const handleFileRemove = async (value) => {
    setUploading(true);
    await deleteFile(value);
    setUploading(false);

    const newValue = item[key].filter((val) => val.node !== value.node);
    setFieldValue(name, newValue);
    setFieldTouched(name, true);
  };
  return (
    <FormItem
      id={name}
      label=""
      error={Boolean(touch && error)}
      helperText={touch && error}
    >
      <StyledFilePicker
        noWrap
        name={name}
        isInputVisible={isInputVisible}
        disabled={disabled || isUploading}
        files={item[key]}
        onFilePicked={handleFilePicked}
        onFileRemoved={handleFileRemove}
        error={Boolean(touch && error)}
      />
      {
        isUploading && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )
      }
    </FormItem>
  );
};

Attachment.propTypes = {
  isInputVisible: PropTypes.bool,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getIn: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Attachment;
