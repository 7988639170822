import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Checkbox, FormGroup, FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import api from 'src/api';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FormItem from 'src/components/FormItem';
import RolesPicker from './RolesPicker';
import UserHRISPicker from './UserHrisPicker';

const FormView = ({
  open,
  handleClose,
  refetch,
  user,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoading = useStoreState((state) => state.loading.isLoading);
  const setLoading = useStoreActions((action) => action.loading.setLoading);
  const setNotif = useStoreActions((action) => action.notif.setNotif);
  const [checkbox, setCheckbox] = React.useState(false);

  const mutateOption = (actionMessage) => ({
    onMutate: () => { setLoading(true); },
    onSettled: (data) => {
      setLoading(false);
      if (data && data.status < 300) {
        setNotif({
          message: `${actionMessage} success`,
          option: {
            variant: 'success'
          }
        });
        refetch();
        handleClose();
      } else {
        setNotif({
          message: data?.data?.Message || data?.data?.message?.messageEng || `${actionMessage} failed`,
          option: {
            variant: 'error'
          }
        });
      }
    }
  });
  const addUser = useMutation((form) => api.setting.user.add(form), mutateOption('Add User'));
  const editUserRole = useMutation((form) => api.setting.user.editRole(user?.id, form), mutateOption('Edit User Role'));

  const handleSubmitUser = (values) => {
    if (user) {
      if (values.roles.length > 0) {
        const newForm = {
          userRoles: values.roles.map((d) => ({ roleId: d }))
        };
        editUserRole.mutate(newForm);
      } else {
        setNotif({
          message: 'Update user failed. Need at least 1 role assigned.',
          option: {
            variant: 'error'
          }
        });
      }
    } else {
      const newForm = {
        empId: values.user.empId || "",
        userAD: values.user.userAd,
        userRoles: values.roles.map((d) => ({ roleId: d }))
      };
      addUser.mutate(newForm);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        user: user || {},
        roles: user?.roles?.map((d) => d.id) || []
      }}
      validationSchema={Yup.object().shape({
        user: Yup.object().shape({
          empId: Yup.string().required('User is required')
        }),
        roles: Yup.array().min(1, 'Roles is required')
      })}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        handleSubmitUser(values, () => actions.setSubmitting(false));
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          fullWidth
          onClose={handleClose}
        >
          <DialogTitle id="responsive-dialog-title">{user ? 'Edit Role Form' : 'Add Form'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Please fill input below to ${user ? 'edit user role' : 'add user'}`}
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {!user && (
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox  
                        name="checkbox"
                        checked={checkbox}
                        onChange={(event, val) => {
                          setFieldValue("checkbox", val, true);
                          setFieldTouched("checkbox", true, false);
                          setCheckbox(val);
                        }}
                        />
                      } label="Banpu User (Banpu.co.th)" />
                  </FormGroup>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {user
                  ? (
                    <FormItem label="User">
                      <Box pt={2} px={2}>
                        {user.fullname}
                      </Box>
                    </FormItem>
                  )
                  : (
                    <UserHRISPicker
                      disabled={isLoading}
                      {...{
                        user,
                        errors,
                        checkbox,
                        touched,
                        values,
                        setFieldTouched,
                        setFieldValue,
                        handleBlur,
                      }}
                    />
                  )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <RolesPicker
                  disabled={isLoading}
                  {...{
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldTouched,
                    setFieldValue,
                    handleBlur,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => handleSubmitUser(values)}
              color="secondary"
            >
              {isLoading && <CircularProgress size={24} />}
              {user ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

FormView.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  user: PropTypes.object
};

export default FormView;
