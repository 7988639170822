import React from 'react';
import PropTypes from 'prop-types';
import FormItem from 'src/components/FormItem';
import StyledRadio from 'src/components/StyledRadio';
import { isValidationRequired } from 'src/utils/validation';

const DirectYearlyBasisRecuring = ({
  disabled,
  handleBlur,
  handleChange,
  values,
  touched,
  errors,
  validation
}) => {
  const estimateOptions = [
    { value: 'Yes' },
    { value: 'No' },
  ];
  const key = 'diYearlyRecurring';
  return (
    <FormItem
      required={isValidationRequired(key, validation)}
      label="Yearly Basis Recurring"
      id={key}
      error={Boolean(touched[key] && errors[key])}
      helperText={touched[key] && errors[key]}
    >
      <StyledRadio
        disabled={disabled}
        error={Boolean(touched[key] && errors[key])}
        name={key}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[key]}
        options={estimateOptions}
      />
    </FormItem>
  );
};

DirectYearlyBasisRecuring.propTypes = {
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default DirectYearlyBasisRecuring;
