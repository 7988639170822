import React from 'react';
import PropTypes from 'prop-types';

import FormItem from 'src/components/FormItem';
import StyledMultiSelect from 'src/components/StyledMultiSelect';

const Year = ({
  yearOptions = [],
  values,
  setFilter
}) => {
  const key = 'year';

  const handleOnChange = (ev) => {
    setFilter({
      ...values,
      [key]: ev.target.value
    });
  };

  const handleOnDelete = (val) => {
    const filteredItems = values[key].filter((item) => item !== val);
    setFilter({
      ...values,
      [key]: filteredItems
    });
  };

  return (
    <FormItem
      id={key}
      label="Year"
    >
      <StyledMultiSelect
        name={key}
        placeholder="Select year"
        value={values[key]}
        options={yearOptions}
        onChange={handleOnChange}
        handleDelete={handleOnDelete}
      />
    </FormItem>
  );
};

Year.propTypes = {
  yearOptions: PropTypes.array,
  values: PropTypes.object,
  setFilter: PropTypes.func
};

export default Year;
